import { ModelTypes } from '@zeus';
import { IRouter } from '@aurelia/router';
import { IEventAggregator, bindable } from 'aurelia'
import { IZeusClient, ZeusClient } from '@services/graphql-service/zeus-client';
import { Dropdown, type DropdownOptions, type DropdownInterface } from 'flowbite';
import { AlertModalType } from '@components/alert-modal/events/alert-modal-event';
import { AlertModalComponent } from '@components/alert-modal/alert-modal-component'

export class TableActionsComponent {
  private dropdown: DropdownInterface

  private $targetEl: HTMLElement
  private $triggerEl: HTMLElement

  @bindable project: ModelTypes["project"];
  @bindable deleteCallback: () => Promise<void>;

  constructor(
    @IRouter private router: IRouter,
    @IZeusClient readonly zeusClient: ZeusClient,
    @IEventAggregator readonly ea: IEventAggregator
  ) { }

  attached() {
    this.$targetEl = document.getElementById(`dropdown-${this.project.id}`);

    this.$triggerEl = document.getElementById(`dropdown-${this.project.id}-button-text`);

    const options: DropdownOptions = {
      placement: 'left',
      triggerType: 'click',
      offsetSkidding: 0,
      offsetDistance: 10,
      delay: 300,
    };

    this.dropdown = new Dropdown(this.$targetEl, this.$triggerEl, options);
  }

  private showDropdown(): void {
    this.dropdown.show();
  }

  private hideDropdown(): void {
    this.dropdown.hide();
  }

  private async handleRedirectProject(): Promise<void> {
    switch (this.project.type) {
      case 'image':
        await this.router.load(`/image/${this.project.id}`);
        break;
      case 'text':
        await this.router.load(`/text/${this.project.id}`);
        break;
      case 'post':
        await this.router.load(`/editor/${this.project.id}`);
        break;
      case 'blog':
        await this.router.load(`/editor/${this.project.id}`);
        break;
      case 'print':
        await this.router.load(`/editor/${this.project.id}`);
        break;
      case 'presentation':
        await this.router.load(`/editor/${this.project.id}`);
        break;
      case 'advert':
        await this.router.load(`/editor/${this.project.id}`);
        break;
      case 'course':
        alert('courses')
        break;

      default:
        break;
    }
  }

  async handleDeleteProject(): Promise<void> {
    this.dropdown.hide();

    AlertModalComponent.show({
      event: this.ea,
      payload: {
        label: 'Deletar',
        message: 'Gostaria de deletar projeto?',
        type: AlertModalType.DANGER,
        confirmFunction: async () => {
          await this.deleteCallback()
        }
      }
    });
  }

  detaching() {
    if (!this.$targetEl || !this.$triggerEl) {
      return;
    }

    this.$targetEl.remove();
    this.$triggerEl.remove();

    this.$targetEl = null;
    this.$triggerEl = null;
    this.dropdown = null;
  }
}