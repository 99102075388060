<div>
	<label class="form-label">${label}</label>

	<div class="w-full p-2 border-2 border-dashed border-gray-300 rounded flex items-center justify-center h-60 group">
		<template if.bind="selectedFileId">
			<div class="relative h-full w-full">
				<img src="${'https://uhgcmhbgmvuhtnaryxwr.storage.sa-east-1.nhost.run/v1/files/' + selectedFileId}" class="w-full h-full object-cover rounded" />

				<label for="${elementid}" class="absolute bg-blue-100/75 cursor-pointer group-hover:block h-full hidden p-4 text-center top-0 w-full">
					<div class="flex flex-col justify-center items-center w-full h-full">
						<i class="fas fa-cloud-upload-alt text-4xl text-gray-600"></i>
						<p class="text-gray-600">Trocar Foto</p>
					</div>
				</label>
				<input type="file" class="hidden" id="${elementid}" change.trigger="changeFile($event)" accept="${accept}" />

				<button click.trigger="removeFile()" class="absolute bg-red-500/75 cursor-pointer group-hover:flex h-8 hidden p-2 text-center top-2 right-2 text-white w-8 rounded-full items-center justify-center">
					<i class="fas fa-trash text-sm"></i>
				</button>
			</div>

		</template>
		<template else>
			<label for="${elementid}" class="cursor-pointer text-center">
				<i class="fas fa-cloud-upload-alt text-4xl text-gray-400"></i>
				<p class="text-gray-400">Escolher Foto</p>
			</label>
	</div>

	<input type="file" class="hidden" id="${elementid}" change.trigger="changeFile($event)" accept="${accept}" />
</div>