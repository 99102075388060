<import from='@components/undraw/undraw-component' />
<import from='@components/card-title/card-title-component' />
<import from='@components/file-upload/file-upload-component' />
<import from='@components/card-header/card-header-component' />
<import from='@components/color-picker/color-picker-component' />

<import from='./components/colors-modal/colors-modal-component' />

<section>
  <card-title-component title-id="marcas">
    <div au-slot="actions">
      <div class="flex justify-end gap-3">
        <button type="button" click.trigger="handleUpsertBrand()" class="btn-primary">Salvar marca</button>
      </div>
    </div>
  </card-title-component>

  <div class="flex flex-col lg:flex-row gap-4 mb-6 mt-8">

    <!-- begin:inputs -->
    <div class="card w-full lg:w-[60%] h-fit">
      <card-header-component header-id="marcas" show-popover.bind="false"></card-header-component>

      <form class="p-4" novalidate="novalidate">

        <div class="flex flex-col gap-4 mb-6">

          <div class="flex flex-col sm:flex-row lg:flex-col xl:flex-row gap-4">
            <!-- begin::brandName -->
            <div class="flex-1">
              <label for="brandName" class="form-label">Nome da marca</label>
              <validation-container>
                <input type="text" id="brandName" value.bind="brandName & validate" autocomplete="off" class="form-input" max="50">
              </validation-container>
            </div>
            <!-- end::brandName -->

            <!-- begin::isBrand -->
            <div class="flex-1">
              <div class="flex flex-row justify-between">
                <label for="isBrand" class="form-label">Contexto da marca</label>

                <button data-popover-target="popover-context" data-popover-placement="right" type="button">
                  <svg class="w-4 h-4 mr-2 mb-2 text-gray-400 hover:text-gray-500" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z" clip-rule="evenodd"></path>
                  </svg>
                </button>

              </div>
              <validation-container>
                <select id="isBrand" value.bind="isBrand & validate" class="form-input">
                  <option model.bind="null">Selecione</option>
                  <option repeat.for="item of isBrandData" model.bind="item.value">${item.text}</option>
                </select>
              </validation-container>
            </div>
            <!-- end::isBrand -->
          </div>

          <div class="flex flex-col gap-4">
            <!-- begin::logo -->
            <div class="flex-1">
              <validation-container>
                <file-upload-component id="logo" label="Logo" current-file.two-way="logoFile & validate:'change'" current-file-id.two-way="logoFileId" is-public="true"></file-upload-component>
              </validation-container>
            </div>
            <!-- end::logo -->

            <!-- begin::slogan -->
            <div>
              <label for="slogan" class="form-label">Slogan</label>
              <validation-container>
                <input type="text" id="slogan" value.bind="slogan & validate" autocomplete="off" class="form-input" max="50">
              </validation-container>
            </div>
            <!-- end::slogan -->
          </div>

          <!-- begin::colors -->
          <div>
            <label for="colors" class="form-label">Cores</label>

            <div class="flex gap-3">
              <template repeat.for="color of colors">

                <div click.trigger="showColorsModal(color)" class="inline-flex relative items-center justify-center cursor-pointer text-gray-400 border border-gray-300 dark:text-gray-500 dark:border-gray-600 rounded-md transition-all ease-in hover:scale-[1.02] hover:border-[#cb0c9f] hover:shadow-md group" style.bind="`background-color: ${color.backgroundColor ? color.backgroundColor : ''}; color: ${color.foregroundColor ? color.foregroundColor : 'gray'};`">

                  <template if.bind="color.foregroundColor">
                    <span class="relative px-5 py-2.5 rounded-md">
                      <i class="fa-solid fa-droplet text-lg"></i>
                    </span>
                  </template>
                  <template else>
                    <span class="relative px-5 py-2.5 rounded-md">
                      <i class="fa-solid fa-plus"></i>
                    </span>
                  </template>

                  <span click.trigger="removeColors(color.id)" class="absolute hidden px-1 border rounded-md -top-1 -right-2 z-50 bg-danger-600 shadow-lg shadow-gray-600 text-xs text-white group-hover:block">
                    <i class="fa-solid fa-minus "></i>
                  </span>
                </div>

              </template>

              <template if.bind="colors.length >= 0 && colors.length < 3">
                <div class="flex my-auto">
                  <button click.trigger="addColors()" class="relative inline-flex items-center justify-center p-0.5 overflow-hidden text-sm font-medium text-white rounded-lg bg-primary-700 hover:bg-primary-80 focus:ring-4 focus:outline-none focus:ring-cyan-200 dark:focus:ring-cyan-800">
                    <span class="relative px-5 py-2.5"><i class="fa-solid fa-plus"></i></span>
                  </button>
                </div>
              </template>

            </div>

            <span style="color:red;" if.bind="colorErrors">${colorErrors}</span>

          </div>
          <!-- end::colors -->

          <div class="flex flex-col sm:flex-row lg:flex-col xl:flex-row gap-4">
            <!-- begin::segment -->
            <div class="flex-1">
              <label for="segment" class="form-label">Segmento do negócio</label>
              <validation-container>
                <select id="segment" value.bind="segment & validate" class="form-input">
                  <option model.bind="null">Selecione</option>
                  <option repeat.for="item of segmentData" model.bind="item.value">${item.text}</option>
                </select>
              </validation-container>
            </div>
            <!-- end::segment -->

            <!-- begin::productOrService -->
            <div class="flex-1">
              <label for="productOrService" class="form-label">Produto / Serviço</label>
              <validation-container>
                <select id="productOrService" value.bind="productOrService & validate" class="form-input">
                  <option model.bind="null">Selecione</option>
                  <option repeat.for="item of productOrServiceData" model.bind="item.value">${item.text}</option>
                </select>
              </validation-container>
            </div>
            <!-- end::productOrService -->
          </div>

          <div class="flex flex-col sm:flex-row lg:flex-col xl:flex-row gap-4">
            <!-- begin::fontPrimary -->
            <div class="flex-1">
              <label for="fontPrimary" class="form-label">Font principal</label>
              <validation-container>
                <select id="fontPrimary" value.bind="fontPrimary & validate" class="form-input" change.trigger="updateFont()">
                  <option model.bind="null">Selecione</option>
                  <option repeat.for="item of fontFamilyData" model.bind="item.value">${item.text}</option>
                </select>
              </validation-container>
            </div>
            <!-- end::fontPrimary -->

            <!-- begin::fontSecondary -->
            <div class="flex-1">
              <label for="fontSecondary" class="form-label">Font secundária</label>
              <validation-container>
                <select id="fontSecondary" value.bind="fontSecondary & validate" class="form-input">
                  <option model.bind="null">Selecione</option>
                  <option repeat.for="item of fontFamilyData" model.bind="item.value">${item.text}</option>
                </select>
              </validation-container>
            </div>
            <!-- end::fontSecondary -->
          </div>

          <div class="flex flex-col sm:flex-row lg:flex-col xl:flex-row gap-4">
            <!-- begin::voiceTone -->
            <div class="flex-1">
              <label for="voiceTone" class="form-label">Tom de voz da marca</label>
              <validation-container>
                <select id="voiceTone" value.bind="voiceTone & validate" class="form-input">
                  <option model.bind="null">Selecione</option>
                  <option repeat.for="item of voiceToneData" model.bind="item.value">${item.text}</option>
                </select>
              </validation-container>
            </div>
            <!-- end::voiceTone -->

            <!-- begin::personality -->
            <div class="flex-1">
              <label for="personality" class="form-label">Personalidade</label>
              <validation-container>
                <select id="personality" value.bind="personality & validate" class="form-input">
                  <option model.bind="null">Selecione</option>
                  <option repeat.for="item of personalityData" model.bind="item.value">${item.text}</option>
                </select>
              </validation-container>
            </div>
            <!-- end::personality -->
          </div>

        </div>

        <!-- begin::button -->
        <div class="flex justify-end">
          <button type="button" click.trigger="handleUpsertBrand()" class="btn-primary">Salvar marca</button>
        </div>
        <!-- end::button -->

      </form>
    </div>
    <!-- end:inputs -->

    <!-- begin:outputs -->
    <div class="w-full lg:w-[40%] h-fit">
      <template if.bind="0 !== 0">
        <undraw-component message="Nenhum arquétipo encontrado, Clique em 'Salvar'"></undraw-component>
      </template>
      <template else>

        <!-- begin::instagram -->
        <section>

          <div class="flex flex-col gap-2 bg-white rounded-xl border border-slate-200 max-w-[542px] mx-auto dark:bg-slate-600 dark:text-white">
            <!-- heading -->
            <div class="flex flex-row justify-between items-center p-3">
              <div class="flex flex-row items-center gap-4">
                <img src="https://t3.ftcdn.net/jpg/05/70/71/06/360_F_570710660_Jana1ujcJyQTiT2rIzvfmyXzXamVcby8.jpg" class="rounded-full w-10 h-10 object-cover" alt="user-avatar">
                <span class="flex-1 text-sm font-semibold">${auth.user.displayName}</span>
              </div>
              <i class="fa-solid fa-ellipsis"></i>
            </div>

            <!-- begin::image -->
            <div class="relative w-full max-h-[530px] overflow-clip flex items-center justify-start">
              <div class="mago">
                <div style="background-color:rgba(0,0,0,1); width:1080px; height:1080px; border:1px solid grey; overflow:hidden; position:relative;">

                  <!-- begin::mago -->
                  <div style="position:absolute; left:-262.7040113456368px; top:0.07708840679328288px; width:1195.8306944822866px; height:1079.8458231864136px; transform:rotate(0deg); transform-origin:top left; opacity:1; filter:none;">
                    <div style="width:100%; height:100%; border-radius:0px; border:none; background:url('assets/brand-guide/mago.png'); transform:none; background-size:1196px 1196px; background-position-x:0px; background-position-y:-58px;">
                    </div>
                  </div>
                  <!-- end::mago -->

                  <!-- begin::triangle-orange -->
                  <div style="position:absolute; left:426.86426777756066px; top:1216.6658083651212px; width:853.0631896628706px; height:865.7449755537933px; transform:rotate(-100.6862956871892deg); transform-origin:top left; opacity:1; filter:none;">
                    <svg style.bind="`color: ${shapeOrange}`" xmlns="http://www.w3.org/2000/svg" width="866" height="866" viewBox="0 0 60 60" style="width:100%; height:100%; border-radius:0px; border:none; transform:none;">
                      <polygon fill="currentColor" points="0 0, 60 60, 0 60" />
                    </svg>
                  </div>
                  <!-- end::triangle-orange -->

                  <!-- begin::triangle-blue -->
                  <div style="position:absolute; left:875.9438162335576px; top:679.5677023129341px; width:729.8779567711027px; height:729.8779567711035px; transform:rotate(-146.6124855361586deg); transform-origin:top left; opacity:1; filter:none;">
                    <svg style.bind="`color: ${shapeBlue}`" xmlns="http://www.w3.org/2000/svg" width="730" height="730" viewBox="0 0 60 60" style="width:100%; height:100%; border-radius:0px; border:none; transform:none;">
                      <polygon fill="currentColor" points="0 0, 60 60, 0 60" />
                    </svg>
                  </div>
                  <!-- end::triangle-blue -->

                  <!-- begin::triangle-black -->
                  <div style="position:absolute; left:518.9647016784106px; top:1200.238405502873px; width:948.4502625203438px; height:948.4502625203436px; transform:rotate(-101.171164977266deg); transform-origin:top left; opacity:0.43; filter:none;">
                    <svg xmlns="http://www.w3.org/2000/svg" width="948" height="948" viewBox="0 0 60 60" style="width:100%; height:100%; border-radius:0px; border:none; transform:none;">
                      <polygon fill="rgb(0, 161, 255)" points="0 0, 60 60, 0 60" style="fill: rgb(0, 0, 0);" />
                    </svg>
                  </div>
                  <!-- end::triangle-black -->

                  <!-- begin::square-orange -->
                  <div style="position:absolute; left:-2.025046796916284e-13px; top:557.0000000000007px; width:1080.0000000000002px; height:310.0000000000003px; transform:rotate(0deg); transform-origin:top left; opacity:0.73; filter:none;">
                    <!-- <div style="width:100%; height:100%; border-radius:0px; border:none; background:url('assets/brand-guide/square-orange.svg'); transform:none; background-size:1080px 1080px; background-position-x:0px; background-position-y:0px;"></div> -->
                    <div style.bind="`background-color: ${shapeOrange}`" class="w-full h-[310px]"></div>
                  </div>
                  <!-- end::square-orange -->

                  <!-- begin::text -->
                  <div style="position:absolute; left:69.21133588551008px; top:588.7411205194641px; width:956px; height:162px; transform:rotate(0deg); transform-origin:top left; opacity:1; filter:none;">
                    <div style="position:relative;">
                      <div style="position:absolute; top:-20.000000000000004px; left:-20.000000000000004px; display:none; width:996px; height:202px; background-color:#7ED321; border-radius:20.000000000000004px;"></div>
                      <div ref="titleRef" style.bind="`color: ${textColor}; position:absolute; top:0; left:0; width:956px; white-space:pre-wrap; font-size:80px; text-align:left; text-decoration:none; line-height:1.0000000000000002; letter-spacing:0em; font-style:normal; font-weight:normal; -webkit-text-stroke:0px black;`">nibh tortor id aliquet lectus proin</div>
                    </div>
                  </div>

                  <div style="position:absolute; left:69.21133588551012px; top:765.2870063187669px; width:957px; height:73px; transform:rotate(0deg); transform-origin:top left; opacity:1; filter:none;">
                    <div style="position:relative;">
                      <div style="position:absolute; top:-9px; left:-9px; display:none; width:975px; height:91px; background-color:#7ED321; border-radius:9px;"></div>
                      <div ref="headlineRef" style.bind="`color: ${textColor}; position:absolute; top:0; left:0; width:957px; white-space:pre-wrap; font-size:30px; text-align:left; text-decoration:none; line-height:1.2; letter-spacing:0em; font-style:normal; font-weight:normal; -webkit-text-stroke:0px black;`">nibh tortor id aliquet lectus proin nibh nisl condimentum id venenatis a condimentum vitae sapien pellentesque habitant morbi tristique</div>
                    </div>
                  </div>
                  <!-- end::text -->

                  <!-- begin::rectangle -->
                  <div style="position:absolute; left:-127.50715616010086px; top:920.6648552522513px; width:418.05766887237434px; height:358.3351447477493px; transform:rotate(0deg); transform-origin:top left; opacity:1; filter:none;">
                    <!-- <div style="width:100%; height:100%; border-radius:0px; border:none; background:url('assets/brand-guide/rectangle.svg'); transform:none; background-size:418px 358px; background-position-x:0px; background-position-y:0px;"></div> -->
                    <svg style.bind="`color: ${shapeOrange}`" xmlns="http://www.w3.org/2000/svg" width="418" height="358" viewBox="8 14 84 72" style="width:100%; height:100%; border-radius:0px; border:none; transform:none;">
                      <polygon stroke="currentColor" stroke-width="4" fill="none" points="30.1,84.5 10.2,50 30.1,15.5 69.9,15.5 89.8,50 69.9,84.5" />
                    </svg>
                  </div>
                  <!-- end::rectangle -->

                  <!-- begin::rectangle -->
                  <div style="position:absolute; left:106.49511407000011px; top:984.6648552522513px; width:418.05766887237434px; height:358.3351447477493px; transform:rotate(0deg); transform-origin:top left; opacity:1; filter:none;">
                    <!-- <div style="width:100%; height:100%; border-radius:0px; border:none; background:url('assets/brand-guide/rectangle.svg'); transform:none; background-size:418px 358px; background-position-x:0px; background-position-y:0px;"></div> -->
                    <svg style.bind="`color: ${shapeOrange}`" xmlns="http://www.w3.org/2000/svg" width="418" height="358" viewBox="8 14 84 72" style="width:100%; height:100%; border-radius:0px; border:none; transform:none;">
                      <polygon stroke="currentColor" stroke-width="4" fill="none" points="30.1,84.5 10.2,50 30.1,15.5 69.9,15.5 89.8,50 69.9,84.5" />
                    </svg>
                  </div>
                  <!-- end::rectangle -->

                  <div style="position:absolute; left:68.6794097256942px; top:74.02992343687794px; width:200px; height:100px; transform:rotate(0deg); transform-origin:top left; opacity:1; filter:none;">
                    <template if.bind="currentFile">
                      <div style.bind="`width:100%; height:100%; border-radius:0px; border:none; background:url('${currentFile.file}'); transform:none; background-size:200px 100px; background-position-x:0px; background-position-y:0px; background-size: contain; background-repeat: no-repeat;`"></div>
                    </template>
                    <template else>
                      <template if.bind="logoFileId">
                        <div style.bind="`width:100%; height:100%; border-radius:0px; border:none; background:url('https://uhgcmhbgmvuhtnaryxwr.storage.sa-east-1.nhost.run/v1/files/${logoFileId}'); transform:none; background-size:200px 100px; background-position-x:0px; background-position-y:0px; background-size: contain; background-repeat: no-repeat;`"></div>
                      </template>
                      <template else>
                        <div style="width:100%; height:100%; border-radius:0px; border:none; background:url(assets/images/logo.png); transform:none; background-size:200px 100px; background-position-x:0px; background-position-y:0px; background-size: contain; background-repeat: no-repeat;"></div>
                      </template>
                    </template>
                  </div>

                </div>
              </div>

              <div if.bind="isLoadingRenderedTemplateUrl" class="btn-secondary absolute -translate-y-1/2 -translate-x-1/2 top-2/4 left-1/2 z-30 motion-safe:animate-pulse w-[250px]">Carregando...</div>
            </div>
            <!-- end::image -->

            <!-- icons -->
            <div class="flex justify-between my-2 mx-4">
              <div class="flex space-x-4 items-center">
                <i class="fa-regular fa-heart text-2xl"></i>
                <i class="fa-regular fa-comment text-2xl"></i>
                <i class="fa-regular fa-paper-plane text-2xl"></i>
              </div>
              <i class="fa-regular fa-bookmark text-2xl"></i>
            </div>

            <!-- comentarios -->
            <div class="mb-4 mx-4">
              <div class="flex flex-row gap-4 items-center mb-2 text-sm">
                <img src="assets/images/cropped_logo.png" class="rounded-full w-6 h-6 object-cover border border-gray-200" alt="">
                <p>Curtido por <strong>copymagico</strong> e <strong>outras 1000 pessoas</strong></p>
              </div>

              <template if.bind="!isMore">
                <p class="text-sm line-clamp-2"><strong>${auth.user.displayName}</strong> ${data.result ?? '...'}</p>
                <p class="text-slate-500 text-sm cursor-pointer dark:text-gray-300" click.trigger="more()">mais</p>
              </template>
              <template else>
                <p class="text-sm"><strong>${auth.user.displayName}</strong> ${data.result ?? '...'}</p>
              </template>

              <span class="text-slate-500 text-sm dark:text-gray-300">Ver todos os 1956 comentários</span>
            </div>
          </div>

        </section>
        <!-- end::instagram -->

      </template>

    </div>
    <!-- end:outputs -->
  </div>

  <div data-popover id="popover-context" role="tooltip" class="absolute z-30 invisible inline-block w-64 text-sm text-gray-500 transition-opacity duration-300 bg-white border border-gray-200 rounded-lg shadow-sm opacity-0 dark:text-gray-400 dark:border-gray-600 dark:bg-gray-800">
    <div class="px-3 py-2 bg-gray-100 border-b border-gray-200 rounded-t-lg dark:border-gray-600 dark:bg-gray-700">
      <h3 class="font-semibold text-gray-900 dark:text-white">Sobre o contexto:</h3>
    </div>
    <div class="px-3 py-2 text-xs">
      <p>Uma marca pessoal é aquela onde você ou alguem é literalmente a imagem da empresa. Já a marca corporativa é
        representada pela criação de uma identidade com um propósito. Ex: Netflix, Mcdonalds e etc</p>
    </div>
    <div data-popper-arrow></div>
  </div>

</section>

<colors-modal-component></colors-modal-component>