<import from="./components/credits/credits-component" />
<import from="./components/search-templates/search-templates-component" />
<import from="./components/campaign/campaign-component" />

<import from="@components/language-picker/language-picker-component" />

<header class="p-4">
  <nav class="z-30 card dark:bg-gray-800">

    <div class="px-3 py-3 lg:px-5 lg:pl-3">
      <div class="flex items-center justify-between">

        <div class="flex items-center justify-start">

          <!-- drawer init and show -->
          <button click.trigger="openDrawer()" class="hidden md:inline rounded-full p-2 mr-3 text-gray-600 cursor-pointer hover:text-gray-900 hover:bg-gray-100 dark:text-gray-400 dark:hover:text-white dark:hover:bg-gray-700">
            <i class="fa-solid fa-arrow-left p-1 ${!open && 'rotate-180'}"></i>
          </button>

          <button data-drawer-target="drawer-navigation" data-drawer-show="drawer-navigation" aria-controls="drawer-navigation" aria-expanded="true" class="md:hidden p-2 mr-3 text-gray-600 rounded cursor-pointer hover:text-gray-900 hover:bg-gray-100 dark:text-gray-400 dark:hover:text-white dark:hover:bg-gray-700">
            <svg class="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h6a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clip-rule="evenodd"></path>
            </svg>
          </button>
        </div>

        <div class="flex items-center gap-2">
          <div class="hidden mr-3 -mb-1 sm:block">
            <span></span>
          </div>
          <a if.bind="domain" href.bind="domain" target="_blank" class="btn-primary-small" external>Visitar Site</a>
          <button click.trigger="openCreateProjectDialog()" class="btn-success-small">Criar projeto</button>

          <!-- begin::search-templates-component -->
          <search-templates-component></search-templates-component>
          <!-- end::search-templates-component -->

          <!-- begin::campaign-component -->
          <campaign-component></campaign-component>
          <!-- end::campaign-component -->

          <!-- begin:credits-component -->
          <!-- <div class="hidden md:inline">
            <credits-component credit-balance.bind="zeusClient.userDetails.creditBalance"></credits-component>
          </div> -->
          <!-- end:credits-component -->

          <!-- begin::language-picker -->
          <div class="hidden md:inline">
            <language-picker-component is-open-drawer.bind="false"></language-picker-component>
          </div>
          <!-- end::language-picker -->

          <!-- begin::Togle-theme -->
          <button id="theme-toggle" type="button" class="bg-gray-50 text-gray-500 dark:bg-gray-700 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-600 focus:outline-none focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 rounded-lg text-sm px-3 py-1.5 hidden md:inline" click.trigger="toggleDarkMode()">
            <svg id="theme-toggle-dark-icon" class="${isDark ? 'hidden' : ''} w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
              <path d="M17.293 13.293A8 8 0 016.707 2.707a8.001 8.001 0 1010.586 10.586z"></path>
            </svg>
            <svg id="theme-toggle-light-icon" class="${!isDark ? 'hidden' : ''} w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
              <path d="M10 2a1 1 0 011 1v1a1 1 0 11-2 0V3a1 1 0 011-1zm4 8a4 4 0 11-8 0 4 4 0 018 0zm-.464 4.95l.707.707a1 1 0 001.414-1.414l-.707-.707a1 1 0 00-1.414 1.414zm2.12-10.607a1 1 0 010 1.414l-.706.707a1 1 0 11-1.414-1.414l.707-.707a1 1 0 011.414 0zM17 11a1 1 0 100-2h-1a1 1 0 100 2h1zm-7 4a1 1 0 011 1v1a1 1 0 11-2 0v-1a1 1 0 011-1zM5.05 6.464A1 1 0 106.465 5.05l-.708-.707a1 1 0 00-1.414 1.414l.707.707zm1.414 8.486l-.707.707a1 1 0 01-1.414-1.414l.707-.707a1 1 0 011.414 1.414zM4 11a1 1 0 100-2H3a1 1 0 000 2h1z" fill-rule="evenodd" clip-rule="evenodd"></path>
            </svg>
          </button>
          <!-- end::Togle-theme -->

          <!-- begin::avatar -->
          <div class="flex items-center">
            <div>
              <button id="dropdownUserMenuButton" type="button" class="flex text-sm rounded-full focus:ring-4 focus:ring-gray-300 dark:focus:ring-gray-600">
                <span class="sr-only">Open user menu</span>
                <img class="w-8 h-8 rounded-full" src="${user.avatarUrl ?? 'https://t3.ftcdn.net/jpg/05/70/71/06/360_F_570710660_Jana1ujcJyQTiT2rIzvfmyXzXamVcby8.jpg'}" alt="user_avatar">
              </button>
            </div>

            <div id="dropdownUserMenu" class="w-48 z-30 hidden bg-white divide-y divide-gray-100 rounded-lg shadow dark:bg-gray-700 dark:divide-gray-600" id="dropdown-2" data-popper-placement="bottom" style="position: absolute; inset: 0px auto auto 0px; margin: 0px; transform: translate(1313px, 61px);">
              <ul class="py-2 text-sm text-gray-700 dark:text-gray-200" aria-labelledby="dropdownUserMenuButton">
                <!-- <li class="md:hidden my-3">
                  <credits-component credit-balance.bind="zeusClient.userDetails.creditBalance"></credits-component>
                </li> -->
                <li class="md:hidden">
                  <language-picker-component class="block hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"></language-picker-component>
                </li>
                <li class="md:hidden">
                  <button id="theme-toggle-d" type="button" class="w-full flex gap-2 px-4 py-2 focus:outline-none hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white" click.trigger="toggleDarkMode()">
                    <svg id="theme-toggle-dark-icon" class="${isDark ? 'hidden' : ''} w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                      <path d="M17.293 13.293A8 8 0 016.707 2.707a8.001 8.001 0 1010.586 10.586z"></path>
                    </svg>
                    <svg id="theme-toggle-light-icon" class="${!isDark ? 'hidden' : ''} w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                      <path d="M10 2a1 1 0 011 1v1a1 1 0 11-2 0V3a1 1 0 011-1zm4 8a4 4 0 11-8 0 4 4 0 018 0zm-.464 4.95l.707.707a1 1 0 001.414-1.414l-.707-.707a1 1 0 00-1.414 1.414zm2.12-10.607a1 1 0 010 1.414l-.706.707a1 1 0 11-1.414-1.414l.707-.707a1 1 0 011.414 0zM17 11a1 1 0 100-2h-1a1 1 0 100 2h1zm-7 4a1 1 0 011 1v1a1 1 0 11-2 0v-1a1 1 0 011-1zM5.05 6.464A1 1 0 106.465 5.05l-.708-.707a1 1 0 00-1.414 1.414l.707.707zm1.414 8.486l-.707.707a1 1 0 01-1.414-1.414l.707-.707a1 1 0 011.414 1.414zM4 11a1 1 0 100-2H3a1 1 0 000 2h1z" fill-rule="evenodd" clip-rule="evenodd"></path>
                    </svg>
                    <span>${isDark ? 'Dark' : 'Light'}</span>
                  </button>
                </li>
                <li click.trigger="closeDropdownUserMenu()">
                  <a t="topnav.myAccount" load="my-account" class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white" role="menuitem">My Account</a>
                </li>
                <li>
                  <a t="topnav.signOut" href="#" click.trigger="signOut()" class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white" role="menuitem">Sign out</a>
                </li>
              </ul>
            </div>
          </div>
          <!-- end::avatar -->

        </div>
      </div>
    </div>
  </nav>

</header>