<div class="card h-full">
  <div class="flex flex-col h-full">
    <img class="rounded-t-lg" src="${template.thumbnailFileId ? 'https://uhgcmhbgmvuhtnaryxwr.storage.sa-east-1.nhost.run/v1/files/' + template.thumbnailFileId : 'https://upload.wikimedia.org/wikipedia/commons/thumb/d/df/Family_Portrait.jpg/1024px-Family_Portrait.jpg'}" width="100%" height="" alt="">
    <div class="flex items-center justify-between p-3">
      <div class="flex items-center gap-3 mr-2">

        <h3 class="text-sm font-bold tracking-tight text-gray-900 dark:text-white">${template.name}</h3>
      </div>
    </div>

    <div class="border-y dark:border-gray-700 p-3 grow">
      <p class="text-sm font-normal text-gray-700 dark:text-gray-400 ">${template.description}</p>
    </div>

    <div class="flex flex-wrap justify-between items-center p-3 gap-3">
      <!-- <div class="flex flex-col gap-1 w-fit">
        <div class="flex gap-1">
          <span class="text-xs font-normal text-gray-700 dark:text-gray-400">Preço mercado:</span>
          <span
            class="text-xs font-normal text-gray-700 dark:text-gray-400 line-through text-right">${template.creditPrice
            | creditToBRL | currency:'pt-BR'}</span>
        </div>
        <div class="flex gap-1">
          <span class="text-xs font-normal text-gray-700 dark:text-gray-400">Preçoaa copymagico:</span>
          <span class="text-sm font-bold text-green-500 dark:text-green-400 text-right">R$ 0,00</span>
          <span class="text-sm font-bold text-green-500 dark:text-green-400 text-right">${template.saleCreditPrice | creditToBRL:zeusClient.userDetails.pricingPlan.creditUnitDiscount | currency:'pt-BR'} <span class="text-[11px] text-secondary-600">(${template.saleCreditPrice} copys)</span></span> -->
      <!-- </div>
      </div>  -->

      <button type="button" click.trigger="action()" class="btn-primary" style="width: 100%;">Gerar</button>
    </div>
  </div>
</div>