import { nhost } from 'lib/nhost';
import { IZeusClient, ZeusClient } from '@services/graphql-service/zeus-client';
import { IGenericListConfig } from '@components/generic-list/interfaces/generic-filtered-interface';

interface IMediaProps {
  imageURL: string;
}

export class MediaPage {
  private selectedFiles: Blob[] | File = [];

  constructor(@IZeusClient private zeusClient: ZeusClient) { }

  private config: IGenericListConfig = {
    tableName: 'userMedia',
    streamName: 'userMedia_stream',
    aggregateName: 'userMedia_aggregate',
    columns: ['id', 'fileId', 'updated_at', 'deleted'],
    filters: [],
    data: [],
    total: 0,
    perPage: 9,
    currentPage: 1,
  }

  private async filesChanged(event: Event) {
    if (!event) return;

    if (this.selectedFiles) {
      const fileResult = await nhost.storage.upload({
        file: this.selectedFiles[0],
        bucketId: 'public',
      })

      await this.zeusClient.chain('mutation')({
        insert_userMedia_one: [
          {
            object: {
              fileId: fileResult.fileMetadata.id,
            },
          },
          {
            id: true,
          },
        ]
      })
    }
  }

  async downloadImage(fileId: string) {
    const fileReference = `https://uhgcmhbgmvuhtnaryxwr.storage.sa-east-1.nhost.run/v1/files/${fileId}?${new Date().getTime()}`;

    await this.downloadFile(fileReference)
  }

  async downloadFile(url: string) {
    try {
      const response = await fetch(url);
      const blob = await response.blob();

      const blobUrl = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = blobUrl;
      a.download = 'image.png';
      document.body.appendChild(a);
      a.click();
      a.remove();
    } catch (error) {
      console.error('Error downloading the file:', error);
    }
  }

  async deleteUserMedia(userMediaId: string) {
    await this.zeusClient.chain('mutation')({
      update_userMedia_by_pk: [
        {
          pk_columns: {
            id: userMediaId,
          },
          _set: {
            deleted: true,
          },
        },
        {
          id: true,
        },
      ]
    })
  }

}