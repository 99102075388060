<aside class="flex">

  <div id="sidebar-navigation"
    class="flex flex-col bg-white dark:bg-gray-800 overflow-y-auto h-screen p-5 pt-5 shadow-sm relative duration-500 ${open ? 'w-64' : 'w-20'} hide-scrollbar">

    <template if.bind="!open">
      <img src="assets/images/cropped_logo.png" class="w-10 h-10 mx-auto" alt="logo-cropped" />
    </template>
    <template else>
      <div class="inline-flex">
        <img src="assets/images/logo.png" class="h-auto mt-1.5 duration-500 ${open && 'rotate-[360deg]'}"
          alt="logo-cropped" />
      </div>
    </template>

    <div class="pb-4 flex flex-col">
      <template repeat.for="structureItem of navBarStructure.description">
        <ul
          class="pt-2 mt-2 space-y-1 font-medium ${$index > 0 ? 'border-t border-gray-200 dark:border-gray-700' : ''}">

          <template if.bind="structureItem.type === 'section' ">
            <template if.bind="!open">
              <span
                class="block ${!open && 'text-center'} text-base font-semibold text-gray-500 uppercase dark:text-gray-400 italic">
                ${structureItem.displayName.substring(0, 2)}
              </span>
            </template>
            <template else>
              <span class="pb-2 text-xs font-semibold text-gray-500 uppercase dark:text-gray-400">
                ${structureItem.displayName}
              </span>
            </template>
          </template>

          <template if.bind="structureItem.itens" repeat.for="item of structureItem.itens">

            <template if.bind="item.type === 'dropdown'">
              <button type="button"
                class="line-clamp-1 flex items-center w-full p-2 ${!open && 'justify-center'} text-sm text-gray-900 transition duration-75 rounded-lg group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700"
                aria-controls="dropdown-${item.displayName}" data-collapse-toggle="dropdown-${item.displayName}">
                <i class="${item.icon}"></i>
                <span t="menu.${item.displayName}"
                  class="flex-1 ${!open && 'hidden'} ms-3 text-left rtl:text-right whitespace-nowrap">${item.displayName}</span>
                <svg class="size-2.5 ${!open && 'hidden'}" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                  fill="none" viewBox="0 0 10 6">
                  <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                    d="m1 1 4 4 4-4" />
                </svg>
              </button>

              <ul id="dropdown-${item.displayName}" class="hidden py-2 space-y-2">
                <li repeat.for="dropdownIten of item.itens"
                  class="${dropdownIten.soon ? 'relative pointer-events-none' : ''}">

                  <a load="${routeById(dropdownIten.id)}"
                    class="transition duration-75 line-clamp-1 flex items-center p-2 ${!open && 'justify-center'} focus:outline-none hover:no-underline hover:text-current text-sm text-gray-900 rounded-lg hover:bg-gray-100 group dark:text-gray-200 dark:hover:bg-gray-700">
                    <i class="${dropdownIten.icon}"></i>
                    <span t="menu.${dropdownIten.displayName}" class="ml-3 ${!open && 'hidden'} flex-none"
                      sidebar-toggle-item="">${dropdownIten.displayName}</span>
                  </a>
                  <span if.bind="dropdownIten.soon"
                    class="absolute right-0 -translate-y-7 bg-secondary-600 text-white text-xs font-medium px-2.5 py-0.5 rounded ${!open && 'hidden'}">Em
                    breve</span>
                </li>
              </ul>
            </template>

            <template else>
              <li class="${item.soon ? 'relative pointer-events-none' : ''}">
                <a load="${routeById(item.id)}"
                  class="line-clamp-1 flex items-center p-2 ${!open && 'justify-center'} focus:outline-none hover:no-underline hover:text-current text-sm text-gray-900 rounded-lg hover:bg-gray-100 group dark:text-gray-200 dark:hover:bg-gray-700">
                  <i class="${item.icon}"></i>
                  <span t="menu.${item.displayName}" class="ml-3 ${!open && 'hidden'} flex-none"
                    sidebar-toggle-item="">${item.displayName}</span>
                </a>
                <span if.bind="item.soon"
                  class="absolute right-0 -translate-y-7 bg-secondary-600 text-white text-xs font-medium px-2.5 py-0.5 rounded ${!open && 'hidden'}">Em
                  breve</span>
              </li>
            </template>
          </template>

        </ul>
      </template>

    </div>
  </div>

</aside>