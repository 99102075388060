<section class="mt-0 transition-all duration-200 ease-soft-in-out ps">

	<!-- beggin::header -->
	<div class="pb-56 pt-12 m-4 min-h-[50vh] items-start rounded-xl p-0 relative overflow-hidden flex bg-cover bg-center bg-[url('assets/images/socialmedia3.png')]">
		<span class="absolute top-0 left-0 w-full h-full bg-center bg-cover opacity-60 bg-gradient-to-tl from-gray-900 to-slate-800"></span>
		<div class="w-full mx-auto px-6 z-50">
			<div class="flex flex-wrap justify-center -mx-3">
				<div class="w-full max-w-full px-3 mx-auto text-center shrink-0 lg:flex-0 lg:w-7/12">
					<h1 class="mb-3 text-white text-5xl font-bold">Bem vindo!</h1>
					<!-- <p class="text-white text-base">Use esses formulários incríveis para fazer login ou criar uma nova conta em seu projeto gratuitamente.</p> -->
				</div>
			</div>
		</div>
	</div>
	<!-- end::header -->

	<!-- beggin::card -->
	<div class="w-full mx-auto px-6">
		<div class="flex flex-wrap justify-center -mx-3 -mt-60 lg:-mt-56 md:-mt-56">
			<div class="w-full max-w-full px-3 mx-auto shrink-0 md:flex-0 md:w-7/12 lg:w-5/12 xl:w-4/12">

				<div class="relative z-0 flex flex-col min-w-0 break-words bg-white dark:bg-gray-800 shadow-gray-300 shadow-soft-xl dark:shadow-current border-0 border-solid border-gray-200 dark:border-gray-700 py-4 rounded-2xl bg-clip-border">

					<!-- beggin::tab -->
					<div class="inline-flex rounded-md justify-center px-6 pb-6" role="group">
						<button type="button" class="inline-flex items-center justify-center min-w-[150px] px-4 py-3 text-sm font-medium text-blue-700 bg-gray-100 border border-gray-200 rounded-l-lg hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-blue-500 dark:focus:text-white">
							<i class="fa-solid fa-right-to-bracket text-sm mr-2"></i>
							Entrar
						</button>
						<a href="/register" type="button" class="inline-flex items-center justify-center min-w-[150px] px-4 py-3 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-r-md hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-blue-500 dark:focus:text-white">
							<i class="fa-solid fa-user-plus text-sm mr-2"></i>
							Cadastro
						</a>
					</div>
					<!-- end::tab -->

					<!-- begin::login social -->
					<div class="flex justify-center items-center flex-wrap px-4 -mx-3 sm:px-6 xl:px-12">
						<div class="w-6/12 md:w-3/12 max-w-full px-1 justify-center">
							<a click.trigger="handleLoginWithGoogle()" class="flex justify-center w-full px-7 py-2 mb-2 font-bold text-center text-gray-200 uppercase align-middle transition-all bg-transparent border border-gray-200 border-solid rounded-lg shadow-none cursor-pointer hover:scale-102 leading-pro text-xs ease-soft-in tracking-tight-soft bg-150 bg-x-25 hover:bg-transparent hover:opacity-75" href="javascript:;">
								<svg width="24px" height="32px" viewBox="0 0 64 64" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
									<g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
										<g transform="translate(3.000000, 2.000000)" fill-rule="nonzero">
											<path d="M57.8123233,30.1515267 C57.8123233,27.7263183 57.6155321,25.9565533 57.1896408,24.1212666 L29.4960833,24.1212666 L29.4960833,35.0674653 L45.7515771,35.0674653 C45.4239683,37.7877475 43.6542033,41.8844383 39.7213169,44.6372555 L39.6661883,45.0037254 L48.4223791,51.7870338 L49.0290201,51.8475849 C54.6004021,46.7020943 57.8123233,39.1313952 57.8123233,30.1515267" fill="#4285F4"></path>
											<path d="M29.4960833,58.9921667 C37.4599129,58.9921667 44.1456164,56.3701671 49.0290201,51.8475849 L39.7213169,44.6372555 C37.2305867,46.3742596 33.887622,47.5868638 29.4960833,47.5868638 C21.6960582,47.5868638 15.0758763,42.4415991 12.7159637,35.3297782 L12.3700541,35.3591501 L3.26524241,42.4054492 L3.14617358,42.736447 C7.9965904,52.3717589 17.959737,58.9921667 29.4960833,58.9921667" fill="#34A853"></path>
											<path d="M12.7159637,35.3297782 C12.0932812,33.4944915 11.7329116,31.5279353 11.7329116,29.4960833 C11.7329116,27.4640054 12.0932812,25.4976752 12.6832029,23.6623884 L12.6667095,23.2715173 L3.44779955,16.1120237 L3.14617358,16.2554937 C1.14708246,20.2539019 0,24.7439491 0,29.4960833 C0,34.2482175 1.14708246,38.7380388 3.14617358,42.736447 L12.7159637,35.3297782" fill="#FBBC05"></path>
											<path d="M29.4960833,11.4050769 C35.0347044,11.4050769 38.7707997,13.7975244 40.9011602,15.7968415 L49.2255853,7.66898166 C44.1130815,2.91684746 37.4599129,0 29.4960833,0 C17.959737,0 7.9965904,6.62018183 3.14617358,16.2554937 L12.6832029,23.6623884 C15.0758763,16.5505675 21.6960582,11.4050769 29.4960833,11.4050769" fill="#EB4335"></path>
										</g>
									</g>
								</svg>
							</a>
						</div>
					</div>
					<!-- end::login social -->

					<!-- begin::or -->
					<div class="flex items-center w-full max-w-full px-20 mt-2 text-center shrink-0">
						<div class="w-full bg-gradient-to-r from-transparent via-neutral-500/40 to-neutral-500/40 right-2 -ml-1/2 inline-block w-3/10 h-px relative align-middle"></div>
						<div class="text-center px-4 text-slate-400 text-sm leading-normal font-semibold">or</div>
						<div class="w-full left-2 -mr-1/2 bg-gradient-to-r from-neutral-500/40 via-neutral-500/40 to-transparent inline-block w-3/10 h-px relative align-middle"></div>
					</div>
					<!-- end::or -->

					<div class="flex-auto px-6 py-4 text-start">
						<form class="space-y-4" novalidate="novalidate" submit.trigger="signInWithEmailAndPassword()">
							<div>
								<label t="login.yourEmail" for="email" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Your email</label>
								<validation-container>
									<input type="email" name="email" id="email" autocomplete="off" value.bind="email & validate" class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="name@company.com" required="">
								</validation-container>
							</div>
							<div>
								<label t="login.password" for="password" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Password</label>
								<validation-container>
									<input type="password" name="password" id="password" placeholder="••••••••" autocomplete="off" value.bind="password & validate" class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required="">
								</validation-container>
							</div>
							<div class="flex items-center justify-end">

								<a t="login.forgotPassword" href="/recover-password" class="text-sm font-medium text-primary-600 hover:underline dark:text-primary-500">Forgot password?</a>
							</div>

							<!-- beggin::button -->
							<button type="submit" click.trigger="signInWithEmailAndPassword()" class="btn-primary w-full">
								<span t="login.signIn" hidden.class="isLoading">Sign In</span>

								<span hidden.class="!isLoading">
									<span t="login.pleaseWait">Please wait...</span>
									<svg aria-hidden="true" role="status" class="inline w-4 h-4 mr-3 text-white animate-spin" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
										<path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="#E5E7EB" />
										<path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentColor" />
									</svg>
								</span>
							</button>
							<!-- end::button -->

							<p class="text-sm font-light text-gray-500 dark:text-gray-400">
								<span t="login.dontHaveAccount">Don’t have an account yet? </span> <span><a t="login.createAccount" href="/register" class="font-medium text-primary-600 hover:underline dark:text-primary-500">Sign up</a></span>
							</p>
						</form>
					</div>
				</div>
			</div>
		</div>
	</div>
	<!-- end::card -->

</section>

<!-- beggin::footer -->
<footer class="py-12">
	<div class="w-full mx-auto px-6">
		<div class="flex flex-wrap -mx-3">
			<div class="w-8/12 max-w-full px-3 mx-auto mt-1 text-center flex-0">
				<p class="mb-0 text-slate-400">
					Copyright ©
					<script>document.write(new Date().getFullYear())</script>2023
					Todos os direitos reservados - copymagico.
				</p>
			</div>
		</div>
	</div>
</footer>
<!-- end::footer -->