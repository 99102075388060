/* eslint-disable */

export const AllTypesProps: Record<string,any> = {
	AppIntegration:{
		configParams:{

		}
	},
	AppIntegration_aggregate_fields:{
		count:{
			columns:"AppIntegration_select_column"
		}
	},
	AppIntegration_append_input:{
		configParams:"jsonb"
	},
	AppIntegration_bool_exp:{
		_and:"AppIntegration_bool_exp",
		_not:"AppIntegration_bool_exp",
		_or:"AppIntegration_bool_exp",
		configParams:"jsonb_comparison_exp",
		description:"String_comparison_exp",
		icon:"String_comparison_exp",
		id:"uuid_comparison_exp",
		name:"String_comparison_exp",
		type:"String_comparison_exp"
	},
	AppIntegration_constraint: "enum" as const,
	AppIntegration_delete_at_path_input:{

	},
	AppIntegration_delete_elem_input:{

	},
	AppIntegration_delete_key_input:{

	},
	AppIntegration_insert_input:{
		configParams:"jsonb",
		id:"uuid"
	},
	AppIntegration_on_conflict:{
		constraint:"AppIntegration_constraint",
		update_columns:"AppIntegration_update_column",
		where:"AppIntegration_bool_exp"
	},
	AppIntegration_order_by:{
		configParams:"order_by",
		description:"order_by",
		icon:"order_by",
		id:"order_by",
		name:"order_by",
		type:"order_by"
	},
	AppIntegration_pk_columns_input:{
		id:"uuid"
	},
	AppIntegration_prepend_input:{
		configParams:"jsonb"
	},
	AppIntegration_select_column: "enum" as const,
	AppIntegration_set_input:{
		configParams:"jsonb",
		id:"uuid"
	},
	AppIntegration_stream_cursor_input:{
		initial_value:"AppIntegration_stream_cursor_value_input",
		ordering:"cursor_ordering"
	},
	AppIntegration_stream_cursor_value_input:{
		configParams:"jsonb",
		id:"uuid"
	},
	AppIntegration_update_column: "enum" as const,
	AppIntegration_updates:{
		_append:"AppIntegration_append_input",
		_delete_at_path:"AppIntegration_delete_at_path_input",
		_delete_elem:"AppIntegration_delete_elem_input",
		_delete_key:"AppIntegration_delete_key_input",
		_prepend:"AppIntegration_prepend_input",
		_set:"AppIntegration_set_input",
		where:"AppIntegration_bool_exp"
	},
	Boolean_comparison_exp:{

	},
	CourseCategory_aggregate_fields:{
		count:{
			columns:"CourseCategory_select_column"
		}
	},
	CourseCategory_bool_exp:{
		_and:"CourseCategory_bool_exp",
		_not:"CourseCategory_bool_exp",
		_or:"CourseCategory_bool_exp",
		id:"bigint_comparison_exp",
		name:"String_comparison_exp"
	},
	CourseCategory_constraint: "enum" as const,
	CourseCategory_inc_input:{
		id:"bigint"
	},
	CourseCategory_insert_input:{
		id:"bigint"
	},
	CourseCategory_on_conflict:{
		constraint:"CourseCategory_constraint",
		update_columns:"CourseCategory_update_column",
		where:"CourseCategory_bool_exp"
	},
	CourseCategory_order_by:{
		id:"order_by",
		name:"order_by"
	},
	CourseCategory_pk_columns_input:{
		id:"bigint"
	},
	CourseCategory_select_column: "enum" as const,
	CourseCategory_set_input:{
		id:"bigint"
	},
	CourseCategory_stream_cursor_input:{
		initial_value:"CourseCategory_stream_cursor_value_input",
		ordering:"cursor_ordering"
	},
	CourseCategory_stream_cursor_value_input:{
		id:"bigint"
	},
	CourseCategory_update_column: "enum" as const,
	CourseCategory_updates:{
		_inc:"CourseCategory_inc_input",
		_set:"CourseCategory_set_input",
		where:"CourseCategory_bool_exp"
	},
	Course_aggregate_fields:{
		count:{
			columns:"Course_select_column"
		}
	},
	Course_bool_exp:{
		_and:"Course_bool_exp",
		_not:"Course_bool_exp",
		_or:"Course_bool_exp",
		courseCategoryId:"bigint_comparison_exp",
		description:"String_comparison_exp",
		id:"uuid_comparison_exp",
		imageUrl:"String_comparison_exp",
		name:"String_comparison_exp"
	},
	Course_constraint: "enum" as const,
	Course_inc_input:{
		courseCategoryId:"bigint"
	},
	Course_insert_input:{
		courseCategoryId:"bigint",
		id:"uuid"
	},
	Course_on_conflict:{
		constraint:"Course_constraint",
		update_columns:"Course_update_column",
		where:"Course_bool_exp"
	},
	Course_order_by:{
		courseCategoryId:"order_by",
		description:"order_by",
		id:"order_by",
		imageUrl:"order_by",
		name:"order_by"
	},
	Course_pk_columns_input:{
		id:"uuid"
	},
	Course_select_column: "enum" as const,
	Course_set_input:{
		courseCategoryId:"bigint",
		id:"uuid"
	},
	Course_stream_cursor_input:{
		initial_value:"Course_stream_cursor_value_input",
		ordering:"cursor_ordering"
	},
	Course_stream_cursor_value_input:{
		courseCategoryId:"bigint",
		id:"uuid"
	},
	Course_update_column: "enum" as const,
	Course_updates:{
		_inc:"Course_inc_input",
		_set:"Course_set_input",
		where:"Course_bool_exp"
	},
	ExportToWordpressInput:{
		scheduledDate:"date"
	},
	GenerateFromTextTemplateInput:{
		selectedParameters:"StringKeyValuePair",
		templateVariables:"StringKeyValuePair"
	},
	GenerateImageFromPromptInput:{

	},
	GenerateNextMonthCalendarInput:{

	},
	GeneratedPromptByTemplate:{
		GeneratedPromptByTemplateImages:{
			distinct_on:"GeneratedPromptByTemplateImage_select_column",
			order_by:"GeneratedPromptByTemplateImage_order_by",
			where:"GeneratedPromptByTemplateImage_bool_exp"
		},
		GeneratedPromptByTemplateImages_aggregate:{
			distinct_on:"GeneratedPromptByTemplateImage_select_column",
			order_by:"GeneratedPromptByTemplateImage_order_by",
			where:"GeneratedPromptByTemplateImage_bool_exp"
		},
		command:{

		},
		displayMapper:{

		}
	},
	GeneratedPromptByTemplateImage_aggregate_bool_exp:{
		count:"GeneratedPromptByTemplateImage_aggregate_bool_exp_count"
	},
	GeneratedPromptByTemplateImage_aggregate_bool_exp_count:{
		arguments:"GeneratedPromptByTemplateImage_select_column",
		filter:"GeneratedPromptByTemplateImage_bool_exp",
		predicate:"Int_comparison_exp"
	},
	GeneratedPromptByTemplateImage_aggregate_fields:{
		count:{
			columns:"GeneratedPromptByTemplateImage_select_column"
		}
	},
	GeneratedPromptByTemplateImage_aggregate_order_by:{
		count:"order_by",
		max:"GeneratedPromptByTemplateImage_max_order_by",
		min:"GeneratedPromptByTemplateImage_min_order_by"
	},
	GeneratedPromptByTemplateImage_arr_rel_insert_input:{
		data:"GeneratedPromptByTemplateImage_insert_input",
		on_conflict:"GeneratedPromptByTemplateImage_on_conflict"
	},
	GeneratedPromptByTemplateImage_bool_exp:{
		GeneratedPromptByTemplateId:"uuid_comparison_exp",
		_and:"GeneratedPromptByTemplateImage_bool_exp",
		_not:"GeneratedPromptByTemplateImage_bool_exp",
		_or:"GeneratedPromptByTemplateImage_bool_exp",
		fileId:"uuid_comparison_exp",
		id:"uuid_comparison_exp"
	},
	GeneratedPromptByTemplateImage_constraint: "enum" as const,
	GeneratedPromptByTemplateImage_insert_input:{
		GeneratedPromptByTemplateId:"uuid",
		fileId:"uuid",
		id:"uuid"
	},
	GeneratedPromptByTemplateImage_max_order_by:{
		GeneratedPromptByTemplateId:"order_by",
		fileId:"order_by",
		id:"order_by"
	},
	GeneratedPromptByTemplateImage_min_order_by:{
		GeneratedPromptByTemplateId:"order_by",
		fileId:"order_by",
		id:"order_by"
	},
	GeneratedPromptByTemplateImage_on_conflict:{
		constraint:"GeneratedPromptByTemplateImage_constraint",
		update_columns:"GeneratedPromptByTemplateImage_update_column",
		where:"GeneratedPromptByTemplateImage_bool_exp"
	},
	GeneratedPromptByTemplateImage_order_by:{
		GeneratedPromptByTemplateId:"order_by",
		fileId:"order_by",
		id:"order_by"
	},
	GeneratedPromptByTemplateImage_pk_columns_input:{
		id:"uuid"
	},
	GeneratedPromptByTemplateImage_select_column: "enum" as const,
	GeneratedPromptByTemplateImage_set_input:{
		GeneratedPromptByTemplateId:"uuid",
		fileId:"uuid",
		id:"uuid"
	},
	GeneratedPromptByTemplateImage_stream_cursor_input:{
		initial_value:"GeneratedPromptByTemplateImage_stream_cursor_value_input",
		ordering:"cursor_ordering"
	},
	GeneratedPromptByTemplateImage_stream_cursor_value_input:{
		GeneratedPromptByTemplateId:"uuid",
		fileId:"uuid",
		id:"uuid"
	},
	GeneratedPromptByTemplateImage_update_column: "enum" as const,
	GeneratedPromptByTemplateImage_updates:{
		_set:"GeneratedPromptByTemplateImage_set_input",
		where:"GeneratedPromptByTemplateImage_bool_exp"
	},
	GeneratedPromptByTemplate_aggregate_bool_exp:{
		bool_and:"GeneratedPromptByTemplate_aggregate_bool_exp_bool_and",
		bool_or:"GeneratedPromptByTemplate_aggregate_bool_exp_bool_or",
		count:"GeneratedPromptByTemplate_aggregate_bool_exp_count"
	},
	GeneratedPromptByTemplate_aggregate_bool_exp_bool_and:{
		arguments:"GeneratedPromptByTemplate_select_column_GeneratedPromptByTemplate_aggregate_bool_exp_bool_and_arguments_columns",
		filter:"GeneratedPromptByTemplate_bool_exp",
		predicate:"Boolean_comparison_exp"
	},
	GeneratedPromptByTemplate_aggregate_bool_exp_bool_or:{
		arguments:"GeneratedPromptByTemplate_select_column_GeneratedPromptByTemplate_aggregate_bool_exp_bool_or_arguments_columns",
		filter:"GeneratedPromptByTemplate_bool_exp",
		predicate:"Boolean_comparison_exp"
	},
	GeneratedPromptByTemplate_aggregate_bool_exp_count:{
		arguments:"GeneratedPromptByTemplate_select_column",
		filter:"GeneratedPromptByTemplate_bool_exp",
		predicate:"Int_comparison_exp"
	},
	GeneratedPromptByTemplate_aggregate_fields:{
		count:{
			columns:"GeneratedPromptByTemplate_select_column"
		}
	},
	GeneratedPromptByTemplate_aggregate_order_by:{
		count:"order_by",
		max:"GeneratedPromptByTemplate_max_order_by",
		min:"GeneratedPromptByTemplate_min_order_by"
	},
	GeneratedPromptByTemplate_append_input:{
		command:"jsonb",
		displayMapper:"jsonb"
	},
	GeneratedPromptByTemplate_arr_rel_insert_input:{
		data:"GeneratedPromptByTemplate_insert_input",
		on_conflict:"GeneratedPromptByTemplate_on_conflict"
	},
	GeneratedPromptByTemplate_bool_exp:{
		GeneratedPromptByTemplateImages:"GeneratedPromptByTemplateImage_bool_exp",
		GeneratedPromptByTemplateImages_aggregate:"GeneratedPromptByTemplateImage_aggregate_bool_exp",
		TextPromptTemplate:"TextPromptTemplate_bool_exp",
		_and:"GeneratedPromptByTemplate_bool_exp",
		_not:"GeneratedPromptByTemplate_bool_exp",
		_or:"GeneratedPromptByTemplate_bool_exp",
		active:"Boolean_comparison_exp",
		alias:"String_comparison_exp",
		backgroundImageFileId:"uuid_comparison_exp",
		channel:"String_comparison_exp",
		command:"jsonb_comparison_exp",
		created_at:"timestamptz_comparison_exp",
		created_by:"uuid_comparison_exp",
		displayFormat:"displayFormats_bool_exp",
		displayImageUrl:"String_comparison_exp",
		displayMapper:"jsonb_comparison_exp",
		generatingDisplayStatus:"String_comparison_exp",
		id:"uuid_comparison_exp",
		pricingPlan:"pricingPlan_bool_exp",
		pricingPlanId:"uuid_comparison_exp",
		publishDate:"timestamptz_comparison_exp",
		result:"String_comparison_exp",
		scheduledDate:"timestamptz_comparison_exp",
		scheduledDateEventId:"uuid_comparison_exp",
		selectedDisplayFormatId:"uuid_comparison_exp",
		status:"String_comparison_exp",
		textPromptTemplateId:"uuid_comparison_exp",
		updated_at:"timestamptz_comparison_exp"
	},
	GeneratedPromptByTemplate_constraint: "enum" as const,
	GeneratedPromptByTemplate_delete_at_path_input:{

	},
	GeneratedPromptByTemplate_delete_elem_input:{

	},
	GeneratedPromptByTemplate_delete_key_input:{

	},
	GeneratedPromptByTemplate_insert_input:{
		GeneratedPromptByTemplateImages:"GeneratedPromptByTemplateImage_arr_rel_insert_input",
		TextPromptTemplate:"TextPromptTemplate_obj_rel_insert_input",
		backgroundImageFileId:"uuid",
		command:"jsonb",
		created_at:"timestamptz",
		created_by:"uuid",
		displayFormat:"displayFormats_obj_rel_insert_input",
		displayMapper:"jsonb",
		id:"uuid",
		pricingPlan:"pricingPlan_obj_rel_insert_input",
		pricingPlanId:"uuid",
		publishDate:"timestamptz",
		scheduledDate:"timestamptz",
		scheduledDateEventId:"uuid",
		selectedDisplayFormatId:"uuid",
		textPromptTemplateId:"uuid",
		updated_at:"timestamptz"
	},
	GeneratedPromptByTemplate_max_order_by:{
		alias:"order_by",
		backgroundImageFileId:"order_by",
		channel:"order_by",
		created_at:"order_by",
		created_by:"order_by",
		displayImageUrl:"order_by",
		generatingDisplayStatus:"order_by",
		id:"order_by",
		pricingPlanId:"order_by",
		publishDate:"order_by",
		result:"order_by",
		scheduledDate:"order_by",
		scheduledDateEventId:"order_by",
		selectedDisplayFormatId:"order_by",
		status:"order_by",
		textPromptTemplateId:"order_by",
		updated_at:"order_by"
	},
	GeneratedPromptByTemplate_min_order_by:{
		alias:"order_by",
		backgroundImageFileId:"order_by",
		channel:"order_by",
		created_at:"order_by",
		created_by:"order_by",
		displayImageUrl:"order_by",
		generatingDisplayStatus:"order_by",
		id:"order_by",
		pricingPlanId:"order_by",
		publishDate:"order_by",
		result:"order_by",
		scheduledDate:"order_by",
		scheduledDateEventId:"order_by",
		selectedDisplayFormatId:"order_by",
		status:"order_by",
		textPromptTemplateId:"order_by",
		updated_at:"order_by"
	},
	GeneratedPromptByTemplate_on_conflict:{
		constraint:"GeneratedPromptByTemplate_constraint",
		update_columns:"GeneratedPromptByTemplate_update_column",
		where:"GeneratedPromptByTemplate_bool_exp"
	},
	GeneratedPromptByTemplate_order_by:{
		GeneratedPromptByTemplateImages_aggregate:"GeneratedPromptByTemplateImage_aggregate_order_by",
		TextPromptTemplate:"TextPromptTemplate_order_by",
		active:"order_by",
		alias:"order_by",
		backgroundImageFileId:"order_by",
		channel:"order_by",
		command:"order_by",
		created_at:"order_by",
		created_by:"order_by",
		displayFormat:"displayFormats_order_by",
		displayImageUrl:"order_by",
		displayMapper:"order_by",
		generatingDisplayStatus:"order_by",
		id:"order_by",
		pricingPlan:"pricingPlan_order_by",
		pricingPlanId:"order_by",
		publishDate:"order_by",
		result:"order_by",
		scheduledDate:"order_by",
		scheduledDateEventId:"order_by",
		selectedDisplayFormatId:"order_by",
		status:"order_by",
		textPromptTemplateId:"order_by",
		updated_at:"order_by"
	},
	GeneratedPromptByTemplate_pk_columns_input:{
		id:"uuid"
	},
	GeneratedPromptByTemplate_prepend_input:{
		command:"jsonb",
		displayMapper:"jsonb"
	},
	GeneratedPromptByTemplate_select_column: "enum" as const,
	GeneratedPromptByTemplate_select_column_GeneratedPromptByTemplate_aggregate_bool_exp_bool_and_arguments_columns: "enum" as const,
	GeneratedPromptByTemplate_select_column_GeneratedPromptByTemplate_aggregate_bool_exp_bool_or_arguments_columns: "enum" as const,
	GeneratedPromptByTemplate_set_input:{
		backgroundImageFileId:"uuid",
		command:"jsonb",
		created_at:"timestamptz",
		created_by:"uuid",
		displayMapper:"jsonb",
		id:"uuid",
		pricingPlanId:"uuid",
		publishDate:"timestamptz",
		scheduledDate:"timestamptz",
		scheduledDateEventId:"uuid",
		selectedDisplayFormatId:"uuid",
		textPromptTemplateId:"uuid",
		updated_at:"timestamptz"
	},
	GeneratedPromptByTemplate_stream_cursor_input:{
		initial_value:"GeneratedPromptByTemplate_stream_cursor_value_input",
		ordering:"cursor_ordering"
	},
	GeneratedPromptByTemplate_stream_cursor_value_input:{
		backgroundImageFileId:"uuid",
		command:"jsonb",
		created_at:"timestamptz",
		created_by:"uuid",
		displayMapper:"jsonb",
		id:"uuid",
		pricingPlanId:"uuid",
		publishDate:"timestamptz",
		scheduledDate:"timestamptz",
		scheduledDateEventId:"uuid",
		selectedDisplayFormatId:"uuid",
		textPromptTemplateId:"uuid",
		updated_at:"timestamptz"
	},
	GeneratedPromptByTemplate_update_column: "enum" as const,
	GeneratedPromptByTemplate_updates:{
		_append:"GeneratedPromptByTemplate_append_input",
		_delete_at_path:"GeneratedPromptByTemplate_delete_at_path_input",
		_delete_elem:"GeneratedPromptByTemplate_delete_elem_input",
		_delete_key:"GeneratedPromptByTemplate_delete_key_input",
		_prepend:"GeneratedPromptByTemplate_prepend_input",
		_set:"GeneratedPromptByTemplate_set_input",
		where:"GeneratedPromptByTemplate_bool_exp"
	},
	GetPaymentUrlCommand:{

	},
	GetWordpressCategoriesInput:{

	},
	GetWordpressTagsInput:{

	},
	Int_comparison_exp:{

	},
	SerpStatTestInput:{

	},
	StringKeyValuePair:{

	},
	String_array_comparison_exp:{

	},
	String_comparison_exp:{

	},
	TextGeneratorOption:{
		Label:{

		},
		TextGeneratorOptionEntries:{
			distinct_on:"TextGeneratorOptionEntry_select_column",
			order_by:"TextGeneratorOptionEntry_order_by",
			where:"TextGeneratorOptionEntry_bool_exp"
		},
		TextGeneratorOptionEntries_aggregate:{
			distinct_on:"TextGeneratorOptionEntry_select_column",
			order_by:"TextGeneratorOptionEntry_order_by",
			where:"TextGeneratorOptionEntry_bool_exp"
		},
		popover_tooltip:{

		}
	},
	TextGeneratorOptionEntry:{
		Label:{

		}
	},
	TextGeneratorOptionEntry_aggregate_bool_exp:{
		count:"TextGeneratorOptionEntry_aggregate_bool_exp_count"
	},
	TextGeneratorOptionEntry_aggregate_bool_exp_count:{
		arguments:"TextGeneratorOptionEntry_select_column",
		filter:"TextGeneratorOptionEntry_bool_exp",
		predicate:"Int_comparison_exp"
	},
	TextGeneratorOptionEntry_aggregate_fields:{
		count:{
			columns:"TextGeneratorOptionEntry_select_column"
		}
	},
	TextGeneratorOptionEntry_aggregate_order_by:{
		count:"order_by",
		max:"TextGeneratorOptionEntry_max_order_by",
		min:"TextGeneratorOptionEntry_min_order_by"
	},
	TextGeneratorOptionEntry_append_input:{
		Label:"jsonb"
	},
	TextGeneratorOptionEntry_arr_rel_insert_input:{
		data:"TextGeneratorOptionEntry_insert_input",
		on_conflict:"TextGeneratorOptionEntry_on_conflict"
	},
	TextGeneratorOptionEntry_bool_exp:{
		Label:"jsonb_comparison_exp",
		TextGeneratorOptionId:"uuid_comparison_exp",
		_and:"TextGeneratorOptionEntry_bool_exp",
		_not:"TextGeneratorOptionEntry_bool_exp",
		_or:"TextGeneratorOptionEntry_bool_exp",
		id:"uuid_comparison_exp"
	},
	TextGeneratorOptionEntry_constraint: "enum" as const,
	TextGeneratorOptionEntry_delete_at_path_input:{

	},
	TextGeneratorOptionEntry_delete_elem_input:{

	},
	TextGeneratorOptionEntry_delete_key_input:{

	},
	TextGeneratorOptionEntry_insert_input:{
		Label:"jsonb",
		TextGeneratorOptionId:"uuid",
		id:"uuid"
	},
	TextGeneratorOptionEntry_max_order_by:{
		TextGeneratorOptionId:"order_by",
		id:"order_by"
	},
	TextGeneratorOptionEntry_min_order_by:{
		TextGeneratorOptionId:"order_by",
		id:"order_by"
	},
	TextGeneratorOptionEntry_on_conflict:{
		constraint:"TextGeneratorOptionEntry_constraint",
		update_columns:"TextGeneratorOptionEntry_update_column",
		where:"TextGeneratorOptionEntry_bool_exp"
	},
	TextGeneratorOptionEntry_order_by:{
		Label:"order_by",
		TextGeneratorOptionId:"order_by",
		id:"order_by"
	},
	TextGeneratorOptionEntry_pk_columns_input:{
		id:"uuid"
	},
	TextGeneratorOptionEntry_prepend_input:{
		Label:"jsonb"
	},
	TextGeneratorOptionEntry_select_column: "enum" as const,
	TextGeneratorOptionEntry_set_input:{
		Label:"jsonb",
		TextGeneratorOptionId:"uuid",
		id:"uuid"
	},
	TextGeneratorOptionEntry_stream_cursor_input:{
		initial_value:"TextGeneratorOptionEntry_stream_cursor_value_input",
		ordering:"cursor_ordering"
	},
	TextGeneratorOptionEntry_stream_cursor_value_input:{
		Label:"jsonb",
		TextGeneratorOptionId:"uuid",
		id:"uuid"
	},
	TextGeneratorOptionEntry_update_column: "enum" as const,
	TextGeneratorOptionEntry_updates:{
		_append:"TextGeneratorOptionEntry_append_input",
		_delete_at_path:"TextGeneratorOptionEntry_delete_at_path_input",
		_delete_elem:"TextGeneratorOptionEntry_delete_elem_input",
		_delete_key:"TextGeneratorOptionEntry_delete_key_input",
		_prepend:"TextGeneratorOptionEntry_prepend_input",
		_set:"TextGeneratorOptionEntry_set_input",
		where:"TextGeneratorOptionEntry_bool_exp"
	},
	TextGeneratorOption_aggregate_fields:{
		count:{
			columns:"TextGeneratorOption_select_column"
		}
	},
	TextGeneratorOption_append_input:{
		Label:"jsonb",
		popover_tooltip:"jsonb"
	},
	TextGeneratorOption_bool_exp:{
		Label:"jsonb_comparison_exp",
		TextGeneratorOptionEntries:"TextGeneratorOptionEntry_bool_exp",
		TextGeneratorOptionEntries_aggregate:"TextGeneratorOptionEntry_aggregate_bool_exp",
		_and:"TextGeneratorOption_bool_exp",
		_not:"TextGeneratorOption_bool_exp",
		_or:"TextGeneratorOption_bool_exp",
		created_at:"timestamptz_comparison_exp",
		id:"uuid_comparison_exp",
		popover_tooltip:"jsonb_comparison_exp",
		updated_at:"timestamptz_comparison_exp"
	},
	TextGeneratorOption_constraint: "enum" as const,
	TextGeneratorOption_delete_at_path_input:{

	},
	TextGeneratorOption_delete_elem_input:{

	},
	TextGeneratorOption_delete_key_input:{

	},
	TextGeneratorOption_insert_input:{
		Label:"jsonb",
		TextGeneratorOptionEntries:"TextGeneratorOptionEntry_arr_rel_insert_input",
		created_at:"timestamptz",
		id:"uuid",
		popover_tooltip:"jsonb",
		updated_at:"timestamptz"
	},
	TextGeneratorOption_on_conflict:{
		constraint:"TextGeneratorOption_constraint",
		update_columns:"TextGeneratorOption_update_column",
		where:"TextGeneratorOption_bool_exp"
	},
	TextGeneratorOption_order_by:{
		Label:"order_by",
		TextGeneratorOptionEntries_aggregate:"TextGeneratorOptionEntry_aggregate_order_by",
		created_at:"order_by",
		id:"order_by",
		popover_tooltip:"order_by",
		updated_at:"order_by"
	},
	TextGeneratorOption_pk_columns_input:{
		id:"uuid"
	},
	TextGeneratorOption_prepend_input:{
		Label:"jsonb",
		popover_tooltip:"jsonb"
	},
	TextGeneratorOption_select_column: "enum" as const,
	TextGeneratorOption_set_input:{
		Label:"jsonb",
		created_at:"timestamptz",
		id:"uuid",
		popover_tooltip:"jsonb",
		updated_at:"timestamptz"
	},
	TextGeneratorOption_stream_cursor_input:{
		initial_value:"TextGeneratorOption_stream_cursor_value_input",
		ordering:"cursor_ordering"
	},
	TextGeneratorOption_stream_cursor_value_input:{
		Label:"jsonb",
		created_at:"timestamptz",
		id:"uuid",
		popover_tooltip:"jsonb",
		updated_at:"timestamptz"
	},
	TextGeneratorOption_update_column: "enum" as const,
	TextGeneratorOption_updates:{
		_append:"TextGeneratorOption_append_input",
		_delete_at_path:"TextGeneratorOption_delete_at_path_input",
		_delete_elem:"TextGeneratorOption_delete_elem_input",
		_delete_key:"TextGeneratorOption_delete_key_input",
		_prepend:"TextGeneratorOption_prepend_input",
		_set:"TextGeneratorOption_set_input",
		where:"TextGeneratorOption_bool_exp"
	},
	TextPromptTemplate:{
		GeneratedPromptByTemplates:{
			distinct_on:"GeneratedPromptByTemplate_select_column",
			order_by:"GeneratedPromptByTemplate_order_by",
			where:"GeneratedPromptByTemplate_bool_exp"
		},
		GeneratedPromptByTemplates_aggregate:{
			distinct_on:"GeneratedPromptByTemplate_select_column",
			order_by:"GeneratedPromptByTemplate_order_by",
			where:"GeneratedPromptByTemplate_bool_exp"
		},
		availableDisplays:{
			distinct_on:"textTemplateDisplayFormat_select_column",
			order_by:"textTemplateDisplayFormat_order_by",
			where:"textTemplateDisplayFormat_bool_exp"
		},
		availableDisplays_aggregate:{
			distinct_on:"textTemplateDisplayFormat_select_column",
			order_by:"textTemplateDisplayFormat_order_by",
			where:"textTemplateDisplayFormat_bool_exp"
		},
		definitions:{

		}
	},
	TextPromptTemplateCategory:{
		TextPromptTemplates:{
			distinct_on:"TextPromptTemplate_select_column",
			order_by:"TextPromptTemplate_order_by",
			where:"TextPromptTemplate_bool_exp"
		},
		TextPromptTemplates_aggregate:{
			distinct_on:"TextPromptTemplate_select_column",
			order_by:"TextPromptTemplate_order_by",
			where:"TextPromptTemplate_bool_exp"
		}
	},
	TextPromptTemplateCategory_aggregate_fields:{
		count:{
			columns:"TextPromptTemplateCategory_select_column"
		}
	},
	TextPromptTemplateCategory_bool_exp:{
		TextPromptTemplates:"TextPromptTemplate_bool_exp",
		TextPromptTemplates_aggregate:"TextPromptTemplate_aggregate_bool_exp",
		_and:"TextPromptTemplateCategory_bool_exp",
		_not:"TextPromptTemplateCategory_bool_exp",
		_or:"TextPromptTemplateCategory_bool_exp",
		categoryName:"String_comparison_exp",
		id:"uuid_comparison_exp"
	},
	TextPromptTemplateCategory_constraint: "enum" as const,
	TextPromptTemplateCategory_insert_input:{
		TextPromptTemplates:"TextPromptTemplate_arr_rel_insert_input",
		id:"uuid"
	},
	TextPromptTemplateCategory_obj_rel_insert_input:{
		data:"TextPromptTemplateCategory_insert_input",
		on_conflict:"TextPromptTemplateCategory_on_conflict"
	},
	TextPromptTemplateCategory_on_conflict:{
		constraint:"TextPromptTemplateCategory_constraint",
		update_columns:"TextPromptTemplateCategory_update_column",
		where:"TextPromptTemplateCategory_bool_exp"
	},
	TextPromptTemplateCategory_order_by:{
		TextPromptTemplates_aggregate:"TextPromptTemplate_aggregate_order_by",
		categoryName:"order_by",
		id:"order_by"
	},
	TextPromptTemplateCategory_pk_columns_input:{
		id:"uuid"
	},
	TextPromptTemplateCategory_select_column: "enum" as const,
	TextPromptTemplateCategory_set_input:{
		id:"uuid"
	},
	TextPromptTemplateCategory_stream_cursor_input:{
		initial_value:"TextPromptTemplateCategory_stream_cursor_value_input",
		ordering:"cursor_ordering"
	},
	TextPromptTemplateCategory_stream_cursor_value_input:{
		id:"uuid"
	},
	TextPromptTemplateCategory_update_column: "enum" as const,
	TextPromptTemplateCategory_updates:{
		_set:"TextPromptTemplateCategory_set_input",
		where:"TextPromptTemplateCategory_bool_exp"
	},
	TextPromptTemplate_aggregate_bool_exp:{
		bool_and:"TextPromptTemplate_aggregate_bool_exp_bool_and",
		bool_or:"TextPromptTemplate_aggregate_bool_exp_bool_or",
		count:"TextPromptTemplate_aggregate_bool_exp_count"
	},
	TextPromptTemplate_aggregate_bool_exp_bool_and:{
		arguments:"TextPromptTemplate_select_column_TextPromptTemplate_aggregate_bool_exp_bool_and_arguments_columns",
		filter:"TextPromptTemplate_bool_exp",
		predicate:"Boolean_comparison_exp"
	},
	TextPromptTemplate_aggregate_bool_exp_bool_or:{
		arguments:"TextPromptTemplate_select_column_TextPromptTemplate_aggregate_bool_exp_bool_or_arguments_columns",
		filter:"TextPromptTemplate_bool_exp",
		predicate:"Boolean_comparison_exp"
	},
	TextPromptTemplate_aggregate_bool_exp_count:{
		arguments:"TextPromptTemplate_select_column",
		filter:"TextPromptTemplate_bool_exp",
		predicate:"Int_comparison_exp"
	},
	TextPromptTemplate_aggregate_fields:{
		count:{
			columns:"TextPromptTemplate_select_column"
		}
	},
	TextPromptTemplate_aggregate_order_by:{
		avg:"TextPromptTemplate_avg_order_by",
		count:"order_by",
		max:"TextPromptTemplate_max_order_by",
		min:"TextPromptTemplate_min_order_by",
		stddev:"TextPromptTemplate_stddev_order_by",
		stddev_pop:"TextPromptTemplate_stddev_pop_order_by",
		stddev_samp:"TextPromptTemplate_stddev_samp_order_by",
		sum:"TextPromptTemplate_sum_order_by",
		var_pop:"TextPromptTemplate_var_pop_order_by",
		var_samp:"TextPromptTemplate_var_samp_order_by",
		variance:"TextPromptTemplate_variance_order_by"
	},
	TextPromptTemplate_append_input:{
		definitions:"jsonb"
	},
	TextPromptTemplate_arr_rel_insert_input:{
		data:"TextPromptTemplate_insert_input",
		on_conflict:"TextPromptTemplate_on_conflict"
	},
	TextPromptTemplate_avg_order_by:{
		creditPrice:"order_by",
		marketPriceInCredits:"order_by",
		saleCreditPrice:"order_by"
	},
	TextPromptTemplate_bool_exp:{
		GeneratedPromptByTemplates:"GeneratedPromptByTemplate_bool_exp",
		GeneratedPromptByTemplates_aggregate:"GeneratedPromptByTemplate_aggregate_bool_exp",
		TextPromptTemplateCategory:"TextPromptTemplateCategory_bool_exp",
		TextTemplateClientCategory:"TextTemplateClientCategory_bool_exp",
		TextTemplateClientCategoryId:"uuid_comparison_exp",
		_and:"TextPromptTemplate_bool_exp",
		_not:"TextPromptTemplate_bool_exp",
		_or:"TextPromptTemplate_bool_exp",
		availableDisplays:"textTemplateDisplayFormat_bool_exp",
		availableDisplays_aggregate:"textTemplateDisplayFormat_aggregate_bool_exp",
		categoryId:"uuid_comparison_exp",
		channel:"String_comparison_exp",
		creditPrice:"Int_comparison_exp",
		definitions:"jsonb_comparison_exp",
		description:"String_comparison_exp",
		displayDimensionId:"uuid_comparison_exp",
		flexible:"Boolean_comparison_exp",
		icon:"String_comparison_exp",
		id:"uuid_comparison_exp",
		marketPriceInCredits:"numeric_comparison_exp",
		name:"String_comparison_exp",
		newTemplateText:"String_comparison_exp",
		saleCreditPrice:"Int_comparison_exp"
	},
	TextPromptTemplate_constraint: "enum" as const,
	TextPromptTemplate_delete_at_path_input:{

	},
	TextPromptTemplate_delete_elem_input:{

	},
	TextPromptTemplate_delete_key_input:{

	},
	TextPromptTemplate_inc_input:{
		marketPriceInCredits:"numeric"
	},
	TextPromptTemplate_insert_input:{
		GeneratedPromptByTemplates:"GeneratedPromptByTemplate_arr_rel_insert_input",
		TextPromptTemplateCategory:"TextPromptTemplateCategory_obj_rel_insert_input",
		TextTemplateClientCategory:"TextTemplateClientCategory_obj_rel_insert_input",
		TextTemplateClientCategoryId:"uuid",
		availableDisplays:"textTemplateDisplayFormat_arr_rel_insert_input",
		categoryId:"uuid",
		definitions:"jsonb",
		displayDimensionId:"uuid",
		id:"uuid",
		marketPriceInCredits:"numeric"
	},
	TextPromptTemplate_max_order_by:{
		TextTemplateClientCategoryId:"order_by",
		categoryId:"order_by",
		channel:"order_by",
		creditPrice:"order_by",
		description:"order_by",
		displayDimensionId:"order_by",
		icon:"order_by",
		id:"order_by",
		marketPriceInCredits:"order_by",
		name:"order_by",
		newTemplateText:"order_by",
		saleCreditPrice:"order_by"
	},
	TextPromptTemplate_min_order_by:{
		TextTemplateClientCategoryId:"order_by",
		categoryId:"order_by",
		channel:"order_by",
		creditPrice:"order_by",
		description:"order_by",
		displayDimensionId:"order_by",
		icon:"order_by",
		id:"order_by",
		marketPriceInCredits:"order_by",
		name:"order_by",
		newTemplateText:"order_by",
		saleCreditPrice:"order_by"
	},
	TextPromptTemplate_obj_rel_insert_input:{
		data:"TextPromptTemplate_insert_input",
		on_conflict:"TextPromptTemplate_on_conflict"
	},
	TextPromptTemplate_on_conflict:{
		constraint:"TextPromptTemplate_constraint",
		update_columns:"TextPromptTemplate_update_column",
		where:"TextPromptTemplate_bool_exp"
	},
	TextPromptTemplate_order_by:{
		GeneratedPromptByTemplates_aggregate:"GeneratedPromptByTemplate_aggregate_order_by",
		TextPromptTemplateCategory:"TextPromptTemplateCategory_order_by",
		TextTemplateClientCategory:"TextTemplateClientCategory_order_by",
		TextTemplateClientCategoryId:"order_by",
		availableDisplays_aggregate:"textTemplateDisplayFormat_aggregate_order_by",
		categoryId:"order_by",
		channel:"order_by",
		creditPrice:"order_by",
		definitions:"order_by",
		description:"order_by",
		displayDimensionId:"order_by",
		flexible:"order_by",
		icon:"order_by",
		id:"order_by",
		marketPriceInCredits:"order_by",
		name:"order_by",
		newTemplateText:"order_by",
		saleCreditPrice:"order_by"
	},
	TextPromptTemplate_pk_columns_input:{
		id:"uuid"
	},
	TextPromptTemplate_prepend_input:{
		definitions:"jsonb"
	},
	TextPromptTemplate_select_column: "enum" as const,
	TextPromptTemplate_select_column_TextPromptTemplate_aggregate_bool_exp_bool_and_arguments_columns: "enum" as const,
	TextPromptTemplate_select_column_TextPromptTemplate_aggregate_bool_exp_bool_or_arguments_columns: "enum" as const,
	TextPromptTemplate_set_input:{
		TextTemplateClientCategoryId:"uuid",
		categoryId:"uuid",
		definitions:"jsonb",
		displayDimensionId:"uuid",
		id:"uuid",
		marketPriceInCredits:"numeric"
	},
	TextPromptTemplate_stddev_order_by:{
		creditPrice:"order_by",
		marketPriceInCredits:"order_by",
		saleCreditPrice:"order_by"
	},
	TextPromptTemplate_stddev_pop_order_by:{
		creditPrice:"order_by",
		marketPriceInCredits:"order_by",
		saleCreditPrice:"order_by"
	},
	TextPromptTemplate_stddev_samp_order_by:{
		creditPrice:"order_by",
		marketPriceInCredits:"order_by",
		saleCreditPrice:"order_by"
	},
	TextPromptTemplate_stream_cursor_input:{
		initial_value:"TextPromptTemplate_stream_cursor_value_input",
		ordering:"cursor_ordering"
	},
	TextPromptTemplate_stream_cursor_value_input:{
		TextTemplateClientCategoryId:"uuid",
		categoryId:"uuid",
		definitions:"jsonb",
		displayDimensionId:"uuid",
		id:"uuid",
		marketPriceInCredits:"numeric"
	},
	TextPromptTemplate_sum_order_by:{
		creditPrice:"order_by",
		marketPriceInCredits:"order_by",
		saleCreditPrice:"order_by"
	},
	TextPromptTemplate_update_column: "enum" as const,
	TextPromptTemplate_updates:{
		_append:"TextPromptTemplate_append_input",
		_delete_at_path:"TextPromptTemplate_delete_at_path_input",
		_delete_elem:"TextPromptTemplate_delete_elem_input",
		_delete_key:"TextPromptTemplate_delete_key_input",
		_inc:"TextPromptTemplate_inc_input",
		_prepend:"TextPromptTemplate_prepend_input",
		_set:"TextPromptTemplate_set_input",
		where:"TextPromptTemplate_bool_exp"
	},
	TextPromptTemplate_var_pop_order_by:{
		creditPrice:"order_by",
		marketPriceInCredits:"order_by",
		saleCreditPrice:"order_by"
	},
	TextPromptTemplate_var_samp_order_by:{
		creditPrice:"order_by",
		marketPriceInCredits:"order_by",
		saleCreditPrice:"order_by"
	},
	TextPromptTemplate_variance_order_by:{
		creditPrice:"order_by",
		marketPriceInCredits:"order_by",
		saleCreditPrice:"order_by"
	},
	TextTemplateClientCategory_aggregate_fields:{
		count:{
			columns:"TextTemplateClientCategory_select_column"
		}
	},
	TextTemplateClientCategory_bool_exp:{
		_and:"TextTemplateClientCategory_bool_exp",
		_not:"TextTemplateClientCategory_bool_exp",
		_or:"TextTemplateClientCategory_bool_exp",
		displayName:"String_comparison_exp",
		iconClass:"String_comparison_exp",
		id:"uuid_comparison_exp"
	},
	TextTemplateClientCategory_constraint: "enum" as const,
	TextTemplateClientCategory_insert_input:{
		id:"uuid"
	},
	TextTemplateClientCategory_obj_rel_insert_input:{
		data:"TextTemplateClientCategory_insert_input",
		on_conflict:"TextTemplateClientCategory_on_conflict"
	},
	TextTemplateClientCategory_on_conflict:{
		constraint:"TextTemplateClientCategory_constraint",
		update_columns:"TextTemplateClientCategory_update_column",
		where:"TextTemplateClientCategory_bool_exp"
	},
	TextTemplateClientCategory_order_by:{
		displayName:"order_by",
		iconClass:"order_by",
		id:"order_by"
	},
	TextTemplateClientCategory_pk_columns_input:{
		id:"uuid"
	},
	TextTemplateClientCategory_select_column: "enum" as const,
	TextTemplateClientCategory_set_input:{
		id:"uuid"
	},
	TextTemplateClientCategory_stream_cursor_input:{
		initial_value:"TextTemplateClientCategory_stream_cursor_value_input",
		ordering:"cursor_ordering"
	},
	TextTemplateClientCategory_stream_cursor_value_input:{
		id:"uuid"
	},
	TextTemplateClientCategory_update_column: "enum" as const,
	TextTemplateClientCategory_updates:{
		_set:"TextTemplateClientCategory_set_input",
		where:"TextTemplateClientCategory_bool_exp"
	},
	audiences:{
		personas:{

		}
	},
	audiences_aggregate_fields:{
		count:{
			columns:"audiences_select_column"
		}
	},
	audiences_append_input:{
		personas:"jsonb"
	},
	audiences_bool_exp:{
		_and:"audiences_bool_exp",
		_not:"audiences_bool_exp",
		_or:"audiences_bool_exp",
		brandId:"uuid_comparison_exp",
		brandName:"String_comparison_exp",
		created_at:"timestamp_comparison_exp",
		created_by:"uuid_comparison_exp",
		deleted:"Boolean_comparison_exp",
		id:"uuid_comparison_exp",
		personas:"jsonb_comparison_exp",
		productOrService:"String_comparison_exp",
		segment:"String_comparison_exp",
		targetAudience:"String_comparison_exp",
		updated_at:"timestamptz_comparison_exp"
	},
	audiences_constraint: "enum" as const,
	audiences_delete_at_path_input:{

	},
	audiences_delete_elem_input:{

	},
	audiences_delete_key_input:{

	},
	audiences_insert_input:{
		brandId:"uuid",
		created_at:"timestamp",
		created_by:"uuid",
		id:"uuid",
		personas:"jsonb",
		updated_at:"timestamptz"
	},
	audiences_on_conflict:{
		constraint:"audiences_constraint",
		update_columns:"audiences_update_column",
		where:"audiences_bool_exp"
	},
	audiences_order_by:{
		brandId:"order_by",
		brandName:"order_by",
		created_at:"order_by",
		created_by:"order_by",
		deleted:"order_by",
		id:"order_by",
		personas:"order_by",
		productOrService:"order_by",
		segment:"order_by",
		targetAudience:"order_by",
		updated_at:"order_by"
	},
	audiences_pk_columns_input:{
		id:"uuid"
	},
	audiences_prepend_input:{
		personas:"jsonb"
	},
	audiences_select_column: "enum" as const,
	audiences_set_input:{
		brandId:"uuid",
		created_at:"timestamp",
		created_by:"uuid",
		id:"uuid",
		personas:"jsonb",
		updated_at:"timestamptz"
	},
	audiences_stream_cursor_input:{
		initial_value:"audiences_stream_cursor_value_input",
		ordering:"cursor_ordering"
	},
	audiences_stream_cursor_value_input:{
		brandId:"uuid",
		created_at:"timestamp",
		created_by:"uuid",
		id:"uuid",
		personas:"jsonb",
		updated_at:"timestamptz"
	},
	audiences_update_column: "enum" as const,
	audiences_updates:{
		_append:"audiences_append_input",
		_delete_at_path:"audiences_delete_at_path_input",
		_delete_elem:"audiences_delete_elem_input",
		_delete_key:"audiences_delete_key_input",
		_prepend:"audiences_prepend_input",
		_set:"audiences_set_input",
		where:"audiences_bool_exp"
	},
	authProviderRequests:{
		options:{

		}
	},
	authProviderRequests_aggregate_fields:{
		count:{
			columns:"authProviderRequests_select_column"
		}
	},
	authProviderRequests_append_input:{
		options:"jsonb"
	},
	authProviderRequests_bool_exp:{
		_and:"authProviderRequests_bool_exp",
		_not:"authProviderRequests_bool_exp",
		_or:"authProviderRequests_bool_exp",
		id:"uuid_comparison_exp",
		options:"jsonb_comparison_exp"
	},
	authProviderRequests_constraint: "enum" as const,
	authProviderRequests_delete_at_path_input:{

	},
	authProviderRequests_delete_elem_input:{

	},
	authProviderRequests_delete_key_input:{

	},
	authProviderRequests_insert_input:{
		id:"uuid",
		options:"jsonb"
	},
	authProviderRequests_on_conflict:{
		constraint:"authProviderRequests_constraint",
		update_columns:"authProviderRequests_update_column",
		where:"authProviderRequests_bool_exp"
	},
	authProviderRequests_order_by:{
		id:"order_by",
		options:"order_by"
	},
	authProviderRequests_pk_columns_input:{
		id:"uuid"
	},
	authProviderRequests_prepend_input:{
		options:"jsonb"
	},
	authProviderRequests_select_column: "enum" as const,
	authProviderRequests_set_input:{
		id:"uuid",
		options:"jsonb"
	},
	authProviderRequests_stream_cursor_input:{
		initial_value:"authProviderRequests_stream_cursor_value_input",
		ordering:"cursor_ordering"
	},
	authProviderRequests_stream_cursor_value_input:{
		id:"uuid",
		options:"jsonb"
	},
	authProviderRequests_update_column: "enum" as const,
	authProviderRequests_updates:{
		_append:"authProviderRequests_append_input",
		_delete_at_path:"authProviderRequests_delete_at_path_input",
		_delete_elem:"authProviderRequests_delete_elem_input",
		_delete_key:"authProviderRequests_delete_key_input",
		_prepend:"authProviderRequests_prepend_input",
		_set:"authProviderRequests_set_input",
		where:"authProviderRequests_bool_exp"
	},
	authProviders:{
		userProviders:{
			distinct_on:"authUserProviders_select_column",
			order_by:"authUserProviders_order_by",
			where:"authUserProviders_bool_exp"
		},
		userProviders_aggregate:{
			distinct_on:"authUserProviders_select_column",
			order_by:"authUserProviders_order_by",
			where:"authUserProviders_bool_exp"
		}
	},
	authProviders_aggregate_fields:{
		count:{
			columns:"authProviders_select_column"
		}
	},
	authProviders_bool_exp:{
		_and:"authProviders_bool_exp",
		_not:"authProviders_bool_exp",
		_or:"authProviders_bool_exp",
		id:"String_comparison_exp",
		userProviders:"authUserProviders_bool_exp",
		userProviders_aggregate:"authUserProviders_aggregate_bool_exp"
	},
	authProviders_constraint: "enum" as const,
	authProviders_insert_input:{
		userProviders:"authUserProviders_arr_rel_insert_input"
	},
	authProviders_obj_rel_insert_input:{
		data:"authProviders_insert_input",
		on_conflict:"authProviders_on_conflict"
	},
	authProviders_on_conflict:{
		constraint:"authProviders_constraint",
		update_columns:"authProviders_update_column",
		where:"authProviders_bool_exp"
	},
	authProviders_order_by:{
		id:"order_by",
		userProviders_aggregate:"authUserProviders_aggregate_order_by"
	},
	authProviders_pk_columns_input:{

	},
	authProviders_select_column: "enum" as const,
	authProviders_set_input:{

	},
	authProviders_stream_cursor_input:{
		initial_value:"authProviders_stream_cursor_value_input",
		ordering:"cursor_ordering"
	},
	authProviders_stream_cursor_value_input:{

	},
	authProviders_update_column: "enum" as const,
	authProviders_updates:{
		_set:"authProviders_set_input",
		where:"authProviders_bool_exp"
	},
	authRefreshTokenTypes:{
		refreshTokens:{
			distinct_on:"authRefreshTokens_select_column",
			order_by:"authRefreshTokens_order_by",
			where:"authRefreshTokens_bool_exp"
		},
		refreshTokens_aggregate:{
			distinct_on:"authRefreshTokens_select_column",
			order_by:"authRefreshTokens_order_by",
			where:"authRefreshTokens_bool_exp"
		}
	},
	authRefreshTokenTypes_aggregate_fields:{
		count:{
			columns:"authRefreshTokenTypes_select_column"
		}
	},
	authRefreshTokenTypes_bool_exp:{
		_and:"authRefreshTokenTypes_bool_exp",
		_not:"authRefreshTokenTypes_bool_exp",
		_or:"authRefreshTokenTypes_bool_exp",
		comment:"String_comparison_exp",
		refreshTokens:"authRefreshTokens_bool_exp",
		refreshTokens_aggregate:"authRefreshTokens_aggregate_bool_exp",
		value:"String_comparison_exp"
	},
	authRefreshTokenTypes_constraint: "enum" as const,
	authRefreshTokenTypes_enum: "enum" as const,
	authRefreshTokenTypes_enum_comparison_exp:{
		_eq:"authRefreshTokenTypes_enum",
		_in:"authRefreshTokenTypes_enum",
		_neq:"authRefreshTokenTypes_enum",
		_nin:"authRefreshTokenTypes_enum"
	},
	authRefreshTokenTypes_insert_input:{
		refreshTokens:"authRefreshTokens_arr_rel_insert_input"
	},
	authRefreshTokenTypes_on_conflict:{
		constraint:"authRefreshTokenTypes_constraint",
		update_columns:"authRefreshTokenTypes_update_column",
		where:"authRefreshTokenTypes_bool_exp"
	},
	authRefreshTokenTypes_order_by:{
		comment:"order_by",
		refreshTokens_aggregate:"authRefreshTokens_aggregate_order_by",
		value:"order_by"
	},
	authRefreshTokenTypes_pk_columns_input:{

	},
	authRefreshTokenTypes_select_column: "enum" as const,
	authRefreshTokenTypes_set_input:{

	},
	authRefreshTokenTypes_stream_cursor_input:{
		initial_value:"authRefreshTokenTypes_stream_cursor_value_input",
		ordering:"cursor_ordering"
	},
	authRefreshTokenTypes_stream_cursor_value_input:{

	},
	authRefreshTokenTypes_update_column: "enum" as const,
	authRefreshTokenTypes_updates:{
		_set:"authRefreshTokenTypes_set_input",
		where:"authRefreshTokenTypes_bool_exp"
	},
	authRefreshTokens:{
		metadata:{

		}
	},
	authRefreshTokens_aggregate_bool_exp:{
		count:"authRefreshTokens_aggregate_bool_exp_count"
	},
	authRefreshTokens_aggregate_bool_exp_count:{
		arguments:"authRefreshTokens_select_column",
		filter:"authRefreshTokens_bool_exp",
		predicate:"Int_comparison_exp"
	},
	authRefreshTokens_aggregate_fields:{
		count:{
			columns:"authRefreshTokens_select_column"
		}
	},
	authRefreshTokens_aggregate_order_by:{
		count:"order_by",
		max:"authRefreshTokens_max_order_by",
		min:"authRefreshTokens_min_order_by"
	},
	authRefreshTokens_append_input:{
		metadata:"jsonb"
	},
	authRefreshTokens_arr_rel_insert_input:{
		data:"authRefreshTokens_insert_input",
		on_conflict:"authRefreshTokens_on_conflict"
	},
	authRefreshTokens_bool_exp:{
		_and:"authRefreshTokens_bool_exp",
		_not:"authRefreshTokens_bool_exp",
		_or:"authRefreshTokens_bool_exp",
		createdAt:"timestamptz_comparison_exp",
		expiresAt:"timestamptz_comparison_exp",
		id:"uuid_comparison_exp",
		metadata:"jsonb_comparison_exp",
		refreshTokenHash:"String_comparison_exp",
		type:"authRefreshTokenTypes_enum_comparison_exp",
		user:"users_bool_exp",
		userId:"uuid_comparison_exp"
	},
	authRefreshTokens_constraint: "enum" as const,
	authRefreshTokens_delete_at_path_input:{

	},
	authRefreshTokens_delete_elem_input:{

	},
	authRefreshTokens_delete_key_input:{

	},
	authRefreshTokens_insert_input:{
		createdAt:"timestamptz",
		expiresAt:"timestamptz",
		id:"uuid",
		metadata:"jsonb",
		type:"authRefreshTokenTypes_enum",
		user:"users_obj_rel_insert_input",
		userId:"uuid"
	},
	authRefreshTokens_max_order_by:{
		createdAt:"order_by",
		expiresAt:"order_by",
		id:"order_by",
		refreshTokenHash:"order_by",
		userId:"order_by"
	},
	authRefreshTokens_min_order_by:{
		createdAt:"order_by",
		expiresAt:"order_by",
		id:"order_by",
		refreshTokenHash:"order_by",
		userId:"order_by"
	},
	authRefreshTokens_on_conflict:{
		constraint:"authRefreshTokens_constraint",
		update_columns:"authRefreshTokens_update_column",
		where:"authRefreshTokens_bool_exp"
	},
	authRefreshTokens_order_by:{
		createdAt:"order_by",
		expiresAt:"order_by",
		id:"order_by",
		metadata:"order_by",
		refreshTokenHash:"order_by",
		type:"order_by",
		user:"users_order_by",
		userId:"order_by"
	},
	authRefreshTokens_pk_columns_input:{
		id:"uuid"
	},
	authRefreshTokens_prepend_input:{
		metadata:"jsonb"
	},
	authRefreshTokens_select_column: "enum" as const,
	authRefreshTokens_set_input:{
		createdAt:"timestamptz",
		expiresAt:"timestamptz",
		id:"uuid",
		metadata:"jsonb",
		type:"authRefreshTokenTypes_enum",
		userId:"uuid"
	},
	authRefreshTokens_stream_cursor_input:{
		initial_value:"authRefreshTokens_stream_cursor_value_input",
		ordering:"cursor_ordering"
	},
	authRefreshTokens_stream_cursor_value_input:{
		createdAt:"timestamptz",
		expiresAt:"timestamptz",
		id:"uuid",
		metadata:"jsonb",
		type:"authRefreshTokenTypes_enum",
		userId:"uuid"
	},
	authRefreshTokens_update_column: "enum" as const,
	authRefreshTokens_updates:{
		_append:"authRefreshTokens_append_input",
		_delete_at_path:"authRefreshTokens_delete_at_path_input",
		_delete_elem:"authRefreshTokens_delete_elem_input",
		_delete_key:"authRefreshTokens_delete_key_input",
		_prepend:"authRefreshTokens_prepend_input",
		_set:"authRefreshTokens_set_input",
		where:"authRefreshTokens_bool_exp"
	},
	authRoles:{
		userRoles:{
			distinct_on:"authUserRoles_select_column",
			order_by:"authUserRoles_order_by",
			where:"authUserRoles_bool_exp"
		},
		userRoles_aggregate:{
			distinct_on:"authUserRoles_select_column",
			order_by:"authUserRoles_order_by",
			where:"authUserRoles_bool_exp"
		},
		usersByDefaultRole:{
			distinct_on:"users_select_column",
			order_by:"users_order_by",
			where:"users_bool_exp"
		},
		usersByDefaultRole_aggregate:{
			distinct_on:"users_select_column",
			order_by:"users_order_by",
			where:"users_bool_exp"
		}
	},
	authRoles_aggregate_fields:{
		count:{
			columns:"authRoles_select_column"
		}
	},
	authRoles_bool_exp:{
		_and:"authRoles_bool_exp",
		_not:"authRoles_bool_exp",
		_or:"authRoles_bool_exp",
		role:"String_comparison_exp",
		userRoles:"authUserRoles_bool_exp",
		userRoles_aggregate:"authUserRoles_aggregate_bool_exp",
		usersByDefaultRole:"users_bool_exp",
		usersByDefaultRole_aggregate:"users_aggregate_bool_exp"
	},
	authRoles_constraint: "enum" as const,
	authRoles_insert_input:{
		userRoles:"authUserRoles_arr_rel_insert_input",
		usersByDefaultRole:"users_arr_rel_insert_input"
	},
	authRoles_obj_rel_insert_input:{
		data:"authRoles_insert_input",
		on_conflict:"authRoles_on_conflict"
	},
	authRoles_on_conflict:{
		constraint:"authRoles_constraint",
		update_columns:"authRoles_update_column",
		where:"authRoles_bool_exp"
	},
	authRoles_order_by:{
		role:"order_by",
		userRoles_aggregate:"authUserRoles_aggregate_order_by",
		usersByDefaultRole_aggregate:"users_aggregate_order_by"
	},
	authRoles_pk_columns_input:{

	},
	authRoles_select_column: "enum" as const,
	authRoles_set_input:{

	},
	authRoles_stream_cursor_input:{
		initial_value:"authRoles_stream_cursor_value_input",
		ordering:"cursor_ordering"
	},
	authRoles_stream_cursor_value_input:{

	},
	authRoles_update_column: "enum" as const,
	authRoles_updates:{
		_set:"authRoles_set_input",
		where:"authRoles_bool_exp"
	},
	authUserProviders_aggregate_bool_exp:{
		count:"authUserProviders_aggregate_bool_exp_count"
	},
	authUserProviders_aggregate_bool_exp_count:{
		arguments:"authUserProviders_select_column",
		filter:"authUserProviders_bool_exp",
		predicate:"Int_comparison_exp"
	},
	authUserProviders_aggregate_fields:{
		count:{
			columns:"authUserProviders_select_column"
		}
	},
	authUserProviders_aggregate_order_by:{
		count:"order_by",
		max:"authUserProviders_max_order_by",
		min:"authUserProviders_min_order_by"
	},
	authUserProviders_arr_rel_insert_input:{
		data:"authUserProviders_insert_input",
		on_conflict:"authUserProviders_on_conflict"
	},
	authUserProviders_bool_exp:{
		_and:"authUserProviders_bool_exp",
		_not:"authUserProviders_bool_exp",
		_or:"authUserProviders_bool_exp",
		accessToken:"String_comparison_exp",
		createdAt:"timestamptz_comparison_exp",
		id:"uuid_comparison_exp",
		provider:"authProviders_bool_exp",
		providerId:"String_comparison_exp",
		providerUserId:"String_comparison_exp",
		refreshToken:"String_comparison_exp",
		updatedAt:"timestamptz_comparison_exp",
		user:"users_bool_exp",
		userId:"uuid_comparison_exp"
	},
	authUserProviders_constraint: "enum" as const,
	authUserProviders_insert_input:{
		createdAt:"timestamptz",
		id:"uuid",
		provider:"authProviders_obj_rel_insert_input",
		updatedAt:"timestamptz",
		user:"users_obj_rel_insert_input",
		userId:"uuid"
	},
	authUserProviders_max_order_by:{
		accessToken:"order_by",
		createdAt:"order_by",
		id:"order_by",
		providerId:"order_by",
		providerUserId:"order_by",
		refreshToken:"order_by",
		updatedAt:"order_by",
		userId:"order_by"
	},
	authUserProviders_min_order_by:{
		accessToken:"order_by",
		createdAt:"order_by",
		id:"order_by",
		providerId:"order_by",
		providerUserId:"order_by",
		refreshToken:"order_by",
		updatedAt:"order_by",
		userId:"order_by"
	},
	authUserProviders_on_conflict:{
		constraint:"authUserProviders_constraint",
		update_columns:"authUserProviders_update_column",
		where:"authUserProviders_bool_exp"
	},
	authUserProviders_order_by:{
		accessToken:"order_by",
		createdAt:"order_by",
		id:"order_by",
		provider:"authProviders_order_by",
		providerId:"order_by",
		providerUserId:"order_by",
		refreshToken:"order_by",
		updatedAt:"order_by",
		user:"users_order_by",
		userId:"order_by"
	},
	authUserProviders_pk_columns_input:{
		id:"uuid"
	},
	authUserProviders_select_column: "enum" as const,
	authUserProviders_set_input:{
		createdAt:"timestamptz",
		id:"uuid",
		updatedAt:"timestamptz",
		userId:"uuid"
	},
	authUserProviders_stream_cursor_input:{
		initial_value:"authUserProviders_stream_cursor_value_input",
		ordering:"cursor_ordering"
	},
	authUserProviders_stream_cursor_value_input:{
		createdAt:"timestamptz",
		id:"uuid",
		updatedAt:"timestamptz",
		userId:"uuid"
	},
	authUserProviders_update_column: "enum" as const,
	authUserProviders_updates:{
		_set:"authUserProviders_set_input",
		where:"authUserProviders_bool_exp"
	},
	authUserRoles_aggregate_bool_exp:{
		count:"authUserRoles_aggregate_bool_exp_count"
	},
	authUserRoles_aggregate_bool_exp_count:{
		arguments:"authUserRoles_select_column",
		filter:"authUserRoles_bool_exp",
		predicate:"Int_comparison_exp"
	},
	authUserRoles_aggregate_fields:{
		count:{
			columns:"authUserRoles_select_column"
		}
	},
	authUserRoles_aggregate_order_by:{
		count:"order_by",
		max:"authUserRoles_max_order_by",
		min:"authUserRoles_min_order_by"
	},
	authUserRoles_arr_rel_insert_input:{
		data:"authUserRoles_insert_input",
		on_conflict:"authUserRoles_on_conflict"
	},
	authUserRoles_bool_exp:{
		_and:"authUserRoles_bool_exp",
		_not:"authUserRoles_bool_exp",
		_or:"authUserRoles_bool_exp",
		createdAt:"timestamptz_comparison_exp",
		id:"uuid_comparison_exp",
		role:"String_comparison_exp",
		roleByRole:"authRoles_bool_exp",
		user:"users_bool_exp",
		userId:"uuid_comparison_exp"
	},
	authUserRoles_constraint: "enum" as const,
	authUserRoles_insert_input:{
		createdAt:"timestamptz",
		id:"uuid",
		roleByRole:"authRoles_obj_rel_insert_input",
		user:"users_obj_rel_insert_input",
		userId:"uuid"
	},
	authUserRoles_max_order_by:{
		createdAt:"order_by",
		id:"order_by",
		role:"order_by",
		userId:"order_by"
	},
	authUserRoles_min_order_by:{
		createdAt:"order_by",
		id:"order_by",
		role:"order_by",
		userId:"order_by"
	},
	authUserRoles_on_conflict:{
		constraint:"authUserRoles_constraint",
		update_columns:"authUserRoles_update_column",
		where:"authUserRoles_bool_exp"
	},
	authUserRoles_order_by:{
		createdAt:"order_by",
		id:"order_by",
		role:"order_by",
		roleByRole:"authRoles_order_by",
		user:"users_order_by",
		userId:"order_by"
	},
	authUserRoles_pk_columns_input:{
		id:"uuid"
	},
	authUserRoles_select_column: "enum" as const,
	authUserRoles_set_input:{
		createdAt:"timestamptz",
		id:"uuid",
		userId:"uuid"
	},
	authUserRoles_stream_cursor_input:{
		initial_value:"authUserRoles_stream_cursor_value_input",
		ordering:"cursor_ordering"
	},
	authUserRoles_stream_cursor_value_input:{
		createdAt:"timestamptz",
		id:"uuid",
		userId:"uuid"
	},
	authUserRoles_update_column: "enum" as const,
	authUserRoles_updates:{
		_set:"authUserRoles_set_input",
		where:"authUserRoles_bool_exp"
	},
	authUserSecurityKeys_aggregate_bool_exp:{
		count:"authUserSecurityKeys_aggregate_bool_exp_count"
	},
	authUserSecurityKeys_aggregate_bool_exp_count:{
		arguments:"authUserSecurityKeys_select_column",
		filter:"authUserSecurityKeys_bool_exp",
		predicate:"Int_comparison_exp"
	},
	authUserSecurityKeys_aggregate_fields:{
		count:{
			columns:"authUserSecurityKeys_select_column"
		}
	},
	authUserSecurityKeys_aggregate_order_by:{
		avg:"authUserSecurityKeys_avg_order_by",
		count:"order_by",
		max:"authUserSecurityKeys_max_order_by",
		min:"authUserSecurityKeys_min_order_by",
		stddev:"authUserSecurityKeys_stddev_order_by",
		stddev_pop:"authUserSecurityKeys_stddev_pop_order_by",
		stddev_samp:"authUserSecurityKeys_stddev_samp_order_by",
		sum:"authUserSecurityKeys_sum_order_by",
		var_pop:"authUserSecurityKeys_var_pop_order_by",
		var_samp:"authUserSecurityKeys_var_samp_order_by",
		variance:"authUserSecurityKeys_variance_order_by"
	},
	authUserSecurityKeys_arr_rel_insert_input:{
		data:"authUserSecurityKeys_insert_input",
		on_conflict:"authUserSecurityKeys_on_conflict"
	},
	authUserSecurityKeys_avg_order_by:{
		counter:"order_by"
	},
	authUserSecurityKeys_bool_exp:{
		_and:"authUserSecurityKeys_bool_exp",
		_not:"authUserSecurityKeys_bool_exp",
		_or:"authUserSecurityKeys_bool_exp",
		counter:"bigint_comparison_exp",
		credentialId:"String_comparison_exp",
		credentialPublicKey:"bytea_comparison_exp",
		id:"uuid_comparison_exp",
		nickname:"String_comparison_exp",
		transports:"String_comparison_exp",
		user:"users_bool_exp",
		userId:"uuid_comparison_exp"
	},
	authUserSecurityKeys_constraint: "enum" as const,
	authUserSecurityKeys_inc_input:{
		counter:"bigint"
	},
	authUserSecurityKeys_insert_input:{
		counter:"bigint",
		credentialPublicKey:"bytea",
		id:"uuid",
		user:"users_obj_rel_insert_input",
		userId:"uuid"
	},
	authUserSecurityKeys_max_order_by:{
		counter:"order_by",
		credentialId:"order_by",
		id:"order_by",
		nickname:"order_by",
		transports:"order_by",
		userId:"order_by"
	},
	authUserSecurityKeys_min_order_by:{
		counter:"order_by",
		credentialId:"order_by",
		id:"order_by",
		nickname:"order_by",
		transports:"order_by",
		userId:"order_by"
	},
	authUserSecurityKeys_on_conflict:{
		constraint:"authUserSecurityKeys_constraint",
		update_columns:"authUserSecurityKeys_update_column",
		where:"authUserSecurityKeys_bool_exp"
	},
	authUserSecurityKeys_order_by:{
		counter:"order_by",
		credentialId:"order_by",
		credentialPublicKey:"order_by",
		id:"order_by",
		nickname:"order_by",
		transports:"order_by",
		user:"users_order_by",
		userId:"order_by"
	},
	authUserSecurityKeys_pk_columns_input:{
		id:"uuid"
	},
	authUserSecurityKeys_select_column: "enum" as const,
	authUserSecurityKeys_set_input:{
		counter:"bigint",
		credentialPublicKey:"bytea",
		id:"uuid",
		userId:"uuid"
	},
	authUserSecurityKeys_stddev_order_by:{
		counter:"order_by"
	},
	authUserSecurityKeys_stddev_pop_order_by:{
		counter:"order_by"
	},
	authUserSecurityKeys_stddev_samp_order_by:{
		counter:"order_by"
	},
	authUserSecurityKeys_stream_cursor_input:{
		initial_value:"authUserSecurityKeys_stream_cursor_value_input",
		ordering:"cursor_ordering"
	},
	authUserSecurityKeys_stream_cursor_value_input:{
		counter:"bigint",
		credentialPublicKey:"bytea",
		id:"uuid",
		userId:"uuid"
	},
	authUserSecurityKeys_sum_order_by:{
		counter:"order_by"
	},
	authUserSecurityKeys_update_column: "enum" as const,
	authUserSecurityKeys_updates:{
		_inc:"authUserSecurityKeys_inc_input",
		_set:"authUserSecurityKeys_set_input",
		where:"authUserSecurityKeys_bool_exp"
	},
	authUserSecurityKeys_var_pop_order_by:{
		counter:"order_by"
	},
	authUserSecurityKeys_var_samp_order_by:{
		counter:"order_by"
	},
	authUserSecurityKeys_variance_order_by:{
		counter:"order_by"
	},
	bigint: `scalar.bigint` as const,
	bigint_comparison_exp:{
		_eq:"bigint",
		_gt:"bigint",
		_gte:"bigint",
		_in:"bigint",
		_lt:"bigint",
		_lte:"bigint",
		_neq:"bigint",
		_nin:"bigint"
	},
	blogCategory:{
		blogs:{
			distinct_on:"blog_select_column",
			order_by:"blog_order_by",
			where:"blog_bool_exp"
		},
		blogs_aggregate:{
			distinct_on:"blog_select_column",
			order_by:"blog_order_by",
			where:"blog_bool_exp"
		}
	},
	blogCategory_aggregate_fields:{
		count:{
			columns:"blogCategory_select_column"
		}
	},
	blogCategory_bool_exp:{
		_and:"blogCategory_bool_exp",
		_not:"blogCategory_bool_exp",
		_or:"blogCategory_bool_exp",
		blogs:"blog_bool_exp",
		blogs_aggregate:"blog_aggregate_bool_exp",
		created_at:"timestamptz_comparison_exp",
		id:"uuid_comparison_exp",
		name:"String_comparison_exp",
		updated_at:"timestamptz_comparison_exp"
	},
	blogCategory_constraint: "enum" as const,
	blogCategory_insert_input:{
		blogs:"blog_arr_rel_insert_input",
		created_at:"timestamptz",
		id:"uuid",
		updated_at:"timestamptz"
	},
	blogCategory_obj_rel_insert_input:{
		data:"blogCategory_insert_input",
		on_conflict:"blogCategory_on_conflict"
	},
	blogCategory_on_conflict:{
		constraint:"blogCategory_constraint",
		update_columns:"blogCategory_update_column",
		where:"blogCategory_bool_exp"
	},
	blogCategory_order_by:{
		blogs_aggregate:"blog_aggregate_order_by",
		created_at:"order_by",
		id:"order_by",
		name:"order_by",
		updated_at:"order_by"
	},
	blogCategory_pk_columns_input:{
		id:"uuid"
	},
	blogCategory_select_column: "enum" as const,
	blogCategory_set_input:{
		created_at:"timestamptz",
		id:"uuid",
		updated_at:"timestamptz"
	},
	blogCategory_stream_cursor_input:{
		initial_value:"blogCategory_stream_cursor_value_input",
		ordering:"cursor_ordering"
	},
	blogCategory_stream_cursor_value_input:{
		created_at:"timestamptz",
		id:"uuid",
		updated_at:"timestamptz"
	},
	blogCategory_update_column: "enum" as const,
	blogCategory_updates:{
		_set:"blogCategory_set_input",
		where:"blogCategory_bool_exp"
	},
	blog_aggregate_bool_exp:{
		count:"blog_aggregate_bool_exp_count"
	},
	blog_aggregate_bool_exp_count:{
		arguments:"blog_select_column",
		filter:"blog_bool_exp",
		predicate:"Int_comparison_exp"
	},
	blog_aggregate_fields:{
		count:{
			columns:"blog_select_column"
		}
	},
	blog_aggregate_order_by:{
		count:"order_by",
		max:"blog_max_order_by",
		min:"blog_min_order_by"
	},
	blog_arr_rel_insert_input:{
		data:"blog_insert_input",
		on_conflict:"blog_on_conflict"
	},
	blog_bool_exp:{
		_and:"blog_bool_exp",
		_not:"blog_bool_exp",
		_or:"blog_bool_exp",
		brand:"brands_bool_exp",
		brandId:"uuid_comparison_exp",
		category:"blogCategory_bool_exp",
		categoryId:"uuid_comparison_exp",
		content:"String_comparison_exp",
		created_at:"timestamptz_comparison_exp",
		created_by:"uuid_comparison_exp",
		featuredImageId:"uuid_comparison_exp",
		file:"files_bool_exp",
		id:"uuid_comparison_exp",
		metaDescription:"String_comparison_exp",
		project:"project_bool_exp",
		projectId:"uuid_comparison_exp",
		publishDate:"timestamptz_comparison_exp",
		scheduledDate:"timestamptz_comparison_exp",
		slug:"String_comparison_exp",
		title:"String_comparison_exp",
		updated_at:"timestamptz_comparison_exp",
		user:"users_bool_exp"
	},
	blog_constraint: "enum" as const,
	blog_insert_input:{
		brand:"brands_obj_rel_insert_input",
		brandId:"uuid",
		category:"blogCategory_obj_rel_insert_input",
		categoryId:"uuid",
		created_at:"timestamptz",
		created_by:"uuid",
		featuredImageId:"uuid",
		file:"files_obj_rel_insert_input",
		id:"uuid",
		project:"project_obj_rel_insert_input",
		projectId:"uuid",
		publishDate:"timestamptz",
		scheduledDate:"timestamptz",
		updated_at:"timestamptz",
		user:"users_obj_rel_insert_input"
	},
	blog_max_order_by:{
		brandId:"order_by",
		categoryId:"order_by",
		content:"order_by",
		created_at:"order_by",
		created_by:"order_by",
		featuredImageId:"order_by",
		id:"order_by",
		metaDescription:"order_by",
		projectId:"order_by",
		publishDate:"order_by",
		scheduledDate:"order_by",
		slug:"order_by",
		title:"order_by",
		updated_at:"order_by"
	},
	blog_min_order_by:{
		brandId:"order_by",
		categoryId:"order_by",
		content:"order_by",
		created_at:"order_by",
		created_by:"order_by",
		featuredImageId:"order_by",
		id:"order_by",
		metaDescription:"order_by",
		projectId:"order_by",
		publishDate:"order_by",
		scheduledDate:"order_by",
		slug:"order_by",
		title:"order_by",
		updated_at:"order_by"
	},
	blog_on_conflict:{
		constraint:"blog_constraint",
		update_columns:"blog_update_column",
		where:"blog_bool_exp"
	},
	blog_order_by:{
		brand:"brands_order_by",
		brandId:"order_by",
		category:"blogCategory_order_by",
		categoryId:"order_by",
		content:"order_by",
		created_at:"order_by",
		created_by:"order_by",
		featuredImageId:"order_by",
		file:"files_order_by",
		id:"order_by",
		metaDescription:"order_by",
		project:"project_order_by",
		projectId:"order_by",
		publishDate:"order_by",
		scheduledDate:"order_by",
		slug:"order_by",
		title:"order_by",
		updated_at:"order_by",
		user:"users_order_by"
	},
	blog_pk_columns_input:{
		id:"uuid"
	},
	blog_select_column: "enum" as const,
	blog_set_input:{
		brandId:"uuid",
		categoryId:"uuid",
		created_at:"timestamptz",
		created_by:"uuid",
		featuredImageId:"uuid",
		id:"uuid",
		projectId:"uuid",
		publishDate:"timestamptz",
		scheduledDate:"timestamptz",
		updated_at:"timestamptz"
	},
	blog_stream_cursor_input:{
		initial_value:"blog_stream_cursor_value_input",
		ordering:"cursor_ordering"
	},
	blog_stream_cursor_value_input:{
		brandId:"uuid",
		categoryId:"uuid",
		created_at:"timestamptz",
		created_by:"uuid",
		featuredImageId:"uuid",
		id:"uuid",
		projectId:"uuid",
		publishDate:"timestamptz",
		scheduledDate:"timestamptz",
		updated_at:"timestamptz"
	},
	blog_update_column: "enum" as const,
	blog_updates:{
		_set:"blog_set_input",
		where:"blog_bool_exp"
	},
	brands:{
		archtypeAdherence:{

		},
		blogs:{
			distinct_on:"blog_select_column",
			order_by:"blog_order_by",
			where:"blog_bool_exp"
		},
		blogs_aggregate:{
			distinct_on:"blog_select_column",
			order_by:"blog_order_by",
			where:"blog_bool_exp"
		},
		colors:{

		},
		products:{
			distinct_on:"products_select_column",
			order_by:"products_order_by",
			where:"products_bool_exp"
		},
		products_aggregate:{
			distinct_on:"products_select_column",
			order_by:"products_order_by",
			where:"products_bool_exp"
		}
	},
	brands_aggregate_fields:{
		count:{
			columns:"brands_select_column"
		}
	},
	brands_append_input:{
		archtypeAdherence:"jsonb",
		colors:"jsonb"
	},
	brands_bool_exp:{
		_and:"brands_bool_exp",
		_not:"brands_bool_exp",
		_or:"brands_bool_exp",
		addressCity:"String_comparison_exp",
		addressComplement:"String_comparison_exp",
		addressDistrict:"String_comparison_exp",
		addressLatitude:"String_comparison_exp",
		addressLongitude:"String_comparison_exp",
		addressNumber:"String_comparison_exp",
		addressStreet:"String_comparison_exp",
		addressUf:"String_comparison_exp",
		addressZipCode:"String_comparison_exp",
		archtypeAdherence:"jsonb_comparison_exp",
		blogs:"blog_bool_exp",
		blogs_aggregate:"blog_aggregate_bool_exp",
		brandName:"String_comparison_exp",
		colors:"jsonb_comparison_exp",
		created_at:"timestamptz_comparison_exp",
		created_by:"uuid_comparison_exp",
		deleted:"Boolean_comparison_exp",
		email:"String_comparison_exp",
		facebookUrl:"String_comparison_exp",
		fontPrimary:"String_comparison_exp",
		fontSecondary:"String_comparison_exp",
		id:"uuid_comparison_exp",
		instagramUrl:"String_comparison_exp",
		isBrand:"Boolean_comparison_exp",
		linkedinUrl:"String_comparison_exp",
		logoFileId:"uuid_comparison_exp",
		personality:"String_comparison_exp",
		phoneNumber:"String_comparison_exp",
		productOrService:"String_comparison_exp",
		products:"products_bool_exp",
		products_aggregate:"products_aggregate_bool_exp",
		segment:"String_comparison_exp",
		shortAbout:"String_comparison_exp",
		slogan:"String_comparison_exp",
		tiktokUrl:"String_comparison_exp",
		twitterUrl:"String_comparison_exp",
		updated_at:"timestamptz_comparison_exp",
		voiceTone:"String_comparison_exp",
		whatsappNumber:"String_comparison_exp",
		youtubeUrl:"String_comparison_exp"
	},
	brands_constraint: "enum" as const,
	brands_delete_at_path_input:{

	},
	brands_delete_elem_input:{

	},
	brands_delete_key_input:{

	},
	brands_insert_input:{
		archtypeAdherence:"jsonb",
		blogs:"blog_arr_rel_insert_input",
		colors:"jsonb",
		created_at:"timestamptz",
		created_by:"uuid",
		id:"uuid",
		logoFileId:"uuid",
		products:"products_arr_rel_insert_input",
		updated_at:"timestamptz"
	},
	brands_obj_rel_insert_input:{
		data:"brands_insert_input",
		on_conflict:"brands_on_conflict"
	},
	brands_on_conflict:{
		constraint:"brands_constraint",
		update_columns:"brands_update_column",
		where:"brands_bool_exp"
	},
	brands_order_by:{
		addressCity:"order_by",
		addressComplement:"order_by",
		addressDistrict:"order_by",
		addressLatitude:"order_by",
		addressLongitude:"order_by",
		addressNumber:"order_by",
		addressStreet:"order_by",
		addressUf:"order_by",
		addressZipCode:"order_by",
		archtypeAdherence:"order_by",
		blogs_aggregate:"blog_aggregate_order_by",
		brandName:"order_by",
		colors:"order_by",
		created_at:"order_by",
		created_by:"order_by",
		deleted:"order_by",
		email:"order_by",
		facebookUrl:"order_by",
		fontPrimary:"order_by",
		fontSecondary:"order_by",
		id:"order_by",
		instagramUrl:"order_by",
		isBrand:"order_by",
		linkedinUrl:"order_by",
		logoFileId:"order_by",
		personality:"order_by",
		phoneNumber:"order_by",
		productOrService:"order_by",
		products_aggregate:"products_aggregate_order_by",
		segment:"order_by",
		shortAbout:"order_by",
		slogan:"order_by",
		tiktokUrl:"order_by",
		twitterUrl:"order_by",
		updated_at:"order_by",
		voiceTone:"order_by",
		whatsappNumber:"order_by",
		youtubeUrl:"order_by"
	},
	brands_pk_columns_input:{
		id:"uuid"
	},
	brands_prepend_input:{
		archtypeAdherence:"jsonb",
		colors:"jsonb"
	},
	brands_select_column: "enum" as const,
	brands_set_input:{
		archtypeAdherence:"jsonb",
		colors:"jsonb",
		created_at:"timestamptz",
		created_by:"uuid",
		id:"uuid",
		logoFileId:"uuid",
		updated_at:"timestamptz"
	},
	brands_stream_cursor_input:{
		initial_value:"brands_stream_cursor_value_input",
		ordering:"cursor_ordering"
	},
	brands_stream_cursor_value_input:{
		archtypeAdherence:"jsonb",
		colors:"jsonb",
		created_at:"timestamptz",
		created_by:"uuid",
		id:"uuid",
		logoFileId:"uuid",
		updated_at:"timestamptz"
	},
	brands_update_column: "enum" as const,
	brands_updates:{
		_append:"brands_append_input",
		_delete_at_path:"brands_delete_at_path_input",
		_delete_elem:"brands_delete_elem_input",
		_delete_key:"brands_delete_key_input",
		_prepend:"brands_prepend_input",
		_set:"brands_set_input",
		where:"brands_bool_exp"
	},
	buckets:{
		files:{
			distinct_on:"files_select_column",
			order_by:"files_order_by",
			where:"files_bool_exp"
		},
		files_aggregate:{
			distinct_on:"files_select_column",
			order_by:"files_order_by",
			where:"files_bool_exp"
		}
	},
	buckets_aggregate_fields:{
		count:{
			columns:"buckets_select_column"
		}
	},
	buckets_bool_exp:{
		_and:"buckets_bool_exp",
		_not:"buckets_bool_exp",
		_or:"buckets_bool_exp",
		cacheControl:"String_comparison_exp",
		createdAt:"timestamptz_comparison_exp",
		downloadExpiration:"Int_comparison_exp",
		files:"files_bool_exp",
		files_aggregate:"files_aggregate_bool_exp",
		id:"String_comparison_exp",
		maxUploadFileSize:"Int_comparison_exp",
		minUploadFileSize:"Int_comparison_exp",
		presignedUrlsEnabled:"Boolean_comparison_exp",
		updatedAt:"timestamptz_comparison_exp"
	},
	buckets_constraint: "enum" as const,
	buckets_inc_input:{

	},
	buckets_insert_input:{
		createdAt:"timestamptz",
		files:"files_arr_rel_insert_input",
		updatedAt:"timestamptz"
	},
	buckets_obj_rel_insert_input:{
		data:"buckets_insert_input",
		on_conflict:"buckets_on_conflict"
	},
	buckets_on_conflict:{
		constraint:"buckets_constraint",
		update_columns:"buckets_update_column",
		where:"buckets_bool_exp"
	},
	buckets_order_by:{
		cacheControl:"order_by",
		createdAt:"order_by",
		downloadExpiration:"order_by",
		files_aggregate:"files_aggregate_order_by",
		id:"order_by",
		maxUploadFileSize:"order_by",
		minUploadFileSize:"order_by",
		presignedUrlsEnabled:"order_by",
		updatedAt:"order_by"
	},
	buckets_pk_columns_input:{

	},
	buckets_select_column: "enum" as const,
	buckets_set_input:{
		createdAt:"timestamptz",
		updatedAt:"timestamptz"
	},
	buckets_stream_cursor_input:{
		initial_value:"buckets_stream_cursor_value_input",
		ordering:"cursor_ordering"
	},
	buckets_stream_cursor_value_input:{
		createdAt:"timestamptz",
		updatedAt:"timestamptz"
	},
	buckets_update_column: "enum" as const,
	buckets_updates:{
		_inc:"buckets_inc_input",
		_set:"buckets_set_input",
		where:"buckets_bool_exp"
	},
	bytea: `scalar.bytea` as const,
	bytea_comparison_exp:{
		_eq:"bytea",
		_gt:"bytea",
		_gte:"bytea",
		_in:"bytea",
		_lt:"bytea",
		_lte:"bytea",
		_neq:"bytea",
		_nin:"bytea"
	},
	campaign:{
		baseJson:{

		}
	},
	campaign_aggregate_fields:{
		count:{
			columns:"campaign_select_column"
		}
	},
	campaign_append_input:{
		baseJson:"jsonb"
	},
	campaign_bool_exp:{
		_and:"campaign_bool_exp",
		_not:"campaign_bool_exp",
		_or:"campaign_bool_exp",
		audiencesIds:"String_comparison_exp",
		baseJson:"jsonb_comparison_exp",
		brandId:"uuid_comparison_exp",
		campaignObjective:"String_comparison_exp",
		created_at:"timestamptz_comparison_exp",
		created_by:"uuid_comparison_exp",
		id:"uuid_comparison_exp",
		productIds:"String_comparison_exp",
		updated_at:"timestamptz_comparison_exp"
	},
	campaign_constraint: "enum" as const,
	campaign_delete_at_path_input:{

	},
	campaign_delete_elem_input:{

	},
	campaign_delete_key_input:{

	},
	campaign_insert_input:{
		baseJson:"jsonb",
		brandId:"uuid",
		created_at:"timestamptz",
		created_by:"uuid",
		id:"uuid",
		updated_at:"timestamptz"
	},
	campaign_on_conflict:{
		constraint:"campaign_constraint",
		update_columns:"campaign_update_column",
		where:"campaign_bool_exp"
	},
	campaign_order_by:{
		audiencesIds:"order_by",
		baseJson:"order_by",
		brandId:"order_by",
		campaignObjective:"order_by",
		created_at:"order_by",
		created_by:"order_by",
		id:"order_by",
		productIds:"order_by",
		updated_at:"order_by"
	},
	campaign_pk_columns_input:{
		id:"uuid"
	},
	campaign_prepend_input:{
		baseJson:"jsonb"
	},
	campaign_select_column: "enum" as const,
	campaign_set_input:{
		baseJson:"jsonb",
		brandId:"uuid",
		created_at:"timestamptz",
		created_by:"uuid",
		id:"uuid",
		updated_at:"timestamptz"
	},
	campaign_stream_cursor_input:{
		initial_value:"campaign_stream_cursor_value_input",
		ordering:"cursor_ordering"
	},
	campaign_stream_cursor_value_input:{
		baseJson:"jsonb",
		brandId:"uuid",
		created_at:"timestamptz",
		created_by:"uuid",
		id:"uuid",
		updated_at:"timestamptz"
	},
	campaign_update_column: "enum" as const,
	campaign_updates:{
		_append:"campaign_append_input",
		_delete_at_path:"campaign_delete_at_path_input",
		_delete_elem:"campaign_delete_elem_input",
		_delete_key:"campaign_delete_key_input",
		_prepend:"campaign_prepend_input",
		_set:"campaign_set_input",
		where:"campaign_bool_exp"
	},
	categorysummaryview_aggregate_fields:{
		count:{
			columns:"categorysummaryview_select_column"
		}
	},
	categorysummaryview_bool_exp:{
		_and:"categorysummaryview_bool_exp",
		_not:"categorysummaryview_bool_exp",
		_or:"categorysummaryview_bool_exp",
		categoryName:"String_comparison_exp",
		count:"bigint_comparison_exp",
		id:"uuid_comparison_exp"
	},
	categorysummaryview_order_by:{
		categoryName:"order_by",
		count:"order_by",
		id:"order_by"
	},
	categorysummaryview_select_column: "enum" as const,
	categorysummaryview_stream_cursor_input:{
		initial_value:"categorysummaryview_stream_cursor_value_input",
		ordering:"cursor_ordering"
	},
	categorysummaryview_stream_cursor_value_input:{
		count:"bigint",
		id:"uuid"
	},
	channels_aggregate_fields:{
		count:{
			columns:"channels_select_column"
		}
	},
	channels_bool_exp:{
		_and:"channels_bool_exp",
		_not:"channels_bool_exp",
		_or:"channels_bool_exp",
		alias:"String_comparison_exp",
		created_at:"timestamp_comparison_exp",
		displayName:"String_comparison_exp",
		id:"uuid_comparison_exp",
		updated_at:"timestamp_comparison_exp"
	},
	channels_constraint: "enum" as const,
	channels_insert_input:{
		created_at:"timestamp",
		id:"uuid",
		updated_at:"timestamp"
	},
	channels_on_conflict:{
		constraint:"channels_constraint",
		update_columns:"channels_update_column",
		where:"channels_bool_exp"
	},
	channels_order_by:{
		alias:"order_by",
		created_at:"order_by",
		displayName:"order_by",
		id:"order_by",
		updated_at:"order_by"
	},
	channels_pk_columns_input:{
		id:"uuid"
	},
	channels_select_column: "enum" as const,
	channels_set_input:{
		created_at:"timestamp",
		id:"uuid",
		updated_at:"timestamp"
	},
	channels_stream_cursor_input:{
		initial_value:"channels_stream_cursor_value_input",
		ordering:"cursor_ordering"
	},
	channels_stream_cursor_value_input:{
		created_at:"timestamp",
		id:"uuid",
		updated_at:"timestamp"
	},
	channels_update_column: "enum" as const,
	channels_updates:{
		_set:"channels_set_input",
		where:"channels_bool_exp"
	},
	citext: `scalar.citext` as const,
	citext_comparison_exp:{
		_eq:"citext",
		_gt:"citext",
		_gte:"citext",
		_ilike:"citext",
		_in:"citext",
		_iregex:"citext",
		_like:"citext",
		_lt:"citext",
		_lte:"citext",
		_neq:"citext",
		_nilike:"citext",
		_nin:"citext",
		_niregex:"citext",
		_nlike:"citext",
		_nregex:"citext",
		_nsimilar:"citext",
		_regex:"citext",
		_similar:"citext"
	},
	consumptionByChannelView_aggregate_fields:{
		count:{
			columns:"consumptionByChannelView_select_column"
		}
	},
	consumptionByChannelView_bool_exp:{
		_and:"consumptionByChannelView_bool_exp",
		_not:"consumptionByChannelView_bool_exp",
		_or:"consumptionByChannelView_bool_exp",
		agendados:"bigint_comparison_exp",
		channel:"String_comparison_exp",
		copymagico:"numeric_comparison_exp",
		created_by:"uuid_comparison_exp",
		economia:"numeric_comparison_exp",
		mercado:"bigint_comparison_exp",
		publicados:"bigint_comparison_exp",
		rascunhos:"bigint_comparison_exp"
	},
	consumptionByChannelView_order_by:{
		agendados:"order_by",
		channel:"order_by",
		copymagico:"order_by",
		created_by:"order_by",
		economia:"order_by",
		mercado:"order_by",
		publicados:"order_by",
		rascunhos:"order_by"
	},
	consumptionByChannelView_select_column: "enum" as const,
	consumptionByChannelView_stream_cursor_input:{
		initial_value:"consumptionByChannelView_stream_cursor_value_input",
		ordering:"cursor_ordering"
	},
	consumptionByChannelView_stream_cursor_value_input:{
		agendados:"bigint",
		copymagico:"numeric",
		created_by:"uuid",
		economia:"numeric",
		mercado:"bigint",
		publicados:"bigint",
		rascunhos:"bigint"
	},
	costTable_aggregate_fields:{
		count:{
			columns:"costTable_select_column"
		}
	},
	costTable_bool_exp:{
		_and:"costTable_bool_exp",
		_not:"costTable_bool_exp",
		_or:"costTable_bool_exp",
		bannerBear:"numeric_comparison_exp",
		created_at:"timestamptz_comparison_exp",
		entityId:"String_comparison_exp",
		entityName:"String_comparison_exp",
		gptCompletionTokens:"numeric_comparison_exp",
		gptPromptTokens:"numeric_comparison_exp",
		id:"uuid_comparison_exp",
		provider:"String_comparison_exp"
	},
	costTable_constraint: "enum" as const,
	costTable_inc_input:{
		bannerBear:"numeric",
		gptCompletionTokens:"numeric",
		gptPromptTokens:"numeric"
	},
	costTable_insert_input:{
		bannerBear:"numeric",
		created_at:"timestamptz",
		gptCompletionTokens:"numeric",
		gptPromptTokens:"numeric",
		id:"uuid"
	},
	costTable_on_conflict:{
		constraint:"costTable_constraint",
		update_columns:"costTable_update_column",
		where:"costTable_bool_exp"
	},
	costTable_order_by:{
		bannerBear:"order_by",
		created_at:"order_by",
		entityId:"order_by",
		entityName:"order_by",
		gptCompletionTokens:"order_by",
		gptPromptTokens:"order_by",
		id:"order_by",
		provider:"order_by"
	},
	costTable_pk_columns_input:{
		id:"uuid"
	},
	costTable_select_column: "enum" as const,
	costTable_set_input:{
		bannerBear:"numeric",
		created_at:"timestamptz",
		gptCompletionTokens:"numeric",
		gptPromptTokens:"numeric",
		id:"uuid"
	},
	costTable_stream_cursor_input:{
		initial_value:"costTable_stream_cursor_value_input",
		ordering:"cursor_ordering"
	},
	costTable_stream_cursor_value_input:{
		bannerBear:"numeric",
		created_at:"timestamptz",
		gptCompletionTokens:"numeric",
		gptPromptTokens:"numeric",
		id:"uuid"
	},
	costTable_update_column: "enum" as const,
	costTable_updates:{
		_inc:"costTable_inc_input",
		_set:"costTable_set_input",
		where:"costTable_bool_exp"
	},
	cursor_ordering: "enum" as const,
	date: `scalar.date` as const,
	date_comparison_exp:{
		_eq:"date",
		_gt:"date",
		_gte:"date",
		_in:"date",
		_lt:"date",
		_lte:"date",
		_neq:"date",
		_nin:"date"
	},
	deal:{
		interactions:{
			distinct_on:"interaction_select_column",
			order_by:"interaction_order_by",
			where:"interaction_bool_exp"
		},
		interactions_aggregate:{
			distinct_on:"interaction_select_column",
			order_by:"interaction_order_by",
			where:"interaction_bool_exp"
		},
		tasks:{
			distinct_on:"task_select_column",
			order_by:"task_order_by",
			where:"task_bool_exp"
		},
		tasks_aggregate:{
			distinct_on:"task_select_column",
			order_by:"task_order_by",
			where:"task_bool_exp"
		}
	},
	dealStatus:{
		deals:{
			distinct_on:"deal_select_column",
			order_by:"deal_order_by",
			where:"deal_bool_exp"
		},
		deals_aggregate:{
			distinct_on:"deal_select_column",
			order_by:"deal_order_by",
			where:"deal_bool_exp"
		}
	},
	dealStatus_aggregate_fields:{
		count:{
			columns:"dealStatus_select_column"
		}
	},
	dealStatus_bool_exp:{
		_and:"dealStatus_bool_exp",
		_not:"dealStatus_bool_exp",
		_or:"dealStatus_bool_exp",
		deals:"deal_bool_exp",
		deals_aggregate:"deal_aggregate_bool_exp",
		displayName:"String_comparison_exp",
		id:"uuid_comparison_exp",
		order:"Int_comparison_exp"
	},
	dealStatus_constraint: "enum" as const,
	dealStatus_inc_input:{

	},
	dealStatus_insert_input:{
		deals:"deal_arr_rel_insert_input",
		id:"uuid"
	},
	dealStatus_obj_rel_insert_input:{
		data:"dealStatus_insert_input",
		on_conflict:"dealStatus_on_conflict"
	},
	dealStatus_on_conflict:{
		constraint:"dealStatus_constraint",
		update_columns:"dealStatus_update_column",
		where:"dealStatus_bool_exp"
	},
	dealStatus_order_by:{
		deals_aggregate:"deal_aggregate_order_by",
		displayName:"order_by",
		id:"order_by",
		order:"order_by"
	},
	dealStatus_pk_columns_input:{
		id:"uuid"
	},
	dealStatus_select_column: "enum" as const,
	dealStatus_set_input:{
		id:"uuid"
	},
	dealStatus_stream_cursor_input:{
		initial_value:"dealStatus_stream_cursor_value_input",
		ordering:"cursor_ordering"
	},
	dealStatus_stream_cursor_value_input:{
		id:"uuid"
	},
	dealStatus_update_column: "enum" as const,
	dealStatus_updates:{
		_inc:"dealStatus_inc_input",
		_set:"dealStatus_set_input",
		where:"dealStatus_bool_exp"
	},
	deal_aggregate_bool_exp:{
		count:"deal_aggregate_bool_exp_count"
	},
	deal_aggregate_bool_exp_count:{
		arguments:"deal_select_column",
		filter:"deal_bool_exp",
		predicate:"Int_comparison_exp"
	},
	deal_aggregate_fields:{
		count:{
			columns:"deal_select_column"
		}
	},
	deal_aggregate_order_by:{
		avg:"deal_avg_order_by",
		count:"order_by",
		max:"deal_max_order_by",
		min:"deal_min_order_by",
		stddev:"deal_stddev_order_by",
		stddev_pop:"deal_stddev_pop_order_by",
		stddev_samp:"deal_stddev_samp_order_by",
		sum:"deal_sum_order_by",
		var_pop:"deal_var_pop_order_by",
		var_samp:"deal_var_samp_order_by",
		variance:"deal_variance_order_by"
	},
	deal_arr_rel_insert_input:{
		data:"deal_insert_input",
		on_conflict:"deal_on_conflict"
	},
	deal_avg_order_by:{
		rating:"order_by"
	},
	deal_bool_exp:{
		_and:"deal_bool_exp",
		_not:"deal_bool_exp",
		_or:"deal_bool_exp",
		created_at:"timestamptz_comparison_exp",
		dealStatus:"dealStatus_bool_exp",
		email:"String_comparison_exp",
		id:"uuid_comparison_exp",
		interactions:"interaction_bool_exp",
		interactions_aggregate:"interaction_aggregate_bool_exp",
		name:"String_comparison_exp",
		phone:"String_comparison_exp",
		product:"products_bool_exp",
		productId:"uuid_comparison_exp",
		rating:"Int_comparison_exp",
		statusId:"uuid_comparison_exp",
		tasks:"task_bool_exp",
		tasks_aggregate:"task_aggregate_bool_exp",
		updated_at:"timestamptz_comparison_exp"
	},
	deal_constraint: "enum" as const,
	deal_inc_input:{

	},
	deal_insert_input:{
		created_at:"timestamptz",
		dealStatus:"dealStatus_obj_rel_insert_input",
		id:"uuid",
		interactions:"interaction_arr_rel_insert_input",
		product:"products_obj_rel_insert_input",
		productId:"uuid",
		statusId:"uuid",
		tasks:"task_arr_rel_insert_input",
		updated_at:"timestamptz"
	},
	deal_max_order_by:{
		created_at:"order_by",
		email:"order_by",
		id:"order_by",
		name:"order_by",
		phone:"order_by",
		productId:"order_by",
		rating:"order_by",
		statusId:"order_by",
		updated_at:"order_by"
	},
	deal_min_order_by:{
		created_at:"order_by",
		email:"order_by",
		id:"order_by",
		name:"order_by",
		phone:"order_by",
		productId:"order_by",
		rating:"order_by",
		statusId:"order_by",
		updated_at:"order_by"
	},
	deal_obj_rel_insert_input:{
		data:"deal_insert_input",
		on_conflict:"deal_on_conflict"
	},
	deal_on_conflict:{
		constraint:"deal_constraint",
		update_columns:"deal_update_column",
		where:"deal_bool_exp"
	},
	deal_order_by:{
		created_at:"order_by",
		dealStatus:"dealStatus_order_by",
		email:"order_by",
		id:"order_by",
		interactions_aggregate:"interaction_aggregate_order_by",
		name:"order_by",
		phone:"order_by",
		product:"products_order_by",
		productId:"order_by",
		rating:"order_by",
		statusId:"order_by",
		tasks_aggregate:"task_aggregate_order_by",
		updated_at:"order_by"
	},
	deal_pk_columns_input:{
		id:"uuid"
	},
	deal_select_column: "enum" as const,
	deal_set_input:{
		created_at:"timestamptz",
		id:"uuid",
		productId:"uuid",
		statusId:"uuid",
		updated_at:"timestamptz"
	},
	deal_stddev_order_by:{
		rating:"order_by"
	},
	deal_stddev_pop_order_by:{
		rating:"order_by"
	},
	deal_stddev_samp_order_by:{
		rating:"order_by"
	},
	deal_stream_cursor_input:{
		initial_value:"deal_stream_cursor_value_input",
		ordering:"cursor_ordering"
	},
	deal_stream_cursor_value_input:{
		created_at:"timestamptz",
		id:"uuid",
		productId:"uuid",
		statusId:"uuid",
		updated_at:"timestamptz"
	},
	deal_sum_order_by:{
		rating:"order_by"
	},
	deal_update_column: "enum" as const,
	deal_updates:{
		_inc:"deal_inc_input",
		_set:"deal_set_input",
		where:"deal_bool_exp"
	},
	deal_var_pop_order_by:{
		rating:"order_by"
	},
	deal_var_samp_order_by:{
		rating:"order_by"
	},
	deal_variance_order_by:{
		rating:"order_by"
	},
	displayCategory:{
		displayName:{

		}
	},
	displayCategory_aggregate_fields:{
		count:{
			columns:"displayCategory_select_column"
		}
	},
	displayCategory_append_input:{
		displayName:"jsonb"
	},
	displayCategory_bool_exp:{
		_and:"displayCategory_bool_exp",
		_not:"displayCategory_bool_exp",
		_or:"displayCategory_bool_exp",
		created_at:"timestamptz_comparison_exp",
		displayName:"jsonb_comparison_exp",
		id:"uuid_comparison_exp",
		name:"String_comparison_exp"
	},
	displayCategory_constraint: "enum" as const,
	displayCategory_delete_at_path_input:{

	},
	displayCategory_delete_elem_input:{

	},
	displayCategory_delete_key_input:{

	},
	displayCategory_insert_input:{
		created_at:"timestamptz",
		displayName:"jsonb",
		id:"uuid"
	},
	displayCategory_on_conflict:{
		constraint:"displayCategory_constraint",
		update_columns:"displayCategory_update_column",
		where:"displayCategory_bool_exp"
	},
	displayCategory_order_by:{
		created_at:"order_by",
		displayName:"order_by",
		id:"order_by",
		name:"order_by"
	},
	displayCategory_pk_columns_input:{
		id:"uuid"
	},
	displayCategory_prepend_input:{
		displayName:"jsonb"
	},
	displayCategory_select_column: "enum" as const,
	displayCategory_set_input:{
		created_at:"timestamptz",
		displayName:"jsonb",
		id:"uuid"
	},
	displayCategory_stream_cursor_input:{
		initial_value:"displayCategory_stream_cursor_value_input",
		ordering:"cursor_ordering"
	},
	displayCategory_stream_cursor_value_input:{
		created_at:"timestamptz",
		displayName:"jsonb",
		id:"uuid"
	},
	displayCategory_update_column: "enum" as const,
	displayCategory_updates:{
		_append:"displayCategory_append_input",
		_delete_at_path:"displayCategory_delete_at_path_input",
		_delete_elem:"displayCategory_delete_elem_input",
		_delete_key:"displayCategory_delete_key_input",
		_prepend:"displayCategory_prepend_input",
		_set:"displayCategory_set_input",
		where:"displayCategory_bool_exp"
	},
	displayDimension:{
		displayName:{

		}
	},
	displayDimension_aggregate_fields:{
		count:{
			columns:"displayDimension_select_column"
		}
	},
	displayDimension_append_input:{
		displayName:"jsonb"
	},
	displayDimension_bool_exp:{
		_and:"displayDimension_bool_exp",
		_not:"displayDimension_bool_exp",
		_or:"displayDimension_bool_exp",
		displayName:"jsonb_comparison_exp",
		id:"uuid_comparison_exp",
		name:"String_comparison_exp"
	},
	displayDimension_constraint: "enum" as const,
	displayDimension_delete_at_path_input:{

	},
	displayDimension_delete_elem_input:{

	},
	displayDimension_delete_key_input:{

	},
	displayDimension_insert_input:{
		displayName:"jsonb",
		id:"uuid"
	},
	displayDimension_obj_rel_insert_input:{
		data:"displayDimension_insert_input",
		on_conflict:"displayDimension_on_conflict"
	},
	displayDimension_on_conflict:{
		constraint:"displayDimension_constraint",
		update_columns:"displayDimension_update_column",
		where:"displayDimension_bool_exp"
	},
	displayDimension_order_by:{
		displayName:"order_by",
		id:"order_by",
		name:"order_by"
	},
	displayDimension_pk_columns_input:{
		id:"uuid"
	},
	displayDimension_prepend_input:{
		displayName:"jsonb"
	},
	displayDimension_select_column: "enum" as const,
	displayDimension_set_input:{
		displayName:"jsonb",
		id:"uuid"
	},
	displayDimension_stream_cursor_input:{
		initial_value:"displayDimension_stream_cursor_value_input",
		ordering:"cursor_ordering"
	},
	displayDimension_stream_cursor_value_input:{
		displayName:"jsonb",
		id:"uuid"
	},
	displayDimension_update_column: "enum" as const,
	displayDimension_updates:{
		_append:"displayDimension_append_input",
		_delete_at_path:"displayDimension_delete_at_path_input",
		_delete_elem:"displayDimension_delete_elem_input",
		_delete_key:"displayDimension_delete_key_input",
		_prepend:"displayDimension_prepend_input",
		_set:"displayDimension_set_input",
		where:"displayDimension_bool_exp"
	},
	displayFormats:{
		displayName:{

		},
		mapper:{

		},
		newData:{

		}
	},
	displayFormats_aggregate_fields:{
		count:{
			columns:"displayFormats_select_column"
		}
	},
	displayFormats_append_input:{
		displayName:"jsonb",
		mapper:"jsonb",
		newData:"jsonb"
	},
	displayFormats_bool_exp:{
		_and:"displayFormats_bool_exp",
		_not:"displayFormats_bool_exp",
		_or:"displayFormats_bool_exp",
		bannerbearId:"String_comparison_exp",
		created_at:"timestamptz_comparison_exp",
		displayCategoryId:"uuid_comparison_exp",
		displayDimension:"displayDimension_bool_exp",
		displayDimensionId:"uuid_comparison_exp",
		displayName:"jsonb_comparison_exp",
		id:"uuid_comparison_exp",
		mapper:"jsonb_comparison_exp",
		name:"String_comparison_exp",
		newData:"jsonb_comparison_exp",
		newTemplate:"String_comparison_exp",
		requiresBrand:"Boolean_comparison_exp"
	},
	displayFormats_constraint: "enum" as const,
	displayFormats_delete_at_path_input:{

	},
	displayFormats_delete_elem_input:{

	},
	displayFormats_delete_key_input:{

	},
	displayFormats_insert_input:{
		created_at:"timestamptz",
		displayCategoryId:"uuid",
		displayDimension:"displayDimension_obj_rel_insert_input",
		displayDimensionId:"uuid",
		displayName:"jsonb",
		id:"uuid",
		mapper:"jsonb",
		newData:"jsonb"
	},
	displayFormats_obj_rel_insert_input:{
		data:"displayFormats_insert_input",
		on_conflict:"displayFormats_on_conflict"
	},
	displayFormats_on_conflict:{
		constraint:"displayFormats_constraint",
		update_columns:"displayFormats_update_column",
		where:"displayFormats_bool_exp"
	},
	displayFormats_order_by:{
		bannerbearId:"order_by",
		created_at:"order_by",
		displayCategoryId:"order_by",
		displayDimension:"displayDimension_order_by",
		displayDimensionId:"order_by",
		displayName:"order_by",
		id:"order_by",
		mapper:"order_by",
		name:"order_by",
		newData:"order_by",
		newTemplate:"order_by",
		requiresBrand:"order_by"
	},
	displayFormats_pk_columns_input:{
		id:"uuid"
	},
	displayFormats_prepend_input:{
		displayName:"jsonb",
		mapper:"jsonb",
		newData:"jsonb"
	},
	displayFormats_select_column: "enum" as const,
	displayFormats_set_input:{
		created_at:"timestamptz",
		displayCategoryId:"uuid",
		displayDimensionId:"uuid",
		displayName:"jsonb",
		id:"uuid",
		mapper:"jsonb",
		newData:"jsonb"
	},
	displayFormats_stream_cursor_input:{
		initial_value:"displayFormats_stream_cursor_value_input",
		ordering:"cursor_ordering"
	},
	displayFormats_stream_cursor_value_input:{
		created_at:"timestamptz",
		displayCategoryId:"uuid",
		displayDimensionId:"uuid",
		displayName:"jsonb",
		id:"uuid",
		mapper:"jsonb",
		newData:"jsonb"
	},
	displayFormats_update_column: "enum" as const,
	displayFormats_updates:{
		_append:"displayFormats_append_input",
		_delete_at_path:"displayFormats_delete_at_path_input",
		_delete_elem:"displayFormats_delete_elem_input",
		_delete_key:"displayFormats_delete_key_input",
		_prepend:"displayFormats_prepend_input",
		_set:"displayFormats_set_input",
		where:"displayFormats_bool_exp"
	},
	displayTemplate:{
		fields:{

		}
	},
	displayTemplate_aggregate_fields:{
		count:{
			columns:"displayTemplate_select_column"
		}
	},
	displayTemplate_append_input:{
		fields:"jsonb"
	},
	displayTemplate_bool_exp:{
		_and:"displayTemplate_bool_exp",
		_not:"displayTemplate_bool_exp",
		_or:"displayTemplate_bool_exp",
		alias:"String_comparison_exp",
		fields:"jsonb_comparison_exp",
		id:"uuid_comparison_exp",
		template:"String_comparison_exp"
	},
	displayTemplate_constraint: "enum" as const,
	displayTemplate_delete_at_path_input:{

	},
	displayTemplate_delete_elem_input:{

	},
	displayTemplate_delete_key_input:{

	},
	displayTemplate_insert_input:{
		fields:"jsonb",
		id:"uuid"
	},
	displayTemplate_on_conflict:{
		constraint:"displayTemplate_constraint",
		update_columns:"displayTemplate_update_column",
		where:"displayTemplate_bool_exp"
	},
	displayTemplate_order_by:{
		alias:"order_by",
		fields:"order_by",
		id:"order_by",
		template:"order_by"
	},
	displayTemplate_pk_columns_input:{
		id:"uuid"
	},
	displayTemplate_prepend_input:{
		fields:"jsonb"
	},
	displayTemplate_select_column: "enum" as const,
	displayTemplate_set_input:{
		fields:"jsonb",
		id:"uuid"
	},
	displayTemplate_stream_cursor_input:{
		initial_value:"displayTemplate_stream_cursor_value_input",
		ordering:"cursor_ordering"
	},
	displayTemplate_stream_cursor_value_input:{
		fields:"jsonb",
		id:"uuid"
	},
	displayTemplate_update_column: "enum" as const,
	displayTemplate_updates:{
		_append:"displayTemplate_append_input",
		_delete_at_path:"displayTemplate_delete_at_path_input",
		_delete_elem:"displayTemplate_delete_elem_input",
		_delete_key:"displayTemplate_delete_key_input",
		_prepend:"displayTemplate_prepend_input",
		_set:"displayTemplate_set_input",
		where:"displayTemplate_bool_exp"
	},
	files:{
		metadata:{

		}
	},
	files_aggregate_bool_exp:{
		bool_and:"files_aggregate_bool_exp_bool_and",
		bool_or:"files_aggregate_bool_exp_bool_or",
		count:"files_aggregate_bool_exp_count"
	},
	files_aggregate_bool_exp_bool_and:{
		arguments:"files_select_column_files_aggregate_bool_exp_bool_and_arguments_columns",
		filter:"files_bool_exp",
		predicate:"Boolean_comparison_exp"
	},
	files_aggregate_bool_exp_bool_or:{
		arguments:"files_select_column_files_aggregate_bool_exp_bool_or_arguments_columns",
		filter:"files_bool_exp",
		predicate:"Boolean_comparison_exp"
	},
	files_aggregate_bool_exp_count:{
		arguments:"files_select_column",
		filter:"files_bool_exp",
		predicate:"Int_comparison_exp"
	},
	files_aggregate_fields:{
		count:{
			columns:"files_select_column"
		}
	},
	files_aggregate_order_by:{
		avg:"files_avg_order_by",
		count:"order_by",
		max:"files_max_order_by",
		min:"files_min_order_by",
		stddev:"files_stddev_order_by",
		stddev_pop:"files_stddev_pop_order_by",
		stddev_samp:"files_stddev_samp_order_by",
		sum:"files_sum_order_by",
		var_pop:"files_var_pop_order_by",
		var_samp:"files_var_samp_order_by",
		variance:"files_variance_order_by"
	},
	files_append_input:{
		metadata:"jsonb"
	},
	files_arr_rel_insert_input:{
		data:"files_insert_input",
		on_conflict:"files_on_conflict"
	},
	files_avg_order_by:{
		size:"order_by"
	},
	files_bool_exp:{
		_and:"files_bool_exp",
		_not:"files_bool_exp",
		_or:"files_bool_exp",
		bucket:"buckets_bool_exp",
		bucketId:"String_comparison_exp",
		createdAt:"timestamptz_comparison_exp",
		etag:"String_comparison_exp",
		id:"uuid_comparison_exp",
		isUploaded:"Boolean_comparison_exp",
		metadata:"jsonb_comparison_exp",
		mimeType:"String_comparison_exp",
		name:"String_comparison_exp",
		size:"Int_comparison_exp",
		updatedAt:"timestamptz_comparison_exp",
		uploadedByUserId:"uuid_comparison_exp"
	},
	files_constraint: "enum" as const,
	files_delete_at_path_input:{

	},
	files_delete_elem_input:{

	},
	files_delete_key_input:{

	},
	files_inc_input:{

	},
	files_insert_input:{
		bucket:"buckets_obj_rel_insert_input",
		createdAt:"timestamptz",
		id:"uuid",
		metadata:"jsonb",
		updatedAt:"timestamptz",
		uploadedByUserId:"uuid"
	},
	files_max_order_by:{
		bucketId:"order_by",
		createdAt:"order_by",
		etag:"order_by",
		id:"order_by",
		mimeType:"order_by",
		name:"order_by",
		size:"order_by",
		updatedAt:"order_by",
		uploadedByUserId:"order_by"
	},
	files_min_order_by:{
		bucketId:"order_by",
		createdAt:"order_by",
		etag:"order_by",
		id:"order_by",
		mimeType:"order_by",
		name:"order_by",
		size:"order_by",
		updatedAt:"order_by",
		uploadedByUserId:"order_by"
	},
	files_obj_rel_insert_input:{
		data:"files_insert_input",
		on_conflict:"files_on_conflict"
	},
	files_on_conflict:{
		constraint:"files_constraint",
		update_columns:"files_update_column",
		where:"files_bool_exp"
	},
	files_order_by:{
		bucket:"buckets_order_by",
		bucketId:"order_by",
		createdAt:"order_by",
		etag:"order_by",
		id:"order_by",
		isUploaded:"order_by",
		metadata:"order_by",
		mimeType:"order_by",
		name:"order_by",
		size:"order_by",
		updatedAt:"order_by",
		uploadedByUserId:"order_by"
	},
	files_pk_columns_input:{
		id:"uuid"
	},
	files_prepend_input:{
		metadata:"jsonb"
	},
	files_select_column: "enum" as const,
	files_select_column_files_aggregate_bool_exp_bool_and_arguments_columns: "enum" as const,
	files_select_column_files_aggregate_bool_exp_bool_or_arguments_columns: "enum" as const,
	files_set_input:{
		createdAt:"timestamptz",
		id:"uuid",
		metadata:"jsonb",
		updatedAt:"timestamptz",
		uploadedByUserId:"uuid"
	},
	files_stddev_order_by:{
		size:"order_by"
	},
	files_stddev_pop_order_by:{
		size:"order_by"
	},
	files_stddev_samp_order_by:{
		size:"order_by"
	},
	files_stream_cursor_input:{
		initial_value:"files_stream_cursor_value_input",
		ordering:"cursor_ordering"
	},
	files_stream_cursor_value_input:{
		createdAt:"timestamptz",
		id:"uuid",
		metadata:"jsonb",
		updatedAt:"timestamptz",
		uploadedByUserId:"uuid"
	},
	files_sum_order_by:{
		size:"order_by"
	},
	files_update_column: "enum" as const,
	files_updates:{
		_append:"files_append_input",
		_delete_at_path:"files_delete_at_path_input",
		_delete_elem:"files_delete_elem_input",
		_delete_key:"files_delete_key_input",
		_inc:"files_inc_input",
		_prepend:"files_prepend_input",
		_set:"files_set_input",
		where:"files_bool_exp"
	},
	files_var_pop_order_by:{
		size:"order_by"
	},
	files_var_samp_order_by:{
		size:"order_by"
	},
	files_variance_order_by:{
		size:"order_by"
	},
	interaction_aggregate_bool_exp:{
		bool_and:"interaction_aggregate_bool_exp_bool_and",
		bool_or:"interaction_aggregate_bool_exp_bool_or",
		count:"interaction_aggregate_bool_exp_count"
	},
	interaction_aggregate_bool_exp_bool_and:{
		arguments:"interaction_select_column_interaction_aggregate_bool_exp_bool_and_arguments_columns",
		filter:"interaction_bool_exp",
		predicate:"Boolean_comparison_exp"
	},
	interaction_aggregate_bool_exp_bool_or:{
		arguments:"interaction_select_column_interaction_aggregate_bool_exp_bool_or_arguments_columns",
		filter:"interaction_bool_exp",
		predicate:"Boolean_comparison_exp"
	},
	interaction_aggregate_bool_exp_count:{
		arguments:"interaction_select_column",
		filter:"interaction_bool_exp",
		predicate:"Int_comparison_exp"
	},
	interaction_aggregate_fields:{
		count:{
			columns:"interaction_select_column"
		}
	},
	interaction_aggregate_order_by:{
		count:"order_by",
		max:"interaction_max_order_by",
		min:"interaction_min_order_by"
	},
	interaction_arr_rel_insert_input:{
		data:"interaction_insert_input",
		on_conflict:"interaction_on_conflict"
	},
	interaction_bool_exp:{
		_and:"interaction_bool_exp",
		_not:"interaction_bool_exp",
		_or:"interaction_bool_exp",
		channel:"uuid_comparison_exp",
		created_at:"timestamptz_comparison_exp",
		created_by:"uuid_comparison_exp",
		date:"timestamptz_comparison_exp",
		dealId:"uuid_comparison_exp",
		id:"uuid_comparison_exp",
		observation:"String_comparison_exp",
		success:"Boolean_comparison_exp",
		updated_at:"timestamptz_comparison_exp",
		user:"users_bool_exp"
	},
	interaction_constraint: "enum" as const,
	interaction_insert_input:{
		channel:"uuid",
		created_at:"timestamptz",
		created_by:"uuid",
		date:"timestamptz",
		dealId:"uuid",
		id:"uuid",
		updated_at:"timestamptz",
		user:"users_obj_rel_insert_input"
	},
	interaction_max_order_by:{
		channel:"order_by",
		created_at:"order_by",
		created_by:"order_by",
		date:"order_by",
		dealId:"order_by",
		id:"order_by",
		observation:"order_by",
		updated_at:"order_by"
	},
	interaction_min_order_by:{
		channel:"order_by",
		created_at:"order_by",
		created_by:"order_by",
		date:"order_by",
		dealId:"order_by",
		id:"order_by",
		observation:"order_by",
		updated_at:"order_by"
	},
	interaction_on_conflict:{
		constraint:"interaction_constraint",
		update_columns:"interaction_update_column",
		where:"interaction_bool_exp"
	},
	interaction_order_by:{
		channel:"order_by",
		created_at:"order_by",
		created_by:"order_by",
		date:"order_by",
		dealId:"order_by",
		id:"order_by",
		observation:"order_by",
		success:"order_by",
		updated_at:"order_by",
		user:"users_order_by"
	},
	interaction_pk_columns_input:{
		id:"uuid"
	},
	interaction_select_column: "enum" as const,
	interaction_select_column_interaction_aggregate_bool_exp_bool_and_arguments_columns: "enum" as const,
	interaction_select_column_interaction_aggregate_bool_exp_bool_or_arguments_columns: "enum" as const,
	interaction_set_input:{
		channel:"uuid",
		created_at:"timestamptz",
		created_by:"uuid",
		date:"timestamptz",
		dealId:"uuid",
		id:"uuid",
		updated_at:"timestamptz"
	},
	interaction_stream_cursor_input:{
		initial_value:"interaction_stream_cursor_value_input",
		ordering:"cursor_ordering"
	},
	interaction_stream_cursor_value_input:{
		channel:"uuid",
		created_at:"timestamptz",
		created_by:"uuid",
		date:"timestamptz",
		dealId:"uuid",
		id:"uuid",
		updated_at:"timestamptz"
	},
	interaction_update_column: "enum" as const,
	interaction_updates:{
		_set:"interaction_set_input",
		where:"interaction_bool_exp"
	},
	json: `scalar.json` as const,
	jsonb: `scalar.jsonb` as const,
	jsonb_cast_exp:{
		String:"String_comparison_exp"
	},
	jsonb_comparison_exp:{
		_cast:"jsonb_cast_exp",
		_contained_in:"jsonb",
		_contains:"jsonb",
		_eq:"jsonb",
		_gt:"jsonb",
		_gte:"jsonb",
		_in:"jsonb",
		_lt:"jsonb",
		_lte:"jsonb",
		_neq:"jsonb",
		_nin:"jsonb"
	},
	landingPageTemplate_aggregate_fields:{
		count:{
			columns:"landingPageTemplate_select_column"
		}
	},
	landingPageTemplate_bool_exp:{
		_and:"landingPageTemplate_bool_exp",
		_not:"landingPageTemplate_bool_exp",
		_or:"landingPageTemplate_bool_exp",
		created_at:"timestamptz_comparison_exp",
		htmlTemplate:"String_comparison_exp",
		id:"uuid_comparison_exp",
		updated_at:"timestamptz_comparison_exp"
	},
	landingPageTemplate_constraint: "enum" as const,
	landingPageTemplate_insert_input:{
		created_at:"timestamptz",
		id:"uuid",
		updated_at:"timestamptz"
	},
	landingPageTemplate_on_conflict:{
		constraint:"landingPageTemplate_constraint",
		update_columns:"landingPageTemplate_update_column",
		where:"landingPageTemplate_bool_exp"
	},
	landingPageTemplate_order_by:{
		created_at:"order_by",
		htmlTemplate:"order_by",
		id:"order_by",
		updated_at:"order_by"
	},
	landingPageTemplate_pk_columns_input:{
		id:"uuid"
	},
	landingPageTemplate_select_column: "enum" as const,
	landingPageTemplate_set_input:{
		created_at:"timestamptz",
		id:"uuid",
		updated_at:"timestamptz"
	},
	landingPageTemplate_stream_cursor_input:{
		initial_value:"landingPageTemplate_stream_cursor_value_input",
		ordering:"cursor_ordering"
	},
	landingPageTemplate_stream_cursor_value_input:{
		created_at:"timestamptz",
		id:"uuid",
		updated_at:"timestamptz"
	},
	landingPageTemplate_update_column: "enum" as const,
	landingPageTemplate_updates:{
		_set:"landingPageTemplate_set_input",
		where:"landingPageTemplate_bool_exp"
	},
	mutation_root:{
		blogPostGeneratorEnhancedForCopy:{

		},
		createAutomaticProject:{

		},
		createImageUsingImagine:{

		},
		deleteAuthProvider:{

		},
		deleteAuthProviderRequest:{
			id:"uuid"
		},
		deleteAuthProviderRequests:{
			where:"authProviderRequests_bool_exp"
		},
		deleteAuthProviders:{
			where:"authProviders_bool_exp"
		},
		deleteAuthRefreshToken:{
			id:"uuid"
		},
		deleteAuthRefreshTokenType:{

		},
		deleteAuthRefreshTokenTypes:{
			where:"authRefreshTokenTypes_bool_exp"
		},
		deleteAuthRefreshTokens:{
			where:"authRefreshTokens_bool_exp"
		},
		deleteAuthRole:{

		},
		deleteAuthRoles:{
			where:"authRoles_bool_exp"
		},
		deleteAuthUserProvider:{
			id:"uuid"
		},
		deleteAuthUserProviders:{
			where:"authUserProviders_bool_exp"
		},
		deleteAuthUserRole:{
			id:"uuid"
		},
		deleteAuthUserRoles:{
			where:"authUserRoles_bool_exp"
		},
		deleteAuthUserSecurityKey:{
			id:"uuid"
		},
		deleteAuthUserSecurityKeys:{
			where:"authUserSecurityKeys_bool_exp"
		},
		deleteBucket:{

		},
		deleteBuckets:{
			where:"buckets_bool_exp"
		},
		deleteFile:{
			id:"uuid"
		},
		deleteFiles:{
			where:"files_bool_exp"
		},
		deleteUser:{
			id:"uuid"
		},
		deleteUsers:{
			where:"users_bool_exp"
		},
		deleteVirus:{
			id:"uuid"
		},
		deleteViruses:{
			where:"virus_bool_exp"
		},
		delete_AppIntegration:{
			where:"AppIntegration_bool_exp"
		},
		delete_AppIntegration_by_pk:{
			id:"uuid"
		},
		delete_Course:{
			where:"Course_bool_exp"
		},
		delete_CourseCategory:{
			where:"CourseCategory_bool_exp"
		},
		delete_CourseCategory_by_pk:{
			id:"bigint"
		},
		delete_Course_by_pk:{
			id:"uuid"
		},
		delete_GeneratedPromptByTemplate:{
			where:"GeneratedPromptByTemplate_bool_exp"
		},
		delete_GeneratedPromptByTemplateImage:{
			where:"GeneratedPromptByTemplateImage_bool_exp"
		},
		delete_GeneratedPromptByTemplateImage_by_pk:{
			id:"uuid"
		},
		delete_GeneratedPromptByTemplate_by_pk:{
			id:"uuid"
		},
		delete_TextGeneratorOption:{
			where:"TextGeneratorOption_bool_exp"
		},
		delete_TextGeneratorOptionEntry:{
			where:"TextGeneratorOptionEntry_bool_exp"
		},
		delete_TextGeneratorOptionEntry_by_pk:{
			id:"uuid"
		},
		delete_TextGeneratorOption_by_pk:{
			id:"uuid"
		},
		delete_TextPromptTemplate:{
			where:"TextPromptTemplate_bool_exp"
		},
		delete_TextPromptTemplateCategory:{
			where:"TextPromptTemplateCategory_bool_exp"
		},
		delete_TextPromptTemplateCategory_by_pk:{
			id:"uuid"
		},
		delete_TextPromptTemplate_by_pk:{
			id:"uuid"
		},
		delete_TextTemplateClientCategory:{
			where:"TextTemplateClientCategory_bool_exp"
		},
		delete_TextTemplateClientCategory_by_pk:{
			id:"uuid"
		},
		delete_audiences:{
			where:"audiences_bool_exp"
		},
		delete_audiences_by_pk:{
			id:"uuid"
		},
		delete_blog:{
			where:"blog_bool_exp"
		},
		delete_blogCategory:{
			where:"blogCategory_bool_exp"
		},
		delete_blogCategory_by_pk:{
			id:"uuid"
		},
		delete_blog_by_pk:{
			id:"uuid"
		},
		delete_brands:{
			where:"brands_bool_exp"
		},
		delete_brands_by_pk:{
			id:"uuid"
		},
		delete_campaign:{
			where:"campaign_bool_exp"
		},
		delete_campaign_by_pk:{
			id:"uuid"
		},
		delete_channels:{
			where:"channels_bool_exp"
		},
		delete_channels_by_pk:{
			id:"uuid"
		},
		delete_costTable:{
			where:"costTable_bool_exp"
		},
		delete_costTable_by_pk:{
			id:"uuid"
		},
		delete_deal:{
			where:"deal_bool_exp"
		},
		delete_dealStatus:{
			where:"dealStatus_bool_exp"
		},
		delete_dealStatus_by_pk:{
			id:"uuid"
		},
		delete_deal_by_pk:{
			id:"uuid"
		},
		delete_displayCategory:{
			where:"displayCategory_bool_exp"
		},
		delete_displayCategory_by_pk:{
			id:"uuid"
		},
		delete_displayDimension:{
			where:"displayDimension_bool_exp"
		},
		delete_displayDimension_by_pk:{
			id:"uuid"
		},
		delete_displayFormats:{
			where:"displayFormats_bool_exp"
		},
		delete_displayFormats_by_pk:{
			id:"uuid"
		},
		delete_displayTemplate:{
			where:"displayTemplate_bool_exp"
		},
		delete_displayTemplate_by_pk:{
			id:"uuid"
		},
		delete_interaction:{
			where:"interaction_bool_exp"
		},
		delete_interaction_by_pk:{
			id:"uuid"
		},
		delete_landingPageTemplate:{
			where:"landingPageTemplate_bool_exp"
		},
		delete_landingPageTemplate_by_pk:{
			id:"uuid"
		},
		delete_newTemplateType:{
			where:"newTemplateType_bool_exp"
		},
		delete_newTemplateType_by_pk:{
			id:"uuid"
		},
		delete_openAIApiKey:{
			where:"openAIApiKey_bool_exp"
		},
		delete_openAIApiKey_by_pk:{
			id:"uuid"
		},
		delete_pTemplate:{
			where:"pTemplate_bool_exp"
		},
		delete_pTemplate_by_pk:{
			id:"uuid"
		},
		delete_pricingPlan:{
			where:"pricingPlan_bool_exp"
		},
		delete_pricingPlan_by_pk:{
			id:"uuid"
		},
		delete_products:{
			where:"products_bool_exp"
		},
		delete_products_by_pk:{
			id:"uuid"
		},
		delete_project:{
			where:"project_bool_exp"
		},
		delete_projectImage:{
			where:"projectImage_bool_exp"
		},
		delete_projectImage_by_pk:{
			id:"uuid"
		},
		delete_project_by_pk:{
			id:"uuid"
		},
		delete_task:{
			where:"task_bool_exp"
		},
		delete_task_by_pk:{
			id:"uuid"
		},
		delete_textTemplateDisplayFormat:{
			where:"textTemplateDisplayFormat_bool_exp"
		},
		delete_textTemplateDisplayFormat_by_pk:{
			id:"uuid"
		},
		delete_userAppIntegration:{
			where:"userAppIntegration_bool_exp"
		},
		delete_userAppIntegration_by_pk:{
			id:"uuid"
		},
		delete_userCreditUsage:{
			where:"userCreditUsage_bool_exp"
		},
		delete_userCreditUsageSummary:{
			where:"userCreditUsageSummary_bool_exp"
		},
		delete_userCreditUsageSummary_by_pk:{
			created_by:"uuid"
		},
		delete_userCreditUsage_by_pk:{
			id:"uuid"
		},
		delete_userCredits:{
			where:"userCredits_bool_exp"
		},
		delete_userCredits_by_pk:{
			id:"uuid"
		},
		delete_userFeedback:{
			where:"userFeedback_bool_exp"
		},
		delete_userFeedback_by_pk:{
			id:"uuid"
		},
		delete_userMedia:{
			where:"userMedia_bool_exp"
		},
		delete_userMedia_by_pk:{
			id:"uuid"
		},
		delete_userMetadata:{
			where:"userMetadata_bool_exp"
		},
		delete_userMetadata_by_pk:{
			id:"uuid"
		},
		delete_userOnboarding:{
			where:"userOnboarding_bool_exp"
		},
		delete_userOnboarding_by_pk:{
			userId:"uuid"
		},
		delete_userTemplateUsage:{
			where:"userTemplateUsage_bool_exp"
		},
		delete_userTemplateUsage_by_pk:{
			id:"uuid"
		},
		delete_website:{
			where:"website_bool_exp"
		},
		delete_website_by_pk:{
			id:"uuid"
		},
		exportToWordpress:{
			command:"ExportToWordpressInput"
		},
		facebookAuthCallback:{

		},
		generateCalendarEx:{

		},
		generateFromTextTemplate:{
			command:"GenerateFromTextTemplateInput"
		},
		generateNextMonthCalendar:{
			command:"GenerateNextMonthCalendarInput"
		},
		getInstagramAuthCallback:{

		},
		get_next_openai_apikey:{
			distinct_on:"openAIApiKey_select_column",
			order_by:"openAIApiKey_order_by",
			where:"openAIApiKey_bool_exp"
		},
		insertAuthProvider:{
			object:"authProviders_insert_input",
			on_conflict:"authProviders_on_conflict"
		},
		insertAuthProviderRequest:{
			object:"authProviderRequests_insert_input",
			on_conflict:"authProviderRequests_on_conflict"
		},
		insertAuthProviderRequests:{
			objects:"authProviderRequests_insert_input",
			on_conflict:"authProviderRequests_on_conflict"
		},
		insertAuthProviders:{
			objects:"authProviders_insert_input",
			on_conflict:"authProviders_on_conflict"
		},
		insertAuthRefreshToken:{
			object:"authRefreshTokens_insert_input",
			on_conflict:"authRefreshTokens_on_conflict"
		},
		insertAuthRefreshTokenType:{
			object:"authRefreshTokenTypes_insert_input",
			on_conflict:"authRefreshTokenTypes_on_conflict"
		},
		insertAuthRefreshTokenTypes:{
			objects:"authRefreshTokenTypes_insert_input",
			on_conflict:"authRefreshTokenTypes_on_conflict"
		},
		insertAuthRefreshTokens:{
			objects:"authRefreshTokens_insert_input",
			on_conflict:"authRefreshTokens_on_conflict"
		},
		insertAuthRole:{
			object:"authRoles_insert_input",
			on_conflict:"authRoles_on_conflict"
		},
		insertAuthRoles:{
			objects:"authRoles_insert_input",
			on_conflict:"authRoles_on_conflict"
		},
		insertAuthUserProvider:{
			object:"authUserProviders_insert_input",
			on_conflict:"authUserProviders_on_conflict"
		},
		insertAuthUserProviders:{
			objects:"authUserProviders_insert_input",
			on_conflict:"authUserProviders_on_conflict"
		},
		insertAuthUserRole:{
			object:"authUserRoles_insert_input",
			on_conflict:"authUserRoles_on_conflict"
		},
		insertAuthUserRoles:{
			objects:"authUserRoles_insert_input",
			on_conflict:"authUserRoles_on_conflict"
		},
		insertAuthUserSecurityKey:{
			object:"authUserSecurityKeys_insert_input",
			on_conflict:"authUserSecurityKeys_on_conflict"
		},
		insertAuthUserSecurityKeys:{
			objects:"authUserSecurityKeys_insert_input",
			on_conflict:"authUserSecurityKeys_on_conflict"
		},
		insertBucket:{
			object:"buckets_insert_input",
			on_conflict:"buckets_on_conflict"
		},
		insertBuckets:{
			objects:"buckets_insert_input",
			on_conflict:"buckets_on_conflict"
		},
		insertFile:{
			object:"files_insert_input",
			on_conflict:"files_on_conflict"
		},
		insertFiles:{
			objects:"files_insert_input",
			on_conflict:"files_on_conflict"
		},
		insertUser:{
			object:"users_insert_input",
			on_conflict:"users_on_conflict"
		},
		insertUsers:{
			objects:"users_insert_input",
			on_conflict:"users_on_conflict"
		},
		insertVirus:{
			object:"virus_insert_input",
			on_conflict:"virus_on_conflict"
		},
		insertViruses:{
			objects:"virus_insert_input",
			on_conflict:"virus_on_conflict"
		},
		insert_AppIntegration:{
			objects:"AppIntegration_insert_input",
			on_conflict:"AppIntegration_on_conflict"
		},
		insert_AppIntegration_one:{
			object:"AppIntegration_insert_input",
			on_conflict:"AppIntegration_on_conflict"
		},
		insert_Course:{
			objects:"Course_insert_input",
			on_conflict:"Course_on_conflict"
		},
		insert_CourseCategory:{
			objects:"CourseCategory_insert_input",
			on_conflict:"CourseCategory_on_conflict"
		},
		insert_CourseCategory_one:{
			object:"CourseCategory_insert_input",
			on_conflict:"CourseCategory_on_conflict"
		},
		insert_Course_one:{
			object:"Course_insert_input",
			on_conflict:"Course_on_conflict"
		},
		insert_GeneratedPromptByTemplate:{
			objects:"GeneratedPromptByTemplate_insert_input",
			on_conflict:"GeneratedPromptByTemplate_on_conflict"
		},
		insert_GeneratedPromptByTemplateImage:{
			objects:"GeneratedPromptByTemplateImage_insert_input",
			on_conflict:"GeneratedPromptByTemplateImage_on_conflict"
		},
		insert_GeneratedPromptByTemplateImage_one:{
			object:"GeneratedPromptByTemplateImage_insert_input",
			on_conflict:"GeneratedPromptByTemplateImage_on_conflict"
		},
		insert_GeneratedPromptByTemplate_one:{
			object:"GeneratedPromptByTemplate_insert_input",
			on_conflict:"GeneratedPromptByTemplate_on_conflict"
		},
		insert_TextGeneratorOption:{
			objects:"TextGeneratorOption_insert_input",
			on_conflict:"TextGeneratorOption_on_conflict"
		},
		insert_TextGeneratorOptionEntry:{
			objects:"TextGeneratorOptionEntry_insert_input",
			on_conflict:"TextGeneratorOptionEntry_on_conflict"
		},
		insert_TextGeneratorOptionEntry_one:{
			object:"TextGeneratorOptionEntry_insert_input",
			on_conflict:"TextGeneratorOptionEntry_on_conflict"
		},
		insert_TextGeneratorOption_one:{
			object:"TextGeneratorOption_insert_input",
			on_conflict:"TextGeneratorOption_on_conflict"
		},
		insert_TextPromptTemplate:{
			objects:"TextPromptTemplate_insert_input",
			on_conflict:"TextPromptTemplate_on_conflict"
		},
		insert_TextPromptTemplateCategory:{
			objects:"TextPromptTemplateCategory_insert_input",
			on_conflict:"TextPromptTemplateCategory_on_conflict"
		},
		insert_TextPromptTemplateCategory_one:{
			object:"TextPromptTemplateCategory_insert_input",
			on_conflict:"TextPromptTemplateCategory_on_conflict"
		},
		insert_TextPromptTemplate_one:{
			object:"TextPromptTemplate_insert_input",
			on_conflict:"TextPromptTemplate_on_conflict"
		},
		insert_TextTemplateClientCategory:{
			objects:"TextTemplateClientCategory_insert_input",
			on_conflict:"TextTemplateClientCategory_on_conflict"
		},
		insert_TextTemplateClientCategory_one:{
			object:"TextTemplateClientCategory_insert_input",
			on_conflict:"TextTemplateClientCategory_on_conflict"
		},
		insert_audiences:{
			objects:"audiences_insert_input",
			on_conflict:"audiences_on_conflict"
		},
		insert_audiences_one:{
			object:"audiences_insert_input",
			on_conflict:"audiences_on_conflict"
		},
		insert_blog:{
			objects:"blog_insert_input",
			on_conflict:"blog_on_conflict"
		},
		insert_blogCategory:{
			objects:"blogCategory_insert_input",
			on_conflict:"blogCategory_on_conflict"
		},
		insert_blogCategory_one:{
			object:"blogCategory_insert_input",
			on_conflict:"blogCategory_on_conflict"
		},
		insert_blog_one:{
			object:"blog_insert_input",
			on_conflict:"blog_on_conflict"
		},
		insert_brands:{
			objects:"brands_insert_input",
			on_conflict:"brands_on_conflict"
		},
		insert_brands_one:{
			object:"brands_insert_input",
			on_conflict:"brands_on_conflict"
		},
		insert_campaign:{
			objects:"campaign_insert_input",
			on_conflict:"campaign_on_conflict"
		},
		insert_campaign_one:{
			object:"campaign_insert_input",
			on_conflict:"campaign_on_conflict"
		},
		insert_channels:{
			objects:"channels_insert_input",
			on_conflict:"channels_on_conflict"
		},
		insert_channels_one:{
			object:"channels_insert_input",
			on_conflict:"channels_on_conflict"
		},
		insert_costTable:{
			objects:"costTable_insert_input",
			on_conflict:"costTable_on_conflict"
		},
		insert_costTable_one:{
			object:"costTable_insert_input",
			on_conflict:"costTable_on_conflict"
		},
		insert_deal:{
			objects:"deal_insert_input",
			on_conflict:"deal_on_conflict"
		},
		insert_dealStatus:{
			objects:"dealStatus_insert_input",
			on_conflict:"dealStatus_on_conflict"
		},
		insert_dealStatus_one:{
			object:"dealStatus_insert_input",
			on_conflict:"dealStatus_on_conflict"
		},
		insert_deal_one:{
			object:"deal_insert_input",
			on_conflict:"deal_on_conflict"
		},
		insert_displayCategory:{
			objects:"displayCategory_insert_input",
			on_conflict:"displayCategory_on_conflict"
		},
		insert_displayCategory_one:{
			object:"displayCategory_insert_input",
			on_conflict:"displayCategory_on_conflict"
		},
		insert_displayDimension:{
			objects:"displayDimension_insert_input",
			on_conflict:"displayDimension_on_conflict"
		},
		insert_displayDimension_one:{
			object:"displayDimension_insert_input",
			on_conflict:"displayDimension_on_conflict"
		},
		insert_displayFormats:{
			objects:"displayFormats_insert_input",
			on_conflict:"displayFormats_on_conflict"
		},
		insert_displayFormats_one:{
			object:"displayFormats_insert_input",
			on_conflict:"displayFormats_on_conflict"
		},
		insert_displayTemplate:{
			objects:"displayTemplate_insert_input",
			on_conflict:"displayTemplate_on_conflict"
		},
		insert_displayTemplate_one:{
			object:"displayTemplate_insert_input",
			on_conflict:"displayTemplate_on_conflict"
		},
		insert_interaction:{
			objects:"interaction_insert_input",
			on_conflict:"interaction_on_conflict"
		},
		insert_interaction_one:{
			object:"interaction_insert_input",
			on_conflict:"interaction_on_conflict"
		},
		insert_landingPageTemplate:{
			objects:"landingPageTemplate_insert_input",
			on_conflict:"landingPageTemplate_on_conflict"
		},
		insert_landingPageTemplate_one:{
			object:"landingPageTemplate_insert_input",
			on_conflict:"landingPageTemplate_on_conflict"
		},
		insert_newTemplateType:{
			objects:"newTemplateType_insert_input",
			on_conflict:"newTemplateType_on_conflict"
		},
		insert_newTemplateType_one:{
			object:"newTemplateType_insert_input",
			on_conflict:"newTemplateType_on_conflict"
		},
		insert_openAIApiKey:{
			objects:"openAIApiKey_insert_input",
			on_conflict:"openAIApiKey_on_conflict"
		},
		insert_openAIApiKey_one:{
			object:"openAIApiKey_insert_input",
			on_conflict:"openAIApiKey_on_conflict"
		},
		insert_pTemplate:{
			objects:"pTemplate_insert_input",
			on_conflict:"pTemplate_on_conflict"
		},
		insert_pTemplate_one:{
			object:"pTemplate_insert_input",
			on_conflict:"pTemplate_on_conflict"
		},
		insert_pricingPlan:{
			objects:"pricingPlan_insert_input",
			on_conflict:"pricingPlan_on_conflict"
		},
		insert_pricingPlan_one:{
			object:"pricingPlan_insert_input",
			on_conflict:"pricingPlan_on_conflict"
		},
		insert_products:{
			objects:"products_insert_input",
			on_conflict:"products_on_conflict"
		},
		insert_products_one:{
			object:"products_insert_input",
			on_conflict:"products_on_conflict"
		},
		insert_project:{
			objects:"project_insert_input",
			on_conflict:"project_on_conflict"
		},
		insert_projectImage:{
			objects:"projectImage_insert_input",
			on_conflict:"projectImage_on_conflict"
		},
		insert_projectImage_one:{
			object:"projectImage_insert_input",
			on_conflict:"projectImage_on_conflict"
		},
		insert_project_one:{
			object:"project_insert_input",
			on_conflict:"project_on_conflict"
		},
		insert_task:{
			objects:"task_insert_input",
			on_conflict:"task_on_conflict"
		},
		insert_task_one:{
			object:"task_insert_input",
			on_conflict:"task_on_conflict"
		},
		insert_textTemplateDisplayFormat:{
			objects:"textTemplateDisplayFormat_insert_input",
			on_conflict:"textTemplateDisplayFormat_on_conflict"
		},
		insert_textTemplateDisplayFormat_one:{
			object:"textTemplateDisplayFormat_insert_input",
			on_conflict:"textTemplateDisplayFormat_on_conflict"
		},
		insert_userAppIntegration:{
			objects:"userAppIntegration_insert_input",
			on_conflict:"userAppIntegration_on_conflict"
		},
		insert_userAppIntegration_one:{
			object:"userAppIntegration_insert_input",
			on_conflict:"userAppIntegration_on_conflict"
		},
		insert_userCreditUsage:{
			objects:"userCreditUsage_insert_input",
			on_conflict:"userCreditUsage_on_conflict"
		},
		insert_userCreditUsageSummary:{
			objects:"userCreditUsageSummary_insert_input",
			on_conflict:"userCreditUsageSummary_on_conflict"
		},
		insert_userCreditUsageSummary_one:{
			object:"userCreditUsageSummary_insert_input",
			on_conflict:"userCreditUsageSummary_on_conflict"
		},
		insert_userCreditUsage_one:{
			object:"userCreditUsage_insert_input",
			on_conflict:"userCreditUsage_on_conflict"
		},
		insert_userCredits:{
			objects:"userCredits_insert_input",
			on_conflict:"userCredits_on_conflict"
		},
		insert_userCredits_one:{
			object:"userCredits_insert_input",
			on_conflict:"userCredits_on_conflict"
		},
		insert_userFeedback:{
			objects:"userFeedback_insert_input",
			on_conflict:"userFeedback_on_conflict"
		},
		insert_userFeedback_one:{
			object:"userFeedback_insert_input",
			on_conflict:"userFeedback_on_conflict"
		},
		insert_userMedia:{
			objects:"userMedia_insert_input",
			on_conflict:"userMedia_on_conflict"
		},
		insert_userMedia_one:{
			object:"userMedia_insert_input",
			on_conflict:"userMedia_on_conflict"
		},
		insert_userMetadata:{
			objects:"userMetadata_insert_input",
			on_conflict:"userMetadata_on_conflict"
		},
		insert_userMetadata_one:{
			object:"userMetadata_insert_input",
			on_conflict:"userMetadata_on_conflict"
		},
		insert_userOnboarding:{
			objects:"userOnboarding_insert_input",
			on_conflict:"userOnboarding_on_conflict"
		},
		insert_userOnboarding_one:{
			object:"userOnboarding_insert_input",
			on_conflict:"userOnboarding_on_conflict"
		},
		insert_userTemplateUsage:{
			objects:"userTemplateUsage_insert_input",
			on_conflict:"userTemplateUsage_on_conflict"
		},
		insert_userTemplateUsage_one:{
			object:"userTemplateUsage_insert_input",
			on_conflict:"userTemplateUsage_on_conflict"
		},
		insert_website:{
			objects:"website_insert_input",
			on_conflict:"website_on_conflict"
		},
		insert_website_one:{
			object:"website_insert_input",
			on_conflict:"website_on_conflict"
		},
		linkedInTokenCallback:{

		},
		postCarouselToInstagram:{

		},
		postFeedToInstagram:{
			scheduledDate:"date"
		},
		postStoryToInstagram:{

		},
		postToFacebook:{
			scheduledDate:"date"
		},
		postToLinkedIn:{
			scheduledDate:"date"
		},
		publishOrScheduleProject:{
			scheduledDate:"date"
		},
		purePrompt:{

		},
		renderAndSaveDisplayImage:{

		},
		saveAndGeneratePolotnoImage:{

		},
		selectNewDisplay:{

		},
		updateAuthProvider:{
			_set:"authProviders_set_input",
			pk_columns:"authProviders_pk_columns_input"
		},
		updateAuthProviderRequest:{
			_append:"authProviderRequests_append_input",
			_delete_at_path:"authProviderRequests_delete_at_path_input",
			_delete_elem:"authProviderRequests_delete_elem_input",
			_delete_key:"authProviderRequests_delete_key_input",
			_prepend:"authProviderRequests_prepend_input",
			_set:"authProviderRequests_set_input",
			pk_columns:"authProviderRequests_pk_columns_input"
		},
		updateAuthProviderRequests:{
			_append:"authProviderRequests_append_input",
			_delete_at_path:"authProviderRequests_delete_at_path_input",
			_delete_elem:"authProviderRequests_delete_elem_input",
			_delete_key:"authProviderRequests_delete_key_input",
			_prepend:"authProviderRequests_prepend_input",
			_set:"authProviderRequests_set_input",
			where:"authProviderRequests_bool_exp"
		},
		updateAuthProviders:{
			_set:"authProviders_set_input",
			where:"authProviders_bool_exp"
		},
		updateAuthRefreshToken:{
			_append:"authRefreshTokens_append_input",
			_delete_at_path:"authRefreshTokens_delete_at_path_input",
			_delete_elem:"authRefreshTokens_delete_elem_input",
			_delete_key:"authRefreshTokens_delete_key_input",
			_prepend:"authRefreshTokens_prepend_input",
			_set:"authRefreshTokens_set_input",
			pk_columns:"authRefreshTokens_pk_columns_input"
		},
		updateAuthRefreshTokenType:{
			_set:"authRefreshTokenTypes_set_input",
			pk_columns:"authRefreshTokenTypes_pk_columns_input"
		},
		updateAuthRefreshTokenTypes:{
			_set:"authRefreshTokenTypes_set_input",
			where:"authRefreshTokenTypes_bool_exp"
		},
		updateAuthRefreshTokens:{
			_append:"authRefreshTokens_append_input",
			_delete_at_path:"authRefreshTokens_delete_at_path_input",
			_delete_elem:"authRefreshTokens_delete_elem_input",
			_delete_key:"authRefreshTokens_delete_key_input",
			_prepend:"authRefreshTokens_prepend_input",
			_set:"authRefreshTokens_set_input",
			where:"authRefreshTokens_bool_exp"
		},
		updateAuthRole:{
			_set:"authRoles_set_input",
			pk_columns:"authRoles_pk_columns_input"
		},
		updateAuthRoles:{
			_set:"authRoles_set_input",
			where:"authRoles_bool_exp"
		},
		updateAuthUserProvider:{
			_set:"authUserProviders_set_input",
			pk_columns:"authUserProviders_pk_columns_input"
		},
		updateAuthUserProviders:{
			_set:"authUserProviders_set_input",
			where:"authUserProviders_bool_exp"
		},
		updateAuthUserRole:{
			_set:"authUserRoles_set_input",
			pk_columns:"authUserRoles_pk_columns_input"
		},
		updateAuthUserRoles:{
			_set:"authUserRoles_set_input",
			where:"authUserRoles_bool_exp"
		},
		updateAuthUserSecurityKey:{
			_inc:"authUserSecurityKeys_inc_input",
			_set:"authUserSecurityKeys_set_input",
			pk_columns:"authUserSecurityKeys_pk_columns_input"
		},
		updateAuthUserSecurityKeys:{
			_inc:"authUserSecurityKeys_inc_input",
			_set:"authUserSecurityKeys_set_input",
			where:"authUserSecurityKeys_bool_exp"
		},
		updateBucket:{
			_inc:"buckets_inc_input",
			_set:"buckets_set_input",
			pk_columns:"buckets_pk_columns_input"
		},
		updateBuckets:{
			_inc:"buckets_inc_input",
			_set:"buckets_set_input",
			where:"buckets_bool_exp"
		},
		updateFile:{
			_append:"files_append_input",
			_delete_at_path:"files_delete_at_path_input",
			_delete_elem:"files_delete_elem_input",
			_delete_key:"files_delete_key_input",
			_inc:"files_inc_input",
			_prepend:"files_prepend_input",
			_set:"files_set_input",
			pk_columns:"files_pk_columns_input"
		},
		updateFiles:{
			_append:"files_append_input",
			_delete_at_path:"files_delete_at_path_input",
			_delete_elem:"files_delete_elem_input",
			_delete_key:"files_delete_key_input",
			_inc:"files_inc_input",
			_prepend:"files_prepend_input",
			_set:"files_set_input",
			where:"files_bool_exp"
		},
		updateUser:{
			_append:"users_append_input",
			_delete_at_path:"users_delete_at_path_input",
			_delete_elem:"users_delete_elem_input",
			_delete_key:"users_delete_key_input",
			_prepend:"users_prepend_input",
			_set:"users_set_input",
			pk_columns:"users_pk_columns_input"
		},
		updateUserAvatar:{

		},
		updateUsers:{
			_append:"users_append_input",
			_delete_at_path:"users_delete_at_path_input",
			_delete_elem:"users_delete_elem_input",
			_delete_key:"users_delete_key_input",
			_prepend:"users_prepend_input",
			_set:"users_set_input",
			where:"users_bool_exp"
		},
		updateVirus:{
			_append:"virus_append_input",
			_delete_at_path:"virus_delete_at_path_input",
			_delete_elem:"virus_delete_elem_input",
			_delete_key:"virus_delete_key_input",
			_prepend:"virus_prepend_input",
			_set:"virus_set_input",
			pk_columns:"virus_pk_columns_input"
		},
		updateViruses:{
			_append:"virus_append_input",
			_delete_at_path:"virus_delete_at_path_input",
			_delete_elem:"virus_delete_elem_input",
			_delete_key:"virus_delete_key_input",
			_prepend:"virus_prepend_input",
			_set:"virus_set_input",
			where:"virus_bool_exp"
		},
		update_AppIntegration:{
			_append:"AppIntegration_append_input",
			_delete_at_path:"AppIntegration_delete_at_path_input",
			_delete_elem:"AppIntegration_delete_elem_input",
			_delete_key:"AppIntegration_delete_key_input",
			_prepend:"AppIntegration_prepend_input",
			_set:"AppIntegration_set_input",
			where:"AppIntegration_bool_exp"
		},
		update_AppIntegration_by_pk:{
			_append:"AppIntegration_append_input",
			_delete_at_path:"AppIntegration_delete_at_path_input",
			_delete_elem:"AppIntegration_delete_elem_input",
			_delete_key:"AppIntegration_delete_key_input",
			_prepend:"AppIntegration_prepend_input",
			_set:"AppIntegration_set_input",
			pk_columns:"AppIntegration_pk_columns_input"
		},
		update_AppIntegration_many:{
			updates:"AppIntegration_updates"
		},
		update_Course:{
			_inc:"Course_inc_input",
			_set:"Course_set_input",
			where:"Course_bool_exp"
		},
		update_CourseCategory:{
			_inc:"CourseCategory_inc_input",
			_set:"CourseCategory_set_input",
			where:"CourseCategory_bool_exp"
		},
		update_CourseCategory_by_pk:{
			_inc:"CourseCategory_inc_input",
			_set:"CourseCategory_set_input",
			pk_columns:"CourseCategory_pk_columns_input"
		},
		update_CourseCategory_many:{
			updates:"CourseCategory_updates"
		},
		update_Course_by_pk:{
			_inc:"Course_inc_input",
			_set:"Course_set_input",
			pk_columns:"Course_pk_columns_input"
		},
		update_Course_many:{
			updates:"Course_updates"
		},
		update_GeneratedPromptByTemplate:{
			_append:"GeneratedPromptByTemplate_append_input",
			_delete_at_path:"GeneratedPromptByTemplate_delete_at_path_input",
			_delete_elem:"GeneratedPromptByTemplate_delete_elem_input",
			_delete_key:"GeneratedPromptByTemplate_delete_key_input",
			_prepend:"GeneratedPromptByTemplate_prepend_input",
			_set:"GeneratedPromptByTemplate_set_input",
			where:"GeneratedPromptByTemplate_bool_exp"
		},
		update_GeneratedPromptByTemplateImage:{
			_set:"GeneratedPromptByTemplateImage_set_input",
			where:"GeneratedPromptByTemplateImage_bool_exp"
		},
		update_GeneratedPromptByTemplateImage_by_pk:{
			_set:"GeneratedPromptByTemplateImage_set_input",
			pk_columns:"GeneratedPromptByTemplateImage_pk_columns_input"
		},
		update_GeneratedPromptByTemplateImage_many:{
			updates:"GeneratedPromptByTemplateImage_updates"
		},
		update_GeneratedPromptByTemplate_by_pk:{
			_append:"GeneratedPromptByTemplate_append_input",
			_delete_at_path:"GeneratedPromptByTemplate_delete_at_path_input",
			_delete_elem:"GeneratedPromptByTemplate_delete_elem_input",
			_delete_key:"GeneratedPromptByTemplate_delete_key_input",
			_prepend:"GeneratedPromptByTemplate_prepend_input",
			_set:"GeneratedPromptByTemplate_set_input",
			pk_columns:"GeneratedPromptByTemplate_pk_columns_input"
		},
		update_GeneratedPromptByTemplate_many:{
			updates:"GeneratedPromptByTemplate_updates"
		},
		update_TextGeneratorOption:{
			_append:"TextGeneratorOption_append_input",
			_delete_at_path:"TextGeneratorOption_delete_at_path_input",
			_delete_elem:"TextGeneratorOption_delete_elem_input",
			_delete_key:"TextGeneratorOption_delete_key_input",
			_prepend:"TextGeneratorOption_prepend_input",
			_set:"TextGeneratorOption_set_input",
			where:"TextGeneratorOption_bool_exp"
		},
		update_TextGeneratorOptionEntry:{
			_append:"TextGeneratorOptionEntry_append_input",
			_delete_at_path:"TextGeneratorOptionEntry_delete_at_path_input",
			_delete_elem:"TextGeneratorOptionEntry_delete_elem_input",
			_delete_key:"TextGeneratorOptionEntry_delete_key_input",
			_prepend:"TextGeneratorOptionEntry_prepend_input",
			_set:"TextGeneratorOptionEntry_set_input",
			where:"TextGeneratorOptionEntry_bool_exp"
		},
		update_TextGeneratorOptionEntry_by_pk:{
			_append:"TextGeneratorOptionEntry_append_input",
			_delete_at_path:"TextGeneratorOptionEntry_delete_at_path_input",
			_delete_elem:"TextGeneratorOptionEntry_delete_elem_input",
			_delete_key:"TextGeneratorOptionEntry_delete_key_input",
			_prepend:"TextGeneratorOptionEntry_prepend_input",
			_set:"TextGeneratorOptionEntry_set_input",
			pk_columns:"TextGeneratorOptionEntry_pk_columns_input"
		},
		update_TextGeneratorOptionEntry_many:{
			updates:"TextGeneratorOptionEntry_updates"
		},
		update_TextGeneratorOption_by_pk:{
			_append:"TextGeneratorOption_append_input",
			_delete_at_path:"TextGeneratorOption_delete_at_path_input",
			_delete_elem:"TextGeneratorOption_delete_elem_input",
			_delete_key:"TextGeneratorOption_delete_key_input",
			_prepend:"TextGeneratorOption_prepend_input",
			_set:"TextGeneratorOption_set_input",
			pk_columns:"TextGeneratorOption_pk_columns_input"
		},
		update_TextGeneratorOption_many:{
			updates:"TextGeneratorOption_updates"
		},
		update_TextPromptTemplate:{
			_append:"TextPromptTemplate_append_input",
			_delete_at_path:"TextPromptTemplate_delete_at_path_input",
			_delete_elem:"TextPromptTemplate_delete_elem_input",
			_delete_key:"TextPromptTemplate_delete_key_input",
			_inc:"TextPromptTemplate_inc_input",
			_prepend:"TextPromptTemplate_prepend_input",
			_set:"TextPromptTemplate_set_input",
			where:"TextPromptTemplate_bool_exp"
		},
		update_TextPromptTemplateCategory:{
			_set:"TextPromptTemplateCategory_set_input",
			where:"TextPromptTemplateCategory_bool_exp"
		},
		update_TextPromptTemplateCategory_by_pk:{
			_set:"TextPromptTemplateCategory_set_input",
			pk_columns:"TextPromptTemplateCategory_pk_columns_input"
		},
		update_TextPromptTemplateCategory_many:{
			updates:"TextPromptTemplateCategory_updates"
		},
		update_TextPromptTemplate_by_pk:{
			_append:"TextPromptTemplate_append_input",
			_delete_at_path:"TextPromptTemplate_delete_at_path_input",
			_delete_elem:"TextPromptTemplate_delete_elem_input",
			_delete_key:"TextPromptTemplate_delete_key_input",
			_inc:"TextPromptTemplate_inc_input",
			_prepend:"TextPromptTemplate_prepend_input",
			_set:"TextPromptTemplate_set_input",
			pk_columns:"TextPromptTemplate_pk_columns_input"
		},
		update_TextPromptTemplate_many:{
			updates:"TextPromptTemplate_updates"
		},
		update_TextTemplateClientCategory:{
			_set:"TextTemplateClientCategory_set_input",
			where:"TextTemplateClientCategory_bool_exp"
		},
		update_TextTemplateClientCategory_by_pk:{
			_set:"TextTemplateClientCategory_set_input",
			pk_columns:"TextTemplateClientCategory_pk_columns_input"
		},
		update_TextTemplateClientCategory_many:{
			updates:"TextTemplateClientCategory_updates"
		},
		update_audiences:{
			_append:"audiences_append_input",
			_delete_at_path:"audiences_delete_at_path_input",
			_delete_elem:"audiences_delete_elem_input",
			_delete_key:"audiences_delete_key_input",
			_prepend:"audiences_prepend_input",
			_set:"audiences_set_input",
			where:"audiences_bool_exp"
		},
		update_audiences_by_pk:{
			_append:"audiences_append_input",
			_delete_at_path:"audiences_delete_at_path_input",
			_delete_elem:"audiences_delete_elem_input",
			_delete_key:"audiences_delete_key_input",
			_prepend:"audiences_prepend_input",
			_set:"audiences_set_input",
			pk_columns:"audiences_pk_columns_input"
		},
		update_audiences_many:{
			updates:"audiences_updates"
		},
		update_authProviderRequests_many:{
			updates:"authProviderRequests_updates"
		},
		update_authProviders_many:{
			updates:"authProviders_updates"
		},
		update_authRefreshTokenTypes_many:{
			updates:"authRefreshTokenTypes_updates"
		},
		update_authRefreshTokens_many:{
			updates:"authRefreshTokens_updates"
		},
		update_authRoles_many:{
			updates:"authRoles_updates"
		},
		update_authUserProviders_many:{
			updates:"authUserProviders_updates"
		},
		update_authUserRoles_many:{
			updates:"authUserRoles_updates"
		},
		update_authUserSecurityKeys_many:{
			updates:"authUserSecurityKeys_updates"
		},
		update_blog:{
			_set:"blog_set_input",
			where:"blog_bool_exp"
		},
		update_blogCategory:{
			_set:"blogCategory_set_input",
			where:"blogCategory_bool_exp"
		},
		update_blogCategory_by_pk:{
			_set:"blogCategory_set_input",
			pk_columns:"blogCategory_pk_columns_input"
		},
		update_blogCategory_many:{
			updates:"blogCategory_updates"
		},
		update_blog_by_pk:{
			_set:"blog_set_input",
			pk_columns:"blog_pk_columns_input"
		},
		update_blog_many:{
			updates:"blog_updates"
		},
		update_brands:{
			_append:"brands_append_input",
			_delete_at_path:"brands_delete_at_path_input",
			_delete_elem:"brands_delete_elem_input",
			_delete_key:"brands_delete_key_input",
			_prepend:"brands_prepend_input",
			_set:"brands_set_input",
			where:"brands_bool_exp"
		},
		update_brands_by_pk:{
			_append:"brands_append_input",
			_delete_at_path:"brands_delete_at_path_input",
			_delete_elem:"brands_delete_elem_input",
			_delete_key:"brands_delete_key_input",
			_prepend:"brands_prepend_input",
			_set:"brands_set_input",
			pk_columns:"brands_pk_columns_input"
		},
		update_brands_many:{
			updates:"brands_updates"
		},
		update_buckets_many:{
			updates:"buckets_updates"
		},
		update_campaign:{
			_append:"campaign_append_input",
			_delete_at_path:"campaign_delete_at_path_input",
			_delete_elem:"campaign_delete_elem_input",
			_delete_key:"campaign_delete_key_input",
			_prepend:"campaign_prepend_input",
			_set:"campaign_set_input",
			where:"campaign_bool_exp"
		},
		update_campaign_by_pk:{
			_append:"campaign_append_input",
			_delete_at_path:"campaign_delete_at_path_input",
			_delete_elem:"campaign_delete_elem_input",
			_delete_key:"campaign_delete_key_input",
			_prepend:"campaign_prepend_input",
			_set:"campaign_set_input",
			pk_columns:"campaign_pk_columns_input"
		},
		update_campaign_many:{
			updates:"campaign_updates"
		},
		update_channels:{
			_set:"channels_set_input",
			where:"channels_bool_exp"
		},
		update_channels_by_pk:{
			_set:"channels_set_input",
			pk_columns:"channels_pk_columns_input"
		},
		update_channels_many:{
			updates:"channels_updates"
		},
		update_costTable:{
			_inc:"costTable_inc_input",
			_set:"costTable_set_input",
			where:"costTable_bool_exp"
		},
		update_costTable_by_pk:{
			_inc:"costTable_inc_input",
			_set:"costTable_set_input",
			pk_columns:"costTable_pk_columns_input"
		},
		update_costTable_many:{
			updates:"costTable_updates"
		},
		update_deal:{
			_inc:"deal_inc_input",
			_set:"deal_set_input",
			where:"deal_bool_exp"
		},
		update_dealStatus:{
			_inc:"dealStatus_inc_input",
			_set:"dealStatus_set_input",
			where:"dealStatus_bool_exp"
		},
		update_dealStatus_by_pk:{
			_inc:"dealStatus_inc_input",
			_set:"dealStatus_set_input",
			pk_columns:"dealStatus_pk_columns_input"
		},
		update_dealStatus_many:{
			updates:"dealStatus_updates"
		},
		update_deal_by_pk:{
			_inc:"deal_inc_input",
			_set:"deal_set_input",
			pk_columns:"deal_pk_columns_input"
		},
		update_deal_many:{
			updates:"deal_updates"
		},
		update_displayCategory:{
			_append:"displayCategory_append_input",
			_delete_at_path:"displayCategory_delete_at_path_input",
			_delete_elem:"displayCategory_delete_elem_input",
			_delete_key:"displayCategory_delete_key_input",
			_prepend:"displayCategory_prepend_input",
			_set:"displayCategory_set_input",
			where:"displayCategory_bool_exp"
		},
		update_displayCategory_by_pk:{
			_append:"displayCategory_append_input",
			_delete_at_path:"displayCategory_delete_at_path_input",
			_delete_elem:"displayCategory_delete_elem_input",
			_delete_key:"displayCategory_delete_key_input",
			_prepend:"displayCategory_prepend_input",
			_set:"displayCategory_set_input",
			pk_columns:"displayCategory_pk_columns_input"
		},
		update_displayCategory_many:{
			updates:"displayCategory_updates"
		},
		update_displayDimension:{
			_append:"displayDimension_append_input",
			_delete_at_path:"displayDimension_delete_at_path_input",
			_delete_elem:"displayDimension_delete_elem_input",
			_delete_key:"displayDimension_delete_key_input",
			_prepend:"displayDimension_prepend_input",
			_set:"displayDimension_set_input",
			where:"displayDimension_bool_exp"
		},
		update_displayDimension_by_pk:{
			_append:"displayDimension_append_input",
			_delete_at_path:"displayDimension_delete_at_path_input",
			_delete_elem:"displayDimension_delete_elem_input",
			_delete_key:"displayDimension_delete_key_input",
			_prepend:"displayDimension_prepend_input",
			_set:"displayDimension_set_input",
			pk_columns:"displayDimension_pk_columns_input"
		},
		update_displayDimension_many:{
			updates:"displayDimension_updates"
		},
		update_displayFormats:{
			_append:"displayFormats_append_input",
			_delete_at_path:"displayFormats_delete_at_path_input",
			_delete_elem:"displayFormats_delete_elem_input",
			_delete_key:"displayFormats_delete_key_input",
			_prepend:"displayFormats_prepend_input",
			_set:"displayFormats_set_input",
			where:"displayFormats_bool_exp"
		},
		update_displayFormats_by_pk:{
			_append:"displayFormats_append_input",
			_delete_at_path:"displayFormats_delete_at_path_input",
			_delete_elem:"displayFormats_delete_elem_input",
			_delete_key:"displayFormats_delete_key_input",
			_prepend:"displayFormats_prepend_input",
			_set:"displayFormats_set_input",
			pk_columns:"displayFormats_pk_columns_input"
		},
		update_displayFormats_many:{
			updates:"displayFormats_updates"
		},
		update_displayTemplate:{
			_append:"displayTemplate_append_input",
			_delete_at_path:"displayTemplate_delete_at_path_input",
			_delete_elem:"displayTemplate_delete_elem_input",
			_delete_key:"displayTemplate_delete_key_input",
			_prepend:"displayTemplate_prepend_input",
			_set:"displayTemplate_set_input",
			where:"displayTemplate_bool_exp"
		},
		update_displayTemplate_by_pk:{
			_append:"displayTemplate_append_input",
			_delete_at_path:"displayTemplate_delete_at_path_input",
			_delete_elem:"displayTemplate_delete_elem_input",
			_delete_key:"displayTemplate_delete_key_input",
			_prepend:"displayTemplate_prepend_input",
			_set:"displayTemplate_set_input",
			pk_columns:"displayTemplate_pk_columns_input"
		},
		update_displayTemplate_many:{
			updates:"displayTemplate_updates"
		},
		update_files_many:{
			updates:"files_updates"
		},
		update_interaction:{
			_set:"interaction_set_input",
			where:"interaction_bool_exp"
		},
		update_interaction_by_pk:{
			_set:"interaction_set_input",
			pk_columns:"interaction_pk_columns_input"
		},
		update_interaction_many:{
			updates:"interaction_updates"
		},
		update_landingPageTemplate:{
			_set:"landingPageTemplate_set_input",
			where:"landingPageTemplate_bool_exp"
		},
		update_landingPageTemplate_by_pk:{
			_set:"landingPageTemplate_set_input",
			pk_columns:"landingPageTemplate_pk_columns_input"
		},
		update_landingPageTemplate_many:{
			updates:"landingPageTemplate_updates"
		},
		update_newTemplateType:{
			_inc:"newTemplateType_inc_input",
			_set:"newTemplateType_set_input",
			where:"newTemplateType_bool_exp"
		},
		update_newTemplateType_by_pk:{
			_inc:"newTemplateType_inc_input",
			_set:"newTemplateType_set_input",
			pk_columns:"newTemplateType_pk_columns_input"
		},
		update_newTemplateType_many:{
			updates:"newTemplateType_updates"
		},
		update_openAIApiKey:{
			_set:"openAIApiKey_set_input",
			where:"openAIApiKey_bool_exp"
		},
		update_openAIApiKey_by_pk:{
			_set:"openAIApiKey_set_input",
			pk_columns:"openAIApiKey_pk_columns_input"
		},
		update_openAIApiKey_many:{
			updates:"openAIApiKey_updates"
		},
		update_pTemplate:{
			_append:"pTemplate_append_input",
			_delete_at_path:"pTemplate_delete_at_path_input",
			_delete_elem:"pTemplate_delete_elem_input",
			_delete_key:"pTemplate_delete_key_input",
			_inc:"pTemplate_inc_input",
			_prepend:"pTemplate_prepend_input",
			_set:"pTemplate_set_input",
			where:"pTemplate_bool_exp"
		},
		update_pTemplate_by_pk:{
			_append:"pTemplate_append_input",
			_delete_at_path:"pTemplate_delete_at_path_input",
			_delete_elem:"pTemplate_delete_elem_input",
			_delete_key:"pTemplate_delete_key_input",
			_inc:"pTemplate_inc_input",
			_prepend:"pTemplate_prepend_input",
			_set:"pTemplate_set_input",
			pk_columns:"pTemplate_pk_columns_input"
		},
		update_pTemplate_many:{
			updates:"pTemplate_updates"
		},
		update_pricingPlan:{
			_inc:"pricingPlan_inc_input",
			_set:"pricingPlan_set_input",
			where:"pricingPlan_bool_exp"
		},
		update_pricingPlan_by_pk:{
			_inc:"pricingPlan_inc_input",
			_set:"pricingPlan_set_input",
			pk_columns:"pricingPlan_pk_columns_input"
		},
		update_pricingPlan_many:{
			updates:"pricingPlan_updates"
		},
		update_products:{
			_append:"products_append_input",
			_delete_at_path:"products_delete_at_path_input",
			_delete_elem:"products_delete_elem_input",
			_delete_key:"products_delete_key_input",
			_inc:"products_inc_input",
			_prepend:"products_prepend_input",
			_set:"products_set_input",
			where:"products_bool_exp"
		},
		update_products_by_pk:{
			_append:"products_append_input",
			_delete_at_path:"products_delete_at_path_input",
			_delete_elem:"products_delete_elem_input",
			_delete_key:"products_delete_key_input",
			_inc:"products_inc_input",
			_prepend:"products_prepend_input",
			_set:"products_set_input",
			pk_columns:"products_pk_columns_input"
		},
		update_products_many:{
			updates:"products_updates"
		},
		update_project:{
			_append:"project_append_input",
			_delete_at_path:"project_delete_at_path_input",
			_delete_elem:"project_delete_elem_input",
			_delete_key:"project_delete_key_input",
			_inc:"project_inc_input",
			_prepend:"project_prepend_input",
			_set:"project_set_input",
			where:"project_bool_exp"
		},
		update_projectImage:{
			_set:"projectImage_set_input",
			where:"projectImage_bool_exp"
		},
		update_projectImage_by_pk:{
			_set:"projectImage_set_input",
			pk_columns:"projectImage_pk_columns_input"
		},
		update_projectImage_many:{
			updates:"projectImage_updates"
		},
		update_project_by_pk:{
			_append:"project_append_input",
			_delete_at_path:"project_delete_at_path_input",
			_delete_elem:"project_delete_elem_input",
			_delete_key:"project_delete_key_input",
			_inc:"project_inc_input",
			_prepend:"project_prepend_input",
			_set:"project_set_input",
			pk_columns:"project_pk_columns_input"
		},
		update_project_many:{
			updates:"project_updates"
		},
		update_task:{
			_set:"task_set_input",
			where:"task_bool_exp"
		},
		update_task_by_pk:{
			_set:"task_set_input",
			pk_columns:"task_pk_columns_input"
		},
		update_task_many:{
			updates:"task_updates"
		},
		update_textTemplateDisplayFormat:{
			_set:"textTemplateDisplayFormat_set_input",
			where:"textTemplateDisplayFormat_bool_exp"
		},
		update_textTemplateDisplayFormat_by_pk:{
			_set:"textTemplateDisplayFormat_set_input",
			pk_columns:"textTemplateDisplayFormat_pk_columns_input"
		},
		update_textTemplateDisplayFormat_many:{
			updates:"textTemplateDisplayFormat_updates"
		},
		update_userAppIntegration:{
			_append:"userAppIntegration_append_input",
			_delete_at_path:"userAppIntegration_delete_at_path_input",
			_delete_elem:"userAppIntegration_delete_elem_input",
			_delete_key:"userAppIntegration_delete_key_input",
			_prepend:"userAppIntegration_prepend_input",
			_set:"userAppIntegration_set_input",
			where:"userAppIntegration_bool_exp"
		},
		update_userAppIntegration_by_pk:{
			_append:"userAppIntegration_append_input",
			_delete_at_path:"userAppIntegration_delete_at_path_input",
			_delete_elem:"userAppIntegration_delete_elem_input",
			_delete_key:"userAppIntegration_delete_key_input",
			_prepend:"userAppIntegration_prepend_input",
			_set:"userAppIntegration_set_input",
			pk_columns:"userAppIntegration_pk_columns_input"
		},
		update_userAppIntegration_many:{
			updates:"userAppIntegration_updates"
		},
		update_userCreditUsage:{
			_inc:"userCreditUsage_inc_input",
			_set:"userCreditUsage_set_input",
			where:"userCreditUsage_bool_exp"
		},
		update_userCreditUsageSummary:{
			_inc:"userCreditUsageSummary_inc_input",
			_set:"userCreditUsageSummary_set_input",
			where:"userCreditUsageSummary_bool_exp"
		},
		update_userCreditUsageSummary_by_pk:{
			_inc:"userCreditUsageSummary_inc_input",
			_set:"userCreditUsageSummary_set_input",
			pk_columns:"userCreditUsageSummary_pk_columns_input"
		},
		update_userCreditUsageSummary_many:{
			updates:"userCreditUsageSummary_updates"
		},
		update_userCreditUsage_by_pk:{
			_inc:"userCreditUsage_inc_input",
			_set:"userCreditUsage_set_input",
			pk_columns:"userCreditUsage_pk_columns_input"
		},
		update_userCreditUsage_many:{
			updates:"userCreditUsage_updates"
		},
		update_userCredits:{
			_inc:"userCredits_inc_input",
			_set:"userCredits_set_input",
			where:"userCredits_bool_exp"
		},
		update_userCredits_by_pk:{
			_inc:"userCredits_inc_input",
			_set:"userCredits_set_input",
			pk_columns:"userCredits_pk_columns_input"
		},
		update_userCredits_many:{
			updates:"userCredits_updates"
		},
		update_userFeedback:{
			_set:"userFeedback_set_input",
			where:"userFeedback_bool_exp"
		},
		update_userFeedback_by_pk:{
			_set:"userFeedback_set_input",
			pk_columns:"userFeedback_pk_columns_input"
		},
		update_userFeedback_many:{
			updates:"userFeedback_updates"
		},
		update_userMedia:{
			_append:"userMedia_append_input",
			_delete_at_path:"userMedia_delete_at_path_input",
			_delete_elem:"userMedia_delete_elem_input",
			_delete_key:"userMedia_delete_key_input",
			_prepend:"userMedia_prepend_input",
			_set:"userMedia_set_input",
			where:"userMedia_bool_exp"
		},
		update_userMedia_by_pk:{
			_append:"userMedia_append_input",
			_delete_at_path:"userMedia_delete_at_path_input",
			_delete_elem:"userMedia_delete_elem_input",
			_delete_key:"userMedia_delete_key_input",
			_prepend:"userMedia_prepend_input",
			_set:"userMedia_set_input",
			pk_columns:"userMedia_pk_columns_input"
		},
		update_userMedia_many:{
			updates:"userMedia_updates"
		},
		update_userMetadata:{
			_inc:"userMetadata_inc_input",
			_set:"userMetadata_set_input",
			where:"userMetadata_bool_exp"
		},
		update_userMetadata_by_pk:{
			_inc:"userMetadata_inc_input",
			_set:"userMetadata_set_input",
			pk_columns:"userMetadata_pk_columns_input"
		},
		update_userMetadata_many:{
			updates:"userMetadata_updates"
		},
		update_userOnboarding:{
			_inc:"userOnboarding_inc_input",
			_set:"userOnboarding_set_input",
			where:"userOnboarding_bool_exp"
		},
		update_userOnboarding_by_pk:{
			_inc:"userOnboarding_inc_input",
			_set:"userOnboarding_set_input",
			pk_columns:"userOnboarding_pk_columns_input"
		},
		update_userOnboarding_many:{
			updates:"userOnboarding_updates"
		},
		update_userTemplateUsage:{
			_set:"userTemplateUsage_set_input",
			where:"userTemplateUsage_bool_exp"
		},
		update_userTemplateUsage_by_pk:{
			_set:"userTemplateUsage_set_input",
			pk_columns:"userTemplateUsage_pk_columns_input"
		},
		update_userTemplateUsage_many:{
			updates:"userTemplateUsage_updates"
		},
		update_users_many:{
			updates:"users_updates"
		},
		update_virus_many:{
			updates:"virus_updates"
		},
		update_website:{
			_set:"website_set_input",
			where:"website_bool_exp"
		},
		update_website_by_pk:{
			_set:"website_set_input",
			pk_columns:"website_pk_columns_input"
		},
		update_website_many:{
			updates:"website_updates"
		},
		upsertAudience:{

		},
		upsertBrand:{

		}
	},
	newTemplateType_aggregate_fields:{
		count:{
			columns:"newTemplateType_select_column"
		}
	},
	newTemplateType_bool_exp:{
		_and:"newTemplateType_bool_exp",
		_not:"newTemplateType_bool_exp",
		_or:"newTemplateType_bool_exp",
		channel:"String_comparison_exp",
		created_at:"timestamptz_comparison_exp",
		creditPrice:"Int_comparison_exp",
		description:"String_comparison_exp",
		icon:"String_comparison_exp",
		id:"uuid_comparison_exp",
		name:"String_comparison_exp",
		saleCreditPrice:"Int_comparison_exp",
		thumbnailFileId:"uuid_comparison_exp",
		updated_at:"timestamptz_comparison_exp"
	},
	newTemplateType_constraint: "enum" as const,
	newTemplateType_inc_input:{

	},
	newTemplateType_insert_input:{
		created_at:"timestamptz",
		id:"uuid",
		thumbnailFileId:"uuid",
		updated_at:"timestamptz"
	},
	newTemplateType_on_conflict:{
		constraint:"newTemplateType_constraint",
		update_columns:"newTemplateType_update_column",
		where:"newTemplateType_bool_exp"
	},
	newTemplateType_order_by:{
		channel:"order_by",
		created_at:"order_by",
		creditPrice:"order_by",
		description:"order_by",
		icon:"order_by",
		id:"order_by",
		name:"order_by",
		saleCreditPrice:"order_by",
		thumbnailFileId:"order_by",
		updated_at:"order_by"
	},
	newTemplateType_pk_columns_input:{
		id:"uuid"
	},
	newTemplateType_select_column: "enum" as const,
	newTemplateType_set_input:{
		created_at:"timestamptz",
		id:"uuid",
		thumbnailFileId:"uuid",
		updated_at:"timestamptz"
	},
	newTemplateType_stream_cursor_input:{
		initial_value:"newTemplateType_stream_cursor_value_input",
		ordering:"cursor_ordering"
	},
	newTemplateType_stream_cursor_value_input:{
		created_at:"timestamptz",
		id:"uuid",
		thumbnailFileId:"uuid",
		updated_at:"timestamptz"
	},
	newTemplateType_update_column: "enum" as const,
	newTemplateType_updates:{
		_inc:"newTemplateType_inc_input",
		_set:"newTemplateType_set_input",
		where:"newTemplateType_bool_exp"
	},
	numeric: `scalar.numeric` as const,
	numeric_comparison_exp:{
		_eq:"numeric",
		_gt:"numeric",
		_gte:"numeric",
		_in:"numeric",
		_lt:"numeric",
		_lte:"numeric",
		_neq:"numeric",
		_nin:"numeric"
	},
	openAIApiKey_aggregate_fields:{
		count:{
			columns:"openAIApiKey_select_column"
		}
	},
	openAIApiKey_bool_exp:{
		_and:"openAIApiKey_bool_exp",
		_not:"openAIApiKey_bool_exp",
		_or:"openAIApiKey_bool_exp",
		apiKey:"String_comparison_exp",
		created_at:"timestamptz_comparison_exp",
		id:"uuid_comparison_exp",
		last_used:"timestamptz_comparison_exp",
		status:"String_comparison_exp"
	},
	openAIApiKey_constraint: "enum" as const,
	openAIApiKey_insert_input:{
		created_at:"timestamptz",
		id:"uuid",
		last_used:"timestamptz"
	},
	openAIApiKey_on_conflict:{
		constraint:"openAIApiKey_constraint",
		update_columns:"openAIApiKey_update_column",
		where:"openAIApiKey_bool_exp"
	},
	openAIApiKey_order_by:{
		apiKey:"order_by",
		created_at:"order_by",
		id:"order_by",
		last_used:"order_by",
		status:"order_by"
	},
	openAIApiKey_pk_columns_input:{
		id:"uuid"
	},
	openAIApiKey_select_column: "enum" as const,
	openAIApiKey_set_input:{
		created_at:"timestamptz",
		id:"uuid",
		last_used:"timestamptz"
	},
	openAIApiKey_stream_cursor_input:{
		initial_value:"openAIApiKey_stream_cursor_value_input",
		ordering:"cursor_ordering"
	},
	openAIApiKey_stream_cursor_value_input:{
		created_at:"timestamptz",
		id:"uuid",
		last_used:"timestamptz"
	},
	openAIApiKey_update_column: "enum" as const,
	openAIApiKey_updates:{
		_set:"openAIApiKey_set_input",
		where:"openAIApiKey_bool_exp"
	},
	order_by: "enum" as const,
	pTemplate:{
		json:{

		},
		tagScores:{

		}
	},
	pTemplate_aggregate_fields:{
		count:{
			columns:"pTemplate_select_column"
		}
	},
	pTemplate_append_input:{
		json:"jsonb",
		tagScores:"jsonb"
	},
	pTemplate_bool_exp:{
		_and:"pTemplate_bool_exp",
		_not:"pTemplate_bool_exp",
		_or:"pTemplate_bool_exp",
		category:"String_comparison_exp",
		createdBy:"uuid_comparison_exp",
		created_at:"timestamptz_comparison_exp",
		deletedAt:"timestamptz_comparison_exp",
		descriptionMaxLength:"Int_comparison_exp",
		height:"String_comparison_exp",
		id:"uuid_comparison_exp",
		json:"jsonb_comparison_exp",
		previewFileId:"uuid_comparison_exp",
		subtitleMaxLength:"Int_comparison_exp",
		tagScores:"jsonb_comparison_exp",
		titleMaxLength:"Int_comparison_exp",
		updated_at:"timestamptz_comparison_exp",
		visibility:"String_comparison_exp",
		width:"String_comparison_exp"
	},
	pTemplate_constraint: "enum" as const,
	pTemplate_delete_at_path_input:{

	},
	pTemplate_delete_elem_input:{

	},
	pTemplate_delete_key_input:{

	},
	pTemplate_inc_input:{

	},
	pTemplate_insert_input:{
		createdBy:"uuid",
		created_at:"timestamptz",
		deletedAt:"timestamptz",
		id:"uuid",
		json:"jsonb",
		previewFileId:"uuid",
		tagScores:"jsonb",
		updated_at:"timestamptz"
	},
	pTemplate_on_conflict:{
		constraint:"pTemplate_constraint",
		update_columns:"pTemplate_update_column",
		where:"pTemplate_bool_exp"
	},
	pTemplate_order_by:{
		category:"order_by",
		createdBy:"order_by",
		created_at:"order_by",
		deletedAt:"order_by",
		descriptionMaxLength:"order_by",
		height:"order_by",
		id:"order_by",
		json:"order_by",
		previewFileId:"order_by",
		subtitleMaxLength:"order_by",
		tagScores:"order_by",
		titleMaxLength:"order_by",
		updated_at:"order_by",
		visibility:"order_by",
		width:"order_by"
	},
	pTemplate_pk_columns_input:{
		id:"uuid"
	},
	pTemplate_prepend_input:{
		json:"jsonb",
		tagScores:"jsonb"
	},
	pTemplate_select_column: "enum" as const,
	pTemplate_set_input:{
		createdBy:"uuid",
		created_at:"timestamptz",
		deletedAt:"timestamptz",
		id:"uuid",
		json:"jsonb",
		previewFileId:"uuid",
		tagScores:"jsonb",
		updated_at:"timestamptz"
	},
	pTemplate_stream_cursor_input:{
		initial_value:"pTemplate_stream_cursor_value_input",
		ordering:"cursor_ordering"
	},
	pTemplate_stream_cursor_value_input:{
		createdBy:"uuid",
		created_at:"timestamptz",
		deletedAt:"timestamptz",
		id:"uuid",
		json:"jsonb",
		previewFileId:"uuid",
		tagScores:"jsonb",
		updated_at:"timestamptz"
	},
	pTemplate_update_column: "enum" as const,
	pTemplate_updates:{
		_append:"pTemplate_append_input",
		_delete_at_path:"pTemplate_delete_at_path_input",
		_delete_elem:"pTemplate_delete_elem_input",
		_delete_key:"pTemplate_delete_key_input",
		_inc:"pTemplate_inc_input",
		_prepend:"pTemplate_prepend_input",
		_set:"pTemplate_set_input",
		where:"pTemplate_bool_exp"
	},
	pricingPlan_aggregate_fields:{
		count:{
			columns:"pricingPlan_select_column"
		}
	},
	pricingPlan_bool_exp:{
		_and:"pricingPlan_bool_exp",
		_not:"pricingPlan_bool_exp",
		_or:"pricingPlan_bool_exp",
		active:"Boolean_comparison_exp",
		alias:"String_comparison_exp",
		created_at:"timestamptz_comparison_exp",
		creditUnitDiscount:"numeric_comparison_exp",
		creditsToAdd:"numeric_comparison_exp",
		htmlFeatureItems:"String_comparison_exp",
		id:"uuid_comparison_exp",
		monthlyPrice:"numeric_comparison_exp",
		order:"numeric_comparison_exp",
		stripePriceId:"String_comparison_exp",
		stripeProductId:"String_comparison_exp",
		type:"String_comparison_exp",
		updated_at:"timestamptz_comparison_exp"
	},
	pricingPlan_constraint: "enum" as const,
	pricingPlan_inc_input:{
		creditUnitDiscount:"numeric",
		creditsToAdd:"numeric",
		monthlyPrice:"numeric",
		order:"numeric"
	},
	pricingPlan_insert_input:{
		created_at:"timestamptz",
		creditUnitDiscount:"numeric",
		creditsToAdd:"numeric",
		id:"uuid",
		monthlyPrice:"numeric",
		order:"numeric",
		updated_at:"timestamptz"
	},
	pricingPlan_obj_rel_insert_input:{
		data:"pricingPlan_insert_input",
		on_conflict:"pricingPlan_on_conflict"
	},
	pricingPlan_on_conflict:{
		constraint:"pricingPlan_constraint",
		update_columns:"pricingPlan_update_column",
		where:"pricingPlan_bool_exp"
	},
	pricingPlan_order_by:{
		active:"order_by",
		alias:"order_by",
		created_at:"order_by",
		creditUnitDiscount:"order_by",
		creditsToAdd:"order_by",
		htmlFeatureItems:"order_by",
		id:"order_by",
		monthlyPrice:"order_by",
		order:"order_by",
		stripePriceId:"order_by",
		stripeProductId:"order_by",
		type:"order_by",
		updated_at:"order_by"
	},
	pricingPlan_pk_columns_input:{
		id:"uuid"
	},
	pricingPlan_select_column: "enum" as const,
	pricingPlan_set_input:{
		created_at:"timestamptz",
		creditUnitDiscount:"numeric",
		creditsToAdd:"numeric",
		id:"uuid",
		monthlyPrice:"numeric",
		order:"numeric",
		updated_at:"timestamptz"
	},
	pricingPlan_stream_cursor_input:{
		initial_value:"pricingPlan_stream_cursor_value_input",
		ordering:"cursor_ordering"
	},
	pricingPlan_stream_cursor_value_input:{
		created_at:"timestamptz",
		creditUnitDiscount:"numeric",
		creditsToAdd:"numeric",
		id:"uuid",
		monthlyPrice:"numeric",
		order:"numeric",
		updated_at:"timestamptz"
	},
	pricingPlan_update_column: "enum" as const,
	pricingPlan_updates:{
		_inc:"pricingPlan_inc_input",
		_set:"pricingPlan_set_input",
		where:"pricingPlan_bool_exp"
	},
	products:{
		benefits:{

		},
		characteristics:{

		},
		testimonials:{

		},
		wordKeys:{

		}
	},
	products_aggregate_bool_exp:{
		bool_and:"products_aggregate_bool_exp_bool_and",
		bool_or:"products_aggregate_bool_exp_bool_or",
		count:"products_aggregate_bool_exp_count"
	},
	products_aggregate_bool_exp_bool_and:{
		arguments:"products_select_column_products_aggregate_bool_exp_bool_and_arguments_columns",
		filter:"products_bool_exp",
		predicate:"Boolean_comparison_exp"
	},
	products_aggregate_bool_exp_bool_or:{
		arguments:"products_select_column_products_aggregate_bool_exp_bool_or_arguments_columns",
		filter:"products_bool_exp",
		predicate:"Boolean_comparison_exp"
	},
	products_aggregate_bool_exp_count:{
		arguments:"products_select_column",
		filter:"products_bool_exp",
		predicate:"Int_comparison_exp"
	},
	products_aggregate_fields:{
		count:{
			columns:"products_select_column"
		}
	},
	products_aggregate_order_by:{
		avg:"products_avg_order_by",
		count:"order_by",
		max:"products_max_order_by",
		min:"products_min_order_by",
		stddev:"products_stddev_order_by",
		stddev_pop:"products_stddev_pop_order_by",
		stddev_samp:"products_stddev_samp_order_by",
		sum:"products_sum_order_by",
		var_pop:"products_var_pop_order_by",
		var_samp:"products_var_samp_order_by",
		variance:"products_variance_order_by"
	},
	products_append_input:{
		benefits:"jsonb",
		characteristics:"jsonb",
		testimonials:"jsonb",
		wordKeys:"jsonb"
	},
	products_arr_rel_insert_input:{
		data:"products_insert_input",
		on_conflict:"products_on_conflict"
	},
	products_avg_order_by:{
		price:"order_by",
		salePrice:"order_by"
	},
	products_bool_exp:{
		_and:"products_bool_exp",
		_not:"products_bool_exp",
		_or:"products_bool_exp",
		alias:"String_comparison_exp",
		benefits:"jsonb_comparison_exp",
		brand:"brands_bool_exp",
		brandId:"uuid_comparison_exp",
		category:"String_comparison_exp",
		characteristics:"jsonb_comparison_exp",
		created_at:"timestamptz_comparison_exp",
		created_by:"uuid_comparison_exp",
		deleted:"Boolean_comparison_exp",
		description:"String_comparison_exp",
		id:"uuid_comparison_exp",
		imageFourId:"uuid_comparison_exp",
		imageOneId:"uuid_comparison_exp",
		imageThreeId:"uuid_comparison_exp",
		imageTwoId:"uuid_comparison_exp",
		linkConversion:"String_comparison_exp",
		price:"numeric_comparison_exp",
		salePrice:"numeric_comparison_exp",
		testimonials:"jsonb_comparison_exp",
		type:"String_comparison_exp",
		updated_at:"timestamptz_comparison_exp",
		wordKeys:"jsonb_comparison_exp"
	},
	products_constraint: "enum" as const,
	products_delete_at_path_input:{

	},
	products_delete_elem_input:{

	},
	products_delete_key_input:{

	},
	products_inc_input:{
		price:"numeric",
		salePrice:"numeric"
	},
	products_insert_input:{
		benefits:"jsonb",
		brand:"brands_obj_rel_insert_input",
		brandId:"uuid",
		characteristics:"jsonb",
		created_at:"timestamptz",
		created_by:"uuid",
		id:"uuid",
		imageFourId:"uuid",
		imageOneId:"uuid",
		imageThreeId:"uuid",
		imageTwoId:"uuid",
		price:"numeric",
		salePrice:"numeric",
		testimonials:"jsonb",
		updated_at:"timestamptz",
		wordKeys:"jsonb"
	},
	products_max_order_by:{
		alias:"order_by",
		brandId:"order_by",
		category:"order_by",
		created_at:"order_by",
		created_by:"order_by",
		description:"order_by",
		id:"order_by",
		imageFourId:"order_by",
		imageOneId:"order_by",
		imageThreeId:"order_by",
		imageTwoId:"order_by",
		linkConversion:"order_by",
		price:"order_by",
		salePrice:"order_by",
		type:"order_by",
		updated_at:"order_by"
	},
	products_min_order_by:{
		alias:"order_by",
		brandId:"order_by",
		category:"order_by",
		created_at:"order_by",
		created_by:"order_by",
		description:"order_by",
		id:"order_by",
		imageFourId:"order_by",
		imageOneId:"order_by",
		imageThreeId:"order_by",
		imageTwoId:"order_by",
		linkConversion:"order_by",
		price:"order_by",
		salePrice:"order_by",
		type:"order_by",
		updated_at:"order_by"
	},
	products_obj_rel_insert_input:{
		data:"products_insert_input",
		on_conflict:"products_on_conflict"
	},
	products_on_conflict:{
		constraint:"products_constraint",
		update_columns:"products_update_column",
		where:"products_bool_exp"
	},
	products_order_by:{
		alias:"order_by",
		benefits:"order_by",
		brand:"brands_order_by",
		brandId:"order_by",
		category:"order_by",
		characteristics:"order_by",
		created_at:"order_by",
		created_by:"order_by",
		deleted:"order_by",
		description:"order_by",
		id:"order_by",
		imageFourId:"order_by",
		imageOneId:"order_by",
		imageThreeId:"order_by",
		imageTwoId:"order_by",
		linkConversion:"order_by",
		price:"order_by",
		salePrice:"order_by",
		testimonials:"order_by",
		type:"order_by",
		updated_at:"order_by",
		wordKeys:"order_by"
	},
	products_pk_columns_input:{
		id:"uuid"
	},
	products_prepend_input:{
		benefits:"jsonb",
		characteristics:"jsonb",
		testimonials:"jsonb",
		wordKeys:"jsonb"
	},
	products_select_column: "enum" as const,
	products_select_column_products_aggregate_bool_exp_bool_and_arguments_columns: "enum" as const,
	products_select_column_products_aggregate_bool_exp_bool_or_arguments_columns: "enum" as const,
	products_set_input:{
		benefits:"jsonb",
		brandId:"uuid",
		characteristics:"jsonb",
		created_at:"timestamptz",
		created_by:"uuid",
		id:"uuid",
		imageFourId:"uuid",
		imageOneId:"uuid",
		imageThreeId:"uuid",
		imageTwoId:"uuid",
		price:"numeric",
		salePrice:"numeric",
		testimonials:"jsonb",
		updated_at:"timestamptz",
		wordKeys:"jsonb"
	},
	products_stddev_order_by:{
		price:"order_by",
		salePrice:"order_by"
	},
	products_stddev_pop_order_by:{
		price:"order_by",
		salePrice:"order_by"
	},
	products_stddev_samp_order_by:{
		price:"order_by",
		salePrice:"order_by"
	},
	products_stream_cursor_input:{
		initial_value:"products_stream_cursor_value_input",
		ordering:"cursor_ordering"
	},
	products_stream_cursor_value_input:{
		benefits:"jsonb",
		brandId:"uuid",
		characteristics:"jsonb",
		created_at:"timestamptz",
		created_by:"uuid",
		id:"uuid",
		imageFourId:"uuid",
		imageOneId:"uuid",
		imageThreeId:"uuid",
		imageTwoId:"uuid",
		price:"numeric",
		salePrice:"numeric",
		testimonials:"jsonb",
		updated_at:"timestamptz",
		wordKeys:"jsonb"
	},
	products_sum_order_by:{
		price:"order_by",
		salePrice:"order_by"
	},
	products_update_column: "enum" as const,
	products_updates:{
		_append:"products_append_input",
		_delete_at_path:"products_delete_at_path_input",
		_delete_elem:"products_delete_elem_input",
		_delete_key:"products_delete_key_input",
		_inc:"products_inc_input",
		_prepend:"products_prepend_input",
		_set:"products_set_input",
		where:"products_bool_exp"
	},
	products_var_pop_order_by:{
		price:"order_by",
		salePrice:"order_by"
	},
	products_var_samp_order_by:{
		price:"order_by",
		salePrice:"order_by"
	},
	products_variance_order_by:{
		price:"order_by",
		salePrice:"order_by"
	},
	project:{
		template:{

		}
	},
	projectImage_aggregate_fields:{
		count:{
			columns:"projectImage_select_column"
		}
	},
	projectImage_bool_exp:{
		_and:"projectImage_bool_exp",
		_not:"projectImage_bool_exp",
		_or:"projectImage_bool_exp",
		fileId:"uuid_comparison_exp",
		id:"uuid_comparison_exp",
		projectId:"uuid_comparison_exp"
	},
	projectImage_constraint: "enum" as const,
	projectImage_insert_input:{
		fileId:"uuid",
		id:"uuid",
		projectId:"uuid"
	},
	projectImage_on_conflict:{
		constraint:"projectImage_constraint",
		update_columns:"projectImage_update_column",
		where:"projectImage_bool_exp"
	},
	projectImage_order_by:{
		fileId:"order_by",
		id:"order_by",
		projectId:"order_by"
	},
	projectImage_pk_columns_input:{
		id:"uuid"
	},
	projectImage_select_column: "enum" as const,
	projectImage_set_input:{
		fileId:"uuid",
		id:"uuid",
		projectId:"uuid"
	},
	projectImage_stream_cursor_input:{
		initial_value:"projectImage_stream_cursor_value_input",
		ordering:"cursor_ordering"
	},
	projectImage_stream_cursor_value_input:{
		fileId:"uuid",
		id:"uuid",
		projectId:"uuid"
	},
	projectImage_update_column: "enum" as const,
	projectImage_updates:{
		_set:"projectImage_set_input",
		where:"projectImage_bool_exp"
	},
	project_aggregate_fields:{
		count:{
			columns:"project_select_column"
		}
	},
	project_append_input:{
		template:"jsonb"
	},
	project_bool_exp:{
		_and:"project_bool_exp",
		_not:"project_bool_exp",
		_or:"project_bool_exp",
		alias:"String_comparison_exp",
		audienceId:"uuid_comparison_exp",
		blogContent:"String_comparison_exp",
		blogTitle:"String_comparison_exp",
		brandId:"uuid_comparison_exp",
		campaignId:"uuid_comparison_exp",
		caption:"String_comparison_exp",
		created_at:"timestamptz_comparison_exp",
		created_by:"uuid_comparison_exp",
		creditPrice:"numeric_comparison_exp",
		deleted:"Boolean_comparison_exp",
		featuredImageFileId:"uuid_comparison_exp",
		id:"uuid_comparison_exp",
		previewImageFile:"files_bool_exp",
		previewImageFileId:"uuid_comparison_exp",
		productId:"uuid_comparison_exp",
		publishDate:"timestamptz_comparison_exp",
		rawText:"String_comparison_exp",
		scheduledDate:"timestamptz_comparison_exp",
		scheduledDateEventId:"uuid_comparison_exp",
		status:"String_comparison_exp",
		suggestedHeightInPixels:"Int_comparison_exp",
		suggestedScheduledDate:"timestamptz_comparison_exp",
		suggestedWidthInPixels:"Int_comparison_exp",
		template:"jsonb_comparison_exp",
		type:"String_comparison_exp",
		updated_at:"timestamptz_comparison_exp"
	},
	project_constraint: "enum" as const,
	project_delete_at_path_input:{

	},
	project_delete_elem_input:{

	},
	project_delete_key_input:{

	},
	project_inc_input:{
		creditPrice:"numeric"
	},
	project_insert_input:{
		audienceId:"uuid",
		brandId:"uuid",
		campaignId:"uuid",
		created_at:"timestamptz",
		created_by:"uuid",
		creditPrice:"numeric",
		featuredImageFileId:"uuid",
		id:"uuid",
		previewImageFile:"files_obj_rel_insert_input",
		previewImageFileId:"uuid",
		productId:"uuid",
		publishDate:"timestamptz",
		scheduledDate:"timestamptz",
		scheduledDateEventId:"uuid",
		suggestedScheduledDate:"timestamptz",
		template:"jsonb",
		updated_at:"timestamptz"
	},
	project_obj_rel_insert_input:{
		data:"project_insert_input",
		on_conflict:"project_on_conflict"
	},
	project_on_conflict:{
		constraint:"project_constraint",
		update_columns:"project_update_column",
		where:"project_bool_exp"
	},
	project_order_by:{
		alias:"order_by",
		audienceId:"order_by",
		blogContent:"order_by",
		blogTitle:"order_by",
		brandId:"order_by",
		campaignId:"order_by",
		caption:"order_by",
		created_at:"order_by",
		created_by:"order_by",
		creditPrice:"order_by",
		deleted:"order_by",
		featuredImageFileId:"order_by",
		id:"order_by",
		previewImageFile:"files_order_by",
		previewImageFileId:"order_by",
		productId:"order_by",
		publishDate:"order_by",
		rawText:"order_by",
		scheduledDate:"order_by",
		scheduledDateEventId:"order_by",
		status:"order_by",
		suggestedHeightInPixels:"order_by",
		suggestedScheduledDate:"order_by",
		suggestedWidthInPixels:"order_by",
		template:"order_by",
		type:"order_by",
		updated_at:"order_by"
	},
	project_pk_columns_input:{
		id:"uuid"
	},
	project_prepend_input:{
		template:"jsonb"
	},
	project_select_column: "enum" as const,
	project_set_input:{
		audienceId:"uuid",
		brandId:"uuid",
		campaignId:"uuid",
		created_at:"timestamptz",
		created_by:"uuid",
		creditPrice:"numeric",
		featuredImageFileId:"uuid",
		id:"uuid",
		previewImageFileId:"uuid",
		productId:"uuid",
		publishDate:"timestamptz",
		scheduledDate:"timestamptz",
		scheduledDateEventId:"uuid",
		suggestedScheduledDate:"timestamptz",
		template:"jsonb",
		updated_at:"timestamptz"
	},
	project_stream_cursor_input:{
		initial_value:"project_stream_cursor_value_input",
		ordering:"cursor_ordering"
	},
	project_stream_cursor_value_input:{
		audienceId:"uuid",
		brandId:"uuid",
		campaignId:"uuid",
		created_at:"timestamptz",
		created_by:"uuid",
		creditPrice:"numeric",
		featuredImageFileId:"uuid",
		id:"uuid",
		previewImageFileId:"uuid",
		productId:"uuid",
		publishDate:"timestamptz",
		scheduledDate:"timestamptz",
		scheduledDateEventId:"uuid",
		suggestedScheduledDate:"timestamptz",
		template:"jsonb",
		updated_at:"timestamptz"
	},
	project_update_column: "enum" as const,
	project_updates:{
		_append:"project_append_input",
		_delete_at_path:"project_delete_at_path_input",
		_delete_elem:"project_delete_elem_input",
		_delete_key:"project_delete_key_input",
		_inc:"project_inc_input",
		_prepend:"project_prepend_input",
		_set:"project_set_input",
		where:"project_bool_exp"
	},
	query_root:{
		AppIntegration:{
			distinct_on:"AppIntegration_select_column",
			order_by:"AppIntegration_order_by",
			where:"AppIntegration_bool_exp"
		},
		AppIntegration_aggregate:{
			distinct_on:"AppIntegration_select_column",
			order_by:"AppIntegration_order_by",
			where:"AppIntegration_bool_exp"
		},
		AppIntegration_by_pk:{
			id:"uuid"
		},
		Course:{
			distinct_on:"Course_select_column",
			order_by:"Course_order_by",
			where:"Course_bool_exp"
		},
		CourseCategory:{
			distinct_on:"CourseCategory_select_column",
			order_by:"CourseCategory_order_by",
			where:"CourseCategory_bool_exp"
		},
		CourseCategory_aggregate:{
			distinct_on:"CourseCategory_select_column",
			order_by:"CourseCategory_order_by",
			where:"CourseCategory_bool_exp"
		},
		CourseCategory_by_pk:{
			id:"bigint"
		},
		Course_aggregate:{
			distinct_on:"Course_select_column",
			order_by:"Course_order_by",
			where:"Course_bool_exp"
		},
		Course_by_pk:{
			id:"uuid"
		},
		GeneratedPromptByTemplate:{
			distinct_on:"GeneratedPromptByTemplate_select_column",
			order_by:"GeneratedPromptByTemplate_order_by",
			where:"GeneratedPromptByTemplate_bool_exp"
		},
		GeneratedPromptByTemplateImage:{
			distinct_on:"GeneratedPromptByTemplateImage_select_column",
			order_by:"GeneratedPromptByTemplateImage_order_by",
			where:"GeneratedPromptByTemplateImage_bool_exp"
		},
		GeneratedPromptByTemplateImage_aggregate:{
			distinct_on:"GeneratedPromptByTemplateImage_select_column",
			order_by:"GeneratedPromptByTemplateImage_order_by",
			where:"GeneratedPromptByTemplateImage_bool_exp"
		},
		GeneratedPromptByTemplateImage_by_pk:{
			id:"uuid"
		},
		GeneratedPromptByTemplate_aggregate:{
			distinct_on:"GeneratedPromptByTemplate_select_column",
			order_by:"GeneratedPromptByTemplate_order_by",
			where:"GeneratedPromptByTemplate_bool_exp"
		},
		GeneratedPromptByTemplate_by_pk:{
			id:"uuid"
		},
		TextGeneratorOption:{
			distinct_on:"TextGeneratorOption_select_column",
			order_by:"TextGeneratorOption_order_by",
			where:"TextGeneratorOption_bool_exp"
		},
		TextGeneratorOptionEntry:{
			distinct_on:"TextGeneratorOptionEntry_select_column",
			order_by:"TextGeneratorOptionEntry_order_by",
			where:"TextGeneratorOptionEntry_bool_exp"
		},
		TextGeneratorOptionEntry_aggregate:{
			distinct_on:"TextGeneratorOptionEntry_select_column",
			order_by:"TextGeneratorOptionEntry_order_by",
			where:"TextGeneratorOptionEntry_bool_exp"
		},
		TextGeneratorOptionEntry_by_pk:{
			id:"uuid"
		},
		TextGeneratorOption_aggregate:{
			distinct_on:"TextGeneratorOption_select_column",
			order_by:"TextGeneratorOption_order_by",
			where:"TextGeneratorOption_bool_exp"
		},
		TextGeneratorOption_by_pk:{
			id:"uuid"
		},
		TextPromptTemplate:{
			distinct_on:"TextPromptTemplate_select_column",
			order_by:"TextPromptTemplate_order_by",
			where:"TextPromptTemplate_bool_exp"
		},
		TextPromptTemplateCategory:{
			distinct_on:"TextPromptTemplateCategory_select_column",
			order_by:"TextPromptTemplateCategory_order_by",
			where:"TextPromptTemplateCategory_bool_exp"
		},
		TextPromptTemplateCategory_aggregate:{
			distinct_on:"TextPromptTemplateCategory_select_column",
			order_by:"TextPromptTemplateCategory_order_by",
			where:"TextPromptTemplateCategory_bool_exp"
		},
		TextPromptTemplateCategory_by_pk:{
			id:"uuid"
		},
		TextPromptTemplate_aggregate:{
			distinct_on:"TextPromptTemplate_select_column",
			order_by:"TextPromptTemplate_order_by",
			where:"TextPromptTemplate_bool_exp"
		},
		TextPromptTemplate_by_pk:{
			id:"uuid"
		},
		TextTemplateClientCategory:{
			distinct_on:"TextTemplateClientCategory_select_column",
			order_by:"TextTemplateClientCategory_order_by",
			where:"TextTemplateClientCategory_bool_exp"
		},
		TextTemplateClientCategory_aggregate:{
			distinct_on:"TextTemplateClientCategory_select_column",
			order_by:"TextTemplateClientCategory_order_by",
			where:"TextTemplateClientCategory_bool_exp"
		},
		TextTemplateClientCategory_by_pk:{
			id:"uuid"
		},
		audiences:{
			distinct_on:"audiences_select_column",
			order_by:"audiences_order_by",
			where:"audiences_bool_exp"
		},
		audiences_aggregate:{
			distinct_on:"audiences_select_column",
			order_by:"audiences_order_by",
			where:"audiences_bool_exp"
		},
		audiences_by_pk:{
			id:"uuid"
		},
		authProvider:{

		},
		authProviderRequest:{
			id:"uuid"
		},
		authProviderRequests:{
			distinct_on:"authProviderRequests_select_column",
			order_by:"authProviderRequests_order_by",
			where:"authProviderRequests_bool_exp"
		},
		authProviderRequestsAggregate:{
			distinct_on:"authProviderRequests_select_column",
			order_by:"authProviderRequests_order_by",
			where:"authProviderRequests_bool_exp"
		},
		authProviders:{
			distinct_on:"authProviders_select_column",
			order_by:"authProviders_order_by",
			where:"authProviders_bool_exp"
		},
		authProvidersAggregate:{
			distinct_on:"authProviders_select_column",
			order_by:"authProviders_order_by",
			where:"authProviders_bool_exp"
		},
		authRefreshToken:{
			id:"uuid"
		},
		authRefreshTokenType:{

		},
		authRefreshTokenTypes:{
			distinct_on:"authRefreshTokenTypes_select_column",
			order_by:"authRefreshTokenTypes_order_by",
			where:"authRefreshTokenTypes_bool_exp"
		},
		authRefreshTokenTypesAggregate:{
			distinct_on:"authRefreshTokenTypes_select_column",
			order_by:"authRefreshTokenTypes_order_by",
			where:"authRefreshTokenTypes_bool_exp"
		},
		authRefreshTokens:{
			distinct_on:"authRefreshTokens_select_column",
			order_by:"authRefreshTokens_order_by",
			where:"authRefreshTokens_bool_exp"
		},
		authRefreshTokensAggregate:{
			distinct_on:"authRefreshTokens_select_column",
			order_by:"authRefreshTokens_order_by",
			where:"authRefreshTokens_bool_exp"
		},
		authRole:{

		},
		authRoles:{
			distinct_on:"authRoles_select_column",
			order_by:"authRoles_order_by",
			where:"authRoles_bool_exp"
		},
		authRolesAggregate:{
			distinct_on:"authRoles_select_column",
			order_by:"authRoles_order_by",
			where:"authRoles_bool_exp"
		},
		authUserProvider:{
			id:"uuid"
		},
		authUserProviders:{
			distinct_on:"authUserProviders_select_column",
			order_by:"authUserProviders_order_by",
			where:"authUserProviders_bool_exp"
		},
		authUserProvidersAggregate:{
			distinct_on:"authUserProviders_select_column",
			order_by:"authUserProviders_order_by",
			where:"authUserProviders_bool_exp"
		},
		authUserRole:{
			id:"uuid"
		},
		authUserRoles:{
			distinct_on:"authUserRoles_select_column",
			order_by:"authUserRoles_order_by",
			where:"authUserRoles_bool_exp"
		},
		authUserRolesAggregate:{
			distinct_on:"authUserRoles_select_column",
			order_by:"authUserRoles_order_by",
			where:"authUserRoles_bool_exp"
		},
		authUserSecurityKey:{
			id:"uuid"
		},
		authUserSecurityKeys:{
			distinct_on:"authUserSecurityKeys_select_column",
			order_by:"authUserSecurityKeys_order_by",
			where:"authUserSecurityKeys_bool_exp"
		},
		authUserSecurityKeysAggregate:{
			distinct_on:"authUserSecurityKeys_select_column",
			order_by:"authUserSecurityKeys_order_by",
			where:"authUserSecurityKeys_bool_exp"
		},
		blog:{
			distinct_on:"blog_select_column",
			order_by:"blog_order_by",
			where:"blog_bool_exp"
		},
		blogCategory:{
			distinct_on:"blogCategory_select_column",
			order_by:"blogCategory_order_by",
			where:"blogCategory_bool_exp"
		},
		blogCategory_aggregate:{
			distinct_on:"blogCategory_select_column",
			order_by:"blogCategory_order_by",
			where:"blogCategory_bool_exp"
		},
		blogCategory_by_pk:{
			id:"uuid"
		},
		blogPostGeneratorEnhancedForCopy:{
			id:"uuid"
		},
		blog_aggregate:{
			distinct_on:"blog_select_column",
			order_by:"blog_order_by",
			where:"blog_bool_exp"
		},
		blog_by_pk:{
			id:"uuid"
		},
		brands:{
			distinct_on:"brands_select_column",
			order_by:"brands_order_by",
			where:"brands_bool_exp"
		},
		brands_aggregate:{
			distinct_on:"brands_select_column",
			order_by:"brands_order_by",
			where:"brands_bool_exp"
		},
		brands_by_pk:{
			id:"uuid"
		},
		bucket:{

		},
		buckets:{
			distinct_on:"buckets_select_column",
			order_by:"buckets_order_by",
			where:"buckets_bool_exp"
		},
		bucketsAggregate:{
			distinct_on:"buckets_select_column",
			order_by:"buckets_order_by",
			where:"buckets_bool_exp"
		},
		campaign:{
			distinct_on:"campaign_select_column",
			order_by:"campaign_order_by",
			where:"campaign_bool_exp"
		},
		campaign_aggregate:{
			distinct_on:"campaign_select_column",
			order_by:"campaign_order_by",
			where:"campaign_bool_exp"
		},
		campaign_by_pk:{
			id:"uuid"
		},
		categorysummaryview:{
			distinct_on:"categorysummaryview_select_column",
			order_by:"categorysummaryview_order_by",
			where:"categorysummaryview_bool_exp"
		},
		categorysummaryview_aggregate:{
			distinct_on:"categorysummaryview_select_column",
			order_by:"categorysummaryview_order_by",
			where:"categorysummaryview_bool_exp"
		},
		channels:{
			distinct_on:"channels_select_column",
			order_by:"channels_order_by",
			where:"channels_bool_exp"
		},
		channels_aggregate:{
			distinct_on:"channels_select_column",
			order_by:"channels_order_by",
			where:"channels_bool_exp"
		},
		channels_by_pk:{
			id:"uuid"
		},
		consumptionByChannelView:{
			distinct_on:"consumptionByChannelView_select_column",
			order_by:"consumptionByChannelView_order_by",
			where:"consumptionByChannelView_bool_exp"
		},
		consumptionByChannelView_aggregate:{
			distinct_on:"consumptionByChannelView_select_column",
			order_by:"consumptionByChannelView_order_by",
			where:"consumptionByChannelView_bool_exp"
		},
		costTable:{
			distinct_on:"costTable_select_column",
			order_by:"costTable_order_by",
			where:"costTable_bool_exp"
		},
		costTable_aggregate:{
			distinct_on:"costTable_select_column",
			order_by:"costTable_order_by",
			where:"costTable_bool_exp"
		},
		costTable_by_pk:{
			id:"uuid"
		},
		deal:{
			distinct_on:"deal_select_column",
			order_by:"deal_order_by",
			where:"deal_bool_exp"
		},
		dealStatus:{
			distinct_on:"dealStatus_select_column",
			order_by:"dealStatus_order_by",
			where:"dealStatus_bool_exp"
		},
		dealStatus_aggregate:{
			distinct_on:"dealStatus_select_column",
			order_by:"dealStatus_order_by",
			where:"dealStatus_bool_exp"
		},
		dealStatus_by_pk:{
			id:"uuid"
		},
		deal_aggregate:{
			distinct_on:"deal_select_column",
			order_by:"deal_order_by",
			where:"deal_bool_exp"
		},
		deal_by_pk:{
			id:"uuid"
		},
		displayCategory:{
			distinct_on:"displayCategory_select_column",
			order_by:"displayCategory_order_by",
			where:"displayCategory_bool_exp"
		},
		displayCategory_aggregate:{
			distinct_on:"displayCategory_select_column",
			order_by:"displayCategory_order_by",
			where:"displayCategory_bool_exp"
		},
		displayCategory_by_pk:{
			id:"uuid"
		},
		displayDimension:{
			distinct_on:"displayDimension_select_column",
			order_by:"displayDimension_order_by",
			where:"displayDimension_bool_exp"
		},
		displayDimension_aggregate:{
			distinct_on:"displayDimension_select_column",
			order_by:"displayDimension_order_by",
			where:"displayDimension_bool_exp"
		},
		displayDimension_by_pk:{
			id:"uuid"
		},
		displayFormats:{
			distinct_on:"displayFormats_select_column",
			order_by:"displayFormats_order_by",
			where:"displayFormats_bool_exp"
		},
		displayFormats_aggregate:{
			distinct_on:"displayFormats_select_column",
			order_by:"displayFormats_order_by",
			where:"displayFormats_bool_exp"
		},
		displayFormats_by_pk:{
			id:"uuid"
		},
		displayTemplate:{
			distinct_on:"displayTemplate_select_column",
			order_by:"displayTemplate_order_by",
			where:"displayTemplate_bool_exp"
		},
		displayTemplate_aggregate:{
			distinct_on:"displayTemplate_select_column",
			order_by:"displayTemplate_order_by",
			where:"displayTemplate_bool_exp"
		},
		displayTemplate_by_pk:{
			id:"uuid"
		},
		file:{
			id:"uuid"
		},
		files:{
			distinct_on:"files_select_column",
			order_by:"files_order_by",
			where:"files_bool_exp"
		},
		filesAggregate:{
			distinct_on:"files_select_column",
			order_by:"files_order_by",
			where:"files_bool_exp"
		},
		generateImageFromPrompt:{
			command:"GenerateImageFromPromptInput"
		},
		generateNextMonthCalendar:{
			id:"uuid"
		},
		getFacebookPages:{

		},
		getImageFromDisplayRenderer:{

		},
		getInstagramPages:{

		},
		getPaymentUrl:{
			command:"GetPaymentUrlCommand"
		},
		getWordpressCategories:{
			command:"GetWordpressCategoriesInput"
		},
		getWordpressTags:{
			command:"GetWordpressTagsInput"
		},
		interaction:{
			distinct_on:"interaction_select_column",
			order_by:"interaction_order_by",
			where:"interaction_bool_exp"
		},
		interaction_aggregate:{
			distinct_on:"interaction_select_column",
			order_by:"interaction_order_by",
			where:"interaction_bool_exp"
		},
		interaction_by_pk:{
			id:"uuid"
		},
		landingPageTemplate:{
			distinct_on:"landingPageTemplate_select_column",
			order_by:"landingPageTemplate_order_by",
			where:"landingPageTemplate_bool_exp"
		},
		landingPageTemplate_aggregate:{
			distinct_on:"landingPageTemplate_select_column",
			order_by:"landingPageTemplate_order_by",
			where:"landingPageTemplate_bool_exp"
		},
		landingPageTemplate_by_pk:{
			id:"uuid"
		},
		newTemplateType:{
			distinct_on:"newTemplateType_select_column",
			order_by:"newTemplateType_order_by",
			where:"newTemplateType_bool_exp"
		},
		newTemplateType_aggregate:{
			distinct_on:"newTemplateType_select_column",
			order_by:"newTemplateType_order_by",
			where:"newTemplateType_bool_exp"
		},
		newTemplateType_by_pk:{
			id:"uuid"
		},
		openAIApiKey:{
			distinct_on:"openAIApiKey_select_column",
			order_by:"openAIApiKey_order_by",
			where:"openAIApiKey_bool_exp"
		},
		openAIApiKey_aggregate:{
			distinct_on:"openAIApiKey_select_column",
			order_by:"openAIApiKey_order_by",
			where:"openAIApiKey_bool_exp"
		},
		openAIApiKey_by_pk:{
			id:"uuid"
		},
		pTemplate:{
			distinct_on:"pTemplate_select_column",
			order_by:"pTemplate_order_by",
			where:"pTemplate_bool_exp"
		},
		pTemplate_aggregate:{
			distinct_on:"pTemplate_select_column",
			order_by:"pTemplate_order_by",
			where:"pTemplate_bool_exp"
		},
		pTemplate_by_pk:{
			id:"uuid"
		},
		pricingPlan:{
			distinct_on:"pricingPlan_select_column",
			order_by:"pricingPlan_order_by",
			where:"pricingPlan_bool_exp"
		},
		pricingPlan_aggregate:{
			distinct_on:"pricingPlan_select_column",
			order_by:"pricingPlan_order_by",
			where:"pricingPlan_bool_exp"
		},
		pricingPlan_by_pk:{
			id:"uuid"
		},
		products:{
			distinct_on:"products_select_column",
			order_by:"products_order_by",
			where:"products_bool_exp"
		},
		products_aggregate:{
			distinct_on:"products_select_column",
			order_by:"products_order_by",
			where:"products_bool_exp"
		},
		products_by_pk:{
			id:"uuid"
		},
		project:{
			distinct_on:"project_select_column",
			order_by:"project_order_by",
			where:"project_bool_exp"
		},
		projectImage:{
			distinct_on:"projectImage_select_column",
			order_by:"projectImage_order_by",
			where:"projectImage_bool_exp"
		},
		projectImage_aggregate:{
			distinct_on:"projectImage_select_column",
			order_by:"projectImage_order_by",
			where:"projectImage_bool_exp"
		},
		projectImage_by_pk:{
			id:"uuid"
		},
		project_aggregate:{
			distinct_on:"project_select_column",
			order_by:"project_order_by",
			where:"project_bool_exp"
		},
		project_by_pk:{
			id:"uuid"
		},
		renderDisplay:{

		},
		searchImagesFromPexels:{

		},
		serpStatTest:{
			command:"SerpStatTestInput"
		},
		task:{
			distinct_on:"task_select_column",
			order_by:"task_order_by",
			where:"task_bool_exp"
		},
		task_aggregate:{
			distinct_on:"task_select_column",
			order_by:"task_order_by",
			where:"task_bool_exp"
		},
		task_by_pk:{
			id:"uuid"
		},
		textTemplateDisplayFormat:{
			distinct_on:"textTemplateDisplayFormat_select_column",
			order_by:"textTemplateDisplayFormat_order_by",
			where:"textTemplateDisplayFormat_bool_exp"
		},
		textTemplateDisplayFormat_aggregate:{
			distinct_on:"textTemplateDisplayFormat_select_column",
			order_by:"textTemplateDisplayFormat_order_by",
			where:"textTemplateDisplayFormat_bool_exp"
		},
		textTemplateDisplayFormat_by_pk:{
			id:"uuid"
		},
		user:{
			id:"uuid"
		},
		userAppIntegration:{
			distinct_on:"userAppIntegration_select_column",
			order_by:"userAppIntegration_order_by",
			where:"userAppIntegration_bool_exp"
		},
		userAppIntegration_aggregate:{
			distinct_on:"userAppIntegration_select_column",
			order_by:"userAppIntegration_order_by",
			where:"userAppIntegration_bool_exp"
		},
		userAppIntegration_by_pk:{
			id:"uuid"
		},
		userCreditUsage:{
			distinct_on:"userCreditUsage_select_column",
			order_by:"userCreditUsage_order_by",
			where:"userCreditUsage_bool_exp"
		},
		userCreditUsageSummary:{
			distinct_on:"userCreditUsageSummary_select_column",
			order_by:"userCreditUsageSummary_order_by",
			where:"userCreditUsageSummary_bool_exp"
		},
		userCreditUsageSummary_aggregate:{
			distinct_on:"userCreditUsageSummary_select_column",
			order_by:"userCreditUsageSummary_order_by",
			where:"userCreditUsageSummary_bool_exp"
		},
		userCreditUsageSummary_by_pk:{
			created_by:"uuid"
		},
		userCreditUsage_aggregate:{
			distinct_on:"userCreditUsage_select_column",
			order_by:"userCreditUsage_order_by",
			where:"userCreditUsage_bool_exp"
		},
		userCreditUsage_by_pk:{
			id:"uuid"
		},
		userCredits:{
			distinct_on:"userCredits_select_column",
			order_by:"userCredits_order_by",
			where:"userCredits_bool_exp"
		},
		userCredits_aggregate:{
			distinct_on:"userCredits_select_column",
			order_by:"userCredits_order_by",
			where:"userCredits_bool_exp"
		},
		userCredits_by_pk:{
			id:"uuid"
		},
		userFeedback:{
			distinct_on:"userFeedback_select_column",
			order_by:"userFeedback_order_by",
			where:"userFeedback_bool_exp"
		},
		userFeedback_aggregate:{
			distinct_on:"userFeedback_select_column",
			order_by:"userFeedback_order_by",
			where:"userFeedback_bool_exp"
		},
		userFeedback_by_pk:{
			id:"uuid"
		},
		userMedia:{
			distinct_on:"userMedia_select_column",
			order_by:"userMedia_order_by",
			where:"userMedia_bool_exp"
		},
		userMedia_aggregate:{
			distinct_on:"userMedia_select_column",
			order_by:"userMedia_order_by",
			where:"userMedia_bool_exp"
		},
		userMedia_by_pk:{
			id:"uuid"
		},
		userMetadata:{
			distinct_on:"userMetadata_select_column",
			order_by:"userMetadata_order_by",
			where:"userMetadata_bool_exp"
		},
		userMetadata_aggregate:{
			distinct_on:"userMetadata_select_column",
			order_by:"userMetadata_order_by",
			where:"userMetadata_bool_exp"
		},
		userMetadata_by_pk:{
			id:"uuid"
		},
		userOnboarding:{
			distinct_on:"userOnboarding_select_column",
			order_by:"userOnboarding_order_by",
			where:"userOnboarding_bool_exp"
		},
		userOnboarding_aggregate:{
			distinct_on:"userOnboarding_select_column",
			order_by:"userOnboarding_order_by",
			where:"userOnboarding_bool_exp"
		},
		userOnboarding_by_pk:{
			userId:"uuid"
		},
		userTemplateUsage:{
			distinct_on:"userTemplateUsage_select_column",
			order_by:"userTemplateUsage_order_by",
			where:"userTemplateUsage_bool_exp"
		},
		userTemplateUsage_aggregate:{
			distinct_on:"userTemplateUsage_select_column",
			order_by:"userTemplateUsage_order_by",
			where:"userTemplateUsage_bool_exp"
		},
		userTemplateUsage_by_pk:{
			id:"uuid"
		},
		users:{
			distinct_on:"users_select_column",
			order_by:"users_order_by",
			where:"users_bool_exp"
		},
		usersAggregate:{
			distinct_on:"users_select_column",
			order_by:"users_order_by",
			where:"users_bool_exp"
		},
		virus:{
			id:"uuid"
		},
		viruses:{
			distinct_on:"virus_select_column",
			order_by:"virus_order_by",
			where:"virus_bool_exp"
		},
		virusesAggregate:{
			distinct_on:"virus_select_column",
			order_by:"virus_order_by",
			where:"virus_bool_exp"
		},
		website:{
			distinct_on:"website_select_column",
			order_by:"website_order_by",
			where:"website_bool_exp"
		},
		website_aggregate:{
			distinct_on:"website_select_column",
			order_by:"website_order_by",
			where:"website_bool_exp"
		},
		website_by_pk:{
			id:"uuid"
		}
	},
	subscription_root:{
		AppIntegration:{
			distinct_on:"AppIntegration_select_column",
			order_by:"AppIntegration_order_by",
			where:"AppIntegration_bool_exp"
		},
		AppIntegration_aggregate:{
			distinct_on:"AppIntegration_select_column",
			order_by:"AppIntegration_order_by",
			where:"AppIntegration_bool_exp"
		},
		AppIntegration_by_pk:{
			id:"uuid"
		},
		AppIntegration_stream:{
			cursor:"AppIntegration_stream_cursor_input",
			where:"AppIntegration_bool_exp"
		},
		Course:{
			distinct_on:"Course_select_column",
			order_by:"Course_order_by",
			where:"Course_bool_exp"
		},
		CourseCategory:{
			distinct_on:"CourseCategory_select_column",
			order_by:"CourseCategory_order_by",
			where:"CourseCategory_bool_exp"
		},
		CourseCategory_aggregate:{
			distinct_on:"CourseCategory_select_column",
			order_by:"CourseCategory_order_by",
			where:"CourseCategory_bool_exp"
		},
		CourseCategory_by_pk:{
			id:"bigint"
		},
		CourseCategory_stream:{
			cursor:"CourseCategory_stream_cursor_input",
			where:"CourseCategory_bool_exp"
		},
		Course_aggregate:{
			distinct_on:"Course_select_column",
			order_by:"Course_order_by",
			where:"Course_bool_exp"
		},
		Course_by_pk:{
			id:"uuid"
		},
		Course_stream:{
			cursor:"Course_stream_cursor_input",
			where:"Course_bool_exp"
		},
		GeneratedPromptByTemplate:{
			distinct_on:"GeneratedPromptByTemplate_select_column",
			order_by:"GeneratedPromptByTemplate_order_by",
			where:"GeneratedPromptByTemplate_bool_exp"
		},
		GeneratedPromptByTemplateImage:{
			distinct_on:"GeneratedPromptByTemplateImage_select_column",
			order_by:"GeneratedPromptByTemplateImage_order_by",
			where:"GeneratedPromptByTemplateImage_bool_exp"
		},
		GeneratedPromptByTemplateImage_aggregate:{
			distinct_on:"GeneratedPromptByTemplateImage_select_column",
			order_by:"GeneratedPromptByTemplateImage_order_by",
			where:"GeneratedPromptByTemplateImage_bool_exp"
		},
		GeneratedPromptByTemplateImage_by_pk:{
			id:"uuid"
		},
		GeneratedPromptByTemplateImage_stream:{
			cursor:"GeneratedPromptByTemplateImage_stream_cursor_input",
			where:"GeneratedPromptByTemplateImage_bool_exp"
		},
		GeneratedPromptByTemplate_aggregate:{
			distinct_on:"GeneratedPromptByTemplate_select_column",
			order_by:"GeneratedPromptByTemplate_order_by",
			where:"GeneratedPromptByTemplate_bool_exp"
		},
		GeneratedPromptByTemplate_by_pk:{
			id:"uuid"
		},
		GeneratedPromptByTemplate_stream:{
			cursor:"GeneratedPromptByTemplate_stream_cursor_input",
			where:"GeneratedPromptByTemplate_bool_exp"
		},
		TextGeneratorOption:{
			distinct_on:"TextGeneratorOption_select_column",
			order_by:"TextGeneratorOption_order_by",
			where:"TextGeneratorOption_bool_exp"
		},
		TextGeneratorOptionEntry:{
			distinct_on:"TextGeneratorOptionEntry_select_column",
			order_by:"TextGeneratorOptionEntry_order_by",
			where:"TextGeneratorOptionEntry_bool_exp"
		},
		TextGeneratorOptionEntry_aggregate:{
			distinct_on:"TextGeneratorOptionEntry_select_column",
			order_by:"TextGeneratorOptionEntry_order_by",
			where:"TextGeneratorOptionEntry_bool_exp"
		},
		TextGeneratorOptionEntry_by_pk:{
			id:"uuid"
		},
		TextGeneratorOptionEntry_stream:{
			cursor:"TextGeneratorOptionEntry_stream_cursor_input",
			where:"TextGeneratorOptionEntry_bool_exp"
		},
		TextGeneratorOption_aggregate:{
			distinct_on:"TextGeneratorOption_select_column",
			order_by:"TextGeneratorOption_order_by",
			where:"TextGeneratorOption_bool_exp"
		},
		TextGeneratorOption_by_pk:{
			id:"uuid"
		},
		TextGeneratorOption_stream:{
			cursor:"TextGeneratorOption_stream_cursor_input",
			where:"TextGeneratorOption_bool_exp"
		},
		TextPromptTemplate:{
			distinct_on:"TextPromptTemplate_select_column",
			order_by:"TextPromptTemplate_order_by",
			where:"TextPromptTemplate_bool_exp"
		},
		TextPromptTemplateCategory:{
			distinct_on:"TextPromptTemplateCategory_select_column",
			order_by:"TextPromptTemplateCategory_order_by",
			where:"TextPromptTemplateCategory_bool_exp"
		},
		TextPromptTemplateCategory_aggregate:{
			distinct_on:"TextPromptTemplateCategory_select_column",
			order_by:"TextPromptTemplateCategory_order_by",
			where:"TextPromptTemplateCategory_bool_exp"
		},
		TextPromptTemplateCategory_by_pk:{
			id:"uuid"
		},
		TextPromptTemplateCategory_stream:{
			cursor:"TextPromptTemplateCategory_stream_cursor_input",
			where:"TextPromptTemplateCategory_bool_exp"
		},
		TextPromptTemplate_aggregate:{
			distinct_on:"TextPromptTemplate_select_column",
			order_by:"TextPromptTemplate_order_by",
			where:"TextPromptTemplate_bool_exp"
		},
		TextPromptTemplate_by_pk:{
			id:"uuid"
		},
		TextPromptTemplate_stream:{
			cursor:"TextPromptTemplate_stream_cursor_input",
			where:"TextPromptTemplate_bool_exp"
		},
		TextTemplateClientCategory:{
			distinct_on:"TextTemplateClientCategory_select_column",
			order_by:"TextTemplateClientCategory_order_by",
			where:"TextTemplateClientCategory_bool_exp"
		},
		TextTemplateClientCategory_aggregate:{
			distinct_on:"TextTemplateClientCategory_select_column",
			order_by:"TextTemplateClientCategory_order_by",
			where:"TextTemplateClientCategory_bool_exp"
		},
		TextTemplateClientCategory_by_pk:{
			id:"uuid"
		},
		TextTemplateClientCategory_stream:{
			cursor:"TextTemplateClientCategory_stream_cursor_input",
			where:"TextTemplateClientCategory_bool_exp"
		},
		audiences:{
			distinct_on:"audiences_select_column",
			order_by:"audiences_order_by",
			where:"audiences_bool_exp"
		},
		audiences_aggregate:{
			distinct_on:"audiences_select_column",
			order_by:"audiences_order_by",
			where:"audiences_bool_exp"
		},
		audiences_by_pk:{
			id:"uuid"
		},
		audiences_stream:{
			cursor:"audiences_stream_cursor_input",
			where:"audiences_bool_exp"
		},
		authProvider:{

		},
		authProviderRequest:{
			id:"uuid"
		},
		authProviderRequests:{
			distinct_on:"authProviderRequests_select_column",
			order_by:"authProviderRequests_order_by",
			where:"authProviderRequests_bool_exp"
		},
		authProviderRequestsAggregate:{
			distinct_on:"authProviderRequests_select_column",
			order_by:"authProviderRequests_order_by",
			where:"authProviderRequests_bool_exp"
		},
		authProviderRequests_stream:{
			cursor:"authProviderRequests_stream_cursor_input",
			where:"authProviderRequests_bool_exp"
		},
		authProviders:{
			distinct_on:"authProviders_select_column",
			order_by:"authProviders_order_by",
			where:"authProviders_bool_exp"
		},
		authProvidersAggregate:{
			distinct_on:"authProviders_select_column",
			order_by:"authProviders_order_by",
			where:"authProviders_bool_exp"
		},
		authProviders_stream:{
			cursor:"authProviders_stream_cursor_input",
			where:"authProviders_bool_exp"
		},
		authRefreshToken:{
			id:"uuid"
		},
		authRefreshTokenType:{

		},
		authRefreshTokenTypes:{
			distinct_on:"authRefreshTokenTypes_select_column",
			order_by:"authRefreshTokenTypes_order_by",
			where:"authRefreshTokenTypes_bool_exp"
		},
		authRefreshTokenTypesAggregate:{
			distinct_on:"authRefreshTokenTypes_select_column",
			order_by:"authRefreshTokenTypes_order_by",
			where:"authRefreshTokenTypes_bool_exp"
		},
		authRefreshTokenTypes_stream:{
			cursor:"authRefreshTokenTypes_stream_cursor_input",
			where:"authRefreshTokenTypes_bool_exp"
		},
		authRefreshTokens:{
			distinct_on:"authRefreshTokens_select_column",
			order_by:"authRefreshTokens_order_by",
			where:"authRefreshTokens_bool_exp"
		},
		authRefreshTokensAggregate:{
			distinct_on:"authRefreshTokens_select_column",
			order_by:"authRefreshTokens_order_by",
			where:"authRefreshTokens_bool_exp"
		},
		authRefreshTokens_stream:{
			cursor:"authRefreshTokens_stream_cursor_input",
			where:"authRefreshTokens_bool_exp"
		},
		authRole:{

		},
		authRoles:{
			distinct_on:"authRoles_select_column",
			order_by:"authRoles_order_by",
			where:"authRoles_bool_exp"
		},
		authRolesAggregate:{
			distinct_on:"authRoles_select_column",
			order_by:"authRoles_order_by",
			where:"authRoles_bool_exp"
		},
		authRoles_stream:{
			cursor:"authRoles_stream_cursor_input",
			where:"authRoles_bool_exp"
		},
		authUserProvider:{
			id:"uuid"
		},
		authUserProviders:{
			distinct_on:"authUserProviders_select_column",
			order_by:"authUserProviders_order_by",
			where:"authUserProviders_bool_exp"
		},
		authUserProvidersAggregate:{
			distinct_on:"authUserProviders_select_column",
			order_by:"authUserProviders_order_by",
			where:"authUserProviders_bool_exp"
		},
		authUserProviders_stream:{
			cursor:"authUserProviders_stream_cursor_input",
			where:"authUserProviders_bool_exp"
		},
		authUserRole:{
			id:"uuid"
		},
		authUserRoles:{
			distinct_on:"authUserRoles_select_column",
			order_by:"authUserRoles_order_by",
			where:"authUserRoles_bool_exp"
		},
		authUserRolesAggregate:{
			distinct_on:"authUserRoles_select_column",
			order_by:"authUserRoles_order_by",
			where:"authUserRoles_bool_exp"
		},
		authUserRoles_stream:{
			cursor:"authUserRoles_stream_cursor_input",
			where:"authUserRoles_bool_exp"
		},
		authUserSecurityKey:{
			id:"uuid"
		},
		authUserSecurityKeys:{
			distinct_on:"authUserSecurityKeys_select_column",
			order_by:"authUserSecurityKeys_order_by",
			where:"authUserSecurityKeys_bool_exp"
		},
		authUserSecurityKeysAggregate:{
			distinct_on:"authUserSecurityKeys_select_column",
			order_by:"authUserSecurityKeys_order_by",
			where:"authUserSecurityKeys_bool_exp"
		},
		authUserSecurityKeys_stream:{
			cursor:"authUserSecurityKeys_stream_cursor_input",
			where:"authUserSecurityKeys_bool_exp"
		},
		blog:{
			distinct_on:"blog_select_column",
			order_by:"blog_order_by",
			where:"blog_bool_exp"
		},
		blogCategory:{
			distinct_on:"blogCategory_select_column",
			order_by:"blogCategory_order_by",
			where:"blogCategory_bool_exp"
		},
		blogCategory_aggregate:{
			distinct_on:"blogCategory_select_column",
			order_by:"blogCategory_order_by",
			where:"blogCategory_bool_exp"
		},
		blogCategory_by_pk:{
			id:"uuid"
		},
		blogCategory_stream:{
			cursor:"blogCategory_stream_cursor_input",
			where:"blogCategory_bool_exp"
		},
		blogPostGeneratorEnhancedForCopy:{
			id:"uuid"
		},
		blog_aggregate:{
			distinct_on:"blog_select_column",
			order_by:"blog_order_by",
			where:"blog_bool_exp"
		},
		blog_by_pk:{
			id:"uuid"
		},
		blog_stream:{
			cursor:"blog_stream_cursor_input",
			where:"blog_bool_exp"
		},
		brands:{
			distinct_on:"brands_select_column",
			order_by:"brands_order_by",
			where:"brands_bool_exp"
		},
		brands_aggregate:{
			distinct_on:"brands_select_column",
			order_by:"brands_order_by",
			where:"brands_bool_exp"
		},
		brands_by_pk:{
			id:"uuid"
		},
		brands_stream:{
			cursor:"brands_stream_cursor_input",
			where:"brands_bool_exp"
		},
		bucket:{

		},
		buckets:{
			distinct_on:"buckets_select_column",
			order_by:"buckets_order_by",
			where:"buckets_bool_exp"
		},
		bucketsAggregate:{
			distinct_on:"buckets_select_column",
			order_by:"buckets_order_by",
			where:"buckets_bool_exp"
		},
		buckets_stream:{
			cursor:"buckets_stream_cursor_input",
			where:"buckets_bool_exp"
		},
		campaign:{
			distinct_on:"campaign_select_column",
			order_by:"campaign_order_by",
			where:"campaign_bool_exp"
		},
		campaign_aggregate:{
			distinct_on:"campaign_select_column",
			order_by:"campaign_order_by",
			where:"campaign_bool_exp"
		},
		campaign_by_pk:{
			id:"uuid"
		},
		campaign_stream:{
			cursor:"campaign_stream_cursor_input",
			where:"campaign_bool_exp"
		},
		categorysummaryview:{
			distinct_on:"categorysummaryview_select_column",
			order_by:"categorysummaryview_order_by",
			where:"categorysummaryview_bool_exp"
		},
		categorysummaryview_aggregate:{
			distinct_on:"categorysummaryview_select_column",
			order_by:"categorysummaryview_order_by",
			where:"categorysummaryview_bool_exp"
		},
		categorysummaryview_stream:{
			cursor:"categorysummaryview_stream_cursor_input",
			where:"categorysummaryview_bool_exp"
		},
		channels:{
			distinct_on:"channels_select_column",
			order_by:"channels_order_by",
			where:"channels_bool_exp"
		},
		channels_aggregate:{
			distinct_on:"channels_select_column",
			order_by:"channels_order_by",
			where:"channels_bool_exp"
		},
		channels_by_pk:{
			id:"uuid"
		},
		channels_stream:{
			cursor:"channels_stream_cursor_input",
			where:"channels_bool_exp"
		},
		consumptionByChannelView:{
			distinct_on:"consumptionByChannelView_select_column",
			order_by:"consumptionByChannelView_order_by",
			where:"consumptionByChannelView_bool_exp"
		},
		consumptionByChannelView_aggregate:{
			distinct_on:"consumptionByChannelView_select_column",
			order_by:"consumptionByChannelView_order_by",
			where:"consumptionByChannelView_bool_exp"
		},
		consumptionByChannelView_stream:{
			cursor:"consumptionByChannelView_stream_cursor_input",
			where:"consumptionByChannelView_bool_exp"
		},
		costTable:{
			distinct_on:"costTable_select_column",
			order_by:"costTable_order_by",
			where:"costTable_bool_exp"
		},
		costTable_aggregate:{
			distinct_on:"costTable_select_column",
			order_by:"costTable_order_by",
			where:"costTable_bool_exp"
		},
		costTable_by_pk:{
			id:"uuid"
		},
		costTable_stream:{
			cursor:"costTable_stream_cursor_input",
			where:"costTable_bool_exp"
		},
		deal:{
			distinct_on:"deal_select_column",
			order_by:"deal_order_by",
			where:"deal_bool_exp"
		},
		dealStatus:{
			distinct_on:"dealStatus_select_column",
			order_by:"dealStatus_order_by",
			where:"dealStatus_bool_exp"
		},
		dealStatus_aggregate:{
			distinct_on:"dealStatus_select_column",
			order_by:"dealStatus_order_by",
			where:"dealStatus_bool_exp"
		},
		dealStatus_by_pk:{
			id:"uuid"
		},
		dealStatus_stream:{
			cursor:"dealStatus_stream_cursor_input",
			where:"dealStatus_bool_exp"
		},
		deal_aggregate:{
			distinct_on:"deal_select_column",
			order_by:"deal_order_by",
			where:"deal_bool_exp"
		},
		deal_by_pk:{
			id:"uuid"
		},
		deal_stream:{
			cursor:"deal_stream_cursor_input",
			where:"deal_bool_exp"
		},
		displayCategory:{
			distinct_on:"displayCategory_select_column",
			order_by:"displayCategory_order_by",
			where:"displayCategory_bool_exp"
		},
		displayCategory_aggregate:{
			distinct_on:"displayCategory_select_column",
			order_by:"displayCategory_order_by",
			where:"displayCategory_bool_exp"
		},
		displayCategory_by_pk:{
			id:"uuid"
		},
		displayCategory_stream:{
			cursor:"displayCategory_stream_cursor_input",
			where:"displayCategory_bool_exp"
		},
		displayDimension:{
			distinct_on:"displayDimension_select_column",
			order_by:"displayDimension_order_by",
			where:"displayDimension_bool_exp"
		},
		displayDimension_aggregate:{
			distinct_on:"displayDimension_select_column",
			order_by:"displayDimension_order_by",
			where:"displayDimension_bool_exp"
		},
		displayDimension_by_pk:{
			id:"uuid"
		},
		displayDimension_stream:{
			cursor:"displayDimension_stream_cursor_input",
			where:"displayDimension_bool_exp"
		},
		displayFormats:{
			distinct_on:"displayFormats_select_column",
			order_by:"displayFormats_order_by",
			where:"displayFormats_bool_exp"
		},
		displayFormats_aggregate:{
			distinct_on:"displayFormats_select_column",
			order_by:"displayFormats_order_by",
			where:"displayFormats_bool_exp"
		},
		displayFormats_by_pk:{
			id:"uuid"
		},
		displayFormats_stream:{
			cursor:"displayFormats_stream_cursor_input",
			where:"displayFormats_bool_exp"
		},
		displayTemplate:{
			distinct_on:"displayTemplate_select_column",
			order_by:"displayTemplate_order_by",
			where:"displayTemplate_bool_exp"
		},
		displayTemplate_aggregate:{
			distinct_on:"displayTemplate_select_column",
			order_by:"displayTemplate_order_by",
			where:"displayTemplate_bool_exp"
		},
		displayTemplate_by_pk:{
			id:"uuid"
		},
		displayTemplate_stream:{
			cursor:"displayTemplate_stream_cursor_input",
			where:"displayTemplate_bool_exp"
		},
		file:{
			id:"uuid"
		},
		files:{
			distinct_on:"files_select_column",
			order_by:"files_order_by",
			where:"files_bool_exp"
		},
		filesAggregate:{
			distinct_on:"files_select_column",
			order_by:"files_order_by",
			where:"files_bool_exp"
		},
		files_stream:{
			cursor:"files_stream_cursor_input",
			where:"files_bool_exp"
		},
		generateNextMonthCalendar:{
			id:"uuid"
		},
		interaction:{
			distinct_on:"interaction_select_column",
			order_by:"interaction_order_by",
			where:"interaction_bool_exp"
		},
		interaction_aggregate:{
			distinct_on:"interaction_select_column",
			order_by:"interaction_order_by",
			where:"interaction_bool_exp"
		},
		interaction_by_pk:{
			id:"uuid"
		},
		interaction_stream:{
			cursor:"interaction_stream_cursor_input",
			where:"interaction_bool_exp"
		},
		landingPageTemplate:{
			distinct_on:"landingPageTemplate_select_column",
			order_by:"landingPageTemplate_order_by",
			where:"landingPageTemplate_bool_exp"
		},
		landingPageTemplate_aggregate:{
			distinct_on:"landingPageTemplate_select_column",
			order_by:"landingPageTemplate_order_by",
			where:"landingPageTemplate_bool_exp"
		},
		landingPageTemplate_by_pk:{
			id:"uuid"
		},
		landingPageTemplate_stream:{
			cursor:"landingPageTemplate_stream_cursor_input",
			where:"landingPageTemplate_bool_exp"
		},
		newTemplateType:{
			distinct_on:"newTemplateType_select_column",
			order_by:"newTemplateType_order_by",
			where:"newTemplateType_bool_exp"
		},
		newTemplateType_aggregate:{
			distinct_on:"newTemplateType_select_column",
			order_by:"newTemplateType_order_by",
			where:"newTemplateType_bool_exp"
		},
		newTemplateType_by_pk:{
			id:"uuid"
		},
		newTemplateType_stream:{
			cursor:"newTemplateType_stream_cursor_input",
			where:"newTemplateType_bool_exp"
		},
		openAIApiKey:{
			distinct_on:"openAIApiKey_select_column",
			order_by:"openAIApiKey_order_by",
			where:"openAIApiKey_bool_exp"
		},
		openAIApiKey_aggregate:{
			distinct_on:"openAIApiKey_select_column",
			order_by:"openAIApiKey_order_by",
			where:"openAIApiKey_bool_exp"
		},
		openAIApiKey_by_pk:{
			id:"uuid"
		},
		openAIApiKey_stream:{
			cursor:"openAIApiKey_stream_cursor_input",
			where:"openAIApiKey_bool_exp"
		},
		pTemplate:{
			distinct_on:"pTemplate_select_column",
			order_by:"pTemplate_order_by",
			where:"pTemplate_bool_exp"
		},
		pTemplate_aggregate:{
			distinct_on:"pTemplate_select_column",
			order_by:"pTemplate_order_by",
			where:"pTemplate_bool_exp"
		},
		pTemplate_by_pk:{
			id:"uuid"
		},
		pTemplate_stream:{
			cursor:"pTemplate_stream_cursor_input",
			where:"pTemplate_bool_exp"
		},
		pricingPlan:{
			distinct_on:"pricingPlan_select_column",
			order_by:"pricingPlan_order_by",
			where:"pricingPlan_bool_exp"
		},
		pricingPlan_aggregate:{
			distinct_on:"pricingPlan_select_column",
			order_by:"pricingPlan_order_by",
			where:"pricingPlan_bool_exp"
		},
		pricingPlan_by_pk:{
			id:"uuid"
		},
		pricingPlan_stream:{
			cursor:"pricingPlan_stream_cursor_input",
			where:"pricingPlan_bool_exp"
		},
		products:{
			distinct_on:"products_select_column",
			order_by:"products_order_by",
			where:"products_bool_exp"
		},
		products_aggregate:{
			distinct_on:"products_select_column",
			order_by:"products_order_by",
			where:"products_bool_exp"
		},
		products_by_pk:{
			id:"uuid"
		},
		products_stream:{
			cursor:"products_stream_cursor_input",
			where:"products_bool_exp"
		},
		project:{
			distinct_on:"project_select_column",
			order_by:"project_order_by",
			where:"project_bool_exp"
		},
		projectImage:{
			distinct_on:"projectImage_select_column",
			order_by:"projectImage_order_by",
			where:"projectImage_bool_exp"
		},
		projectImage_aggregate:{
			distinct_on:"projectImage_select_column",
			order_by:"projectImage_order_by",
			where:"projectImage_bool_exp"
		},
		projectImage_by_pk:{
			id:"uuid"
		},
		projectImage_stream:{
			cursor:"projectImage_stream_cursor_input",
			where:"projectImage_bool_exp"
		},
		project_aggregate:{
			distinct_on:"project_select_column",
			order_by:"project_order_by",
			where:"project_bool_exp"
		},
		project_by_pk:{
			id:"uuid"
		},
		project_stream:{
			cursor:"project_stream_cursor_input",
			where:"project_bool_exp"
		},
		task:{
			distinct_on:"task_select_column",
			order_by:"task_order_by",
			where:"task_bool_exp"
		},
		task_aggregate:{
			distinct_on:"task_select_column",
			order_by:"task_order_by",
			where:"task_bool_exp"
		},
		task_by_pk:{
			id:"uuid"
		},
		task_stream:{
			cursor:"task_stream_cursor_input",
			where:"task_bool_exp"
		},
		textTemplateDisplayFormat:{
			distinct_on:"textTemplateDisplayFormat_select_column",
			order_by:"textTemplateDisplayFormat_order_by",
			where:"textTemplateDisplayFormat_bool_exp"
		},
		textTemplateDisplayFormat_aggregate:{
			distinct_on:"textTemplateDisplayFormat_select_column",
			order_by:"textTemplateDisplayFormat_order_by",
			where:"textTemplateDisplayFormat_bool_exp"
		},
		textTemplateDisplayFormat_by_pk:{
			id:"uuid"
		},
		textTemplateDisplayFormat_stream:{
			cursor:"textTemplateDisplayFormat_stream_cursor_input",
			where:"textTemplateDisplayFormat_bool_exp"
		},
		user:{
			id:"uuid"
		},
		userAppIntegration:{
			distinct_on:"userAppIntegration_select_column",
			order_by:"userAppIntegration_order_by",
			where:"userAppIntegration_bool_exp"
		},
		userAppIntegration_aggregate:{
			distinct_on:"userAppIntegration_select_column",
			order_by:"userAppIntegration_order_by",
			where:"userAppIntegration_bool_exp"
		},
		userAppIntegration_by_pk:{
			id:"uuid"
		},
		userAppIntegration_stream:{
			cursor:"userAppIntegration_stream_cursor_input",
			where:"userAppIntegration_bool_exp"
		},
		userCreditUsage:{
			distinct_on:"userCreditUsage_select_column",
			order_by:"userCreditUsage_order_by",
			where:"userCreditUsage_bool_exp"
		},
		userCreditUsageSummary:{
			distinct_on:"userCreditUsageSummary_select_column",
			order_by:"userCreditUsageSummary_order_by",
			where:"userCreditUsageSummary_bool_exp"
		},
		userCreditUsageSummary_aggregate:{
			distinct_on:"userCreditUsageSummary_select_column",
			order_by:"userCreditUsageSummary_order_by",
			where:"userCreditUsageSummary_bool_exp"
		},
		userCreditUsageSummary_by_pk:{
			created_by:"uuid"
		},
		userCreditUsageSummary_stream:{
			cursor:"userCreditUsageSummary_stream_cursor_input",
			where:"userCreditUsageSummary_bool_exp"
		},
		userCreditUsage_aggregate:{
			distinct_on:"userCreditUsage_select_column",
			order_by:"userCreditUsage_order_by",
			where:"userCreditUsage_bool_exp"
		},
		userCreditUsage_by_pk:{
			id:"uuid"
		},
		userCreditUsage_stream:{
			cursor:"userCreditUsage_stream_cursor_input",
			where:"userCreditUsage_bool_exp"
		},
		userCredits:{
			distinct_on:"userCredits_select_column",
			order_by:"userCredits_order_by",
			where:"userCredits_bool_exp"
		},
		userCredits_aggregate:{
			distinct_on:"userCredits_select_column",
			order_by:"userCredits_order_by",
			where:"userCredits_bool_exp"
		},
		userCredits_by_pk:{
			id:"uuid"
		},
		userCredits_stream:{
			cursor:"userCredits_stream_cursor_input",
			where:"userCredits_bool_exp"
		},
		userFeedback:{
			distinct_on:"userFeedback_select_column",
			order_by:"userFeedback_order_by",
			where:"userFeedback_bool_exp"
		},
		userFeedback_aggregate:{
			distinct_on:"userFeedback_select_column",
			order_by:"userFeedback_order_by",
			where:"userFeedback_bool_exp"
		},
		userFeedback_by_pk:{
			id:"uuid"
		},
		userFeedback_stream:{
			cursor:"userFeedback_stream_cursor_input",
			where:"userFeedback_bool_exp"
		},
		userMedia:{
			distinct_on:"userMedia_select_column",
			order_by:"userMedia_order_by",
			where:"userMedia_bool_exp"
		},
		userMedia_aggregate:{
			distinct_on:"userMedia_select_column",
			order_by:"userMedia_order_by",
			where:"userMedia_bool_exp"
		},
		userMedia_by_pk:{
			id:"uuid"
		},
		userMedia_stream:{
			cursor:"userMedia_stream_cursor_input",
			where:"userMedia_bool_exp"
		},
		userMetadata:{
			distinct_on:"userMetadata_select_column",
			order_by:"userMetadata_order_by",
			where:"userMetadata_bool_exp"
		},
		userMetadata_aggregate:{
			distinct_on:"userMetadata_select_column",
			order_by:"userMetadata_order_by",
			where:"userMetadata_bool_exp"
		},
		userMetadata_by_pk:{
			id:"uuid"
		},
		userMetadata_stream:{
			cursor:"userMetadata_stream_cursor_input",
			where:"userMetadata_bool_exp"
		},
		userOnboarding:{
			distinct_on:"userOnboarding_select_column",
			order_by:"userOnboarding_order_by",
			where:"userOnboarding_bool_exp"
		},
		userOnboarding_aggregate:{
			distinct_on:"userOnboarding_select_column",
			order_by:"userOnboarding_order_by",
			where:"userOnboarding_bool_exp"
		},
		userOnboarding_by_pk:{
			userId:"uuid"
		},
		userOnboarding_stream:{
			cursor:"userOnboarding_stream_cursor_input",
			where:"userOnboarding_bool_exp"
		},
		userTemplateUsage:{
			distinct_on:"userTemplateUsage_select_column",
			order_by:"userTemplateUsage_order_by",
			where:"userTemplateUsage_bool_exp"
		},
		userTemplateUsage_aggregate:{
			distinct_on:"userTemplateUsage_select_column",
			order_by:"userTemplateUsage_order_by",
			where:"userTemplateUsage_bool_exp"
		},
		userTemplateUsage_by_pk:{
			id:"uuid"
		},
		userTemplateUsage_stream:{
			cursor:"userTemplateUsage_stream_cursor_input",
			where:"userTemplateUsage_bool_exp"
		},
		users:{
			distinct_on:"users_select_column",
			order_by:"users_order_by",
			where:"users_bool_exp"
		},
		usersAggregate:{
			distinct_on:"users_select_column",
			order_by:"users_order_by",
			where:"users_bool_exp"
		},
		users_stream:{
			cursor:"users_stream_cursor_input",
			where:"users_bool_exp"
		},
		virus:{
			id:"uuid"
		},
		virus_stream:{
			cursor:"virus_stream_cursor_input",
			where:"virus_bool_exp"
		},
		viruses:{
			distinct_on:"virus_select_column",
			order_by:"virus_order_by",
			where:"virus_bool_exp"
		},
		virusesAggregate:{
			distinct_on:"virus_select_column",
			order_by:"virus_order_by",
			where:"virus_bool_exp"
		},
		website:{
			distinct_on:"website_select_column",
			order_by:"website_order_by",
			where:"website_bool_exp"
		},
		website_aggregate:{
			distinct_on:"website_select_column",
			order_by:"website_order_by",
			where:"website_bool_exp"
		},
		website_by_pk:{
			id:"uuid"
		},
		website_stream:{
			cursor:"website_stream_cursor_input",
			where:"website_bool_exp"
		}
	},
	task_aggregate_bool_exp:{
		bool_and:"task_aggregate_bool_exp_bool_and",
		bool_or:"task_aggregate_bool_exp_bool_or",
		count:"task_aggregate_bool_exp_count"
	},
	task_aggregate_bool_exp_bool_and:{
		arguments:"task_select_column_task_aggregate_bool_exp_bool_and_arguments_columns",
		filter:"task_bool_exp",
		predicate:"Boolean_comparison_exp"
	},
	task_aggregate_bool_exp_bool_or:{
		arguments:"task_select_column_task_aggregate_bool_exp_bool_or_arguments_columns",
		filter:"task_bool_exp",
		predicate:"Boolean_comparison_exp"
	},
	task_aggregate_bool_exp_count:{
		arguments:"task_select_column",
		filter:"task_bool_exp",
		predicate:"Int_comparison_exp"
	},
	task_aggregate_fields:{
		count:{
			columns:"task_select_column"
		}
	},
	task_aggregate_order_by:{
		count:"order_by",
		max:"task_max_order_by",
		min:"task_min_order_by"
	},
	task_arr_rel_insert_input:{
		data:"task_insert_input",
		on_conflict:"task_on_conflict"
	},
	task_bool_exp:{
		_and:"task_bool_exp",
		_not:"task_bool_exp",
		_or:"task_bool_exp",
		category:"uuid_comparison_exp",
		created_at:"timestamptz_comparison_exp",
		created_by:"uuid_comparison_exp",
		date:"timestamp_comparison_exp",
		deal:"deal_bool_exp",
		dealId:"uuid_comparison_exp",
		description:"String_comparison_exp",
		finished:"Boolean_comparison_exp",
		id:"uuid_comparison_exp",
		title:"String_comparison_exp",
		updated_at:"timestamptz_comparison_exp",
		user:"users_bool_exp"
	},
	task_constraint: "enum" as const,
	task_insert_input:{
		category:"uuid",
		created_at:"timestamptz",
		created_by:"uuid",
		date:"timestamp",
		deal:"deal_obj_rel_insert_input",
		dealId:"uuid",
		id:"uuid",
		updated_at:"timestamptz",
		user:"users_obj_rel_insert_input"
	},
	task_max_order_by:{
		category:"order_by",
		created_at:"order_by",
		created_by:"order_by",
		date:"order_by",
		dealId:"order_by",
		description:"order_by",
		id:"order_by",
		title:"order_by",
		updated_at:"order_by"
	},
	task_min_order_by:{
		category:"order_by",
		created_at:"order_by",
		created_by:"order_by",
		date:"order_by",
		dealId:"order_by",
		description:"order_by",
		id:"order_by",
		title:"order_by",
		updated_at:"order_by"
	},
	task_on_conflict:{
		constraint:"task_constraint",
		update_columns:"task_update_column",
		where:"task_bool_exp"
	},
	task_order_by:{
		category:"order_by",
		created_at:"order_by",
		created_by:"order_by",
		date:"order_by",
		deal:"deal_order_by",
		dealId:"order_by",
		description:"order_by",
		finished:"order_by",
		id:"order_by",
		title:"order_by",
		updated_at:"order_by",
		user:"users_order_by"
	},
	task_pk_columns_input:{
		id:"uuid"
	},
	task_select_column: "enum" as const,
	task_select_column_task_aggregate_bool_exp_bool_and_arguments_columns: "enum" as const,
	task_select_column_task_aggregate_bool_exp_bool_or_arguments_columns: "enum" as const,
	task_set_input:{
		category:"uuid",
		created_at:"timestamptz",
		created_by:"uuid",
		date:"timestamp",
		dealId:"uuid",
		id:"uuid",
		updated_at:"timestamptz"
	},
	task_stream_cursor_input:{
		initial_value:"task_stream_cursor_value_input",
		ordering:"cursor_ordering"
	},
	task_stream_cursor_value_input:{
		category:"uuid",
		created_at:"timestamptz",
		created_by:"uuid",
		date:"timestamp",
		dealId:"uuid",
		id:"uuid",
		updated_at:"timestamptz"
	},
	task_update_column: "enum" as const,
	task_updates:{
		_set:"task_set_input",
		where:"task_bool_exp"
	},
	textTemplateDisplayFormat_aggregate_bool_exp:{
		count:"textTemplateDisplayFormat_aggregate_bool_exp_count"
	},
	textTemplateDisplayFormat_aggregate_bool_exp_count:{
		arguments:"textTemplateDisplayFormat_select_column",
		filter:"textTemplateDisplayFormat_bool_exp",
		predicate:"Int_comparison_exp"
	},
	textTemplateDisplayFormat_aggregate_fields:{
		count:{
			columns:"textTemplateDisplayFormat_select_column"
		}
	},
	textTemplateDisplayFormat_aggregate_order_by:{
		count:"order_by",
		max:"textTemplateDisplayFormat_max_order_by",
		min:"textTemplateDisplayFormat_min_order_by"
	},
	textTemplateDisplayFormat_arr_rel_insert_input:{
		data:"textTemplateDisplayFormat_insert_input",
		on_conflict:"textTemplateDisplayFormat_on_conflict"
	},
	textTemplateDisplayFormat_bool_exp:{
		_and:"textTemplateDisplayFormat_bool_exp",
		_not:"textTemplateDisplayFormat_bool_exp",
		_or:"textTemplateDisplayFormat_bool_exp",
		displayFormat:"displayFormats_bool_exp",
		displayFormatId:"uuid_comparison_exp",
		id:"uuid_comparison_exp",
		textTemplateId:"uuid_comparison_exp"
	},
	textTemplateDisplayFormat_constraint: "enum" as const,
	textTemplateDisplayFormat_insert_input:{
		displayFormat:"displayFormats_obj_rel_insert_input",
		displayFormatId:"uuid",
		id:"uuid",
		textTemplateId:"uuid"
	},
	textTemplateDisplayFormat_max_order_by:{
		displayFormatId:"order_by",
		id:"order_by",
		textTemplateId:"order_by"
	},
	textTemplateDisplayFormat_min_order_by:{
		displayFormatId:"order_by",
		id:"order_by",
		textTemplateId:"order_by"
	},
	textTemplateDisplayFormat_on_conflict:{
		constraint:"textTemplateDisplayFormat_constraint",
		update_columns:"textTemplateDisplayFormat_update_column",
		where:"textTemplateDisplayFormat_bool_exp"
	},
	textTemplateDisplayFormat_order_by:{
		displayFormat:"displayFormats_order_by",
		displayFormatId:"order_by",
		id:"order_by",
		textTemplateId:"order_by"
	},
	textTemplateDisplayFormat_pk_columns_input:{
		id:"uuid"
	},
	textTemplateDisplayFormat_select_column: "enum" as const,
	textTemplateDisplayFormat_set_input:{
		displayFormatId:"uuid",
		id:"uuid",
		textTemplateId:"uuid"
	},
	textTemplateDisplayFormat_stream_cursor_input:{
		initial_value:"textTemplateDisplayFormat_stream_cursor_value_input",
		ordering:"cursor_ordering"
	},
	textTemplateDisplayFormat_stream_cursor_value_input:{
		displayFormatId:"uuid",
		id:"uuid",
		textTemplateId:"uuid"
	},
	textTemplateDisplayFormat_update_column: "enum" as const,
	textTemplateDisplayFormat_updates:{
		_set:"textTemplateDisplayFormat_set_input",
		where:"textTemplateDisplayFormat_bool_exp"
	},
	timestamp: `scalar.timestamp` as const,
	timestamp_comparison_exp:{
		_eq:"timestamp",
		_gt:"timestamp",
		_gte:"timestamp",
		_in:"timestamp",
		_lt:"timestamp",
		_lte:"timestamp",
		_neq:"timestamp",
		_nin:"timestamp"
	},
	timestamptz: `scalar.timestamptz` as const,
	timestamptz_comparison_exp:{
		_eq:"timestamptz",
		_gt:"timestamptz",
		_gte:"timestamptz",
		_in:"timestamptz",
		_lt:"timestamptz",
		_lte:"timestamptz",
		_neq:"timestamptz",
		_nin:"timestamptz"
	},
	userAppIntegration:{
		params:{

		}
	},
	userAppIntegration_aggregate_fields:{
		count:{
			columns:"userAppIntegration_select_column"
		}
	},
	userAppIntegration_append_input:{
		params:"jsonb"
	},
	userAppIntegration_bool_exp:{
		_and:"userAppIntegration_bool_exp",
		_not:"userAppIntegration_bool_exp",
		_or:"userAppIntegration_bool_exp",
		alias:"String_comparison_exp",
		brandId:"uuid_comparison_exp",
		created_at:"timestamptz_comparison_exp",
		created_by:"uuid_comparison_exp",
		deleted:"Boolean_comparison_exp",
		id:"uuid_comparison_exp",
		integrationType:"String_comparison_exp",
		params:"jsonb_comparison_exp",
		updated_at:"timestamptz_comparison_exp"
	},
	userAppIntegration_constraint: "enum" as const,
	userAppIntegration_delete_at_path_input:{

	},
	userAppIntegration_delete_elem_input:{

	},
	userAppIntegration_delete_key_input:{

	},
	userAppIntegration_insert_input:{
		brandId:"uuid",
		created_at:"timestamptz",
		created_by:"uuid",
		id:"uuid",
		params:"jsonb",
		updated_at:"timestamptz"
	},
	userAppIntegration_on_conflict:{
		constraint:"userAppIntegration_constraint",
		update_columns:"userAppIntegration_update_column",
		where:"userAppIntegration_bool_exp"
	},
	userAppIntegration_order_by:{
		alias:"order_by",
		brandId:"order_by",
		created_at:"order_by",
		created_by:"order_by",
		deleted:"order_by",
		id:"order_by",
		integrationType:"order_by",
		params:"order_by",
		updated_at:"order_by"
	},
	userAppIntegration_pk_columns_input:{
		id:"uuid"
	},
	userAppIntegration_prepend_input:{
		params:"jsonb"
	},
	userAppIntegration_select_column: "enum" as const,
	userAppIntegration_set_input:{
		brandId:"uuid",
		created_at:"timestamptz",
		created_by:"uuid",
		id:"uuid",
		params:"jsonb",
		updated_at:"timestamptz"
	},
	userAppIntegration_stream_cursor_input:{
		initial_value:"userAppIntegration_stream_cursor_value_input",
		ordering:"cursor_ordering"
	},
	userAppIntegration_stream_cursor_value_input:{
		brandId:"uuid",
		created_at:"timestamptz",
		created_by:"uuid",
		id:"uuid",
		params:"jsonb",
		updated_at:"timestamptz"
	},
	userAppIntegration_update_column: "enum" as const,
	userAppIntegration_updates:{
		_append:"userAppIntegration_append_input",
		_delete_at_path:"userAppIntegration_delete_at_path_input",
		_delete_elem:"userAppIntegration_delete_elem_input",
		_delete_key:"userAppIntegration_delete_key_input",
		_prepend:"userAppIntegration_prepend_input",
		_set:"userAppIntegration_set_input",
		where:"userAppIntegration_bool_exp"
	},
	userCreditUsageSummary_aggregate_fields:{
		count:{
			columns:"userCreditUsageSummary_select_column"
		}
	},
	userCreditUsageSummary_bool_exp:{
		_and:"userCreditUsageSummary_bool_exp",
		_not:"userCreditUsageSummary_bool_exp",
		_or:"userCreditUsageSummary_bool_exp",
		created_at:"timestamptz_comparison_exp",
		created_by:"uuid_comparison_exp",
		freeCreditUsage:"numeric_comparison_exp",
		id:"uuid_comparison_exp",
		originalVaue:"numeric_comparison_exp",
		totalCharged:"numeric_comparison_exp",
		updated_at:"timestamptz_comparison_exp",
		yearMonthDayReference:"String_comparison_exp"
	},
	userCreditUsageSummary_constraint: "enum" as const,
	userCreditUsageSummary_inc_input:{
		freeCreditUsage:"numeric",
		originalVaue:"numeric",
		totalCharged:"numeric"
	},
	userCreditUsageSummary_insert_input:{
		created_at:"timestamptz",
		created_by:"uuid",
		freeCreditUsage:"numeric",
		id:"uuid",
		originalVaue:"numeric",
		totalCharged:"numeric",
		updated_at:"timestamptz"
	},
	userCreditUsageSummary_on_conflict:{
		constraint:"userCreditUsageSummary_constraint",
		update_columns:"userCreditUsageSummary_update_column",
		where:"userCreditUsageSummary_bool_exp"
	},
	userCreditUsageSummary_order_by:{
		created_at:"order_by",
		created_by:"order_by",
		freeCreditUsage:"order_by",
		id:"order_by",
		originalVaue:"order_by",
		totalCharged:"order_by",
		updated_at:"order_by",
		yearMonthDayReference:"order_by"
	},
	userCreditUsageSummary_pk_columns_input:{
		created_by:"uuid"
	},
	userCreditUsageSummary_select_column: "enum" as const,
	userCreditUsageSummary_set_input:{
		created_at:"timestamptz",
		created_by:"uuid",
		freeCreditUsage:"numeric",
		id:"uuid",
		originalVaue:"numeric",
		totalCharged:"numeric",
		updated_at:"timestamptz"
	},
	userCreditUsageSummary_stream_cursor_input:{
		initial_value:"userCreditUsageSummary_stream_cursor_value_input",
		ordering:"cursor_ordering"
	},
	userCreditUsageSummary_stream_cursor_value_input:{
		created_at:"timestamptz",
		created_by:"uuid",
		freeCreditUsage:"numeric",
		id:"uuid",
		originalVaue:"numeric",
		totalCharged:"numeric",
		updated_at:"timestamptz"
	},
	userCreditUsageSummary_update_column: "enum" as const,
	userCreditUsageSummary_updates:{
		_inc:"userCreditUsageSummary_inc_input",
		_set:"userCreditUsageSummary_set_input",
		where:"userCreditUsageSummary_bool_exp"
	},
	userCreditUsage_aggregate_fields:{
		count:{
			columns:"userCreditUsage_select_column"
		}
	},
	userCreditUsage_bool_exp:{
		GeneratedPromptByTemplateId:"uuid_comparison_exp",
		_and:"userCreditUsage_bool_exp",
		_not:"userCreditUsage_bool_exp",
		_or:"userCreditUsage_bool_exp",
		created_at:"timestamptz_comparison_exp",
		created_by:"uuid_comparison_exp",
		freeCreditUsed:"numeric_comparison_exp",
		id:"uuid_comparison_exp",
		originalValue:"numeric_comparison_exp",
		totalCharged:"numeric_comparison_exp"
	},
	userCreditUsage_constraint: "enum" as const,
	userCreditUsage_inc_input:{
		freeCreditUsed:"numeric",
		originalValue:"numeric",
		totalCharged:"numeric"
	},
	userCreditUsage_insert_input:{
		GeneratedPromptByTemplateId:"uuid",
		created_at:"timestamptz",
		created_by:"uuid",
		freeCreditUsed:"numeric",
		id:"uuid",
		originalValue:"numeric",
		totalCharged:"numeric"
	},
	userCreditUsage_on_conflict:{
		constraint:"userCreditUsage_constraint",
		update_columns:"userCreditUsage_update_column",
		where:"userCreditUsage_bool_exp"
	},
	userCreditUsage_order_by:{
		GeneratedPromptByTemplateId:"order_by",
		created_at:"order_by",
		created_by:"order_by",
		freeCreditUsed:"order_by",
		id:"order_by",
		originalValue:"order_by",
		totalCharged:"order_by"
	},
	userCreditUsage_pk_columns_input:{
		id:"uuid"
	},
	userCreditUsage_select_column: "enum" as const,
	userCreditUsage_set_input:{
		GeneratedPromptByTemplateId:"uuid",
		created_at:"timestamptz",
		created_by:"uuid",
		freeCreditUsed:"numeric",
		id:"uuid",
		originalValue:"numeric",
		totalCharged:"numeric"
	},
	userCreditUsage_stream_cursor_input:{
		initial_value:"userCreditUsage_stream_cursor_value_input",
		ordering:"cursor_ordering"
	},
	userCreditUsage_stream_cursor_value_input:{
		GeneratedPromptByTemplateId:"uuid",
		created_at:"timestamptz",
		created_by:"uuid",
		freeCreditUsed:"numeric",
		id:"uuid",
		originalValue:"numeric",
		totalCharged:"numeric"
	},
	userCreditUsage_update_column: "enum" as const,
	userCreditUsage_updates:{
		_inc:"userCreditUsage_inc_input",
		_set:"userCreditUsage_set_input",
		where:"userCreditUsage_bool_exp"
	},
	userCredits_aggregate_fields:{
		count:{
			columns:"userCredits_select_column"
		}
	},
	userCredits_bool_exp:{
		_and:"userCredits_bool_exp",
		_not:"userCredits_bool_exp",
		_or:"userCredits_bool_exp",
		created_at:"timestamptz_comparison_exp",
		id:"uuid_comparison_exp",
		quantity:"numeric_comparison_exp",
		reason:"String_comparison_exp",
		userId:"uuid_comparison_exp"
	},
	userCredits_constraint: "enum" as const,
	userCredits_inc_input:{
		quantity:"numeric"
	},
	userCredits_insert_input:{
		created_at:"timestamptz",
		id:"uuid",
		quantity:"numeric",
		userId:"uuid"
	},
	userCredits_on_conflict:{
		constraint:"userCredits_constraint",
		update_columns:"userCredits_update_column",
		where:"userCredits_bool_exp"
	},
	userCredits_order_by:{
		created_at:"order_by",
		id:"order_by",
		quantity:"order_by",
		reason:"order_by",
		userId:"order_by"
	},
	userCredits_pk_columns_input:{
		id:"uuid"
	},
	userCredits_select_column: "enum" as const,
	userCredits_set_input:{
		created_at:"timestamptz",
		id:"uuid",
		quantity:"numeric",
		userId:"uuid"
	},
	userCredits_stream_cursor_input:{
		initial_value:"userCredits_stream_cursor_value_input",
		ordering:"cursor_ordering"
	},
	userCredits_stream_cursor_value_input:{
		created_at:"timestamptz",
		id:"uuid",
		quantity:"numeric",
		userId:"uuid"
	},
	userCredits_update_column: "enum" as const,
	userCredits_updates:{
		_inc:"userCredits_inc_input",
		_set:"userCredits_set_input",
		where:"userCredits_bool_exp"
	},
	userFeedback_aggregate_fields:{
		count:{
			columns:"userFeedback_select_column"
		}
	},
	userFeedback_bool_exp:{
		_and:"userFeedback_bool_exp",
		_not:"userFeedback_bool_exp",
		_or:"userFeedback_bool_exp",
		createdBy:"uuid_comparison_exp",
		created_at:"timestamptz_comparison_exp",
		feedbackType:"String_comparison_exp",
		id:"uuid_comparison_exp",
		message:"String_comparison_exp",
		replied_at:"timestamptz_comparison_exp"
	},
	userFeedback_constraint: "enum" as const,
	userFeedback_insert_input:{
		createdBy:"uuid",
		created_at:"timestamptz",
		id:"uuid",
		replied_at:"timestamptz"
	},
	userFeedback_on_conflict:{
		constraint:"userFeedback_constraint",
		update_columns:"userFeedback_update_column",
		where:"userFeedback_bool_exp"
	},
	userFeedback_order_by:{
		createdBy:"order_by",
		created_at:"order_by",
		feedbackType:"order_by",
		id:"order_by",
		message:"order_by",
		replied_at:"order_by"
	},
	userFeedback_pk_columns_input:{
		id:"uuid"
	},
	userFeedback_select_column: "enum" as const,
	userFeedback_set_input:{
		createdBy:"uuid",
		created_at:"timestamptz",
		id:"uuid",
		replied_at:"timestamptz"
	},
	userFeedback_stream_cursor_input:{
		initial_value:"userFeedback_stream_cursor_value_input",
		ordering:"cursor_ordering"
	},
	userFeedback_stream_cursor_value_input:{
		createdBy:"uuid",
		created_at:"timestamptz",
		id:"uuid",
		replied_at:"timestamptz"
	},
	userFeedback_update_column: "enum" as const,
	userFeedback_updates:{
		_set:"userFeedback_set_input",
		where:"userFeedback_bool_exp"
	},
	userMedia:{
		aiParameters:{

		}
	},
	userMedia_aggregate_fields:{
		count:{
			columns:"userMedia_select_column"
		}
	},
	userMedia_append_input:{
		aiParameters:"jsonb"
	},
	userMedia_bool_exp:{
		_and:"userMedia_bool_exp",
		_not:"userMedia_bool_exp",
		_or:"userMedia_bool_exp",
		aiParameters:"jsonb_comparison_exp",
		created_at:"timestamptz_comparison_exp",
		deleted:"Boolean_comparison_exp",
		fileId:"uuid_comparison_exp",
		id:"uuid_comparison_exp",
		mediaType:"String_comparison_exp",
		ownerId:"uuid_comparison_exp",
		updated_at:"timestamptz_comparison_exp",
		videoFileId:"uuid_comparison_exp"
	},
	userMedia_constraint: "enum" as const,
	userMedia_delete_at_path_input:{

	},
	userMedia_delete_elem_input:{

	},
	userMedia_delete_key_input:{

	},
	userMedia_insert_input:{
		aiParameters:"jsonb",
		created_at:"timestamptz",
		fileId:"uuid",
		id:"uuid",
		ownerId:"uuid",
		updated_at:"timestamptz",
		videoFileId:"uuid"
	},
	userMedia_on_conflict:{
		constraint:"userMedia_constraint",
		update_columns:"userMedia_update_column",
		where:"userMedia_bool_exp"
	},
	userMedia_order_by:{
		aiParameters:"order_by",
		created_at:"order_by",
		deleted:"order_by",
		fileId:"order_by",
		id:"order_by",
		mediaType:"order_by",
		ownerId:"order_by",
		updated_at:"order_by",
		videoFileId:"order_by"
	},
	userMedia_pk_columns_input:{
		id:"uuid"
	},
	userMedia_prepend_input:{
		aiParameters:"jsonb"
	},
	userMedia_select_column: "enum" as const,
	userMedia_set_input:{
		aiParameters:"jsonb",
		created_at:"timestamptz",
		fileId:"uuid",
		id:"uuid",
		ownerId:"uuid",
		updated_at:"timestamptz",
		videoFileId:"uuid"
	},
	userMedia_stream_cursor_input:{
		initial_value:"userMedia_stream_cursor_value_input",
		ordering:"cursor_ordering"
	},
	userMedia_stream_cursor_value_input:{
		aiParameters:"jsonb",
		created_at:"timestamptz",
		fileId:"uuid",
		id:"uuid",
		ownerId:"uuid",
		updated_at:"timestamptz",
		videoFileId:"uuid"
	},
	userMedia_update_column: "enum" as const,
	userMedia_updates:{
		_append:"userMedia_append_input",
		_delete_at_path:"userMedia_delete_at_path_input",
		_delete_elem:"userMedia_delete_elem_input",
		_delete_key:"userMedia_delete_key_input",
		_prepend:"userMedia_prepend_input",
		_set:"userMedia_set_input",
		where:"userMedia_bool_exp"
	},
	userMetadata_aggregate_bool_exp:{
		bool_and:"userMetadata_aggregate_bool_exp_bool_and",
		bool_or:"userMetadata_aggregate_bool_exp_bool_or",
		count:"userMetadata_aggregate_bool_exp_count"
	},
	userMetadata_aggregate_bool_exp_bool_and:{
		arguments:"userMetadata_select_column_userMetadata_aggregate_bool_exp_bool_and_arguments_columns",
		filter:"userMetadata_bool_exp",
		predicate:"Boolean_comparison_exp"
	},
	userMetadata_aggregate_bool_exp_bool_or:{
		arguments:"userMetadata_select_column_userMetadata_aggregate_bool_exp_bool_or_arguments_columns",
		filter:"userMetadata_bool_exp",
		predicate:"Boolean_comparison_exp"
	},
	userMetadata_aggregate_bool_exp_count:{
		arguments:"userMetadata_select_column",
		filter:"userMetadata_bool_exp",
		predicate:"Int_comparison_exp"
	},
	userMetadata_aggregate_fields:{
		count:{
			columns:"userMetadata_select_column"
		}
	},
	userMetadata_aggregate_order_by:{
		avg:"userMetadata_avg_order_by",
		count:"order_by",
		max:"userMetadata_max_order_by",
		min:"userMetadata_min_order_by",
		stddev:"userMetadata_stddev_order_by",
		stddev_pop:"userMetadata_stddev_pop_order_by",
		stddev_samp:"userMetadata_stddev_samp_order_by",
		sum:"userMetadata_sum_order_by",
		var_pop:"userMetadata_var_pop_order_by",
		var_samp:"userMetadata_var_samp_order_by",
		variance:"userMetadata_variance_order_by"
	},
	userMetadata_arr_rel_insert_input:{
		data:"userMetadata_insert_input",
		on_conflict:"userMetadata_on_conflict"
	},
	userMetadata_avg_order_by:{
		creditBalance:"order_by"
	},
	userMetadata_bool_exp:{
		_and:"userMetadata_bool_exp",
		_not:"userMetadata_bool_exp",
		_or:"userMetadata_bool_exp",
		created_at:"timestamptz_comparison_exp",
		creditBalance:"numeric_comparison_exp",
		id:"uuid_comparison_exp",
		lastCreditRefill:"date_comparison_exp",
		last_seen:"timestamp_comparison_exp",
		onboardCompleted:"Boolean_comparison_exp",
		pricingPlan:"pricingPlan_bool_exp",
		stripeCustomerId:"String_comparison_exp",
		stripePriceId:"String_comparison_exp",
		stripeProductId:"String_comparison_exp",
		stripeSubscriptionId:"String_comparison_exp",
		stripeSubscriptionItem:"String_comparison_exp",
		subscriptionType:"String_comparison_exp",
		updated_at:"timestamptz_comparison_exp",
		user:"users_bool_exp"
	},
	userMetadata_constraint: "enum" as const,
	userMetadata_inc_input:{
		creditBalance:"numeric"
	},
	userMetadata_insert_input:{
		created_at:"timestamptz",
		creditBalance:"numeric",
		id:"uuid",
		lastCreditRefill:"date",
		last_seen:"timestamp",
		pricingPlan:"pricingPlan_obj_rel_insert_input",
		updated_at:"timestamptz",
		user:"users_obj_rel_insert_input"
	},
	userMetadata_max_order_by:{
		created_at:"order_by",
		creditBalance:"order_by",
		id:"order_by",
		lastCreditRefill:"order_by",
		last_seen:"order_by",
		stripeCustomerId:"order_by",
		stripePriceId:"order_by",
		stripeProductId:"order_by",
		stripeSubscriptionId:"order_by",
		stripeSubscriptionItem:"order_by",
		subscriptionType:"order_by",
		updated_at:"order_by"
	},
	userMetadata_min_order_by:{
		created_at:"order_by",
		creditBalance:"order_by",
		id:"order_by",
		lastCreditRefill:"order_by",
		last_seen:"order_by",
		stripeCustomerId:"order_by",
		stripePriceId:"order_by",
		stripeProductId:"order_by",
		stripeSubscriptionId:"order_by",
		stripeSubscriptionItem:"order_by",
		subscriptionType:"order_by",
		updated_at:"order_by"
	},
	userMetadata_on_conflict:{
		constraint:"userMetadata_constraint",
		update_columns:"userMetadata_update_column",
		where:"userMetadata_bool_exp"
	},
	userMetadata_order_by:{
		created_at:"order_by",
		creditBalance:"order_by",
		id:"order_by",
		lastCreditRefill:"order_by",
		last_seen:"order_by",
		onboardCompleted:"order_by",
		pricingPlan:"pricingPlan_order_by",
		stripeCustomerId:"order_by",
		stripePriceId:"order_by",
		stripeProductId:"order_by",
		stripeSubscriptionId:"order_by",
		stripeSubscriptionItem:"order_by",
		subscriptionType:"order_by",
		updated_at:"order_by",
		user:"users_order_by"
	},
	userMetadata_pk_columns_input:{
		id:"uuid"
	},
	userMetadata_select_column: "enum" as const,
	userMetadata_select_column_userMetadata_aggregate_bool_exp_bool_and_arguments_columns: "enum" as const,
	userMetadata_select_column_userMetadata_aggregate_bool_exp_bool_or_arguments_columns: "enum" as const,
	userMetadata_set_input:{
		created_at:"timestamptz",
		creditBalance:"numeric",
		id:"uuid",
		lastCreditRefill:"date",
		last_seen:"timestamp",
		updated_at:"timestamptz"
	},
	userMetadata_stddev_order_by:{
		creditBalance:"order_by"
	},
	userMetadata_stddev_pop_order_by:{
		creditBalance:"order_by"
	},
	userMetadata_stddev_samp_order_by:{
		creditBalance:"order_by"
	},
	userMetadata_stream_cursor_input:{
		initial_value:"userMetadata_stream_cursor_value_input",
		ordering:"cursor_ordering"
	},
	userMetadata_stream_cursor_value_input:{
		created_at:"timestamptz",
		creditBalance:"numeric",
		id:"uuid",
		lastCreditRefill:"date",
		last_seen:"timestamp",
		updated_at:"timestamptz"
	},
	userMetadata_sum_order_by:{
		creditBalance:"order_by"
	},
	userMetadata_update_column: "enum" as const,
	userMetadata_updates:{
		_inc:"userMetadata_inc_input",
		_set:"userMetadata_set_input",
		where:"userMetadata_bool_exp"
	},
	userMetadata_var_pop_order_by:{
		creditBalance:"order_by"
	},
	userMetadata_var_samp_order_by:{
		creditBalance:"order_by"
	},
	userMetadata_variance_order_by:{
		creditBalance:"order_by"
	},
	userOnboarding_aggregate_fields:{
		count:{
			columns:"userOnboarding_select_column"
		}
	},
	userOnboarding_bool_exp:{
		_and:"userOnboarding_bool_exp",
		_not:"userOnboarding_bool_exp",
		_or:"userOnboarding_bool_exp",
		behaviorProfile:"String_comparison_exp",
		brandName:"String_comparison_exp",
		clientPreference:"String_comparison_exp",
		companySegment:"String_comparison_exp",
		currentStep:"Int_comparison_exp",
		done:"Boolean_comparison_exp",
		expectation:"String_comparison_exp",
		importantSocialNetworkForBusiness:"String_comparison_exp",
		investInPaidChannels:"String_comparison_exp",
		marketingObjective:"String_comparison_exp",
		name:"String_comparison_exp",
		networkingOnCopyMagico:"String_comparison_exp",
		phone:"String_comparison_exp",
		productOrService:"String_comparison_exp",
		purchaseBehavior:"String_comparison_exp",
		purchaseChallenges:"String_comparison_exp",
		purchaseInfluencers:"String_comparison_exp",
		sellOnCopyMagico:"String_comparison_exp",
		siteUrl:"String_comparison_exp",
		socioeconomicProfile:"String_comparison_exp",
		staffCount:"String_comparison_exp",
		userId:"uuid_comparison_exp"
	},
	userOnboarding_constraint: "enum" as const,
	userOnboarding_inc_input:{

	},
	userOnboarding_insert_input:{
		userId:"uuid"
	},
	userOnboarding_on_conflict:{
		constraint:"userOnboarding_constraint",
		update_columns:"userOnboarding_update_column",
		where:"userOnboarding_bool_exp"
	},
	userOnboarding_order_by:{
		behaviorProfile:"order_by",
		brandName:"order_by",
		clientPreference:"order_by",
		companySegment:"order_by",
		currentStep:"order_by",
		done:"order_by",
		expectation:"order_by",
		importantSocialNetworkForBusiness:"order_by",
		investInPaidChannels:"order_by",
		marketingObjective:"order_by",
		name:"order_by",
		networkingOnCopyMagico:"order_by",
		phone:"order_by",
		productOrService:"order_by",
		purchaseBehavior:"order_by",
		purchaseChallenges:"order_by",
		purchaseInfluencers:"order_by",
		sellOnCopyMagico:"order_by",
		siteUrl:"order_by",
		socioeconomicProfile:"order_by",
		staffCount:"order_by",
		userId:"order_by"
	},
	userOnboarding_pk_columns_input:{
		userId:"uuid"
	},
	userOnboarding_select_column: "enum" as const,
	userOnboarding_set_input:{
		userId:"uuid"
	},
	userOnboarding_stream_cursor_input:{
		initial_value:"userOnboarding_stream_cursor_value_input",
		ordering:"cursor_ordering"
	},
	userOnboarding_stream_cursor_value_input:{
		userId:"uuid"
	},
	userOnboarding_update_column: "enum" as const,
	userOnboarding_updates:{
		_inc:"userOnboarding_inc_input",
		_set:"userOnboarding_set_input",
		where:"userOnboarding_bool_exp"
	},
	userTemplateUsage_aggregate_fields:{
		count:{
			columns:"userTemplateUsage_select_column"
		}
	},
	userTemplateUsage_bool_exp:{
		_and:"userTemplateUsage_bool_exp",
		_not:"userTemplateUsage_bool_exp",
		_or:"userTemplateUsage_bool_exp",
		created_at:"timestamptz_comparison_exp",
		created_by:"uuid_comparison_exp",
		id:"uuid_comparison_exp",
		textPromptTemplateId:"uuid_comparison_exp"
	},
	userTemplateUsage_constraint: "enum" as const,
	userTemplateUsage_insert_input:{
		created_at:"timestamptz",
		created_by:"uuid",
		id:"uuid",
		textPromptTemplateId:"uuid"
	},
	userTemplateUsage_on_conflict:{
		constraint:"userTemplateUsage_constraint",
		update_columns:"userTemplateUsage_update_column",
		where:"userTemplateUsage_bool_exp"
	},
	userTemplateUsage_order_by:{
		created_at:"order_by",
		created_by:"order_by",
		id:"order_by",
		textPromptTemplateId:"order_by"
	},
	userTemplateUsage_pk_columns_input:{
		id:"uuid"
	},
	userTemplateUsage_select_column: "enum" as const,
	userTemplateUsage_set_input:{
		created_at:"timestamptz",
		created_by:"uuid",
		id:"uuid",
		textPromptTemplateId:"uuid"
	},
	userTemplateUsage_stream_cursor_input:{
		initial_value:"userTemplateUsage_stream_cursor_value_input",
		ordering:"cursor_ordering"
	},
	userTemplateUsage_stream_cursor_value_input:{
		created_at:"timestamptz",
		created_by:"uuid",
		id:"uuid",
		textPromptTemplateId:"uuid"
	},
	userTemplateUsage_update_column: "enum" as const,
	userTemplateUsage_updates:{
		_set:"userTemplateUsage_set_input",
		where:"userTemplateUsage_bool_exp"
	},
	users:{
		metadata:{

		},
		refreshTokens:{
			distinct_on:"authRefreshTokens_select_column",
			order_by:"authRefreshTokens_order_by",
			where:"authRefreshTokens_bool_exp"
		},
		refreshTokens_aggregate:{
			distinct_on:"authRefreshTokens_select_column",
			order_by:"authRefreshTokens_order_by",
			where:"authRefreshTokens_bool_exp"
		},
		roles:{
			distinct_on:"authUserRoles_select_column",
			order_by:"authUserRoles_order_by",
			where:"authUserRoles_bool_exp"
		},
		roles_aggregate:{
			distinct_on:"authUserRoles_select_column",
			order_by:"authUserRoles_order_by",
			where:"authUserRoles_bool_exp"
		},
		securityKeys:{
			distinct_on:"authUserSecurityKeys_select_column",
			order_by:"authUserSecurityKeys_order_by",
			where:"authUserSecurityKeys_bool_exp"
		},
		securityKeys_aggregate:{
			distinct_on:"authUserSecurityKeys_select_column",
			order_by:"authUserSecurityKeys_order_by",
			where:"authUserSecurityKeys_bool_exp"
		},
		userMetadata:{
			distinct_on:"userMetadata_select_column",
			order_by:"userMetadata_order_by",
			where:"userMetadata_bool_exp"
		},
		userMetadata_aggregate:{
			distinct_on:"userMetadata_select_column",
			order_by:"userMetadata_order_by",
			where:"userMetadata_bool_exp"
		},
		userProviders:{
			distinct_on:"authUserProviders_select_column",
			order_by:"authUserProviders_order_by",
			where:"authUserProviders_bool_exp"
		},
		userProviders_aggregate:{
			distinct_on:"authUserProviders_select_column",
			order_by:"authUserProviders_order_by",
			where:"authUserProviders_bool_exp"
		}
	},
	users_aggregate_bool_exp:{
		bool_and:"users_aggregate_bool_exp_bool_and",
		bool_or:"users_aggregate_bool_exp_bool_or",
		count:"users_aggregate_bool_exp_count"
	},
	users_aggregate_bool_exp_bool_and:{
		arguments:"users_select_column_users_aggregate_bool_exp_bool_and_arguments_columns",
		filter:"users_bool_exp",
		predicate:"Boolean_comparison_exp"
	},
	users_aggregate_bool_exp_bool_or:{
		arguments:"users_select_column_users_aggregate_bool_exp_bool_or_arguments_columns",
		filter:"users_bool_exp",
		predicate:"Boolean_comparison_exp"
	},
	users_aggregate_bool_exp_count:{
		arguments:"users_select_column",
		filter:"users_bool_exp",
		predicate:"Int_comparison_exp"
	},
	users_aggregate_fields:{
		count:{
			columns:"users_select_column"
		}
	},
	users_aggregate_order_by:{
		count:"order_by",
		max:"users_max_order_by",
		min:"users_min_order_by"
	},
	users_append_input:{
		metadata:"jsonb"
	},
	users_arr_rel_insert_input:{
		data:"users_insert_input",
		on_conflict:"users_on_conflict"
	},
	users_bool_exp:{
		_and:"users_bool_exp",
		_not:"users_bool_exp",
		_or:"users_bool_exp",
		activeMfaType:"String_comparison_exp",
		avatarUrl:"String_comparison_exp",
		createdAt:"timestamptz_comparison_exp",
		currentChallenge:"String_comparison_exp",
		defaultRole:"String_comparison_exp",
		defaultRoleByRole:"authRoles_bool_exp",
		disabled:"Boolean_comparison_exp",
		displayName:"String_comparison_exp",
		email:"citext_comparison_exp",
		emailVerified:"Boolean_comparison_exp",
		id:"uuid_comparison_exp",
		isAnonymous:"Boolean_comparison_exp",
		lastSeen:"timestamptz_comparison_exp",
		locale:"String_comparison_exp",
		metadata:"jsonb_comparison_exp",
		newEmail:"citext_comparison_exp",
		otpHash:"String_comparison_exp",
		otpHashExpiresAt:"timestamptz_comparison_exp",
		otpMethodLastUsed:"String_comparison_exp",
		passwordHash:"String_comparison_exp",
		phoneNumber:"String_comparison_exp",
		phoneNumberVerified:"Boolean_comparison_exp",
		refreshTokens:"authRefreshTokens_bool_exp",
		refreshTokens_aggregate:"authRefreshTokens_aggregate_bool_exp",
		roles:"authUserRoles_bool_exp",
		roles_aggregate:"authUserRoles_aggregate_bool_exp",
		securityKeys:"authUserSecurityKeys_bool_exp",
		securityKeys_aggregate:"authUserSecurityKeys_aggregate_bool_exp",
		ticket:"String_comparison_exp",
		ticketExpiresAt:"timestamptz_comparison_exp",
		totpSecret:"String_comparison_exp",
		updatedAt:"timestamptz_comparison_exp",
		userMetadata:"userMetadata_bool_exp",
		userMetadata_aggregate:"userMetadata_aggregate_bool_exp",
		userProviders:"authUserProviders_bool_exp",
		userProviders_aggregate:"authUserProviders_aggregate_bool_exp"
	},
	users_constraint: "enum" as const,
	users_delete_at_path_input:{

	},
	users_delete_elem_input:{

	},
	users_delete_key_input:{

	},
	users_insert_input:{
		createdAt:"timestamptz",
		defaultRoleByRole:"authRoles_obj_rel_insert_input",
		email:"citext",
		id:"uuid",
		lastSeen:"timestamptz",
		metadata:"jsonb",
		newEmail:"citext",
		otpHashExpiresAt:"timestamptz",
		refreshTokens:"authRefreshTokens_arr_rel_insert_input",
		roles:"authUserRoles_arr_rel_insert_input",
		securityKeys:"authUserSecurityKeys_arr_rel_insert_input",
		ticketExpiresAt:"timestamptz",
		updatedAt:"timestamptz",
		userMetadata:"userMetadata_arr_rel_insert_input",
		userProviders:"authUserProviders_arr_rel_insert_input"
	},
	users_max_order_by:{
		activeMfaType:"order_by",
		avatarUrl:"order_by",
		createdAt:"order_by",
		currentChallenge:"order_by",
		defaultRole:"order_by",
		displayName:"order_by",
		email:"order_by",
		id:"order_by",
		lastSeen:"order_by",
		locale:"order_by",
		newEmail:"order_by",
		otpHash:"order_by",
		otpHashExpiresAt:"order_by",
		otpMethodLastUsed:"order_by",
		passwordHash:"order_by",
		phoneNumber:"order_by",
		ticket:"order_by",
		ticketExpiresAt:"order_by",
		totpSecret:"order_by",
		updatedAt:"order_by"
	},
	users_min_order_by:{
		activeMfaType:"order_by",
		avatarUrl:"order_by",
		createdAt:"order_by",
		currentChallenge:"order_by",
		defaultRole:"order_by",
		displayName:"order_by",
		email:"order_by",
		id:"order_by",
		lastSeen:"order_by",
		locale:"order_by",
		newEmail:"order_by",
		otpHash:"order_by",
		otpHashExpiresAt:"order_by",
		otpMethodLastUsed:"order_by",
		passwordHash:"order_by",
		phoneNumber:"order_by",
		ticket:"order_by",
		ticketExpiresAt:"order_by",
		totpSecret:"order_by",
		updatedAt:"order_by"
	},
	users_obj_rel_insert_input:{
		data:"users_insert_input",
		on_conflict:"users_on_conflict"
	},
	users_on_conflict:{
		constraint:"users_constraint",
		update_columns:"users_update_column",
		where:"users_bool_exp"
	},
	users_order_by:{
		activeMfaType:"order_by",
		avatarUrl:"order_by",
		createdAt:"order_by",
		currentChallenge:"order_by",
		defaultRole:"order_by",
		defaultRoleByRole:"authRoles_order_by",
		disabled:"order_by",
		displayName:"order_by",
		email:"order_by",
		emailVerified:"order_by",
		id:"order_by",
		isAnonymous:"order_by",
		lastSeen:"order_by",
		locale:"order_by",
		metadata:"order_by",
		newEmail:"order_by",
		otpHash:"order_by",
		otpHashExpiresAt:"order_by",
		otpMethodLastUsed:"order_by",
		passwordHash:"order_by",
		phoneNumber:"order_by",
		phoneNumberVerified:"order_by",
		refreshTokens_aggregate:"authRefreshTokens_aggregate_order_by",
		roles_aggregate:"authUserRoles_aggregate_order_by",
		securityKeys_aggregate:"authUserSecurityKeys_aggregate_order_by",
		ticket:"order_by",
		ticketExpiresAt:"order_by",
		totpSecret:"order_by",
		updatedAt:"order_by",
		userMetadata_aggregate:"userMetadata_aggregate_order_by",
		userProviders_aggregate:"authUserProviders_aggregate_order_by"
	},
	users_pk_columns_input:{
		id:"uuid"
	},
	users_prepend_input:{
		metadata:"jsonb"
	},
	users_select_column: "enum" as const,
	users_select_column_users_aggregate_bool_exp_bool_and_arguments_columns: "enum" as const,
	users_select_column_users_aggregate_bool_exp_bool_or_arguments_columns: "enum" as const,
	users_set_input:{
		createdAt:"timestamptz",
		email:"citext",
		id:"uuid",
		lastSeen:"timestamptz",
		metadata:"jsonb",
		newEmail:"citext",
		otpHashExpiresAt:"timestamptz",
		ticketExpiresAt:"timestamptz",
		updatedAt:"timestamptz"
	},
	users_stream_cursor_input:{
		initial_value:"users_stream_cursor_value_input",
		ordering:"cursor_ordering"
	},
	users_stream_cursor_value_input:{
		createdAt:"timestamptz",
		email:"citext",
		id:"uuid",
		lastSeen:"timestamptz",
		metadata:"jsonb",
		newEmail:"citext",
		otpHashExpiresAt:"timestamptz",
		ticketExpiresAt:"timestamptz",
		updatedAt:"timestamptz"
	},
	users_update_column: "enum" as const,
	users_updates:{
		_append:"users_append_input",
		_delete_at_path:"users_delete_at_path_input",
		_delete_elem:"users_delete_elem_input",
		_delete_key:"users_delete_key_input",
		_prepend:"users_prepend_input",
		_set:"users_set_input",
		where:"users_bool_exp"
	},
	uuid: `scalar.uuid` as const,
	uuid_comparison_exp:{
		_eq:"uuid",
		_gt:"uuid",
		_gte:"uuid",
		_in:"uuid",
		_lt:"uuid",
		_lte:"uuid",
		_neq:"uuid",
		_nin:"uuid"
	},
	virus:{
		userSession:{

		}
	},
	virus_aggregate_fields:{
		count:{
			columns:"virus_select_column"
		}
	},
	virus_append_input:{
		userSession:"jsonb"
	},
	virus_bool_exp:{
		_and:"virus_bool_exp",
		_not:"virus_bool_exp",
		_or:"virus_bool_exp",
		createdAt:"timestamptz_comparison_exp",
		file:"files_bool_exp",
		fileId:"uuid_comparison_exp",
		filename:"String_comparison_exp",
		id:"uuid_comparison_exp",
		updatedAt:"timestamptz_comparison_exp",
		userSession:"jsonb_comparison_exp",
		virus:"String_comparison_exp"
	},
	virus_constraint: "enum" as const,
	virus_delete_at_path_input:{

	},
	virus_delete_elem_input:{

	},
	virus_delete_key_input:{

	},
	virus_insert_input:{
		createdAt:"timestamptz",
		file:"files_obj_rel_insert_input",
		fileId:"uuid",
		id:"uuid",
		updatedAt:"timestamptz",
		userSession:"jsonb"
	},
	virus_on_conflict:{
		constraint:"virus_constraint",
		update_columns:"virus_update_column",
		where:"virus_bool_exp"
	},
	virus_order_by:{
		createdAt:"order_by",
		file:"files_order_by",
		fileId:"order_by",
		filename:"order_by",
		id:"order_by",
		updatedAt:"order_by",
		userSession:"order_by",
		virus:"order_by"
	},
	virus_pk_columns_input:{
		id:"uuid"
	},
	virus_prepend_input:{
		userSession:"jsonb"
	},
	virus_select_column: "enum" as const,
	virus_set_input:{
		createdAt:"timestamptz",
		fileId:"uuid",
		id:"uuid",
		updatedAt:"timestamptz",
		userSession:"jsonb"
	},
	virus_stream_cursor_input:{
		initial_value:"virus_stream_cursor_value_input",
		ordering:"cursor_ordering"
	},
	virus_stream_cursor_value_input:{
		createdAt:"timestamptz",
		fileId:"uuid",
		id:"uuid",
		updatedAt:"timestamptz",
		userSession:"jsonb"
	},
	virus_update_column: "enum" as const,
	virus_updates:{
		_append:"virus_append_input",
		_delete_at_path:"virus_delete_at_path_input",
		_delete_elem:"virus_delete_elem_input",
		_delete_key:"virus_delete_key_input",
		_prepend:"virus_prepend_input",
		_set:"virus_set_input",
		where:"virus_bool_exp"
	},
	website_aggregate_fields:{
		count:{
			columns:"website_select_column"
		}
	},
	website_bool_exp:{
		_and:"website_bool_exp",
		_not:"website_bool_exp",
		_or:"website_bool_exp",
		aboutUsContactBannerImageFileId:"uuid_comparison_exp",
		aboutUsContactTitle:"String_comparison_exp",
		aboutUsHistoryBannerImageFileId:"uuid_comparison_exp",
		aboutUsHistoryDescription:"String_comparison_exp",
		aboutUsHistoryTitle:"String_comparison_exp",
		aboutUsHistorytKeypointSubtitleOne:"String_comparison_exp",
		aboutUsHistorytKeypointSubtitleTwo:"String_comparison_exp",
		aboutUsHistorytKeypointTitleOne:"String_comparison_exp",
		aboutUsHistorytKeypointTitleTwo:"String_comparison_exp",
		aboutUsStatementBannerImageFileId:"uuid_comparison_exp",
		aboutUsStatementBannerText:"String_comparison_exp",
		aboutUsWeAreReadyBannerImageId:"uuid_comparison_exp",
		aboutUsWeAreReadyCustomerSatisfactionDescription:"String_comparison_exp",
		aboutUsWeAreReadyInnovationDescription:"String_comparison_exp",
		aboutUsWeAreReadySocialResponsibilityDescription:"String_comparison_exp",
		aboutUsWeAreReadySpecializedSupportDescription:"String_comparison_exp",
		aboutUsWeAreReadySubjectMatterExpertiseDescription:"String_comparison_exp",
		aboutUsWeAreReadyTitle:"String_comparison_exp",
		aboutUsWeAreReadyTransparencyDescription:"String_comparison_exp",
		aboutUsWhatMoveBannerImageFileId:"uuid_comparison_exp",
		aboutUsWhatMoveUsMission:"String_comparison_exp",
		aboutUsWhatMoveUsPurpose:"String_comparison_exp",
		aboutUsWhatMoveUsTitle:"String_comparison_exp",
		aboutUsWhatMoveUsValues:"String_comparison_exp",
		aboutUsWhatMoveUsVision:"String_comparison_exp",
		accentColor:"String_comparison_exp",
		backgroundColor:"String_comparison_exp",
		brand:"brands_bool_exp",
		brandId:"uuid_comparison_exp",
		created_at:"timestamptz_comparison_exp",
		domain:"String_comparison_exp",
		foregroundColor:"String_comparison_exp",
		googleAnalyticsKey:"String_comparison_exp",
		googleTagManagerKey:"String_comparison_exp",
		homeBrandHighlightBannerImageFileId:"uuid_comparison_exp",
		homeBrandHighlightDescription:"String_comparison_exp",
		homeBrandHighlightKeypointSubtitleOne:"String_comparison_exp",
		homeBrandHighlightKeypointSubtitleTwo:"String_comparison_exp",
		homeBrandHighlightKeypointTitleOne:"String_comparison_exp",
		homeBrandHighlightKeypointTitleTwo:"String_comparison_exp",
		homeBrandHighlightKeypointValuePropositionList:"String_array_comparison_exp",
		homeBrandHighlightTitle:"String_comparison_exp",
		homeHeroBannerImageFileId:"uuid_comparison_exp",
		homeHeroCallToActionTargetUrl:"String_comparison_exp",
		homeHeroCallToActionText:"String_comparison_exp",
		homeHeroDescription:"String_comparison_exp",
		homeHeroLabel:"String_comparison_exp",
		homeHeroTitle:"String_comparison_exp",
		homeOurDifferentiatorsBannerImageFileId:"uuid_comparison_exp",
		homeOurDifferentiatorsDescription:"String_comparison_exp",
		homeOurDifferentiatorsKeypointSubtitleOne:"String_comparison_exp",
		homeOurDifferentiatorsKeypointSubtitleTwo:"String_comparison_exp",
		homeOurDifferentiatorsKeypointTitleOne:"String_comparison_exp",
		homeOurDifferentiatorsKeypointTitleTwo:"String_comparison_exp",
		homeOurDifferentiatorsTitle:"String_comparison_exp",
		homeWhatWeDoCallToActionTargetUrl:"String_comparison_exp",
		homeWhatWeDoCallToActionText:"String_comparison_exp",
		homeWhatWeDoCallToBannerOneImageFileId:"uuid_comparison_exp",
		homeWhatWeDoCallToBannerOneLegend:"String_comparison_exp",
		homeWhatWeDoCallToBannerThreeImageFileId:"uuid_comparison_exp",
		homeWhatWeDoCallToBannerThreeLegend:"String_comparison_exp",
		homeWhatWeDoCallToBannerTwoImageFileId:"uuid_comparison_exp",
		homeWhatWeDoCallToBannerTwoLegend:"String_comparison_exp",
		homeWhatWeDoDescription:"String_comparison_exp",
		homeWhatWeDoTitle:"String_comparison_exp",
		id:"uuid_comparison_exp",
		openGraphDescription:"String_comparison_exp",
		updated_at:"timestamptz_comparison_exp"
	},
	website_constraint: "enum" as const,
	website_insert_input:{
		aboutUsContactBannerImageFileId:"uuid",
		aboutUsHistoryBannerImageFileId:"uuid",
		aboutUsStatementBannerImageFileId:"uuid",
		aboutUsWeAreReadyBannerImageId:"uuid",
		aboutUsWhatMoveBannerImageFileId:"uuid",
		brand:"brands_obj_rel_insert_input",
		brandId:"uuid",
		created_at:"timestamptz",
		homeBrandHighlightBannerImageFileId:"uuid",
		homeHeroBannerImageFileId:"uuid",
		homeOurDifferentiatorsBannerImageFileId:"uuid",
		homeWhatWeDoCallToBannerOneImageFileId:"uuid",
		homeWhatWeDoCallToBannerThreeImageFileId:"uuid",
		homeWhatWeDoCallToBannerTwoImageFileId:"uuid",
		id:"uuid",
		updated_at:"timestamptz"
	},
	website_on_conflict:{
		constraint:"website_constraint",
		update_columns:"website_update_column",
		where:"website_bool_exp"
	},
	website_order_by:{
		aboutUsContactBannerImageFileId:"order_by",
		aboutUsContactTitle:"order_by",
		aboutUsHistoryBannerImageFileId:"order_by",
		aboutUsHistoryDescription:"order_by",
		aboutUsHistoryTitle:"order_by",
		aboutUsHistorytKeypointSubtitleOne:"order_by",
		aboutUsHistorytKeypointSubtitleTwo:"order_by",
		aboutUsHistorytKeypointTitleOne:"order_by",
		aboutUsHistorytKeypointTitleTwo:"order_by",
		aboutUsStatementBannerImageFileId:"order_by",
		aboutUsStatementBannerText:"order_by",
		aboutUsWeAreReadyBannerImageId:"order_by",
		aboutUsWeAreReadyCustomerSatisfactionDescription:"order_by",
		aboutUsWeAreReadyInnovationDescription:"order_by",
		aboutUsWeAreReadySocialResponsibilityDescription:"order_by",
		aboutUsWeAreReadySpecializedSupportDescription:"order_by",
		aboutUsWeAreReadySubjectMatterExpertiseDescription:"order_by",
		aboutUsWeAreReadyTitle:"order_by",
		aboutUsWeAreReadyTransparencyDescription:"order_by",
		aboutUsWhatMoveBannerImageFileId:"order_by",
		aboutUsWhatMoveUsMission:"order_by",
		aboutUsWhatMoveUsPurpose:"order_by",
		aboutUsWhatMoveUsTitle:"order_by",
		aboutUsWhatMoveUsValues:"order_by",
		aboutUsWhatMoveUsVision:"order_by",
		accentColor:"order_by",
		backgroundColor:"order_by",
		brand:"brands_order_by",
		brandId:"order_by",
		created_at:"order_by",
		domain:"order_by",
		foregroundColor:"order_by",
		googleAnalyticsKey:"order_by",
		googleTagManagerKey:"order_by",
		homeBrandHighlightBannerImageFileId:"order_by",
		homeBrandHighlightDescription:"order_by",
		homeBrandHighlightKeypointSubtitleOne:"order_by",
		homeBrandHighlightKeypointSubtitleTwo:"order_by",
		homeBrandHighlightKeypointTitleOne:"order_by",
		homeBrandHighlightKeypointTitleTwo:"order_by",
		homeBrandHighlightKeypointValuePropositionList:"order_by",
		homeBrandHighlightTitle:"order_by",
		homeHeroBannerImageFileId:"order_by",
		homeHeroCallToActionTargetUrl:"order_by",
		homeHeroCallToActionText:"order_by",
		homeHeroDescription:"order_by",
		homeHeroLabel:"order_by",
		homeHeroTitle:"order_by",
		homeOurDifferentiatorsBannerImageFileId:"order_by",
		homeOurDifferentiatorsDescription:"order_by",
		homeOurDifferentiatorsKeypointSubtitleOne:"order_by",
		homeOurDifferentiatorsKeypointSubtitleTwo:"order_by",
		homeOurDifferentiatorsKeypointTitleOne:"order_by",
		homeOurDifferentiatorsKeypointTitleTwo:"order_by",
		homeOurDifferentiatorsTitle:"order_by",
		homeWhatWeDoCallToActionTargetUrl:"order_by",
		homeWhatWeDoCallToActionText:"order_by",
		homeWhatWeDoCallToBannerOneImageFileId:"order_by",
		homeWhatWeDoCallToBannerOneLegend:"order_by",
		homeWhatWeDoCallToBannerThreeImageFileId:"order_by",
		homeWhatWeDoCallToBannerThreeLegend:"order_by",
		homeWhatWeDoCallToBannerTwoImageFileId:"order_by",
		homeWhatWeDoCallToBannerTwoLegend:"order_by",
		homeWhatWeDoDescription:"order_by",
		homeWhatWeDoTitle:"order_by",
		id:"order_by",
		openGraphDescription:"order_by",
		updated_at:"order_by"
	},
	website_pk_columns_input:{
		id:"uuid"
	},
	website_select_column: "enum" as const,
	website_set_input:{
		aboutUsContactBannerImageFileId:"uuid",
		aboutUsHistoryBannerImageFileId:"uuid",
		aboutUsStatementBannerImageFileId:"uuid",
		aboutUsWeAreReadyBannerImageId:"uuid",
		aboutUsWhatMoveBannerImageFileId:"uuid",
		brandId:"uuid",
		created_at:"timestamptz",
		homeBrandHighlightBannerImageFileId:"uuid",
		homeHeroBannerImageFileId:"uuid",
		homeOurDifferentiatorsBannerImageFileId:"uuid",
		homeWhatWeDoCallToBannerOneImageFileId:"uuid",
		homeWhatWeDoCallToBannerThreeImageFileId:"uuid",
		homeWhatWeDoCallToBannerTwoImageFileId:"uuid",
		id:"uuid",
		updated_at:"timestamptz"
	},
	website_stream_cursor_input:{
		initial_value:"website_stream_cursor_value_input",
		ordering:"cursor_ordering"
	},
	website_stream_cursor_value_input:{
		aboutUsContactBannerImageFileId:"uuid",
		aboutUsHistoryBannerImageFileId:"uuid",
		aboutUsStatementBannerImageFileId:"uuid",
		aboutUsWeAreReadyBannerImageId:"uuid",
		aboutUsWhatMoveBannerImageFileId:"uuid",
		brandId:"uuid",
		created_at:"timestamptz",
		homeBrandHighlightBannerImageFileId:"uuid",
		homeHeroBannerImageFileId:"uuid",
		homeOurDifferentiatorsBannerImageFileId:"uuid",
		homeWhatWeDoCallToBannerOneImageFileId:"uuid",
		homeWhatWeDoCallToBannerThreeImageFileId:"uuid",
		homeWhatWeDoCallToBannerTwoImageFileId:"uuid",
		id:"uuid",
		updated_at:"timestamptz"
	},
	website_update_column: "enum" as const,
	website_updates:{
		_set:"website_set_input",
		where:"website_bool_exp"
	}
}

export const ReturnTypes: Record<string,any> = {
	cached:{
		ttl:"Int",
		refresh:"Boolean"
	},
	AIImageResult:{
		id:"String",
		url:"String"
	},
	AppIntegration:{
		configParams:"jsonb",
		description:"String",
		icon:"String",
		id:"uuid",
		name:"String",
		type:"String"
	},
	AppIntegration_aggregate:{
		aggregate:"AppIntegration_aggregate_fields",
		nodes:"AppIntegration"
	},
	AppIntegration_aggregate_fields:{
		count:"Int",
		max:"AppIntegration_max_fields",
		min:"AppIntegration_min_fields"
	},
	AppIntegration_max_fields:{
		description:"String",
		icon:"String",
		id:"uuid",
		name:"String",
		type:"String"
	},
	AppIntegration_min_fields:{
		description:"String",
		icon:"String",
		id:"uuid",
		name:"String",
		type:"String"
	},
	AppIntegration_mutation_response:{
		affected_rows:"Int",
		returning:"AppIntegration"
	},
	Category:{
		id:"String",
		name:"String"
	},
	Course:{
		courseCategoryId:"bigint",
		description:"String",
		id:"uuid",
		imageUrl:"String",
		name:"String"
	},
	CourseCategory:{
		id:"bigint",
		name:"String"
	},
	CourseCategory_aggregate:{
		aggregate:"CourseCategory_aggregate_fields",
		nodes:"CourseCategory"
	},
	CourseCategory_aggregate_fields:{
		avg:"CourseCategory_avg_fields",
		count:"Int",
		max:"CourseCategory_max_fields",
		min:"CourseCategory_min_fields",
		stddev:"CourseCategory_stddev_fields",
		stddev_pop:"CourseCategory_stddev_pop_fields",
		stddev_samp:"CourseCategory_stddev_samp_fields",
		sum:"CourseCategory_sum_fields",
		var_pop:"CourseCategory_var_pop_fields",
		var_samp:"CourseCategory_var_samp_fields",
		variance:"CourseCategory_variance_fields"
	},
	CourseCategory_avg_fields:{
		id:"Float"
	},
	CourseCategory_max_fields:{
		id:"bigint",
		name:"String"
	},
	CourseCategory_min_fields:{
		id:"bigint",
		name:"String"
	},
	CourseCategory_mutation_response:{
		affected_rows:"Int",
		returning:"CourseCategory"
	},
	CourseCategory_stddev_fields:{
		id:"Float"
	},
	CourseCategory_stddev_pop_fields:{
		id:"Float"
	},
	CourseCategory_stddev_samp_fields:{
		id:"Float"
	},
	CourseCategory_sum_fields:{
		id:"bigint"
	},
	CourseCategory_var_pop_fields:{
		id:"Float"
	},
	CourseCategory_var_samp_fields:{
		id:"Float"
	},
	CourseCategory_variance_fields:{
		id:"Float"
	},
	Course_aggregate:{
		aggregate:"Course_aggregate_fields",
		nodes:"Course"
	},
	Course_aggregate_fields:{
		avg:"Course_avg_fields",
		count:"Int",
		max:"Course_max_fields",
		min:"Course_min_fields",
		stddev:"Course_stddev_fields",
		stddev_pop:"Course_stddev_pop_fields",
		stddev_samp:"Course_stddev_samp_fields",
		sum:"Course_sum_fields",
		var_pop:"Course_var_pop_fields",
		var_samp:"Course_var_samp_fields",
		variance:"Course_variance_fields"
	},
	Course_avg_fields:{
		courseCategoryId:"Float"
	},
	Course_max_fields:{
		courseCategoryId:"bigint",
		description:"String",
		id:"uuid",
		imageUrl:"String",
		name:"String"
	},
	Course_min_fields:{
		courseCategoryId:"bigint",
		description:"String",
		id:"uuid",
		imageUrl:"String",
		name:"String"
	},
	Course_mutation_response:{
		affected_rows:"Int",
		returning:"Course"
	},
	Course_stddev_fields:{
		courseCategoryId:"Float"
	},
	Course_stddev_pop_fields:{
		courseCategoryId:"Float"
	},
	Course_stddev_samp_fields:{
		courseCategoryId:"Float"
	},
	Course_sum_fields:{
		courseCategoryId:"bigint"
	},
	Course_var_pop_fields:{
		courseCategoryId:"Float"
	},
	Course_var_samp_fields:{
		courseCategoryId:"Float"
	},
	Course_variance_fields:{
		courseCategoryId:"Float"
	},
	CreateAutomaticProjectOutput:{
		projectId:"String"
	},
	CreateImageUsingImagineOutput:{
		errorMessage:"String",
		fileId:"String",
		success:"Boolean"
	},
	ExportToWordpressOutput:{
		url:"String"
	},
	FacebookAuthCallbackOutput:{
		success:"Boolean"
	},
	FinishOnboardOutput:{
		success:"Boolean"
	},
	GenerateCalendarExOutput:{
		success:"Boolean"
	},
	GenerateFromTextTemplateOutput:{
		text:"String"
	},
	GenerateImageFromPromptOutput:{
		images:"AIImageResult"
	},
	GenerateNextMonthCalendarOutput:{
		created:"Boolean"
	},
	GeneratedPromptByTemplate:{
		GeneratedPromptByTemplateImages:"GeneratedPromptByTemplateImage",
		GeneratedPromptByTemplateImages_aggregate:"GeneratedPromptByTemplateImage_aggregate",
		TextPromptTemplate:"TextPromptTemplate",
		active:"Boolean",
		alias:"String",
		backgroundImageFileId:"uuid",
		channel:"String",
		command:"jsonb",
		created_at:"timestamptz",
		created_by:"uuid",
		displayFormat:"displayFormats",
		displayImageUrl:"String",
		displayMapper:"jsonb",
		generatingDisplayStatus:"String",
		id:"uuid",
		pricingPlan:"pricingPlan",
		pricingPlanId:"uuid",
		publishDate:"timestamptz",
		result:"String",
		scheduledDate:"timestamptz",
		scheduledDateEventId:"uuid",
		selectedDisplayFormatId:"uuid",
		status:"String",
		textPromptTemplateId:"uuid",
		updated_at:"timestamptz"
	},
	GeneratedPromptByTemplateImage:{
		GeneratedPromptByTemplateId:"uuid",
		fileId:"uuid",
		id:"uuid"
	},
	GeneratedPromptByTemplateImage_aggregate:{
		aggregate:"GeneratedPromptByTemplateImage_aggregate_fields",
		nodes:"GeneratedPromptByTemplateImage"
	},
	GeneratedPromptByTemplateImage_aggregate_fields:{
		count:"Int",
		max:"GeneratedPromptByTemplateImage_max_fields",
		min:"GeneratedPromptByTemplateImage_min_fields"
	},
	GeneratedPromptByTemplateImage_max_fields:{
		GeneratedPromptByTemplateId:"uuid",
		fileId:"uuid",
		id:"uuid"
	},
	GeneratedPromptByTemplateImage_min_fields:{
		GeneratedPromptByTemplateId:"uuid",
		fileId:"uuid",
		id:"uuid"
	},
	GeneratedPromptByTemplateImage_mutation_response:{
		affected_rows:"Int",
		returning:"GeneratedPromptByTemplateImage"
	},
	GeneratedPromptByTemplate_aggregate:{
		aggregate:"GeneratedPromptByTemplate_aggregate_fields",
		nodes:"GeneratedPromptByTemplate"
	},
	GeneratedPromptByTemplate_aggregate_fields:{
		count:"Int",
		max:"GeneratedPromptByTemplate_max_fields",
		min:"GeneratedPromptByTemplate_min_fields"
	},
	GeneratedPromptByTemplate_max_fields:{
		alias:"String",
		backgroundImageFileId:"uuid",
		channel:"String",
		created_at:"timestamptz",
		created_by:"uuid",
		displayImageUrl:"String",
		generatingDisplayStatus:"String",
		id:"uuid",
		pricingPlanId:"uuid",
		publishDate:"timestamptz",
		result:"String",
		scheduledDate:"timestamptz",
		scheduledDateEventId:"uuid",
		selectedDisplayFormatId:"uuid",
		status:"String",
		textPromptTemplateId:"uuid",
		updated_at:"timestamptz"
	},
	GeneratedPromptByTemplate_min_fields:{
		alias:"String",
		backgroundImageFileId:"uuid",
		channel:"String",
		created_at:"timestamptz",
		created_by:"uuid",
		displayImageUrl:"String",
		generatingDisplayStatus:"String",
		id:"uuid",
		pricingPlanId:"uuid",
		publishDate:"timestamptz",
		result:"String",
		scheduledDate:"timestamptz",
		scheduledDateEventId:"uuid",
		selectedDisplayFormatId:"uuid",
		status:"String",
		textPromptTemplateId:"uuid",
		updated_at:"timestamptz"
	},
	GeneratedPromptByTemplate_mutation_response:{
		affected_rows:"Int",
		returning:"GeneratedPromptByTemplate"
	},
	GetFacebookPagesOutput:{
		pages:"facebookPage"
	},
	GetFacebookTokenOutput:{
		loginUrl:"String"
	},
	GetImageFromDisplayRendererOutput:{
		image:"String"
	},
	GetInstagramAuthCallbackOutput:{
		success:"Boolean"
	},
	GetInstagramPagesOutput:{
		accounts:"instagramPageAccount"
	},
	GetInstagramTokenOutput:{
		loginUrl:"String"
	},
	GetPaymentUrlResponse:{
		url:"String"
	},
	GetWordpressCategoriesOutput:{
		categories:"Category"
	},
	GetWordpressTagsOutput:{
		tags:"Tag"
	},
	LinkedInTokenCallbackOutput:{
		success:"Boolean"
	},
	ManageSubscriptionOutput:{
		url:"String"
	},
	PexelsImageResult:{
		alt:"String",
		height:"String",
		id:"String",
		photographer:"String",
		photographer_url:"String",
		small:"String",
		url:"String",
		width:"String"
	},
	PostCarouselToInstagramOutput:{
		error:"String",
		success:"Boolean"
	},
	PostFeedToInstagramOutput:{
		error:"String",
		success:"Boolean"
	},
	PostStoryToInstagramOutput:{
		error:"String",
		success:"Boolean"
	},
	PostToFacebookOutput:{
		success:"Boolean"
	},
	PostToLinkedInOutput:{
		success:"Boolean"
	},
	PromptOutput:{
		text:"String"
	},
	PublishOrScheduleProjectOutput:{
		errorMessage:"String",
		success:"Boolean"
	},
	RenderAndSaveDisplayImageOutput:{
		image:"String"
	},
	RenderDisplayOutput:{
		image:"String"
	},
	SaveAndGeneratePolotnoImageOutput:{
		message:"String",
		success:"Boolean"
	},
	SearchImagesFromPexelsResponse:{
		images:"PexelsImageResult"
	},
	SelectNewDisplayOutput:{
		error:"String",
		id:"String"
	},
	SerpStatTestInputData:{
		ad_keywords:"Float",
		ads:"Float",
		ads_dynamic:"Float",
		domain:"String",
		down_keywords:"Float",
		keywords:"Float",
		keywords_dynamic:"Float",
		new_keywords:"Float",
		out_keywords:"Float",
		prev_date:"String",
		rised_keywords:"Float",
		traff:"Float",
		traff_dynamic:"Float",
		visible:"Float",
		visible_dynamic:"Float"
	},
	SerpStatTestInputResult:{
		data:"SerpStatTestInputData",
		summary_info:"SerpStatTestInputSummaryInfo"
	},
	SerpStatTestInputSummaryInfo:{
		left_lines:"Float",
		page:"Float"
	},
	SerpStatTestOutput:{
		data:"SerpStatTestOutputData"
	},
	SerpStatTestOutputData:{
		id:"String",
		result:"SerpStatTestInputResult"
	},
	Tag:{
		id:"String",
		name:"String"
	},
	TextGeneratorOption:{
		Label:"jsonb",
		TextGeneratorOptionEntries:"TextGeneratorOptionEntry",
		TextGeneratorOptionEntries_aggregate:"TextGeneratorOptionEntry_aggregate",
		created_at:"timestamptz",
		id:"uuid",
		popover_tooltip:"jsonb",
		updated_at:"timestamptz"
	},
	TextGeneratorOptionEntry:{
		Label:"jsonb",
		TextGeneratorOptionId:"uuid",
		id:"uuid"
	},
	TextGeneratorOptionEntry_aggregate:{
		aggregate:"TextGeneratorOptionEntry_aggregate_fields",
		nodes:"TextGeneratorOptionEntry"
	},
	TextGeneratorOptionEntry_aggregate_fields:{
		count:"Int",
		max:"TextGeneratorOptionEntry_max_fields",
		min:"TextGeneratorOptionEntry_min_fields"
	},
	TextGeneratorOptionEntry_max_fields:{
		TextGeneratorOptionId:"uuid",
		id:"uuid"
	},
	TextGeneratorOptionEntry_min_fields:{
		TextGeneratorOptionId:"uuid",
		id:"uuid"
	},
	TextGeneratorOptionEntry_mutation_response:{
		affected_rows:"Int",
		returning:"TextGeneratorOptionEntry"
	},
	TextGeneratorOption_aggregate:{
		aggregate:"TextGeneratorOption_aggregate_fields",
		nodes:"TextGeneratorOption"
	},
	TextGeneratorOption_aggregate_fields:{
		count:"Int",
		max:"TextGeneratorOption_max_fields",
		min:"TextGeneratorOption_min_fields"
	},
	TextGeneratorOption_max_fields:{
		created_at:"timestamptz",
		id:"uuid",
		updated_at:"timestamptz"
	},
	TextGeneratorOption_min_fields:{
		created_at:"timestamptz",
		id:"uuid",
		updated_at:"timestamptz"
	},
	TextGeneratorOption_mutation_response:{
		affected_rows:"Int",
		returning:"TextGeneratorOption"
	},
	TextPromptTemplate:{
		GeneratedPromptByTemplates:"GeneratedPromptByTemplate",
		GeneratedPromptByTemplates_aggregate:"GeneratedPromptByTemplate_aggregate",
		TextPromptTemplateCategory:"TextPromptTemplateCategory",
		TextTemplateClientCategory:"TextTemplateClientCategory",
		TextTemplateClientCategoryId:"uuid",
		availableDisplays:"textTemplateDisplayFormat",
		availableDisplays_aggregate:"textTemplateDisplayFormat_aggregate",
		categoryId:"uuid",
		channel:"String",
		creditPrice:"Int",
		definitions:"jsonb",
		description:"String",
		displayDimensionId:"uuid",
		flexible:"Boolean",
		icon:"String",
		id:"uuid",
		marketPriceInCredits:"numeric",
		name:"String",
		newTemplateText:"String",
		saleCreditPrice:"Int"
	},
	TextPromptTemplateCategory:{
		TextPromptTemplates:"TextPromptTemplate",
		TextPromptTemplates_aggregate:"TextPromptTemplate_aggregate",
		categoryName:"String",
		id:"uuid"
	},
	TextPromptTemplateCategory_aggregate:{
		aggregate:"TextPromptTemplateCategory_aggregate_fields",
		nodes:"TextPromptTemplateCategory"
	},
	TextPromptTemplateCategory_aggregate_fields:{
		count:"Int",
		max:"TextPromptTemplateCategory_max_fields",
		min:"TextPromptTemplateCategory_min_fields"
	},
	TextPromptTemplateCategory_max_fields:{
		categoryName:"String",
		id:"uuid"
	},
	TextPromptTemplateCategory_min_fields:{
		categoryName:"String",
		id:"uuid"
	},
	TextPromptTemplateCategory_mutation_response:{
		affected_rows:"Int",
		returning:"TextPromptTemplateCategory"
	},
	TextPromptTemplate_aggregate:{
		aggregate:"TextPromptTemplate_aggregate_fields",
		nodes:"TextPromptTemplate"
	},
	TextPromptTemplate_aggregate_fields:{
		avg:"TextPromptTemplate_avg_fields",
		count:"Int",
		max:"TextPromptTemplate_max_fields",
		min:"TextPromptTemplate_min_fields",
		stddev:"TextPromptTemplate_stddev_fields",
		stddev_pop:"TextPromptTemplate_stddev_pop_fields",
		stddev_samp:"TextPromptTemplate_stddev_samp_fields",
		sum:"TextPromptTemplate_sum_fields",
		var_pop:"TextPromptTemplate_var_pop_fields",
		var_samp:"TextPromptTemplate_var_samp_fields",
		variance:"TextPromptTemplate_variance_fields"
	},
	TextPromptTemplate_avg_fields:{
		creditPrice:"Float",
		marketPriceInCredits:"Float",
		saleCreditPrice:"Float"
	},
	TextPromptTemplate_max_fields:{
		TextTemplateClientCategoryId:"uuid",
		categoryId:"uuid",
		channel:"String",
		creditPrice:"Int",
		description:"String",
		displayDimensionId:"uuid",
		icon:"String",
		id:"uuid",
		marketPriceInCredits:"numeric",
		name:"String",
		newTemplateText:"String",
		saleCreditPrice:"Int"
	},
	TextPromptTemplate_min_fields:{
		TextTemplateClientCategoryId:"uuid",
		categoryId:"uuid",
		channel:"String",
		creditPrice:"Int",
		description:"String",
		displayDimensionId:"uuid",
		icon:"String",
		id:"uuid",
		marketPriceInCredits:"numeric",
		name:"String",
		newTemplateText:"String",
		saleCreditPrice:"Int"
	},
	TextPromptTemplate_mutation_response:{
		affected_rows:"Int",
		returning:"TextPromptTemplate"
	},
	TextPromptTemplate_stddev_fields:{
		creditPrice:"Float",
		marketPriceInCredits:"Float",
		saleCreditPrice:"Float"
	},
	TextPromptTemplate_stddev_pop_fields:{
		creditPrice:"Float",
		marketPriceInCredits:"Float",
		saleCreditPrice:"Float"
	},
	TextPromptTemplate_stddev_samp_fields:{
		creditPrice:"Float",
		marketPriceInCredits:"Float",
		saleCreditPrice:"Float"
	},
	TextPromptTemplate_sum_fields:{
		creditPrice:"Int",
		marketPriceInCredits:"numeric",
		saleCreditPrice:"Int"
	},
	TextPromptTemplate_var_pop_fields:{
		creditPrice:"Float",
		marketPriceInCredits:"Float",
		saleCreditPrice:"Float"
	},
	TextPromptTemplate_var_samp_fields:{
		creditPrice:"Float",
		marketPriceInCredits:"Float",
		saleCreditPrice:"Float"
	},
	TextPromptTemplate_variance_fields:{
		creditPrice:"Float",
		marketPriceInCredits:"Float",
		saleCreditPrice:"Float"
	},
	TextTemplateClientCategory:{
		displayName:"String",
		iconClass:"String",
		id:"uuid"
	},
	TextTemplateClientCategory_aggregate:{
		aggregate:"TextTemplateClientCategory_aggregate_fields",
		nodes:"TextTemplateClientCategory"
	},
	TextTemplateClientCategory_aggregate_fields:{
		count:"Int",
		max:"TextTemplateClientCategory_max_fields",
		min:"TextTemplateClientCategory_min_fields"
	},
	TextTemplateClientCategory_max_fields:{
		displayName:"String",
		iconClass:"String",
		id:"uuid"
	},
	TextTemplateClientCategory_min_fields:{
		displayName:"String",
		iconClass:"String",
		id:"uuid"
	},
	TextTemplateClientCategory_mutation_response:{
		affected_rows:"Int",
		returning:"TextTemplateClientCategory"
	},
	UpdateUserAvatarOutput:{
		message:"String",
		photoURL:"String",
		success:"Boolean"
	},
	UpsertAudienceOutput:{
		brandName:"String",
		id:"String",
		personas:"String",
		productOrService:"String",
		segment:"String",
		targetAudience:"String"
	},
	audiences:{
		brandId:"uuid",
		brandName:"String",
		created_at:"timestamp",
		created_by:"uuid",
		deleted:"Boolean",
		id:"uuid",
		personas:"jsonb",
		productOrService:"String",
		segment:"String",
		targetAudience:"String",
		updated_at:"timestamptz"
	},
	audiences_aggregate:{
		aggregate:"audiences_aggregate_fields",
		nodes:"audiences"
	},
	audiences_aggregate_fields:{
		count:"Int",
		max:"audiences_max_fields",
		min:"audiences_min_fields"
	},
	audiences_max_fields:{
		brandId:"uuid",
		brandName:"String",
		created_at:"timestamp",
		created_by:"uuid",
		id:"uuid",
		productOrService:"String",
		segment:"String",
		targetAudience:"String",
		updated_at:"timestamptz"
	},
	audiences_min_fields:{
		brandId:"uuid",
		brandName:"String",
		created_at:"timestamp",
		created_by:"uuid",
		id:"uuid",
		productOrService:"String",
		segment:"String",
		targetAudience:"String",
		updated_at:"timestamptz"
	},
	audiences_mutation_response:{
		affected_rows:"Int",
		returning:"audiences"
	},
	authProviderRequests:{
		id:"uuid",
		options:"jsonb"
	},
	authProviderRequests_aggregate:{
		aggregate:"authProviderRequests_aggregate_fields",
		nodes:"authProviderRequests"
	},
	authProviderRequests_aggregate_fields:{
		count:"Int",
		max:"authProviderRequests_max_fields",
		min:"authProviderRequests_min_fields"
	},
	authProviderRequests_max_fields:{
		id:"uuid"
	},
	authProviderRequests_min_fields:{
		id:"uuid"
	},
	authProviderRequests_mutation_response:{
		affected_rows:"Int",
		returning:"authProviderRequests"
	},
	authProviders:{
		id:"String",
		userProviders:"authUserProviders",
		userProviders_aggregate:"authUserProviders_aggregate"
	},
	authProviders_aggregate:{
		aggregate:"authProviders_aggregate_fields",
		nodes:"authProviders"
	},
	authProviders_aggregate_fields:{
		count:"Int",
		max:"authProviders_max_fields",
		min:"authProviders_min_fields"
	},
	authProviders_max_fields:{
		id:"String"
	},
	authProviders_min_fields:{
		id:"String"
	},
	authProviders_mutation_response:{
		affected_rows:"Int",
		returning:"authProviders"
	},
	authRefreshTokenTypes:{
		comment:"String",
		refreshTokens:"authRefreshTokens",
		refreshTokens_aggregate:"authRefreshTokens_aggregate",
		value:"String"
	},
	authRefreshTokenTypes_aggregate:{
		aggregate:"authRefreshTokenTypes_aggregate_fields",
		nodes:"authRefreshTokenTypes"
	},
	authRefreshTokenTypes_aggregate_fields:{
		count:"Int",
		max:"authRefreshTokenTypes_max_fields",
		min:"authRefreshTokenTypes_min_fields"
	},
	authRefreshTokenTypes_max_fields:{
		comment:"String",
		value:"String"
	},
	authRefreshTokenTypes_min_fields:{
		comment:"String",
		value:"String"
	},
	authRefreshTokenTypes_mutation_response:{
		affected_rows:"Int",
		returning:"authRefreshTokenTypes"
	},
	authRefreshTokens:{
		createdAt:"timestamptz",
		expiresAt:"timestamptz",
		id:"uuid",
		metadata:"jsonb",
		refreshTokenHash:"String",
		type:"authRefreshTokenTypes_enum",
		user:"users",
		userId:"uuid"
	},
	authRefreshTokens_aggregate:{
		aggregate:"authRefreshTokens_aggregate_fields",
		nodes:"authRefreshTokens"
	},
	authRefreshTokens_aggregate_fields:{
		count:"Int",
		max:"authRefreshTokens_max_fields",
		min:"authRefreshTokens_min_fields"
	},
	authRefreshTokens_max_fields:{
		createdAt:"timestamptz",
		expiresAt:"timestamptz",
		id:"uuid",
		refreshTokenHash:"String",
		userId:"uuid"
	},
	authRefreshTokens_min_fields:{
		createdAt:"timestamptz",
		expiresAt:"timestamptz",
		id:"uuid",
		refreshTokenHash:"String",
		userId:"uuid"
	},
	authRefreshTokens_mutation_response:{
		affected_rows:"Int",
		returning:"authRefreshTokens"
	},
	authRoles:{
		role:"String",
		userRoles:"authUserRoles",
		userRoles_aggregate:"authUserRoles_aggregate",
		usersByDefaultRole:"users",
		usersByDefaultRole_aggregate:"users_aggregate"
	},
	authRoles_aggregate:{
		aggregate:"authRoles_aggregate_fields",
		nodes:"authRoles"
	},
	authRoles_aggregate_fields:{
		count:"Int",
		max:"authRoles_max_fields",
		min:"authRoles_min_fields"
	},
	authRoles_max_fields:{
		role:"String"
	},
	authRoles_min_fields:{
		role:"String"
	},
	authRoles_mutation_response:{
		affected_rows:"Int",
		returning:"authRoles"
	},
	authUserProviders:{
		accessToken:"String",
		createdAt:"timestamptz",
		id:"uuid",
		provider:"authProviders",
		providerId:"String",
		providerUserId:"String",
		refreshToken:"String",
		updatedAt:"timestamptz",
		user:"users",
		userId:"uuid"
	},
	authUserProviders_aggregate:{
		aggregate:"authUserProviders_aggregate_fields",
		nodes:"authUserProviders"
	},
	authUserProviders_aggregate_fields:{
		count:"Int",
		max:"authUserProviders_max_fields",
		min:"authUserProviders_min_fields"
	},
	authUserProviders_max_fields:{
		accessToken:"String",
		createdAt:"timestamptz",
		id:"uuid",
		providerId:"String",
		providerUserId:"String",
		refreshToken:"String",
		updatedAt:"timestamptz",
		userId:"uuid"
	},
	authUserProviders_min_fields:{
		accessToken:"String",
		createdAt:"timestamptz",
		id:"uuid",
		providerId:"String",
		providerUserId:"String",
		refreshToken:"String",
		updatedAt:"timestamptz",
		userId:"uuid"
	},
	authUserProviders_mutation_response:{
		affected_rows:"Int",
		returning:"authUserProviders"
	},
	authUserRoles:{
		createdAt:"timestamptz",
		id:"uuid",
		role:"String",
		roleByRole:"authRoles",
		user:"users",
		userId:"uuid"
	},
	authUserRoles_aggregate:{
		aggregate:"authUserRoles_aggregate_fields",
		nodes:"authUserRoles"
	},
	authUserRoles_aggregate_fields:{
		count:"Int",
		max:"authUserRoles_max_fields",
		min:"authUserRoles_min_fields"
	},
	authUserRoles_max_fields:{
		createdAt:"timestamptz",
		id:"uuid",
		role:"String",
		userId:"uuid"
	},
	authUserRoles_min_fields:{
		createdAt:"timestamptz",
		id:"uuid",
		role:"String",
		userId:"uuid"
	},
	authUserRoles_mutation_response:{
		affected_rows:"Int",
		returning:"authUserRoles"
	},
	authUserSecurityKeys:{
		counter:"bigint",
		credentialId:"String",
		credentialPublicKey:"bytea",
		id:"uuid",
		nickname:"String",
		transports:"String",
		user:"users",
		userId:"uuid"
	},
	authUserSecurityKeys_aggregate:{
		aggregate:"authUserSecurityKeys_aggregate_fields",
		nodes:"authUserSecurityKeys"
	},
	authUserSecurityKeys_aggregate_fields:{
		avg:"authUserSecurityKeys_avg_fields",
		count:"Int",
		max:"authUserSecurityKeys_max_fields",
		min:"authUserSecurityKeys_min_fields",
		stddev:"authUserSecurityKeys_stddev_fields",
		stddev_pop:"authUserSecurityKeys_stddev_pop_fields",
		stddev_samp:"authUserSecurityKeys_stddev_samp_fields",
		sum:"authUserSecurityKeys_sum_fields",
		var_pop:"authUserSecurityKeys_var_pop_fields",
		var_samp:"authUserSecurityKeys_var_samp_fields",
		variance:"authUserSecurityKeys_variance_fields"
	},
	authUserSecurityKeys_avg_fields:{
		counter:"Float"
	},
	authUserSecurityKeys_max_fields:{
		counter:"bigint",
		credentialId:"String",
		id:"uuid",
		nickname:"String",
		transports:"String",
		userId:"uuid"
	},
	authUserSecurityKeys_min_fields:{
		counter:"bigint",
		credentialId:"String",
		id:"uuid",
		nickname:"String",
		transports:"String",
		userId:"uuid"
	},
	authUserSecurityKeys_mutation_response:{
		affected_rows:"Int",
		returning:"authUserSecurityKeys"
	},
	authUserSecurityKeys_stddev_fields:{
		counter:"Float"
	},
	authUserSecurityKeys_stddev_pop_fields:{
		counter:"Float"
	},
	authUserSecurityKeys_stddev_samp_fields:{
		counter:"Float"
	},
	authUserSecurityKeys_sum_fields:{
		counter:"bigint"
	},
	authUserSecurityKeys_var_pop_fields:{
		counter:"Float"
	},
	authUserSecurityKeys_var_samp_fields:{
		counter:"Float"
	},
	authUserSecurityKeys_variance_fields:{
		counter:"Float"
	},
	bigint: `scalar.bigint` as const,
	blog:{
		brand:"brands",
		brandId:"uuid",
		category:"blogCategory",
		categoryId:"uuid",
		content:"String",
		created_at:"timestamptz",
		created_by:"uuid",
		featuredImageId:"uuid",
		file:"files",
		id:"uuid",
		metaDescription:"String",
		project:"project",
		projectId:"uuid",
		publishDate:"timestamptz",
		scheduledDate:"timestamptz",
		slug:"String",
		title:"String",
		updated_at:"timestamptz",
		user:"users"
	},
	blogCategory:{
		blogs:"blog",
		blogs_aggregate:"blog_aggregate",
		created_at:"timestamptz",
		id:"uuid",
		name:"String",
		updated_at:"timestamptz"
	},
	blogCategory_aggregate:{
		aggregate:"blogCategory_aggregate_fields",
		nodes:"blogCategory"
	},
	blogCategory_aggregate_fields:{
		count:"Int",
		max:"blogCategory_max_fields",
		min:"blogCategory_min_fields"
	},
	blogCategory_max_fields:{
		created_at:"timestamptz",
		id:"uuid",
		name:"String",
		updated_at:"timestamptz"
	},
	blogCategory_min_fields:{
		created_at:"timestamptz",
		id:"uuid",
		name:"String",
		updated_at:"timestamptz"
	},
	blogCategory_mutation_response:{
		affected_rows:"Int",
		returning:"blogCategory"
	},
	blogPostGeneratorEnhancedForCopy:{
		created_at:"timestamptz",
		errors:"json",
		id:"uuid",
		output:"blogPostGeneratorEnhancedForCopyOutput"
	},
	blogPostGeneratorEnhancedForCopyOutput:{
		html:"String"
	},
	blog_aggregate:{
		aggregate:"blog_aggregate_fields",
		nodes:"blog"
	},
	blog_aggregate_fields:{
		count:"Int",
		max:"blog_max_fields",
		min:"blog_min_fields"
	},
	blog_max_fields:{
		brandId:"uuid",
		categoryId:"uuid",
		content:"String",
		created_at:"timestamptz",
		created_by:"uuid",
		featuredImageId:"uuid",
		id:"uuid",
		metaDescription:"String",
		projectId:"uuid",
		publishDate:"timestamptz",
		scheduledDate:"timestamptz",
		slug:"String",
		title:"String",
		updated_at:"timestamptz"
	},
	blog_min_fields:{
		brandId:"uuid",
		categoryId:"uuid",
		content:"String",
		created_at:"timestamptz",
		created_by:"uuid",
		featuredImageId:"uuid",
		id:"uuid",
		metaDescription:"String",
		projectId:"uuid",
		publishDate:"timestamptz",
		scheduledDate:"timestamptz",
		slug:"String",
		title:"String",
		updated_at:"timestamptz"
	},
	blog_mutation_response:{
		affected_rows:"Int",
		returning:"blog"
	},
	brands:{
		addressCity:"String",
		addressComplement:"String",
		addressDistrict:"String",
		addressLatitude:"String",
		addressLongitude:"String",
		addressNumber:"String",
		addressStreet:"String",
		addressUf:"String",
		addressZipCode:"String",
		archtypeAdherence:"jsonb",
		blogs:"blog",
		blogs_aggregate:"blog_aggregate",
		brandName:"String",
		colors:"jsonb",
		created_at:"timestamptz",
		created_by:"uuid",
		deleted:"Boolean",
		email:"String",
		facebookUrl:"String",
		fontPrimary:"String",
		fontSecondary:"String",
		id:"uuid",
		instagramUrl:"String",
		isBrand:"Boolean",
		linkedinUrl:"String",
		logoFileId:"uuid",
		personality:"String",
		phoneNumber:"String",
		productOrService:"String",
		products:"products",
		products_aggregate:"products_aggregate",
		segment:"String",
		shortAbout:"String",
		slogan:"String",
		tiktokUrl:"String",
		twitterUrl:"String",
		updated_at:"timestamptz",
		voiceTone:"String",
		whatsappNumber:"String",
		youtubeUrl:"String"
	},
	brands_aggregate:{
		aggregate:"brands_aggregate_fields",
		nodes:"brands"
	},
	brands_aggregate_fields:{
		count:"Int",
		max:"brands_max_fields",
		min:"brands_min_fields"
	},
	brands_max_fields:{
		addressCity:"String",
		addressComplement:"String",
		addressDistrict:"String",
		addressLatitude:"String",
		addressLongitude:"String",
		addressNumber:"String",
		addressStreet:"String",
		addressUf:"String",
		addressZipCode:"String",
		brandName:"String",
		created_at:"timestamptz",
		created_by:"uuid",
		email:"String",
		facebookUrl:"String",
		fontPrimary:"String",
		fontSecondary:"String",
		id:"uuid",
		instagramUrl:"String",
		linkedinUrl:"String",
		logoFileId:"uuid",
		personality:"String",
		phoneNumber:"String",
		productOrService:"String",
		segment:"String",
		shortAbout:"String",
		slogan:"String",
		tiktokUrl:"String",
		twitterUrl:"String",
		updated_at:"timestamptz",
		voiceTone:"String",
		whatsappNumber:"String",
		youtubeUrl:"String"
	},
	brands_min_fields:{
		addressCity:"String",
		addressComplement:"String",
		addressDistrict:"String",
		addressLatitude:"String",
		addressLongitude:"String",
		addressNumber:"String",
		addressStreet:"String",
		addressUf:"String",
		addressZipCode:"String",
		brandName:"String",
		created_at:"timestamptz",
		created_by:"uuid",
		email:"String",
		facebookUrl:"String",
		fontPrimary:"String",
		fontSecondary:"String",
		id:"uuid",
		instagramUrl:"String",
		linkedinUrl:"String",
		logoFileId:"uuid",
		personality:"String",
		phoneNumber:"String",
		productOrService:"String",
		segment:"String",
		shortAbout:"String",
		slogan:"String",
		tiktokUrl:"String",
		twitterUrl:"String",
		updated_at:"timestamptz",
		voiceTone:"String",
		whatsappNumber:"String",
		youtubeUrl:"String"
	},
	brands_mutation_response:{
		affected_rows:"Int",
		returning:"brands"
	},
	buckets:{
		cacheControl:"String",
		createdAt:"timestamptz",
		downloadExpiration:"Int",
		files:"files",
		files_aggregate:"files_aggregate",
		id:"String",
		maxUploadFileSize:"Int",
		minUploadFileSize:"Int",
		presignedUrlsEnabled:"Boolean",
		updatedAt:"timestamptz"
	},
	buckets_aggregate:{
		aggregate:"buckets_aggregate_fields",
		nodes:"buckets"
	},
	buckets_aggregate_fields:{
		avg:"buckets_avg_fields",
		count:"Int",
		max:"buckets_max_fields",
		min:"buckets_min_fields",
		stddev:"buckets_stddev_fields",
		stddev_pop:"buckets_stddev_pop_fields",
		stddev_samp:"buckets_stddev_samp_fields",
		sum:"buckets_sum_fields",
		var_pop:"buckets_var_pop_fields",
		var_samp:"buckets_var_samp_fields",
		variance:"buckets_variance_fields"
	},
	buckets_avg_fields:{
		downloadExpiration:"Float",
		maxUploadFileSize:"Float",
		minUploadFileSize:"Float"
	},
	buckets_max_fields:{
		cacheControl:"String",
		createdAt:"timestamptz",
		downloadExpiration:"Int",
		id:"String",
		maxUploadFileSize:"Int",
		minUploadFileSize:"Int",
		updatedAt:"timestamptz"
	},
	buckets_min_fields:{
		cacheControl:"String",
		createdAt:"timestamptz",
		downloadExpiration:"Int",
		id:"String",
		maxUploadFileSize:"Int",
		minUploadFileSize:"Int",
		updatedAt:"timestamptz"
	},
	buckets_mutation_response:{
		affected_rows:"Int",
		returning:"buckets"
	},
	buckets_stddev_fields:{
		downloadExpiration:"Float",
		maxUploadFileSize:"Float",
		minUploadFileSize:"Float"
	},
	buckets_stddev_pop_fields:{
		downloadExpiration:"Float",
		maxUploadFileSize:"Float",
		minUploadFileSize:"Float"
	},
	buckets_stddev_samp_fields:{
		downloadExpiration:"Float",
		maxUploadFileSize:"Float",
		minUploadFileSize:"Float"
	},
	buckets_sum_fields:{
		downloadExpiration:"Int",
		maxUploadFileSize:"Int",
		minUploadFileSize:"Int"
	},
	buckets_var_pop_fields:{
		downloadExpiration:"Float",
		maxUploadFileSize:"Float",
		minUploadFileSize:"Float"
	},
	buckets_var_samp_fields:{
		downloadExpiration:"Float",
		maxUploadFileSize:"Float",
		minUploadFileSize:"Float"
	},
	buckets_variance_fields:{
		downloadExpiration:"Float",
		maxUploadFileSize:"Float",
		minUploadFileSize:"Float"
	},
	bytea: `scalar.bytea` as const,
	campaign:{
		audiencesIds:"String",
		baseJson:"jsonb",
		brandId:"uuid",
		campaignObjective:"String",
		created_at:"timestamptz",
		created_by:"uuid",
		id:"uuid",
		productIds:"String",
		updated_at:"timestamptz"
	},
	campaign_aggregate:{
		aggregate:"campaign_aggregate_fields",
		nodes:"campaign"
	},
	campaign_aggregate_fields:{
		count:"Int",
		max:"campaign_max_fields",
		min:"campaign_min_fields"
	},
	campaign_max_fields:{
		audiencesIds:"String",
		brandId:"uuid",
		campaignObjective:"String",
		created_at:"timestamptz",
		created_by:"uuid",
		id:"uuid",
		productIds:"String",
		updated_at:"timestamptz"
	},
	campaign_min_fields:{
		audiencesIds:"String",
		brandId:"uuid",
		campaignObjective:"String",
		created_at:"timestamptz",
		created_by:"uuid",
		id:"uuid",
		productIds:"String",
		updated_at:"timestamptz"
	},
	campaign_mutation_response:{
		affected_rows:"Int",
		returning:"campaign"
	},
	categorysummaryview:{
		categoryName:"String",
		count:"bigint",
		id:"uuid"
	},
	categorysummaryview_aggregate:{
		aggregate:"categorysummaryview_aggregate_fields",
		nodes:"categorysummaryview"
	},
	categorysummaryview_aggregate_fields:{
		avg:"categorysummaryview_avg_fields",
		count:"Int",
		max:"categorysummaryview_max_fields",
		min:"categorysummaryview_min_fields",
		stddev:"categorysummaryview_stddev_fields",
		stddev_pop:"categorysummaryview_stddev_pop_fields",
		stddev_samp:"categorysummaryview_stddev_samp_fields",
		sum:"categorysummaryview_sum_fields",
		var_pop:"categorysummaryview_var_pop_fields",
		var_samp:"categorysummaryview_var_samp_fields",
		variance:"categorysummaryview_variance_fields"
	},
	categorysummaryview_avg_fields:{
		count:"Float"
	},
	categorysummaryview_max_fields:{
		categoryName:"String",
		count:"bigint",
		id:"uuid"
	},
	categorysummaryview_min_fields:{
		categoryName:"String",
		count:"bigint",
		id:"uuid"
	},
	categorysummaryview_stddev_fields:{
		count:"Float"
	},
	categorysummaryview_stddev_pop_fields:{
		count:"Float"
	},
	categorysummaryview_stddev_samp_fields:{
		count:"Float"
	},
	categorysummaryview_sum_fields:{
		count:"bigint"
	},
	categorysummaryview_var_pop_fields:{
		count:"Float"
	},
	categorysummaryview_var_samp_fields:{
		count:"Float"
	},
	categorysummaryview_variance_fields:{
		count:"Float"
	},
	channels:{
		alias:"String",
		created_at:"timestamp",
		displayName:"String",
		id:"uuid",
		updated_at:"timestamp"
	},
	channels_aggregate:{
		aggregate:"channels_aggregate_fields",
		nodes:"channels"
	},
	channels_aggregate_fields:{
		count:"Int",
		max:"channels_max_fields",
		min:"channels_min_fields"
	},
	channels_max_fields:{
		alias:"String",
		created_at:"timestamp",
		displayName:"String",
		id:"uuid",
		updated_at:"timestamp"
	},
	channels_min_fields:{
		alias:"String",
		created_at:"timestamp",
		displayName:"String",
		id:"uuid",
		updated_at:"timestamp"
	},
	channels_mutation_response:{
		affected_rows:"Int",
		returning:"channels"
	},
	citext: `scalar.citext` as const,
	consumptionByChannelView:{
		agendados:"bigint",
		channel:"String",
		copymagico:"numeric",
		created_by:"uuid",
		economia:"numeric",
		mercado:"bigint",
		publicados:"bigint",
		rascunhos:"bigint"
	},
	consumptionByChannelView_aggregate:{
		aggregate:"consumptionByChannelView_aggregate_fields",
		nodes:"consumptionByChannelView"
	},
	consumptionByChannelView_aggregate_fields:{
		avg:"consumptionByChannelView_avg_fields",
		count:"Int",
		max:"consumptionByChannelView_max_fields",
		min:"consumptionByChannelView_min_fields",
		stddev:"consumptionByChannelView_stddev_fields",
		stddev_pop:"consumptionByChannelView_stddev_pop_fields",
		stddev_samp:"consumptionByChannelView_stddev_samp_fields",
		sum:"consumptionByChannelView_sum_fields",
		var_pop:"consumptionByChannelView_var_pop_fields",
		var_samp:"consumptionByChannelView_var_samp_fields",
		variance:"consumptionByChannelView_variance_fields"
	},
	consumptionByChannelView_avg_fields:{
		agendados:"Float",
		copymagico:"Float",
		economia:"Float",
		mercado:"Float",
		publicados:"Float",
		rascunhos:"Float"
	},
	consumptionByChannelView_max_fields:{
		agendados:"bigint",
		channel:"String",
		copymagico:"numeric",
		created_by:"uuid",
		economia:"numeric",
		mercado:"bigint",
		publicados:"bigint",
		rascunhos:"bigint"
	},
	consumptionByChannelView_min_fields:{
		agendados:"bigint",
		channel:"String",
		copymagico:"numeric",
		created_by:"uuid",
		economia:"numeric",
		mercado:"bigint",
		publicados:"bigint",
		rascunhos:"bigint"
	},
	consumptionByChannelView_stddev_fields:{
		agendados:"Float",
		copymagico:"Float",
		economia:"Float",
		mercado:"Float",
		publicados:"Float",
		rascunhos:"Float"
	},
	consumptionByChannelView_stddev_pop_fields:{
		agendados:"Float",
		copymagico:"Float",
		economia:"Float",
		mercado:"Float",
		publicados:"Float",
		rascunhos:"Float"
	},
	consumptionByChannelView_stddev_samp_fields:{
		agendados:"Float",
		copymagico:"Float",
		economia:"Float",
		mercado:"Float",
		publicados:"Float",
		rascunhos:"Float"
	},
	consumptionByChannelView_sum_fields:{
		agendados:"bigint",
		copymagico:"numeric",
		economia:"numeric",
		mercado:"bigint",
		publicados:"bigint",
		rascunhos:"bigint"
	},
	consumptionByChannelView_var_pop_fields:{
		agendados:"Float",
		copymagico:"Float",
		economia:"Float",
		mercado:"Float",
		publicados:"Float",
		rascunhos:"Float"
	},
	consumptionByChannelView_var_samp_fields:{
		agendados:"Float",
		copymagico:"Float",
		economia:"Float",
		mercado:"Float",
		publicados:"Float",
		rascunhos:"Float"
	},
	consumptionByChannelView_variance_fields:{
		agendados:"Float",
		copymagico:"Float",
		economia:"Float",
		mercado:"Float",
		publicados:"Float",
		rascunhos:"Float"
	},
	costTable:{
		bannerBear:"numeric",
		created_at:"timestamptz",
		entityId:"String",
		entityName:"String",
		gptCompletionTokens:"numeric",
		gptPromptTokens:"numeric",
		id:"uuid",
		provider:"String"
	},
	costTable_aggregate:{
		aggregate:"costTable_aggregate_fields",
		nodes:"costTable"
	},
	costTable_aggregate_fields:{
		avg:"costTable_avg_fields",
		count:"Int",
		max:"costTable_max_fields",
		min:"costTable_min_fields",
		stddev:"costTable_stddev_fields",
		stddev_pop:"costTable_stddev_pop_fields",
		stddev_samp:"costTable_stddev_samp_fields",
		sum:"costTable_sum_fields",
		var_pop:"costTable_var_pop_fields",
		var_samp:"costTable_var_samp_fields",
		variance:"costTable_variance_fields"
	},
	costTable_avg_fields:{
		bannerBear:"Float",
		gptCompletionTokens:"Float",
		gptPromptTokens:"Float"
	},
	costTable_max_fields:{
		bannerBear:"numeric",
		created_at:"timestamptz",
		entityId:"String",
		entityName:"String",
		gptCompletionTokens:"numeric",
		gptPromptTokens:"numeric",
		id:"uuid",
		provider:"String"
	},
	costTable_min_fields:{
		bannerBear:"numeric",
		created_at:"timestamptz",
		entityId:"String",
		entityName:"String",
		gptCompletionTokens:"numeric",
		gptPromptTokens:"numeric",
		id:"uuid",
		provider:"String"
	},
	costTable_mutation_response:{
		affected_rows:"Int",
		returning:"costTable"
	},
	costTable_stddev_fields:{
		bannerBear:"Float",
		gptCompletionTokens:"Float",
		gptPromptTokens:"Float"
	},
	costTable_stddev_pop_fields:{
		bannerBear:"Float",
		gptCompletionTokens:"Float",
		gptPromptTokens:"Float"
	},
	costTable_stddev_samp_fields:{
		bannerBear:"Float",
		gptCompletionTokens:"Float",
		gptPromptTokens:"Float"
	},
	costTable_sum_fields:{
		bannerBear:"numeric",
		gptCompletionTokens:"numeric",
		gptPromptTokens:"numeric"
	},
	costTable_var_pop_fields:{
		bannerBear:"Float",
		gptCompletionTokens:"Float",
		gptPromptTokens:"Float"
	},
	costTable_var_samp_fields:{
		bannerBear:"Float",
		gptCompletionTokens:"Float",
		gptPromptTokens:"Float"
	},
	costTable_variance_fields:{
		bannerBear:"Float",
		gptCompletionTokens:"Float",
		gptPromptTokens:"Float"
	},
	date: `scalar.date` as const,
	deal:{
		created_at:"timestamptz",
		dealStatus:"dealStatus",
		email:"String",
		id:"uuid",
		interactions:"interaction",
		interactions_aggregate:"interaction_aggregate",
		name:"String",
		phone:"String",
		product:"products",
		productId:"uuid",
		rating:"Int",
		statusId:"uuid",
		tasks:"task",
		tasks_aggregate:"task_aggregate",
		updated_at:"timestamptz"
	},
	dealStatus:{
		deals:"deal",
		deals_aggregate:"deal_aggregate",
		displayName:"String",
		id:"uuid",
		order:"Int"
	},
	dealStatus_aggregate:{
		aggregate:"dealStatus_aggregate_fields",
		nodes:"dealStatus"
	},
	dealStatus_aggregate_fields:{
		avg:"dealStatus_avg_fields",
		count:"Int",
		max:"dealStatus_max_fields",
		min:"dealStatus_min_fields",
		stddev:"dealStatus_stddev_fields",
		stddev_pop:"dealStatus_stddev_pop_fields",
		stddev_samp:"dealStatus_stddev_samp_fields",
		sum:"dealStatus_sum_fields",
		var_pop:"dealStatus_var_pop_fields",
		var_samp:"dealStatus_var_samp_fields",
		variance:"dealStatus_variance_fields"
	},
	dealStatus_avg_fields:{
		order:"Float"
	},
	dealStatus_max_fields:{
		displayName:"String",
		id:"uuid",
		order:"Int"
	},
	dealStatus_min_fields:{
		displayName:"String",
		id:"uuid",
		order:"Int"
	},
	dealStatus_mutation_response:{
		affected_rows:"Int",
		returning:"dealStatus"
	},
	dealStatus_stddev_fields:{
		order:"Float"
	},
	dealStatus_stddev_pop_fields:{
		order:"Float"
	},
	dealStatus_stddev_samp_fields:{
		order:"Float"
	},
	dealStatus_sum_fields:{
		order:"Int"
	},
	dealStatus_var_pop_fields:{
		order:"Float"
	},
	dealStatus_var_samp_fields:{
		order:"Float"
	},
	dealStatus_variance_fields:{
		order:"Float"
	},
	deal_aggregate:{
		aggregate:"deal_aggregate_fields",
		nodes:"deal"
	},
	deal_aggregate_fields:{
		avg:"deal_avg_fields",
		count:"Int",
		max:"deal_max_fields",
		min:"deal_min_fields",
		stddev:"deal_stddev_fields",
		stddev_pop:"deal_stddev_pop_fields",
		stddev_samp:"deal_stddev_samp_fields",
		sum:"deal_sum_fields",
		var_pop:"deal_var_pop_fields",
		var_samp:"deal_var_samp_fields",
		variance:"deal_variance_fields"
	},
	deal_avg_fields:{
		rating:"Float"
	},
	deal_max_fields:{
		created_at:"timestamptz",
		email:"String",
		id:"uuid",
		name:"String",
		phone:"String",
		productId:"uuid",
		rating:"Int",
		statusId:"uuid",
		updated_at:"timestamptz"
	},
	deal_min_fields:{
		created_at:"timestamptz",
		email:"String",
		id:"uuid",
		name:"String",
		phone:"String",
		productId:"uuid",
		rating:"Int",
		statusId:"uuid",
		updated_at:"timestamptz"
	},
	deal_mutation_response:{
		affected_rows:"Int",
		returning:"deal"
	},
	deal_stddev_fields:{
		rating:"Float"
	},
	deal_stddev_pop_fields:{
		rating:"Float"
	},
	deal_stddev_samp_fields:{
		rating:"Float"
	},
	deal_sum_fields:{
		rating:"Int"
	},
	deal_var_pop_fields:{
		rating:"Float"
	},
	deal_var_samp_fields:{
		rating:"Float"
	},
	deal_variance_fields:{
		rating:"Float"
	},
	displayCategory:{
		created_at:"timestamptz",
		displayName:"jsonb",
		id:"uuid",
		name:"String"
	},
	displayCategory_aggregate:{
		aggregate:"displayCategory_aggregate_fields",
		nodes:"displayCategory"
	},
	displayCategory_aggregate_fields:{
		count:"Int",
		max:"displayCategory_max_fields",
		min:"displayCategory_min_fields"
	},
	displayCategory_max_fields:{
		created_at:"timestamptz",
		id:"uuid",
		name:"String"
	},
	displayCategory_min_fields:{
		created_at:"timestamptz",
		id:"uuid",
		name:"String"
	},
	displayCategory_mutation_response:{
		affected_rows:"Int",
		returning:"displayCategory"
	},
	displayDimension:{
		displayName:"jsonb",
		id:"uuid",
		name:"String"
	},
	displayDimension_aggregate:{
		aggregate:"displayDimension_aggregate_fields",
		nodes:"displayDimension"
	},
	displayDimension_aggregate_fields:{
		count:"Int",
		max:"displayDimension_max_fields",
		min:"displayDimension_min_fields"
	},
	displayDimension_max_fields:{
		id:"uuid",
		name:"String"
	},
	displayDimension_min_fields:{
		id:"uuid",
		name:"String"
	},
	displayDimension_mutation_response:{
		affected_rows:"Int",
		returning:"displayDimension"
	},
	displayFormats:{
		bannerbearId:"String",
		created_at:"timestamptz",
		displayCategoryId:"uuid",
		displayDimension:"displayDimension",
		displayDimensionId:"uuid",
		displayName:"jsonb",
		id:"uuid",
		mapper:"jsonb",
		name:"String",
		newData:"jsonb",
		newTemplate:"String",
		requiresBrand:"Boolean"
	},
	displayFormats_aggregate:{
		aggregate:"displayFormats_aggregate_fields",
		nodes:"displayFormats"
	},
	displayFormats_aggregate_fields:{
		count:"Int",
		max:"displayFormats_max_fields",
		min:"displayFormats_min_fields"
	},
	displayFormats_max_fields:{
		bannerbearId:"String",
		created_at:"timestamptz",
		displayCategoryId:"uuid",
		displayDimensionId:"uuid",
		id:"uuid",
		name:"String",
		newTemplate:"String"
	},
	displayFormats_min_fields:{
		bannerbearId:"String",
		created_at:"timestamptz",
		displayCategoryId:"uuid",
		displayDimensionId:"uuid",
		id:"uuid",
		name:"String",
		newTemplate:"String"
	},
	displayFormats_mutation_response:{
		affected_rows:"Int",
		returning:"displayFormats"
	},
	displayTemplate:{
		alias:"String",
		fields:"jsonb",
		id:"uuid",
		template:"String"
	},
	displayTemplate_aggregate:{
		aggregate:"displayTemplate_aggregate_fields",
		nodes:"displayTemplate"
	},
	displayTemplate_aggregate_fields:{
		count:"Int",
		max:"displayTemplate_max_fields",
		min:"displayTemplate_min_fields"
	},
	displayTemplate_max_fields:{
		alias:"String",
		id:"uuid",
		template:"String"
	},
	displayTemplate_min_fields:{
		alias:"String",
		id:"uuid",
		template:"String"
	},
	displayTemplate_mutation_response:{
		affected_rows:"Int",
		returning:"displayTemplate"
	},
	facebookPage:{
		id:"String",
		name:"String"
	},
	files:{
		bucket:"buckets",
		bucketId:"String",
		createdAt:"timestamptz",
		etag:"String",
		id:"uuid",
		isUploaded:"Boolean",
		metadata:"jsonb",
		mimeType:"String",
		name:"String",
		size:"Int",
		updatedAt:"timestamptz",
		uploadedByUserId:"uuid"
	},
	files_aggregate:{
		aggregate:"files_aggregate_fields",
		nodes:"files"
	},
	files_aggregate_fields:{
		avg:"files_avg_fields",
		count:"Int",
		max:"files_max_fields",
		min:"files_min_fields",
		stddev:"files_stddev_fields",
		stddev_pop:"files_stddev_pop_fields",
		stddev_samp:"files_stddev_samp_fields",
		sum:"files_sum_fields",
		var_pop:"files_var_pop_fields",
		var_samp:"files_var_samp_fields",
		variance:"files_variance_fields"
	},
	files_avg_fields:{
		size:"Float"
	},
	files_max_fields:{
		bucketId:"String",
		createdAt:"timestamptz",
		etag:"String",
		id:"uuid",
		mimeType:"String",
		name:"String",
		size:"Int",
		updatedAt:"timestamptz",
		uploadedByUserId:"uuid"
	},
	files_min_fields:{
		bucketId:"String",
		createdAt:"timestamptz",
		etag:"String",
		id:"uuid",
		mimeType:"String",
		name:"String",
		size:"Int",
		updatedAt:"timestamptz",
		uploadedByUserId:"uuid"
	},
	files_mutation_response:{
		affected_rows:"Int",
		returning:"files"
	},
	files_stddev_fields:{
		size:"Float"
	},
	files_stddev_pop_fields:{
		size:"Float"
	},
	files_stddev_samp_fields:{
		size:"Float"
	},
	files_sum_fields:{
		size:"Int"
	},
	files_var_pop_fields:{
		size:"Float"
	},
	files_var_samp_fields:{
		size:"Float"
	},
	files_variance_fields:{
		size:"Float"
	},
	generateNextMonthCalendar:{
		created_at:"timestamptz",
		errors:"json",
		id:"uuid",
		output:"GenerateNextMonthCalendarOutput"
	},
	instagramPageAccount:{
		id:"String",
		instagramBusinessAccountId:"String",
		name:"String"
	},
	interaction:{
		channel:"uuid",
		created_at:"timestamptz",
		created_by:"uuid",
		date:"timestamptz",
		dealId:"uuid",
		id:"uuid",
		observation:"String",
		success:"Boolean",
		updated_at:"timestamptz",
		user:"users"
	},
	interaction_aggregate:{
		aggregate:"interaction_aggregate_fields",
		nodes:"interaction"
	},
	interaction_aggregate_fields:{
		count:"Int",
		max:"interaction_max_fields",
		min:"interaction_min_fields"
	},
	interaction_max_fields:{
		channel:"uuid",
		created_at:"timestamptz",
		created_by:"uuid",
		date:"timestamptz",
		dealId:"uuid",
		id:"uuid",
		observation:"String",
		updated_at:"timestamptz"
	},
	interaction_min_fields:{
		channel:"uuid",
		created_at:"timestamptz",
		created_by:"uuid",
		date:"timestamptz",
		dealId:"uuid",
		id:"uuid",
		observation:"String",
		updated_at:"timestamptz"
	},
	interaction_mutation_response:{
		affected_rows:"Int",
		returning:"interaction"
	},
	json: `scalar.json` as const,
	jsonb: `scalar.jsonb` as const,
	landingPageTemplate:{
		created_at:"timestamptz",
		htmlTemplate:"String",
		id:"uuid",
		updated_at:"timestamptz"
	},
	landingPageTemplate_aggregate:{
		aggregate:"landingPageTemplate_aggregate_fields",
		nodes:"landingPageTemplate"
	},
	landingPageTemplate_aggregate_fields:{
		count:"Int",
		max:"landingPageTemplate_max_fields",
		min:"landingPageTemplate_min_fields"
	},
	landingPageTemplate_max_fields:{
		created_at:"timestamptz",
		htmlTemplate:"String",
		id:"uuid",
		updated_at:"timestamptz"
	},
	landingPageTemplate_min_fields:{
		created_at:"timestamptz",
		htmlTemplate:"String",
		id:"uuid",
		updated_at:"timestamptz"
	},
	landingPageTemplate_mutation_response:{
		affected_rows:"Int",
		returning:"landingPageTemplate"
	},
	mutation_root:{
		blogPostGeneratorEnhancedForCopy:"uuid",
		createAutomaticProject:"CreateAutomaticProjectOutput",
		createImageUsingImagine:"CreateImageUsingImagineOutput",
		deleteAuthProvider:"authProviders",
		deleteAuthProviderRequest:"authProviderRequests",
		deleteAuthProviderRequests:"authProviderRequests_mutation_response",
		deleteAuthProviders:"authProviders_mutation_response",
		deleteAuthRefreshToken:"authRefreshTokens",
		deleteAuthRefreshTokenType:"authRefreshTokenTypes",
		deleteAuthRefreshTokenTypes:"authRefreshTokenTypes_mutation_response",
		deleteAuthRefreshTokens:"authRefreshTokens_mutation_response",
		deleteAuthRole:"authRoles",
		deleteAuthRoles:"authRoles_mutation_response",
		deleteAuthUserProvider:"authUserProviders",
		deleteAuthUserProviders:"authUserProviders_mutation_response",
		deleteAuthUserRole:"authUserRoles",
		deleteAuthUserRoles:"authUserRoles_mutation_response",
		deleteAuthUserSecurityKey:"authUserSecurityKeys",
		deleteAuthUserSecurityKeys:"authUserSecurityKeys_mutation_response",
		deleteBucket:"buckets",
		deleteBuckets:"buckets_mutation_response",
		deleteFile:"files",
		deleteFiles:"files_mutation_response",
		deleteUser:"users",
		deleteUsers:"users_mutation_response",
		deleteVirus:"virus",
		deleteViruses:"virus_mutation_response",
		delete_AppIntegration:"AppIntegration_mutation_response",
		delete_AppIntegration_by_pk:"AppIntegration",
		delete_Course:"Course_mutation_response",
		delete_CourseCategory:"CourseCategory_mutation_response",
		delete_CourseCategory_by_pk:"CourseCategory",
		delete_Course_by_pk:"Course",
		delete_GeneratedPromptByTemplate:"GeneratedPromptByTemplate_mutation_response",
		delete_GeneratedPromptByTemplateImage:"GeneratedPromptByTemplateImage_mutation_response",
		delete_GeneratedPromptByTemplateImage_by_pk:"GeneratedPromptByTemplateImage",
		delete_GeneratedPromptByTemplate_by_pk:"GeneratedPromptByTemplate",
		delete_TextGeneratorOption:"TextGeneratorOption_mutation_response",
		delete_TextGeneratorOptionEntry:"TextGeneratorOptionEntry_mutation_response",
		delete_TextGeneratorOptionEntry_by_pk:"TextGeneratorOptionEntry",
		delete_TextGeneratorOption_by_pk:"TextGeneratorOption",
		delete_TextPromptTemplate:"TextPromptTemplate_mutation_response",
		delete_TextPromptTemplateCategory:"TextPromptTemplateCategory_mutation_response",
		delete_TextPromptTemplateCategory_by_pk:"TextPromptTemplateCategory",
		delete_TextPromptTemplate_by_pk:"TextPromptTemplate",
		delete_TextTemplateClientCategory:"TextTemplateClientCategory_mutation_response",
		delete_TextTemplateClientCategory_by_pk:"TextTemplateClientCategory",
		delete_audiences:"audiences_mutation_response",
		delete_audiences_by_pk:"audiences",
		delete_blog:"blog_mutation_response",
		delete_blogCategory:"blogCategory_mutation_response",
		delete_blogCategory_by_pk:"blogCategory",
		delete_blog_by_pk:"blog",
		delete_brands:"brands_mutation_response",
		delete_brands_by_pk:"brands",
		delete_campaign:"campaign_mutation_response",
		delete_campaign_by_pk:"campaign",
		delete_channels:"channels_mutation_response",
		delete_channels_by_pk:"channels",
		delete_costTable:"costTable_mutation_response",
		delete_costTable_by_pk:"costTable",
		delete_deal:"deal_mutation_response",
		delete_dealStatus:"dealStatus_mutation_response",
		delete_dealStatus_by_pk:"dealStatus",
		delete_deal_by_pk:"deal",
		delete_displayCategory:"displayCategory_mutation_response",
		delete_displayCategory_by_pk:"displayCategory",
		delete_displayDimension:"displayDimension_mutation_response",
		delete_displayDimension_by_pk:"displayDimension",
		delete_displayFormats:"displayFormats_mutation_response",
		delete_displayFormats_by_pk:"displayFormats",
		delete_displayTemplate:"displayTemplate_mutation_response",
		delete_displayTemplate_by_pk:"displayTemplate",
		delete_interaction:"interaction_mutation_response",
		delete_interaction_by_pk:"interaction",
		delete_landingPageTemplate:"landingPageTemplate_mutation_response",
		delete_landingPageTemplate_by_pk:"landingPageTemplate",
		delete_newTemplateType:"newTemplateType_mutation_response",
		delete_newTemplateType_by_pk:"newTemplateType",
		delete_openAIApiKey:"openAIApiKey_mutation_response",
		delete_openAIApiKey_by_pk:"openAIApiKey",
		delete_pTemplate:"pTemplate_mutation_response",
		delete_pTemplate_by_pk:"pTemplate",
		delete_pricingPlan:"pricingPlan_mutation_response",
		delete_pricingPlan_by_pk:"pricingPlan",
		delete_products:"products_mutation_response",
		delete_products_by_pk:"products",
		delete_project:"project_mutation_response",
		delete_projectImage:"projectImage_mutation_response",
		delete_projectImage_by_pk:"projectImage",
		delete_project_by_pk:"project",
		delete_task:"task_mutation_response",
		delete_task_by_pk:"task",
		delete_textTemplateDisplayFormat:"textTemplateDisplayFormat_mutation_response",
		delete_textTemplateDisplayFormat_by_pk:"textTemplateDisplayFormat",
		delete_userAppIntegration:"userAppIntegration_mutation_response",
		delete_userAppIntegration_by_pk:"userAppIntegration",
		delete_userCreditUsage:"userCreditUsage_mutation_response",
		delete_userCreditUsageSummary:"userCreditUsageSummary_mutation_response",
		delete_userCreditUsageSummary_by_pk:"userCreditUsageSummary",
		delete_userCreditUsage_by_pk:"userCreditUsage",
		delete_userCredits:"userCredits_mutation_response",
		delete_userCredits_by_pk:"userCredits",
		delete_userFeedback:"userFeedback_mutation_response",
		delete_userFeedback_by_pk:"userFeedback",
		delete_userMedia:"userMedia_mutation_response",
		delete_userMedia_by_pk:"userMedia",
		delete_userMetadata:"userMetadata_mutation_response",
		delete_userMetadata_by_pk:"userMetadata",
		delete_userOnboarding:"userOnboarding_mutation_response",
		delete_userOnboarding_by_pk:"userOnboarding",
		delete_userTemplateUsage:"userTemplateUsage_mutation_response",
		delete_userTemplateUsage_by_pk:"userTemplateUsage",
		delete_website:"website_mutation_response",
		delete_website_by_pk:"website",
		exportToWordpress:"ExportToWordpressOutput",
		facebookAuthCallback:"FacebookAuthCallbackOutput",
		finishOnboard:"FinishOnboardOutput",
		generateCalendarEx:"GenerateCalendarExOutput",
		generateFromTextTemplate:"GenerateFromTextTemplateOutput",
		generateNextMonthCalendar:"uuid",
		getInstagramAuthCallback:"GetInstagramAuthCallbackOutput",
		get_next_openai_apikey:"openAIApiKey",
		insertAuthProvider:"authProviders",
		insertAuthProviderRequest:"authProviderRequests",
		insertAuthProviderRequests:"authProviderRequests_mutation_response",
		insertAuthProviders:"authProviders_mutation_response",
		insertAuthRefreshToken:"authRefreshTokens",
		insertAuthRefreshTokenType:"authRefreshTokenTypes",
		insertAuthRefreshTokenTypes:"authRefreshTokenTypes_mutation_response",
		insertAuthRefreshTokens:"authRefreshTokens_mutation_response",
		insertAuthRole:"authRoles",
		insertAuthRoles:"authRoles_mutation_response",
		insertAuthUserProvider:"authUserProviders",
		insertAuthUserProviders:"authUserProviders_mutation_response",
		insertAuthUserRole:"authUserRoles",
		insertAuthUserRoles:"authUserRoles_mutation_response",
		insertAuthUserSecurityKey:"authUserSecurityKeys",
		insertAuthUserSecurityKeys:"authUserSecurityKeys_mutation_response",
		insertBucket:"buckets",
		insertBuckets:"buckets_mutation_response",
		insertFile:"files",
		insertFiles:"files_mutation_response",
		insertUser:"users",
		insertUsers:"users_mutation_response",
		insertVirus:"virus",
		insertViruses:"virus_mutation_response",
		insert_AppIntegration:"AppIntegration_mutation_response",
		insert_AppIntegration_one:"AppIntegration",
		insert_Course:"Course_mutation_response",
		insert_CourseCategory:"CourseCategory_mutation_response",
		insert_CourseCategory_one:"CourseCategory",
		insert_Course_one:"Course",
		insert_GeneratedPromptByTemplate:"GeneratedPromptByTemplate_mutation_response",
		insert_GeneratedPromptByTemplateImage:"GeneratedPromptByTemplateImage_mutation_response",
		insert_GeneratedPromptByTemplateImage_one:"GeneratedPromptByTemplateImage",
		insert_GeneratedPromptByTemplate_one:"GeneratedPromptByTemplate",
		insert_TextGeneratorOption:"TextGeneratorOption_mutation_response",
		insert_TextGeneratorOptionEntry:"TextGeneratorOptionEntry_mutation_response",
		insert_TextGeneratorOptionEntry_one:"TextGeneratorOptionEntry",
		insert_TextGeneratorOption_one:"TextGeneratorOption",
		insert_TextPromptTemplate:"TextPromptTemplate_mutation_response",
		insert_TextPromptTemplateCategory:"TextPromptTemplateCategory_mutation_response",
		insert_TextPromptTemplateCategory_one:"TextPromptTemplateCategory",
		insert_TextPromptTemplate_one:"TextPromptTemplate",
		insert_TextTemplateClientCategory:"TextTemplateClientCategory_mutation_response",
		insert_TextTemplateClientCategory_one:"TextTemplateClientCategory",
		insert_audiences:"audiences_mutation_response",
		insert_audiences_one:"audiences",
		insert_blog:"blog_mutation_response",
		insert_blogCategory:"blogCategory_mutation_response",
		insert_blogCategory_one:"blogCategory",
		insert_blog_one:"blog",
		insert_brands:"brands_mutation_response",
		insert_brands_one:"brands",
		insert_campaign:"campaign_mutation_response",
		insert_campaign_one:"campaign",
		insert_channels:"channels_mutation_response",
		insert_channels_one:"channels",
		insert_costTable:"costTable_mutation_response",
		insert_costTable_one:"costTable",
		insert_deal:"deal_mutation_response",
		insert_dealStatus:"dealStatus_mutation_response",
		insert_dealStatus_one:"dealStatus",
		insert_deal_one:"deal",
		insert_displayCategory:"displayCategory_mutation_response",
		insert_displayCategory_one:"displayCategory",
		insert_displayDimension:"displayDimension_mutation_response",
		insert_displayDimension_one:"displayDimension",
		insert_displayFormats:"displayFormats_mutation_response",
		insert_displayFormats_one:"displayFormats",
		insert_displayTemplate:"displayTemplate_mutation_response",
		insert_displayTemplate_one:"displayTemplate",
		insert_interaction:"interaction_mutation_response",
		insert_interaction_one:"interaction",
		insert_landingPageTemplate:"landingPageTemplate_mutation_response",
		insert_landingPageTemplate_one:"landingPageTemplate",
		insert_newTemplateType:"newTemplateType_mutation_response",
		insert_newTemplateType_one:"newTemplateType",
		insert_openAIApiKey:"openAIApiKey_mutation_response",
		insert_openAIApiKey_one:"openAIApiKey",
		insert_pTemplate:"pTemplate_mutation_response",
		insert_pTemplate_one:"pTemplate",
		insert_pricingPlan:"pricingPlan_mutation_response",
		insert_pricingPlan_one:"pricingPlan",
		insert_products:"products_mutation_response",
		insert_products_one:"products",
		insert_project:"project_mutation_response",
		insert_projectImage:"projectImage_mutation_response",
		insert_projectImage_one:"projectImage",
		insert_project_one:"project",
		insert_task:"task_mutation_response",
		insert_task_one:"task",
		insert_textTemplateDisplayFormat:"textTemplateDisplayFormat_mutation_response",
		insert_textTemplateDisplayFormat_one:"textTemplateDisplayFormat",
		insert_userAppIntegration:"userAppIntegration_mutation_response",
		insert_userAppIntegration_one:"userAppIntegration",
		insert_userCreditUsage:"userCreditUsage_mutation_response",
		insert_userCreditUsageSummary:"userCreditUsageSummary_mutation_response",
		insert_userCreditUsageSummary_one:"userCreditUsageSummary",
		insert_userCreditUsage_one:"userCreditUsage",
		insert_userCredits:"userCredits_mutation_response",
		insert_userCredits_one:"userCredits",
		insert_userFeedback:"userFeedback_mutation_response",
		insert_userFeedback_one:"userFeedback",
		insert_userMedia:"userMedia_mutation_response",
		insert_userMedia_one:"userMedia",
		insert_userMetadata:"userMetadata_mutation_response",
		insert_userMetadata_one:"userMetadata",
		insert_userOnboarding:"userOnboarding_mutation_response",
		insert_userOnboarding_one:"userOnboarding",
		insert_userTemplateUsage:"userTemplateUsage_mutation_response",
		insert_userTemplateUsage_one:"userTemplateUsage",
		insert_website:"website_mutation_response",
		insert_website_one:"website",
		linkedInTokenCallback:"LinkedInTokenCallbackOutput",
		postCarouselToInstagram:"PostCarouselToInstagramOutput",
		postFeedToInstagram:"PostFeedToInstagramOutput",
		postStoryToInstagram:"PostStoryToInstagramOutput",
		postToFacebook:"PostToFacebookOutput",
		postToLinkedIn:"PostToLinkedInOutput",
		publishOrScheduleProject:"PublishOrScheduleProjectOutput",
		purePrompt:"PromptOutput",
		renderAndSaveDisplayImage:"RenderAndSaveDisplayImageOutput",
		saveAndGeneratePolotnoImage:"SaveAndGeneratePolotnoImageOutput",
		selectNewDisplay:"SelectNewDisplayOutput",
		updateAuthProvider:"authProviders",
		updateAuthProviderRequest:"authProviderRequests",
		updateAuthProviderRequests:"authProviderRequests_mutation_response",
		updateAuthProviders:"authProviders_mutation_response",
		updateAuthRefreshToken:"authRefreshTokens",
		updateAuthRefreshTokenType:"authRefreshTokenTypes",
		updateAuthRefreshTokenTypes:"authRefreshTokenTypes_mutation_response",
		updateAuthRefreshTokens:"authRefreshTokens_mutation_response",
		updateAuthRole:"authRoles",
		updateAuthRoles:"authRoles_mutation_response",
		updateAuthUserProvider:"authUserProviders",
		updateAuthUserProviders:"authUserProviders_mutation_response",
		updateAuthUserRole:"authUserRoles",
		updateAuthUserRoles:"authUserRoles_mutation_response",
		updateAuthUserSecurityKey:"authUserSecurityKeys",
		updateAuthUserSecurityKeys:"authUserSecurityKeys_mutation_response",
		updateBucket:"buckets",
		updateBuckets:"buckets_mutation_response",
		updateFile:"files",
		updateFiles:"files_mutation_response",
		updateUser:"users",
		updateUserAvatar:"UpdateUserAvatarOutput",
		updateUsers:"users_mutation_response",
		updateVirus:"virus",
		updateViruses:"virus_mutation_response",
		update_AppIntegration:"AppIntegration_mutation_response",
		update_AppIntegration_by_pk:"AppIntegration",
		update_AppIntegration_many:"AppIntegration_mutation_response",
		update_Course:"Course_mutation_response",
		update_CourseCategory:"CourseCategory_mutation_response",
		update_CourseCategory_by_pk:"CourseCategory",
		update_CourseCategory_many:"CourseCategory_mutation_response",
		update_Course_by_pk:"Course",
		update_Course_many:"Course_mutation_response",
		update_GeneratedPromptByTemplate:"GeneratedPromptByTemplate_mutation_response",
		update_GeneratedPromptByTemplateImage:"GeneratedPromptByTemplateImage_mutation_response",
		update_GeneratedPromptByTemplateImage_by_pk:"GeneratedPromptByTemplateImage",
		update_GeneratedPromptByTemplateImage_many:"GeneratedPromptByTemplateImage_mutation_response",
		update_GeneratedPromptByTemplate_by_pk:"GeneratedPromptByTemplate",
		update_GeneratedPromptByTemplate_many:"GeneratedPromptByTemplate_mutation_response",
		update_TextGeneratorOption:"TextGeneratorOption_mutation_response",
		update_TextGeneratorOptionEntry:"TextGeneratorOptionEntry_mutation_response",
		update_TextGeneratorOptionEntry_by_pk:"TextGeneratorOptionEntry",
		update_TextGeneratorOptionEntry_many:"TextGeneratorOptionEntry_mutation_response",
		update_TextGeneratorOption_by_pk:"TextGeneratorOption",
		update_TextGeneratorOption_many:"TextGeneratorOption_mutation_response",
		update_TextPromptTemplate:"TextPromptTemplate_mutation_response",
		update_TextPromptTemplateCategory:"TextPromptTemplateCategory_mutation_response",
		update_TextPromptTemplateCategory_by_pk:"TextPromptTemplateCategory",
		update_TextPromptTemplateCategory_many:"TextPromptTemplateCategory_mutation_response",
		update_TextPromptTemplate_by_pk:"TextPromptTemplate",
		update_TextPromptTemplate_many:"TextPromptTemplate_mutation_response",
		update_TextTemplateClientCategory:"TextTemplateClientCategory_mutation_response",
		update_TextTemplateClientCategory_by_pk:"TextTemplateClientCategory",
		update_TextTemplateClientCategory_many:"TextTemplateClientCategory_mutation_response",
		update_audiences:"audiences_mutation_response",
		update_audiences_by_pk:"audiences",
		update_audiences_many:"audiences_mutation_response",
		update_authProviderRequests_many:"authProviderRequests_mutation_response",
		update_authProviders_many:"authProviders_mutation_response",
		update_authRefreshTokenTypes_many:"authRefreshTokenTypes_mutation_response",
		update_authRefreshTokens_many:"authRefreshTokens_mutation_response",
		update_authRoles_many:"authRoles_mutation_response",
		update_authUserProviders_many:"authUserProviders_mutation_response",
		update_authUserRoles_many:"authUserRoles_mutation_response",
		update_authUserSecurityKeys_many:"authUserSecurityKeys_mutation_response",
		update_blog:"blog_mutation_response",
		update_blogCategory:"blogCategory_mutation_response",
		update_blogCategory_by_pk:"blogCategory",
		update_blogCategory_many:"blogCategory_mutation_response",
		update_blog_by_pk:"blog",
		update_blog_many:"blog_mutation_response",
		update_brands:"brands_mutation_response",
		update_brands_by_pk:"brands",
		update_brands_many:"brands_mutation_response",
		update_buckets_many:"buckets_mutation_response",
		update_campaign:"campaign_mutation_response",
		update_campaign_by_pk:"campaign",
		update_campaign_many:"campaign_mutation_response",
		update_channels:"channels_mutation_response",
		update_channels_by_pk:"channels",
		update_channels_many:"channels_mutation_response",
		update_costTable:"costTable_mutation_response",
		update_costTable_by_pk:"costTable",
		update_costTable_many:"costTable_mutation_response",
		update_deal:"deal_mutation_response",
		update_dealStatus:"dealStatus_mutation_response",
		update_dealStatus_by_pk:"dealStatus",
		update_dealStatus_many:"dealStatus_mutation_response",
		update_deal_by_pk:"deal",
		update_deal_many:"deal_mutation_response",
		update_displayCategory:"displayCategory_mutation_response",
		update_displayCategory_by_pk:"displayCategory",
		update_displayCategory_many:"displayCategory_mutation_response",
		update_displayDimension:"displayDimension_mutation_response",
		update_displayDimension_by_pk:"displayDimension",
		update_displayDimension_many:"displayDimension_mutation_response",
		update_displayFormats:"displayFormats_mutation_response",
		update_displayFormats_by_pk:"displayFormats",
		update_displayFormats_many:"displayFormats_mutation_response",
		update_displayTemplate:"displayTemplate_mutation_response",
		update_displayTemplate_by_pk:"displayTemplate",
		update_displayTemplate_many:"displayTemplate_mutation_response",
		update_files_many:"files_mutation_response",
		update_interaction:"interaction_mutation_response",
		update_interaction_by_pk:"interaction",
		update_interaction_many:"interaction_mutation_response",
		update_landingPageTemplate:"landingPageTemplate_mutation_response",
		update_landingPageTemplate_by_pk:"landingPageTemplate",
		update_landingPageTemplate_many:"landingPageTemplate_mutation_response",
		update_newTemplateType:"newTemplateType_mutation_response",
		update_newTemplateType_by_pk:"newTemplateType",
		update_newTemplateType_many:"newTemplateType_mutation_response",
		update_openAIApiKey:"openAIApiKey_mutation_response",
		update_openAIApiKey_by_pk:"openAIApiKey",
		update_openAIApiKey_many:"openAIApiKey_mutation_response",
		update_pTemplate:"pTemplate_mutation_response",
		update_pTemplate_by_pk:"pTemplate",
		update_pTemplate_many:"pTemplate_mutation_response",
		update_pricingPlan:"pricingPlan_mutation_response",
		update_pricingPlan_by_pk:"pricingPlan",
		update_pricingPlan_many:"pricingPlan_mutation_response",
		update_products:"products_mutation_response",
		update_products_by_pk:"products",
		update_products_many:"products_mutation_response",
		update_project:"project_mutation_response",
		update_projectImage:"projectImage_mutation_response",
		update_projectImage_by_pk:"projectImage",
		update_projectImage_many:"projectImage_mutation_response",
		update_project_by_pk:"project",
		update_project_many:"project_mutation_response",
		update_task:"task_mutation_response",
		update_task_by_pk:"task",
		update_task_many:"task_mutation_response",
		update_textTemplateDisplayFormat:"textTemplateDisplayFormat_mutation_response",
		update_textTemplateDisplayFormat_by_pk:"textTemplateDisplayFormat",
		update_textTemplateDisplayFormat_many:"textTemplateDisplayFormat_mutation_response",
		update_userAppIntegration:"userAppIntegration_mutation_response",
		update_userAppIntegration_by_pk:"userAppIntegration",
		update_userAppIntegration_many:"userAppIntegration_mutation_response",
		update_userCreditUsage:"userCreditUsage_mutation_response",
		update_userCreditUsageSummary:"userCreditUsageSummary_mutation_response",
		update_userCreditUsageSummary_by_pk:"userCreditUsageSummary",
		update_userCreditUsageSummary_many:"userCreditUsageSummary_mutation_response",
		update_userCreditUsage_by_pk:"userCreditUsage",
		update_userCreditUsage_many:"userCreditUsage_mutation_response",
		update_userCredits:"userCredits_mutation_response",
		update_userCredits_by_pk:"userCredits",
		update_userCredits_many:"userCredits_mutation_response",
		update_userFeedback:"userFeedback_mutation_response",
		update_userFeedback_by_pk:"userFeedback",
		update_userFeedback_many:"userFeedback_mutation_response",
		update_userMedia:"userMedia_mutation_response",
		update_userMedia_by_pk:"userMedia",
		update_userMedia_many:"userMedia_mutation_response",
		update_userMetadata:"userMetadata_mutation_response",
		update_userMetadata_by_pk:"userMetadata",
		update_userMetadata_many:"userMetadata_mutation_response",
		update_userOnboarding:"userOnboarding_mutation_response",
		update_userOnboarding_by_pk:"userOnboarding",
		update_userOnboarding_many:"userOnboarding_mutation_response",
		update_userTemplateUsage:"userTemplateUsage_mutation_response",
		update_userTemplateUsage_by_pk:"userTemplateUsage",
		update_userTemplateUsage_many:"userTemplateUsage_mutation_response",
		update_users_many:"users_mutation_response",
		update_virus_many:"virus_mutation_response",
		update_website:"website_mutation_response",
		update_website_by_pk:"website",
		update_website_many:"website_mutation_response",
		upsertAudience:"UpsertAudienceOutput",
		upsertBrand:"upsertBrandOutput"
	},
	newTemplateType:{
		channel:"String",
		created_at:"timestamptz",
		creditPrice:"Int",
		description:"String",
		icon:"String",
		id:"uuid",
		name:"String",
		saleCreditPrice:"Int",
		thumbnailFileId:"uuid",
		updated_at:"timestamptz"
	},
	newTemplateType_aggregate:{
		aggregate:"newTemplateType_aggregate_fields",
		nodes:"newTemplateType"
	},
	newTemplateType_aggregate_fields:{
		avg:"newTemplateType_avg_fields",
		count:"Int",
		max:"newTemplateType_max_fields",
		min:"newTemplateType_min_fields",
		stddev:"newTemplateType_stddev_fields",
		stddev_pop:"newTemplateType_stddev_pop_fields",
		stddev_samp:"newTemplateType_stddev_samp_fields",
		sum:"newTemplateType_sum_fields",
		var_pop:"newTemplateType_var_pop_fields",
		var_samp:"newTemplateType_var_samp_fields",
		variance:"newTemplateType_variance_fields"
	},
	newTemplateType_avg_fields:{
		creditPrice:"Float",
		saleCreditPrice:"Float"
	},
	newTemplateType_max_fields:{
		channel:"String",
		created_at:"timestamptz",
		creditPrice:"Int",
		description:"String",
		icon:"String",
		id:"uuid",
		name:"String",
		saleCreditPrice:"Int",
		thumbnailFileId:"uuid",
		updated_at:"timestamptz"
	},
	newTemplateType_min_fields:{
		channel:"String",
		created_at:"timestamptz",
		creditPrice:"Int",
		description:"String",
		icon:"String",
		id:"uuid",
		name:"String",
		saleCreditPrice:"Int",
		thumbnailFileId:"uuid",
		updated_at:"timestamptz"
	},
	newTemplateType_mutation_response:{
		affected_rows:"Int",
		returning:"newTemplateType"
	},
	newTemplateType_stddev_fields:{
		creditPrice:"Float",
		saleCreditPrice:"Float"
	},
	newTemplateType_stddev_pop_fields:{
		creditPrice:"Float",
		saleCreditPrice:"Float"
	},
	newTemplateType_stddev_samp_fields:{
		creditPrice:"Float",
		saleCreditPrice:"Float"
	},
	newTemplateType_sum_fields:{
		creditPrice:"Int",
		saleCreditPrice:"Int"
	},
	newTemplateType_var_pop_fields:{
		creditPrice:"Float",
		saleCreditPrice:"Float"
	},
	newTemplateType_var_samp_fields:{
		creditPrice:"Float",
		saleCreditPrice:"Float"
	},
	newTemplateType_variance_fields:{
		creditPrice:"Float",
		saleCreditPrice:"Float"
	},
	numeric: `scalar.numeric` as const,
	openAIApiKey:{
		apiKey:"String",
		created_at:"timestamptz",
		id:"uuid",
		last_used:"timestamptz",
		status:"String"
	},
	openAIApiKey_aggregate:{
		aggregate:"openAIApiKey_aggregate_fields",
		nodes:"openAIApiKey"
	},
	openAIApiKey_aggregate_fields:{
		count:"Int",
		max:"openAIApiKey_max_fields",
		min:"openAIApiKey_min_fields"
	},
	openAIApiKey_max_fields:{
		apiKey:"String",
		created_at:"timestamptz",
		id:"uuid",
		last_used:"timestamptz",
		status:"String"
	},
	openAIApiKey_min_fields:{
		apiKey:"String",
		created_at:"timestamptz",
		id:"uuid",
		last_used:"timestamptz",
		status:"String"
	},
	openAIApiKey_mutation_response:{
		affected_rows:"Int",
		returning:"openAIApiKey"
	},
	pTemplate:{
		category:"String",
		createdBy:"uuid",
		created_at:"timestamptz",
		deletedAt:"timestamptz",
		descriptionMaxLength:"Int",
		height:"String",
		id:"uuid",
		json:"jsonb",
		previewFileId:"uuid",
		subtitleMaxLength:"Int",
		tagScores:"jsonb",
		titleMaxLength:"Int",
		updated_at:"timestamptz",
		visibility:"String",
		width:"String"
	},
	pTemplate_aggregate:{
		aggregate:"pTemplate_aggregate_fields",
		nodes:"pTemplate"
	},
	pTemplate_aggregate_fields:{
		avg:"pTemplate_avg_fields",
		count:"Int",
		max:"pTemplate_max_fields",
		min:"pTemplate_min_fields",
		stddev:"pTemplate_stddev_fields",
		stddev_pop:"pTemplate_stddev_pop_fields",
		stddev_samp:"pTemplate_stddev_samp_fields",
		sum:"pTemplate_sum_fields",
		var_pop:"pTemplate_var_pop_fields",
		var_samp:"pTemplate_var_samp_fields",
		variance:"pTemplate_variance_fields"
	},
	pTemplate_avg_fields:{
		descriptionMaxLength:"Float",
		subtitleMaxLength:"Float",
		titleMaxLength:"Float"
	},
	pTemplate_max_fields:{
		category:"String",
		createdBy:"uuid",
		created_at:"timestamptz",
		deletedAt:"timestamptz",
		descriptionMaxLength:"Int",
		height:"String",
		id:"uuid",
		previewFileId:"uuid",
		subtitleMaxLength:"Int",
		titleMaxLength:"Int",
		updated_at:"timestamptz",
		visibility:"String",
		width:"String"
	},
	pTemplate_min_fields:{
		category:"String",
		createdBy:"uuid",
		created_at:"timestamptz",
		deletedAt:"timestamptz",
		descriptionMaxLength:"Int",
		height:"String",
		id:"uuid",
		previewFileId:"uuid",
		subtitleMaxLength:"Int",
		titleMaxLength:"Int",
		updated_at:"timestamptz",
		visibility:"String",
		width:"String"
	},
	pTemplate_mutation_response:{
		affected_rows:"Int",
		returning:"pTemplate"
	},
	pTemplate_stddev_fields:{
		descriptionMaxLength:"Float",
		subtitleMaxLength:"Float",
		titleMaxLength:"Float"
	},
	pTemplate_stddev_pop_fields:{
		descriptionMaxLength:"Float",
		subtitleMaxLength:"Float",
		titleMaxLength:"Float"
	},
	pTemplate_stddev_samp_fields:{
		descriptionMaxLength:"Float",
		subtitleMaxLength:"Float",
		titleMaxLength:"Float"
	},
	pTemplate_sum_fields:{
		descriptionMaxLength:"Int",
		subtitleMaxLength:"Int",
		titleMaxLength:"Int"
	},
	pTemplate_var_pop_fields:{
		descriptionMaxLength:"Float",
		subtitleMaxLength:"Float",
		titleMaxLength:"Float"
	},
	pTemplate_var_samp_fields:{
		descriptionMaxLength:"Float",
		subtitleMaxLength:"Float",
		titleMaxLength:"Float"
	},
	pTemplate_variance_fields:{
		descriptionMaxLength:"Float",
		subtitleMaxLength:"Float",
		titleMaxLength:"Float"
	},
	pricingPlan:{
		active:"Boolean",
		alias:"String",
		created_at:"timestamptz",
		creditUnitDiscount:"numeric",
		creditsToAdd:"numeric",
		htmlFeatureItems:"String",
		id:"uuid",
		monthlyPrice:"numeric",
		order:"numeric",
		stripePriceId:"String",
		stripeProductId:"String",
		type:"String",
		updated_at:"timestamptz"
	},
	pricingPlan_aggregate:{
		aggregate:"pricingPlan_aggregate_fields",
		nodes:"pricingPlan"
	},
	pricingPlan_aggregate_fields:{
		avg:"pricingPlan_avg_fields",
		count:"Int",
		max:"pricingPlan_max_fields",
		min:"pricingPlan_min_fields",
		stddev:"pricingPlan_stddev_fields",
		stddev_pop:"pricingPlan_stddev_pop_fields",
		stddev_samp:"pricingPlan_stddev_samp_fields",
		sum:"pricingPlan_sum_fields",
		var_pop:"pricingPlan_var_pop_fields",
		var_samp:"pricingPlan_var_samp_fields",
		variance:"pricingPlan_variance_fields"
	},
	pricingPlan_avg_fields:{
		creditUnitDiscount:"Float",
		creditsToAdd:"Float",
		monthlyPrice:"Float",
		order:"Float"
	},
	pricingPlan_max_fields:{
		alias:"String",
		created_at:"timestamptz",
		creditUnitDiscount:"numeric",
		creditsToAdd:"numeric",
		htmlFeatureItems:"String",
		id:"uuid",
		monthlyPrice:"numeric",
		order:"numeric",
		stripePriceId:"String",
		stripeProductId:"String",
		type:"String",
		updated_at:"timestamptz"
	},
	pricingPlan_min_fields:{
		alias:"String",
		created_at:"timestamptz",
		creditUnitDiscount:"numeric",
		creditsToAdd:"numeric",
		htmlFeatureItems:"String",
		id:"uuid",
		monthlyPrice:"numeric",
		order:"numeric",
		stripePriceId:"String",
		stripeProductId:"String",
		type:"String",
		updated_at:"timestamptz"
	},
	pricingPlan_mutation_response:{
		affected_rows:"Int",
		returning:"pricingPlan"
	},
	pricingPlan_stddev_fields:{
		creditUnitDiscount:"Float",
		creditsToAdd:"Float",
		monthlyPrice:"Float",
		order:"Float"
	},
	pricingPlan_stddev_pop_fields:{
		creditUnitDiscount:"Float",
		creditsToAdd:"Float",
		monthlyPrice:"Float",
		order:"Float"
	},
	pricingPlan_stddev_samp_fields:{
		creditUnitDiscount:"Float",
		creditsToAdd:"Float",
		monthlyPrice:"Float",
		order:"Float"
	},
	pricingPlan_sum_fields:{
		creditUnitDiscount:"numeric",
		creditsToAdd:"numeric",
		monthlyPrice:"numeric",
		order:"numeric"
	},
	pricingPlan_var_pop_fields:{
		creditUnitDiscount:"Float",
		creditsToAdd:"Float",
		monthlyPrice:"Float",
		order:"Float"
	},
	pricingPlan_var_samp_fields:{
		creditUnitDiscount:"Float",
		creditsToAdd:"Float",
		monthlyPrice:"Float",
		order:"Float"
	},
	pricingPlan_variance_fields:{
		creditUnitDiscount:"Float",
		creditsToAdd:"Float",
		monthlyPrice:"Float",
		order:"Float"
	},
	products:{
		alias:"String",
		benefits:"jsonb",
		brand:"brands",
		brandId:"uuid",
		category:"String",
		characteristics:"jsonb",
		created_at:"timestamptz",
		created_by:"uuid",
		deleted:"Boolean",
		description:"String",
		id:"uuid",
		imageFourId:"uuid",
		imageOneId:"uuid",
		imageThreeId:"uuid",
		imageTwoId:"uuid",
		linkConversion:"String",
		price:"numeric",
		salePrice:"numeric",
		testimonials:"jsonb",
		type:"String",
		updated_at:"timestamptz",
		wordKeys:"jsonb"
	},
	products_aggregate:{
		aggregate:"products_aggregate_fields",
		nodes:"products"
	},
	products_aggregate_fields:{
		avg:"products_avg_fields",
		count:"Int",
		max:"products_max_fields",
		min:"products_min_fields",
		stddev:"products_stddev_fields",
		stddev_pop:"products_stddev_pop_fields",
		stddev_samp:"products_stddev_samp_fields",
		sum:"products_sum_fields",
		var_pop:"products_var_pop_fields",
		var_samp:"products_var_samp_fields",
		variance:"products_variance_fields"
	},
	products_avg_fields:{
		price:"Float",
		salePrice:"Float"
	},
	products_max_fields:{
		alias:"String",
		brandId:"uuid",
		category:"String",
		created_at:"timestamptz",
		created_by:"uuid",
		description:"String",
		id:"uuid",
		imageFourId:"uuid",
		imageOneId:"uuid",
		imageThreeId:"uuid",
		imageTwoId:"uuid",
		linkConversion:"String",
		price:"numeric",
		salePrice:"numeric",
		type:"String",
		updated_at:"timestamptz"
	},
	products_min_fields:{
		alias:"String",
		brandId:"uuid",
		category:"String",
		created_at:"timestamptz",
		created_by:"uuid",
		description:"String",
		id:"uuid",
		imageFourId:"uuid",
		imageOneId:"uuid",
		imageThreeId:"uuid",
		imageTwoId:"uuid",
		linkConversion:"String",
		price:"numeric",
		salePrice:"numeric",
		type:"String",
		updated_at:"timestamptz"
	},
	products_mutation_response:{
		affected_rows:"Int",
		returning:"products"
	},
	products_stddev_fields:{
		price:"Float",
		salePrice:"Float"
	},
	products_stddev_pop_fields:{
		price:"Float",
		salePrice:"Float"
	},
	products_stddev_samp_fields:{
		price:"Float",
		salePrice:"Float"
	},
	products_sum_fields:{
		price:"numeric",
		salePrice:"numeric"
	},
	products_var_pop_fields:{
		price:"Float",
		salePrice:"Float"
	},
	products_var_samp_fields:{
		price:"Float",
		salePrice:"Float"
	},
	products_variance_fields:{
		price:"Float",
		salePrice:"Float"
	},
	project:{
		alias:"String",
		audienceId:"uuid",
		blogContent:"String",
		blogTitle:"String",
		brandId:"uuid",
		campaignId:"uuid",
		caption:"String",
		created_at:"timestamptz",
		created_by:"uuid",
		creditPrice:"numeric",
		deleted:"Boolean",
		featuredImageFileId:"uuid",
		id:"uuid",
		previewImageFile:"files",
		previewImageFileId:"uuid",
		productId:"uuid",
		publishDate:"timestamptz",
		rawText:"String",
		scheduledDate:"timestamptz",
		scheduledDateEventId:"uuid",
		status:"String",
		suggestedHeightInPixels:"Int",
		suggestedScheduledDate:"timestamptz",
		suggestedWidthInPixels:"Int",
		template:"jsonb",
		type:"String",
		updated_at:"timestamptz"
	},
	projectImage:{
		fileId:"uuid",
		id:"uuid",
		projectId:"uuid"
	},
	projectImage_aggregate:{
		aggregate:"projectImage_aggregate_fields",
		nodes:"projectImage"
	},
	projectImage_aggregate_fields:{
		count:"Int",
		max:"projectImage_max_fields",
		min:"projectImage_min_fields"
	},
	projectImage_max_fields:{
		fileId:"uuid",
		id:"uuid",
		projectId:"uuid"
	},
	projectImage_min_fields:{
		fileId:"uuid",
		id:"uuid",
		projectId:"uuid"
	},
	projectImage_mutation_response:{
		affected_rows:"Int",
		returning:"projectImage"
	},
	project_aggregate:{
		aggregate:"project_aggregate_fields",
		nodes:"project"
	},
	project_aggregate_fields:{
		avg:"project_avg_fields",
		count:"Int",
		max:"project_max_fields",
		min:"project_min_fields",
		stddev:"project_stddev_fields",
		stddev_pop:"project_stddev_pop_fields",
		stddev_samp:"project_stddev_samp_fields",
		sum:"project_sum_fields",
		var_pop:"project_var_pop_fields",
		var_samp:"project_var_samp_fields",
		variance:"project_variance_fields"
	},
	project_avg_fields:{
		creditPrice:"Float",
		suggestedHeightInPixels:"Float",
		suggestedWidthInPixels:"Float"
	},
	project_max_fields:{
		alias:"String",
		audienceId:"uuid",
		blogContent:"String",
		blogTitle:"String",
		brandId:"uuid",
		campaignId:"uuid",
		caption:"String",
		created_at:"timestamptz",
		created_by:"uuid",
		creditPrice:"numeric",
		featuredImageFileId:"uuid",
		id:"uuid",
		previewImageFileId:"uuid",
		productId:"uuid",
		publishDate:"timestamptz",
		rawText:"String",
		scheduledDate:"timestamptz",
		scheduledDateEventId:"uuid",
		status:"String",
		suggestedHeightInPixels:"Int",
		suggestedScheduledDate:"timestamptz",
		suggestedWidthInPixels:"Int",
		type:"String",
		updated_at:"timestamptz"
	},
	project_min_fields:{
		alias:"String",
		audienceId:"uuid",
		blogContent:"String",
		blogTitle:"String",
		brandId:"uuid",
		campaignId:"uuid",
		caption:"String",
		created_at:"timestamptz",
		created_by:"uuid",
		creditPrice:"numeric",
		featuredImageFileId:"uuid",
		id:"uuid",
		previewImageFileId:"uuid",
		productId:"uuid",
		publishDate:"timestamptz",
		rawText:"String",
		scheduledDate:"timestamptz",
		scheduledDateEventId:"uuid",
		status:"String",
		suggestedHeightInPixels:"Int",
		suggestedScheduledDate:"timestamptz",
		suggestedWidthInPixels:"Int",
		type:"String",
		updated_at:"timestamptz"
	},
	project_mutation_response:{
		affected_rows:"Int",
		returning:"project"
	},
	project_stddev_fields:{
		creditPrice:"Float",
		suggestedHeightInPixels:"Float",
		suggestedWidthInPixels:"Float"
	},
	project_stddev_pop_fields:{
		creditPrice:"Float",
		suggestedHeightInPixels:"Float",
		suggestedWidthInPixels:"Float"
	},
	project_stddev_samp_fields:{
		creditPrice:"Float",
		suggestedHeightInPixels:"Float",
		suggestedWidthInPixels:"Float"
	},
	project_sum_fields:{
		creditPrice:"numeric",
		suggestedHeightInPixels:"Int",
		suggestedWidthInPixels:"Int"
	},
	project_var_pop_fields:{
		creditPrice:"Float",
		suggestedHeightInPixels:"Float",
		suggestedWidthInPixels:"Float"
	},
	project_var_samp_fields:{
		creditPrice:"Float",
		suggestedHeightInPixels:"Float",
		suggestedWidthInPixels:"Float"
	},
	project_variance_fields:{
		creditPrice:"Float",
		suggestedHeightInPixels:"Float",
		suggestedWidthInPixels:"Float"
	},
	query_root:{
		AppIntegration:"AppIntegration",
		AppIntegration_aggregate:"AppIntegration_aggregate",
		AppIntegration_by_pk:"AppIntegration",
		Course:"Course",
		CourseCategory:"CourseCategory",
		CourseCategory_aggregate:"CourseCategory_aggregate",
		CourseCategory_by_pk:"CourseCategory",
		Course_aggregate:"Course_aggregate",
		Course_by_pk:"Course",
		GeneratedPromptByTemplate:"GeneratedPromptByTemplate",
		GeneratedPromptByTemplateImage:"GeneratedPromptByTemplateImage",
		GeneratedPromptByTemplateImage_aggregate:"GeneratedPromptByTemplateImage_aggregate",
		GeneratedPromptByTemplateImage_by_pk:"GeneratedPromptByTemplateImage",
		GeneratedPromptByTemplate_aggregate:"GeneratedPromptByTemplate_aggregate",
		GeneratedPromptByTemplate_by_pk:"GeneratedPromptByTemplate",
		TextGeneratorOption:"TextGeneratorOption",
		TextGeneratorOptionEntry:"TextGeneratorOptionEntry",
		TextGeneratorOptionEntry_aggregate:"TextGeneratorOptionEntry_aggregate",
		TextGeneratorOptionEntry_by_pk:"TextGeneratorOptionEntry",
		TextGeneratorOption_aggregate:"TextGeneratorOption_aggregate",
		TextGeneratorOption_by_pk:"TextGeneratorOption",
		TextPromptTemplate:"TextPromptTemplate",
		TextPromptTemplateCategory:"TextPromptTemplateCategory",
		TextPromptTemplateCategory_aggregate:"TextPromptTemplateCategory_aggregate",
		TextPromptTemplateCategory_by_pk:"TextPromptTemplateCategory",
		TextPromptTemplate_aggregate:"TextPromptTemplate_aggregate",
		TextPromptTemplate_by_pk:"TextPromptTemplate",
		TextTemplateClientCategory:"TextTemplateClientCategory",
		TextTemplateClientCategory_aggregate:"TextTemplateClientCategory_aggregate",
		TextTemplateClientCategory_by_pk:"TextTemplateClientCategory",
		audiences:"audiences",
		audiences_aggregate:"audiences_aggregate",
		audiences_by_pk:"audiences",
		authProvider:"authProviders",
		authProviderRequest:"authProviderRequests",
		authProviderRequests:"authProviderRequests",
		authProviderRequestsAggregate:"authProviderRequests_aggregate",
		authProviders:"authProviders",
		authProvidersAggregate:"authProviders_aggregate",
		authRefreshToken:"authRefreshTokens",
		authRefreshTokenType:"authRefreshTokenTypes",
		authRefreshTokenTypes:"authRefreshTokenTypes",
		authRefreshTokenTypesAggregate:"authRefreshTokenTypes_aggregate",
		authRefreshTokens:"authRefreshTokens",
		authRefreshTokensAggregate:"authRefreshTokens_aggregate",
		authRole:"authRoles",
		authRoles:"authRoles",
		authRolesAggregate:"authRoles_aggregate",
		authUserProvider:"authUserProviders",
		authUserProviders:"authUserProviders",
		authUserProvidersAggregate:"authUserProviders_aggregate",
		authUserRole:"authUserRoles",
		authUserRoles:"authUserRoles",
		authUserRolesAggregate:"authUserRoles_aggregate",
		authUserSecurityKey:"authUserSecurityKeys",
		authUserSecurityKeys:"authUserSecurityKeys",
		authUserSecurityKeysAggregate:"authUserSecurityKeys_aggregate",
		blog:"blog",
		blogCategory:"blogCategory",
		blogCategory_aggregate:"blogCategory_aggregate",
		blogCategory_by_pk:"blogCategory",
		blogPostGeneratorEnhancedForCopy:"blogPostGeneratorEnhancedForCopy",
		blog_aggregate:"blog_aggregate",
		blog_by_pk:"blog",
		brands:"brands",
		brands_aggregate:"brands_aggregate",
		brands_by_pk:"brands",
		bucket:"buckets",
		buckets:"buckets",
		bucketsAggregate:"buckets_aggregate",
		campaign:"campaign",
		campaign_aggregate:"campaign_aggregate",
		campaign_by_pk:"campaign",
		categorysummaryview:"categorysummaryview",
		categorysummaryview_aggregate:"categorysummaryview_aggregate",
		channels:"channels",
		channels_aggregate:"channels_aggregate",
		channels_by_pk:"channels",
		consumptionByChannelView:"consumptionByChannelView",
		consumptionByChannelView_aggregate:"consumptionByChannelView_aggregate",
		costTable:"costTable",
		costTable_aggregate:"costTable_aggregate",
		costTable_by_pk:"costTable",
		deal:"deal",
		dealStatus:"dealStatus",
		dealStatus_aggregate:"dealStatus_aggregate",
		dealStatus_by_pk:"dealStatus",
		deal_aggregate:"deal_aggregate",
		deal_by_pk:"deal",
		displayCategory:"displayCategory",
		displayCategory_aggregate:"displayCategory_aggregate",
		displayCategory_by_pk:"displayCategory",
		displayDimension:"displayDimension",
		displayDimension_aggregate:"displayDimension_aggregate",
		displayDimension_by_pk:"displayDimension",
		displayFormats:"displayFormats",
		displayFormats_aggregate:"displayFormats_aggregate",
		displayFormats_by_pk:"displayFormats",
		displayTemplate:"displayTemplate",
		displayTemplate_aggregate:"displayTemplate_aggregate",
		displayTemplate_by_pk:"displayTemplate",
		file:"files",
		files:"files",
		filesAggregate:"files_aggregate",
		generateImageFromPrompt:"GenerateImageFromPromptOutput",
		generateNextMonthCalendar:"generateNextMonthCalendar",
		getFacebookPages:"GetFacebookPagesOutput",
		getFacebookToken:"GetFacebookTokenOutput",
		getImageFromDisplayRenderer:"GetImageFromDisplayRendererOutput",
		getInstagramPages:"GetInstagramPagesOutput",
		getInstagramToken:"GetInstagramTokenOutput",
		getPaymentUrl:"GetPaymentUrlResponse",
		getWordpressCategories:"GetWordpressCategoriesOutput",
		getWordpressTags:"GetWordpressTagsOutput",
		interaction:"interaction",
		interaction_aggregate:"interaction_aggregate",
		interaction_by_pk:"interaction",
		landingPageTemplate:"landingPageTemplate",
		landingPageTemplate_aggregate:"landingPageTemplate_aggregate",
		landingPageTemplate_by_pk:"landingPageTemplate",
		manageSubscription:"ManageSubscriptionOutput",
		newTemplateType:"newTemplateType",
		newTemplateType_aggregate:"newTemplateType_aggregate",
		newTemplateType_by_pk:"newTemplateType",
		openAIApiKey:"openAIApiKey",
		openAIApiKey_aggregate:"openAIApiKey_aggregate",
		openAIApiKey_by_pk:"openAIApiKey",
		pTemplate:"pTemplate",
		pTemplate_aggregate:"pTemplate_aggregate",
		pTemplate_by_pk:"pTemplate",
		pricingPlan:"pricingPlan",
		pricingPlan_aggregate:"pricingPlan_aggregate",
		pricingPlan_by_pk:"pricingPlan",
		products:"products",
		products_aggregate:"products_aggregate",
		products_by_pk:"products",
		project:"project",
		projectImage:"projectImage",
		projectImage_aggregate:"projectImage_aggregate",
		projectImage_by_pk:"projectImage",
		project_aggregate:"project_aggregate",
		project_by_pk:"project",
		renderDisplay:"RenderDisplayOutput",
		searchImagesFromPexels:"SearchImagesFromPexelsResponse",
		serpStatTest:"SerpStatTestOutput",
		task:"task",
		task_aggregate:"task_aggregate",
		task_by_pk:"task",
		textTemplateDisplayFormat:"textTemplateDisplayFormat",
		textTemplateDisplayFormat_aggregate:"textTemplateDisplayFormat_aggregate",
		textTemplateDisplayFormat_by_pk:"textTemplateDisplayFormat",
		user:"users",
		userAppIntegration:"userAppIntegration",
		userAppIntegration_aggregate:"userAppIntegration_aggregate",
		userAppIntegration_by_pk:"userAppIntegration",
		userCreditUsage:"userCreditUsage",
		userCreditUsageSummary:"userCreditUsageSummary",
		userCreditUsageSummary_aggregate:"userCreditUsageSummary_aggregate",
		userCreditUsageSummary_by_pk:"userCreditUsageSummary",
		userCreditUsage_aggregate:"userCreditUsage_aggregate",
		userCreditUsage_by_pk:"userCreditUsage",
		userCredits:"userCredits",
		userCredits_aggregate:"userCredits_aggregate",
		userCredits_by_pk:"userCredits",
		userFeedback:"userFeedback",
		userFeedback_aggregate:"userFeedback_aggregate",
		userFeedback_by_pk:"userFeedback",
		userMedia:"userMedia",
		userMedia_aggregate:"userMedia_aggregate",
		userMedia_by_pk:"userMedia",
		userMetadata:"userMetadata",
		userMetadata_aggregate:"userMetadata_aggregate",
		userMetadata_by_pk:"userMetadata",
		userOnboarding:"userOnboarding",
		userOnboarding_aggregate:"userOnboarding_aggregate",
		userOnboarding_by_pk:"userOnboarding",
		userTemplateUsage:"userTemplateUsage",
		userTemplateUsage_aggregate:"userTemplateUsage_aggregate",
		userTemplateUsage_by_pk:"userTemplateUsage",
		users:"users",
		usersAggregate:"users_aggregate",
		virus:"virus",
		viruses:"virus",
		virusesAggregate:"virus_aggregate",
		website:"website",
		website_aggregate:"website_aggregate",
		website_by_pk:"website"
	},
	subscription_root:{
		AppIntegration:"AppIntegration",
		AppIntegration_aggregate:"AppIntegration_aggregate",
		AppIntegration_by_pk:"AppIntegration",
		AppIntegration_stream:"AppIntegration",
		Course:"Course",
		CourseCategory:"CourseCategory",
		CourseCategory_aggregate:"CourseCategory_aggregate",
		CourseCategory_by_pk:"CourseCategory",
		CourseCategory_stream:"CourseCategory",
		Course_aggregate:"Course_aggregate",
		Course_by_pk:"Course",
		Course_stream:"Course",
		GeneratedPromptByTemplate:"GeneratedPromptByTemplate",
		GeneratedPromptByTemplateImage:"GeneratedPromptByTemplateImage",
		GeneratedPromptByTemplateImage_aggregate:"GeneratedPromptByTemplateImage_aggregate",
		GeneratedPromptByTemplateImage_by_pk:"GeneratedPromptByTemplateImage",
		GeneratedPromptByTemplateImage_stream:"GeneratedPromptByTemplateImage",
		GeneratedPromptByTemplate_aggregate:"GeneratedPromptByTemplate_aggregate",
		GeneratedPromptByTemplate_by_pk:"GeneratedPromptByTemplate",
		GeneratedPromptByTemplate_stream:"GeneratedPromptByTemplate",
		TextGeneratorOption:"TextGeneratorOption",
		TextGeneratorOptionEntry:"TextGeneratorOptionEntry",
		TextGeneratorOptionEntry_aggregate:"TextGeneratorOptionEntry_aggregate",
		TextGeneratorOptionEntry_by_pk:"TextGeneratorOptionEntry",
		TextGeneratorOptionEntry_stream:"TextGeneratorOptionEntry",
		TextGeneratorOption_aggregate:"TextGeneratorOption_aggregate",
		TextGeneratorOption_by_pk:"TextGeneratorOption",
		TextGeneratorOption_stream:"TextGeneratorOption",
		TextPromptTemplate:"TextPromptTemplate",
		TextPromptTemplateCategory:"TextPromptTemplateCategory",
		TextPromptTemplateCategory_aggregate:"TextPromptTemplateCategory_aggregate",
		TextPromptTemplateCategory_by_pk:"TextPromptTemplateCategory",
		TextPromptTemplateCategory_stream:"TextPromptTemplateCategory",
		TextPromptTemplate_aggregate:"TextPromptTemplate_aggregate",
		TextPromptTemplate_by_pk:"TextPromptTemplate",
		TextPromptTemplate_stream:"TextPromptTemplate",
		TextTemplateClientCategory:"TextTemplateClientCategory",
		TextTemplateClientCategory_aggregate:"TextTemplateClientCategory_aggregate",
		TextTemplateClientCategory_by_pk:"TextTemplateClientCategory",
		TextTemplateClientCategory_stream:"TextTemplateClientCategory",
		audiences:"audiences",
		audiences_aggregate:"audiences_aggregate",
		audiences_by_pk:"audiences",
		audiences_stream:"audiences",
		authProvider:"authProviders",
		authProviderRequest:"authProviderRequests",
		authProviderRequests:"authProviderRequests",
		authProviderRequestsAggregate:"authProviderRequests_aggregate",
		authProviderRequests_stream:"authProviderRequests",
		authProviders:"authProviders",
		authProvidersAggregate:"authProviders_aggregate",
		authProviders_stream:"authProviders",
		authRefreshToken:"authRefreshTokens",
		authRefreshTokenType:"authRefreshTokenTypes",
		authRefreshTokenTypes:"authRefreshTokenTypes",
		authRefreshTokenTypesAggregate:"authRefreshTokenTypes_aggregate",
		authRefreshTokenTypes_stream:"authRefreshTokenTypes",
		authRefreshTokens:"authRefreshTokens",
		authRefreshTokensAggregate:"authRefreshTokens_aggregate",
		authRefreshTokens_stream:"authRefreshTokens",
		authRole:"authRoles",
		authRoles:"authRoles",
		authRolesAggregate:"authRoles_aggregate",
		authRoles_stream:"authRoles",
		authUserProvider:"authUserProviders",
		authUserProviders:"authUserProviders",
		authUserProvidersAggregate:"authUserProviders_aggregate",
		authUserProviders_stream:"authUserProviders",
		authUserRole:"authUserRoles",
		authUserRoles:"authUserRoles",
		authUserRolesAggregate:"authUserRoles_aggregate",
		authUserRoles_stream:"authUserRoles",
		authUserSecurityKey:"authUserSecurityKeys",
		authUserSecurityKeys:"authUserSecurityKeys",
		authUserSecurityKeysAggregate:"authUserSecurityKeys_aggregate",
		authUserSecurityKeys_stream:"authUserSecurityKeys",
		blog:"blog",
		blogCategory:"blogCategory",
		blogCategory_aggregate:"blogCategory_aggregate",
		blogCategory_by_pk:"blogCategory",
		blogCategory_stream:"blogCategory",
		blogPostGeneratorEnhancedForCopy:"blogPostGeneratorEnhancedForCopy",
		blog_aggregate:"blog_aggregate",
		blog_by_pk:"blog",
		blog_stream:"blog",
		brands:"brands",
		brands_aggregate:"brands_aggregate",
		brands_by_pk:"brands",
		brands_stream:"brands",
		bucket:"buckets",
		buckets:"buckets",
		bucketsAggregate:"buckets_aggregate",
		buckets_stream:"buckets",
		campaign:"campaign",
		campaign_aggregate:"campaign_aggregate",
		campaign_by_pk:"campaign",
		campaign_stream:"campaign",
		categorysummaryview:"categorysummaryview",
		categorysummaryview_aggregate:"categorysummaryview_aggregate",
		categorysummaryview_stream:"categorysummaryview",
		channels:"channels",
		channels_aggregate:"channels_aggregate",
		channels_by_pk:"channels",
		channels_stream:"channels",
		consumptionByChannelView:"consumptionByChannelView",
		consumptionByChannelView_aggregate:"consumptionByChannelView_aggregate",
		consumptionByChannelView_stream:"consumptionByChannelView",
		costTable:"costTable",
		costTable_aggregate:"costTable_aggregate",
		costTable_by_pk:"costTable",
		costTable_stream:"costTable",
		deal:"deal",
		dealStatus:"dealStatus",
		dealStatus_aggregate:"dealStatus_aggregate",
		dealStatus_by_pk:"dealStatus",
		dealStatus_stream:"dealStatus",
		deal_aggregate:"deal_aggregate",
		deal_by_pk:"deal",
		deal_stream:"deal",
		displayCategory:"displayCategory",
		displayCategory_aggregate:"displayCategory_aggregate",
		displayCategory_by_pk:"displayCategory",
		displayCategory_stream:"displayCategory",
		displayDimension:"displayDimension",
		displayDimension_aggregate:"displayDimension_aggregate",
		displayDimension_by_pk:"displayDimension",
		displayDimension_stream:"displayDimension",
		displayFormats:"displayFormats",
		displayFormats_aggregate:"displayFormats_aggregate",
		displayFormats_by_pk:"displayFormats",
		displayFormats_stream:"displayFormats",
		displayTemplate:"displayTemplate",
		displayTemplate_aggregate:"displayTemplate_aggregate",
		displayTemplate_by_pk:"displayTemplate",
		displayTemplate_stream:"displayTemplate",
		file:"files",
		files:"files",
		filesAggregate:"files_aggregate",
		files_stream:"files",
		generateNextMonthCalendar:"generateNextMonthCalendar",
		interaction:"interaction",
		interaction_aggregate:"interaction_aggregate",
		interaction_by_pk:"interaction",
		interaction_stream:"interaction",
		landingPageTemplate:"landingPageTemplate",
		landingPageTemplate_aggregate:"landingPageTemplate_aggregate",
		landingPageTemplate_by_pk:"landingPageTemplate",
		landingPageTemplate_stream:"landingPageTemplate",
		newTemplateType:"newTemplateType",
		newTemplateType_aggregate:"newTemplateType_aggregate",
		newTemplateType_by_pk:"newTemplateType",
		newTemplateType_stream:"newTemplateType",
		openAIApiKey:"openAIApiKey",
		openAIApiKey_aggregate:"openAIApiKey_aggregate",
		openAIApiKey_by_pk:"openAIApiKey",
		openAIApiKey_stream:"openAIApiKey",
		pTemplate:"pTemplate",
		pTemplate_aggregate:"pTemplate_aggregate",
		pTemplate_by_pk:"pTemplate",
		pTemplate_stream:"pTemplate",
		pricingPlan:"pricingPlan",
		pricingPlan_aggregate:"pricingPlan_aggregate",
		pricingPlan_by_pk:"pricingPlan",
		pricingPlan_stream:"pricingPlan",
		products:"products",
		products_aggregate:"products_aggregate",
		products_by_pk:"products",
		products_stream:"products",
		project:"project",
		projectImage:"projectImage",
		projectImage_aggregate:"projectImage_aggregate",
		projectImage_by_pk:"projectImage",
		projectImage_stream:"projectImage",
		project_aggregate:"project_aggregate",
		project_by_pk:"project",
		project_stream:"project",
		task:"task",
		task_aggregate:"task_aggregate",
		task_by_pk:"task",
		task_stream:"task",
		textTemplateDisplayFormat:"textTemplateDisplayFormat",
		textTemplateDisplayFormat_aggregate:"textTemplateDisplayFormat_aggregate",
		textTemplateDisplayFormat_by_pk:"textTemplateDisplayFormat",
		textTemplateDisplayFormat_stream:"textTemplateDisplayFormat",
		user:"users",
		userAppIntegration:"userAppIntegration",
		userAppIntegration_aggregate:"userAppIntegration_aggregate",
		userAppIntegration_by_pk:"userAppIntegration",
		userAppIntegration_stream:"userAppIntegration",
		userCreditUsage:"userCreditUsage",
		userCreditUsageSummary:"userCreditUsageSummary",
		userCreditUsageSummary_aggregate:"userCreditUsageSummary_aggregate",
		userCreditUsageSummary_by_pk:"userCreditUsageSummary",
		userCreditUsageSummary_stream:"userCreditUsageSummary",
		userCreditUsage_aggregate:"userCreditUsage_aggregate",
		userCreditUsage_by_pk:"userCreditUsage",
		userCreditUsage_stream:"userCreditUsage",
		userCredits:"userCredits",
		userCredits_aggregate:"userCredits_aggregate",
		userCredits_by_pk:"userCredits",
		userCredits_stream:"userCredits",
		userFeedback:"userFeedback",
		userFeedback_aggregate:"userFeedback_aggregate",
		userFeedback_by_pk:"userFeedback",
		userFeedback_stream:"userFeedback",
		userMedia:"userMedia",
		userMedia_aggregate:"userMedia_aggregate",
		userMedia_by_pk:"userMedia",
		userMedia_stream:"userMedia",
		userMetadata:"userMetadata",
		userMetadata_aggregate:"userMetadata_aggregate",
		userMetadata_by_pk:"userMetadata",
		userMetadata_stream:"userMetadata",
		userOnboarding:"userOnboarding",
		userOnboarding_aggregate:"userOnboarding_aggregate",
		userOnboarding_by_pk:"userOnboarding",
		userOnboarding_stream:"userOnboarding",
		userTemplateUsage:"userTemplateUsage",
		userTemplateUsage_aggregate:"userTemplateUsage_aggregate",
		userTemplateUsage_by_pk:"userTemplateUsage",
		userTemplateUsage_stream:"userTemplateUsage",
		users:"users",
		usersAggregate:"users_aggregate",
		users_stream:"users",
		virus:"virus",
		virus_stream:"virus",
		viruses:"virus",
		virusesAggregate:"virus_aggregate",
		website:"website",
		website_aggregate:"website_aggregate",
		website_by_pk:"website",
		website_stream:"website"
	},
	task:{
		category:"uuid",
		created_at:"timestamptz",
		created_by:"uuid",
		date:"timestamp",
		deal:"deal",
		dealId:"uuid",
		description:"String",
		finished:"Boolean",
		id:"uuid",
		title:"String",
		updated_at:"timestamptz",
		user:"users"
	},
	task_aggregate:{
		aggregate:"task_aggregate_fields",
		nodes:"task"
	},
	task_aggregate_fields:{
		count:"Int",
		max:"task_max_fields",
		min:"task_min_fields"
	},
	task_max_fields:{
		category:"uuid",
		created_at:"timestamptz",
		created_by:"uuid",
		date:"timestamp",
		dealId:"uuid",
		description:"String",
		id:"uuid",
		title:"String",
		updated_at:"timestamptz"
	},
	task_min_fields:{
		category:"uuid",
		created_at:"timestamptz",
		created_by:"uuid",
		date:"timestamp",
		dealId:"uuid",
		description:"String",
		id:"uuid",
		title:"String",
		updated_at:"timestamptz"
	},
	task_mutation_response:{
		affected_rows:"Int",
		returning:"task"
	},
	textTemplateDisplayFormat:{
		displayFormat:"displayFormats",
		displayFormatId:"uuid",
		id:"uuid",
		textTemplateId:"uuid"
	},
	textTemplateDisplayFormat_aggregate:{
		aggregate:"textTemplateDisplayFormat_aggregate_fields",
		nodes:"textTemplateDisplayFormat"
	},
	textTemplateDisplayFormat_aggregate_fields:{
		count:"Int",
		max:"textTemplateDisplayFormat_max_fields",
		min:"textTemplateDisplayFormat_min_fields"
	},
	textTemplateDisplayFormat_max_fields:{
		displayFormatId:"uuid",
		id:"uuid",
		textTemplateId:"uuid"
	},
	textTemplateDisplayFormat_min_fields:{
		displayFormatId:"uuid",
		id:"uuid",
		textTemplateId:"uuid"
	},
	textTemplateDisplayFormat_mutation_response:{
		affected_rows:"Int",
		returning:"textTemplateDisplayFormat"
	},
	timestamp: `scalar.timestamp` as const,
	timestamptz: `scalar.timestamptz` as const,
	upsertBrandOutput:{
		archtypeAdherence:"String",
		brandName:"String",
		colors:"String",
		fontPrimary:"String",
		fontSecondary:"String",
		id:"String",
		isBrand:"Boolean",
		logoFileId:"String",
		personality:"String",
		productOrService:"String",
		segment:"String",
		slogan:"String",
		voiceTone:"String"
	},
	userAppIntegration:{
		alias:"String",
		brandId:"uuid",
		created_at:"timestamptz",
		created_by:"uuid",
		deleted:"Boolean",
		id:"uuid",
		integrationType:"String",
		params:"jsonb",
		updated_at:"timestamptz"
	},
	userAppIntegration_aggregate:{
		aggregate:"userAppIntegration_aggregate_fields",
		nodes:"userAppIntegration"
	},
	userAppIntegration_aggregate_fields:{
		count:"Int",
		max:"userAppIntegration_max_fields",
		min:"userAppIntegration_min_fields"
	},
	userAppIntegration_max_fields:{
		alias:"String",
		brandId:"uuid",
		created_at:"timestamptz",
		created_by:"uuid",
		id:"uuid",
		integrationType:"String",
		updated_at:"timestamptz"
	},
	userAppIntegration_min_fields:{
		alias:"String",
		brandId:"uuid",
		created_at:"timestamptz",
		created_by:"uuid",
		id:"uuid",
		integrationType:"String",
		updated_at:"timestamptz"
	},
	userAppIntegration_mutation_response:{
		affected_rows:"Int",
		returning:"userAppIntegration"
	},
	userCreditUsage:{
		GeneratedPromptByTemplateId:"uuid",
		created_at:"timestamptz",
		created_by:"uuid",
		freeCreditUsed:"numeric",
		id:"uuid",
		originalValue:"numeric",
		totalCharged:"numeric"
	},
	userCreditUsageSummary:{
		created_at:"timestamptz",
		created_by:"uuid",
		freeCreditUsage:"numeric",
		id:"uuid",
		originalVaue:"numeric",
		totalCharged:"numeric",
		updated_at:"timestamptz",
		yearMonthDayReference:"String"
	},
	userCreditUsageSummary_aggregate:{
		aggregate:"userCreditUsageSummary_aggregate_fields",
		nodes:"userCreditUsageSummary"
	},
	userCreditUsageSummary_aggregate_fields:{
		avg:"userCreditUsageSummary_avg_fields",
		count:"Int",
		max:"userCreditUsageSummary_max_fields",
		min:"userCreditUsageSummary_min_fields",
		stddev:"userCreditUsageSummary_stddev_fields",
		stddev_pop:"userCreditUsageSummary_stddev_pop_fields",
		stddev_samp:"userCreditUsageSummary_stddev_samp_fields",
		sum:"userCreditUsageSummary_sum_fields",
		var_pop:"userCreditUsageSummary_var_pop_fields",
		var_samp:"userCreditUsageSummary_var_samp_fields",
		variance:"userCreditUsageSummary_variance_fields"
	},
	userCreditUsageSummary_avg_fields:{
		freeCreditUsage:"Float",
		originalVaue:"Float",
		totalCharged:"Float"
	},
	userCreditUsageSummary_max_fields:{
		created_at:"timestamptz",
		created_by:"uuid",
		freeCreditUsage:"numeric",
		id:"uuid",
		originalVaue:"numeric",
		totalCharged:"numeric",
		updated_at:"timestamptz",
		yearMonthDayReference:"String"
	},
	userCreditUsageSummary_min_fields:{
		created_at:"timestamptz",
		created_by:"uuid",
		freeCreditUsage:"numeric",
		id:"uuid",
		originalVaue:"numeric",
		totalCharged:"numeric",
		updated_at:"timestamptz",
		yearMonthDayReference:"String"
	},
	userCreditUsageSummary_mutation_response:{
		affected_rows:"Int",
		returning:"userCreditUsageSummary"
	},
	userCreditUsageSummary_stddev_fields:{
		freeCreditUsage:"Float",
		originalVaue:"Float",
		totalCharged:"Float"
	},
	userCreditUsageSummary_stddev_pop_fields:{
		freeCreditUsage:"Float",
		originalVaue:"Float",
		totalCharged:"Float"
	},
	userCreditUsageSummary_stddev_samp_fields:{
		freeCreditUsage:"Float",
		originalVaue:"Float",
		totalCharged:"Float"
	},
	userCreditUsageSummary_sum_fields:{
		freeCreditUsage:"numeric",
		originalVaue:"numeric",
		totalCharged:"numeric"
	},
	userCreditUsageSummary_var_pop_fields:{
		freeCreditUsage:"Float",
		originalVaue:"Float",
		totalCharged:"Float"
	},
	userCreditUsageSummary_var_samp_fields:{
		freeCreditUsage:"Float",
		originalVaue:"Float",
		totalCharged:"Float"
	},
	userCreditUsageSummary_variance_fields:{
		freeCreditUsage:"Float",
		originalVaue:"Float",
		totalCharged:"Float"
	},
	userCreditUsage_aggregate:{
		aggregate:"userCreditUsage_aggregate_fields",
		nodes:"userCreditUsage"
	},
	userCreditUsage_aggregate_fields:{
		avg:"userCreditUsage_avg_fields",
		count:"Int",
		max:"userCreditUsage_max_fields",
		min:"userCreditUsage_min_fields",
		stddev:"userCreditUsage_stddev_fields",
		stddev_pop:"userCreditUsage_stddev_pop_fields",
		stddev_samp:"userCreditUsage_stddev_samp_fields",
		sum:"userCreditUsage_sum_fields",
		var_pop:"userCreditUsage_var_pop_fields",
		var_samp:"userCreditUsage_var_samp_fields",
		variance:"userCreditUsage_variance_fields"
	},
	userCreditUsage_avg_fields:{
		freeCreditUsed:"Float",
		originalValue:"Float",
		totalCharged:"Float"
	},
	userCreditUsage_max_fields:{
		GeneratedPromptByTemplateId:"uuid",
		created_at:"timestamptz",
		created_by:"uuid",
		freeCreditUsed:"numeric",
		id:"uuid",
		originalValue:"numeric",
		totalCharged:"numeric"
	},
	userCreditUsage_min_fields:{
		GeneratedPromptByTemplateId:"uuid",
		created_at:"timestamptz",
		created_by:"uuid",
		freeCreditUsed:"numeric",
		id:"uuid",
		originalValue:"numeric",
		totalCharged:"numeric"
	},
	userCreditUsage_mutation_response:{
		affected_rows:"Int",
		returning:"userCreditUsage"
	},
	userCreditUsage_stddev_fields:{
		freeCreditUsed:"Float",
		originalValue:"Float",
		totalCharged:"Float"
	},
	userCreditUsage_stddev_pop_fields:{
		freeCreditUsed:"Float",
		originalValue:"Float",
		totalCharged:"Float"
	},
	userCreditUsage_stddev_samp_fields:{
		freeCreditUsed:"Float",
		originalValue:"Float",
		totalCharged:"Float"
	},
	userCreditUsage_sum_fields:{
		freeCreditUsed:"numeric",
		originalValue:"numeric",
		totalCharged:"numeric"
	},
	userCreditUsage_var_pop_fields:{
		freeCreditUsed:"Float",
		originalValue:"Float",
		totalCharged:"Float"
	},
	userCreditUsage_var_samp_fields:{
		freeCreditUsed:"Float",
		originalValue:"Float",
		totalCharged:"Float"
	},
	userCreditUsage_variance_fields:{
		freeCreditUsed:"Float",
		originalValue:"Float",
		totalCharged:"Float"
	},
	userCredits:{
		created_at:"timestamptz",
		id:"uuid",
		quantity:"numeric",
		reason:"String",
		userId:"uuid"
	},
	userCredits_aggregate:{
		aggregate:"userCredits_aggregate_fields",
		nodes:"userCredits"
	},
	userCredits_aggregate_fields:{
		avg:"userCredits_avg_fields",
		count:"Int",
		max:"userCredits_max_fields",
		min:"userCredits_min_fields",
		stddev:"userCredits_stddev_fields",
		stddev_pop:"userCredits_stddev_pop_fields",
		stddev_samp:"userCredits_stddev_samp_fields",
		sum:"userCredits_sum_fields",
		var_pop:"userCredits_var_pop_fields",
		var_samp:"userCredits_var_samp_fields",
		variance:"userCredits_variance_fields"
	},
	userCredits_avg_fields:{
		quantity:"Float"
	},
	userCredits_max_fields:{
		created_at:"timestamptz",
		id:"uuid",
		quantity:"numeric",
		reason:"String",
		userId:"uuid"
	},
	userCredits_min_fields:{
		created_at:"timestamptz",
		id:"uuid",
		quantity:"numeric",
		reason:"String",
		userId:"uuid"
	},
	userCredits_mutation_response:{
		affected_rows:"Int",
		returning:"userCredits"
	},
	userCredits_stddev_fields:{
		quantity:"Float"
	},
	userCredits_stddev_pop_fields:{
		quantity:"Float"
	},
	userCredits_stddev_samp_fields:{
		quantity:"Float"
	},
	userCredits_sum_fields:{
		quantity:"numeric"
	},
	userCredits_var_pop_fields:{
		quantity:"Float"
	},
	userCredits_var_samp_fields:{
		quantity:"Float"
	},
	userCredits_variance_fields:{
		quantity:"Float"
	},
	userFeedback:{
		createdBy:"uuid",
		created_at:"timestamptz",
		feedbackType:"String",
		id:"uuid",
		message:"String",
		replied_at:"timestamptz"
	},
	userFeedback_aggregate:{
		aggregate:"userFeedback_aggregate_fields",
		nodes:"userFeedback"
	},
	userFeedback_aggregate_fields:{
		count:"Int",
		max:"userFeedback_max_fields",
		min:"userFeedback_min_fields"
	},
	userFeedback_max_fields:{
		createdBy:"uuid",
		created_at:"timestamptz",
		feedbackType:"String",
		id:"uuid",
		message:"String",
		replied_at:"timestamptz"
	},
	userFeedback_min_fields:{
		createdBy:"uuid",
		created_at:"timestamptz",
		feedbackType:"String",
		id:"uuid",
		message:"String",
		replied_at:"timestamptz"
	},
	userFeedback_mutation_response:{
		affected_rows:"Int",
		returning:"userFeedback"
	},
	userMedia:{
		aiParameters:"jsonb",
		created_at:"timestamptz",
		deleted:"Boolean",
		fileId:"uuid",
		id:"uuid",
		mediaType:"String",
		ownerId:"uuid",
		updated_at:"timestamptz",
		videoFileId:"uuid"
	},
	userMedia_aggregate:{
		aggregate:"userMedia_aggregate_fields",
		nodes:"userMedia"
	},
	userMedia_aggregate_fields:{
		count:"Int",
		max:"userMedia_max_fields",
		min:"userMedia_min_fields"
	},
	userMedia_max_fields:{
		created_at:"timestamptz",
		fileId:"uuid",
		id:"uuid",
		mediaType:"String",
		ownerId:"uuid",
		updated_at:"timestamptz",
		videoFileId:"uuid"
	},
	userMedia_min_fields:{
		created_at:"timestamptz",
		fileId:"uuid",
		id:"uuid",
		mediaType:"String",
		ownerId:"uuid",
		updated_at:"timestamptz",
		videoFileId:"uuid"
	},
	userMedia_mutation_response:{
		affected_rows:"Int",
		returning:"userMedia"
	},
	userMetadata:{
		created_at:"timestamptz",
		creditBalance:"numeric",
		id:"uuid",
		lastCreditRefill:"date",
		last_seen:"timestamp",
		onboardCompleted:"Boolean",
		pricingPlan:"pricingPlan",
		stripeCustomerId:"String",
		stripePriceId:"String",
		stripeProductId:"String",
		stripeSubscriptionId:"String",
		stripeSubscriptionItem:"String",
		subscriptionType:"String",
		updated_at:"timestamptz",
		user:"users"
	},
	userMetadata_aggregate:{
		aggregate:"userMetadata_aggregate_fields",
		nodes:"userMetadata"
	},
	userMetadata_aggregate_fields:{
		avg:"userMetadata_avg_fields",
		count:"Int",
		max:"userMetadata_max_fields",
		min:"userMetadata_min_fields",
		stddev:"userMetadata_stddev_fields",
		stddev_pop:"userMetadata_stddev_pop_fields",
		stddev_samp:"userMetadata_stddev_samp_fields",
		sum:"userMetadata_sum_fields",
		var_pop:"userMetadata_var_pop_fields",
		var_samp:"userMetadata_var_samp_fields",
		variance:"userMetadata_variance_fields"
	},
	userMetadata_avg_fields:{
		creditBalance:"Float"
	},
	userMetadata_max_fields:{
		created_at:"timestamptz",
		creditBalance:"numeric",
		id:"uuid",
		lastCreditRefill:"date",
		last_seen:"timestamp",
		stripeCustomerId:"String",
		stripePriceId:"String",
		stripeProductId:"String",
		stripeSubscriptionId:"String",
		stripeSubscriptionItem:"String",
		subscriptionType:"String",
		updated_at:"timestamptz"
	},
	userMetadata_min_fields:{
		created_at:"timestamptz",
		creditBalance:"numeric",
		id:"uuid",
		lastCreditRefill:"date",
		last_seen:"timestamp",
		stripeCustomerId:"String",
		stripePriceId:"String",
		stripeProductId:"String",
		stripeSubscriptionId:"String",
		stripeSubscriptionItem:"String",
		subscriptionType:"String",
		updated_at:"timestamptz"
	},
	userMetadata_mutation_response:{
		affected_rows:"Int",
		returning:"userMetadata"
	},
	userMetadata_stddev_fields:{
		creditBalance:"Float"
	},
	userMetadata_stddev_pop_fields:{
		creditBalance:"Float"
	},
	userMetadata_stddev_samp_fields:{
		creditBalance:"Float"
	},
	userMetadata_sum_fields:{
		creditBalance:"numeric"
	},
	userMetadata_var_pop_fields:{
		creditBalance:"Float"
	},
	userMetadata_var_samp_fields:{
		creditBalance:"Float"
	},
	userMetadata_variance_fields:{
		creditBalance:"Float"
	},
	userOnboarding:{
		behaviorProfile:"String",
		brandName:"String",
		clientPreference:"String",
		companySegment:"String",
		currentStep:"Int",
		done:"Boolean",
		expectation:"String",
		importantSocialNetworkForBusiness:"String",
		investInPaidChannels:"String",
		marketingObjective:"String",
		name:"String",
		networkingOnCopyMagico:"String",
		phone:"String",
		productOrService:"String",
		purchaseBehavior:"String",
		purchaseChallenges:"String",
		purchaseInfluencers:"String",
		sellOnCopyMagico:"String",
		siteUrl:"String",
		socioeconomicProfile:"String",
		staffCount:"String",
		userId:"uuid"
	},
	userOnboarding_aggregate:{
		aggregate:"userOnboarding_aggregate_fields",
		nodes:"userOnboarding"
	},
	userOnboarding_aggregate_fields:{
		avg:"userOnboarding_avg_fields",
		count:"Int",
		max:"userOnboarding_max_fields",
		min:"userOnboarding_min_fields",
		stddev:"userOnboarding_stddev_fields",
		stddev_pop:"userOnboarding_stddev_pop_fields",
		stddev_samp:"userOnboarding_stddev_samp_fields",
		sum:"userOnboarding_sum_fields",
		var_pop:"userOnboarding_var_pop_fields",
		var_samp:"userOnboarding_var_samp_fields",
		variance:"userOnboarding_variance_fields"
	},
	userOnboarding_avg_fields:{
		currentStep:"Float"
	},
	userOnboarding_max_fields:{
		behaviorProfile:"String",
		brandName:"String",
		clientPreference:"String",
		companySegment:"String",
		currentStep:"Int",
		expectation:"String",
		importantSocialNetworkForBusiness:"String",
		investInPaidChannels:"String",
		marketingObjective:"String",
		name:"String",
		networkingOnCopyMagico:"String",
		phone:"String",
		productOrService:"String",
		purchaseBehavior:"String",
		purchaseChallenges:"String",
		purchaseInfluencers:"String",
		sellOnCopyMagico:"String",
		siteUrl:"String",
		socioeconomicProfile:"String",
		staffCount:"String",
		userId:"uuid"
	},
	userOnboarding_min_fields:{
		behaviorProfile:"String",
		brandName:"String",
		clientPreference:"String",
		companySegment:"String",
		currentStep:"Int",
		expectation:"String",
		importantSocialNetworkForBusiness:"String",
		investInPaidChannels:"String",
		marketingObjective:"String",
		name:"String",
		networkingOnCopyMagico:"String",
		phone:"String",
		productOrService:"String",
		purchaseBehavior:"String",
		purchaseChallenges:"String",
		purchaseInfluencers:"String",
		sellOnCopyMagico:"String",
		siteUrl:"String",
		socioeconomicProfile:"String",
		staffCount:"String",
		userId:"uuid"
	},
	userOnboarding_mutation_response:{
		affected_rows:"Int",
		returning:"userOnboarding"
	},
	userOnboarding_stddev_fields:{
		currentStep:"Float"
	},
	userOnboarding_stddev_pop_fields:{
		currentStep:"Float"
	},
	userOnboarding_stddev_samp_fields:{
		currentStep:"Float"
	},
	userOnboarding_sum_fields:{
		currentStep:"Int"
	},
	userOnboarding_var_pop_fields:{
		currentStep:"Float"
	},
	userOnboarding_var_samp_fields:{
		currentStep:"Float"
	},
	userOnboarding_variance_fields:{
		currentStep:"Float"
	},
	userTemplateUsage:{
		created_at:"timestamptz",
		created_by:"uuid",
		id:"uuid",
		textPromptTemplateId:"uuid"
	},
	userTemplateUsage_aggregate:{
		aggregate:"userTemplateUsage_aggregate_fields",
		nodes:"userTemplateUsage"
	},
	userTemplateUsage_aggregate_fields:{
		count:"Int",
		max:"userTemplateUsage_max_fields",
		min:"userTemplateUsage_min_fields"
	},
	userTemplateUsage_max_fields:{
		created_at:"timestamptz",
		created_by:"uuid",
		id:"uuid",
		textPromptTemplateId:"uuid"
	},
	userTemplateUsage_min_fields:{
		created_at:"timestamptz",
		created_by:"uuid",
		id:"uuid",
		textPromptTemplateId:"uuid"
	},
	userTemplateUsage_mutation_response:{
		affected_rows:"Int",
		returning:"userTemplateUsage"
	},
	users:{
		activeMfaType:"String",
		avatarUrl:"String",
		createdAt:"timestamptz",
		currentChallenge:"String",
		defaultRole:"String",
		defaultRoleByRole:"authRoles",
		disabled:"Boolean",
		displayName:"String",
		email:"citext",
		emailVerified:"Boolean",
		id:"uuid",
		isAnonymous:"Boolean",
		lastSeen:"timestamptz",
		locale:"String",
		metadata:"jsonb",
		newEmail:"citext",
		otpHash:"String",
		otpHashExpiresAt:"timestamptz",
		otpMethodLastUsed:"String",
		passwordHash:"String",
		phoneNumber:"String",
		phoneNumberVerified:"Boolean",
		refreshTokens:"authRefreshTokens",
		refreshTokens_aggregate:"authRefreshTokens_aggregate",
		roles:"authUserRoles",
		roles_aggregate:"authUserRoles_aggregate",
		securityKeys:"authUserSecurityKeys",
		securityKeys_aggregate:"authUserSecurityKeys_aggregate",
		ticket:"String",
		ticketExpiresAt:"timestamptz",
		totpSecret:"String",
		updatedAt:"timestamptz",
		userMetadata:"userMetadata",
		userMetadata_aggregate:"userMetadata_aggregate",
		userProviders:"authUserProviders",
		userProviders_aggregate:"authUserProviders_aggregate"
	},
	users_aggregate:{
		aggregate:"users_aggregate_fields",
		nodes:"users"
	},
	users_aggregate_fields:{
		count:"Int",
		max:"users_max_fields",
		min:"users_min_fields"
	},
	users_max_fields:{
		activeMfaType:"String",
		avatarUrl:"String",
		createdAt:"timestamptz",
		currentChallenge:"String",
		defaultRole:"String",
		displayName:"String",
		email:"citext",
		id:"uuid",
		lastSeen:"timestamptz",
		locale:"String",
		newEmail:"citext",
		otpHash:"String",
		otpHashExpiresAt:"timestamptz",
		otpMethodLastUsed:"String",
		passwordHash:"String",
		phoneNumber:"String",
		ticket:"String",
		ticketExpiresAt:"timestamptz",
		totpSecret:"String",
		updatedAt:"timestamptz"
	},
	users_min_fields:{
		activeMfaType:"String",
		avatarUrl:"String",
		createdAt:"timestamptz",
		currentChallenge:"String",
		defaultRole:"String",
		displayName:"String",
		email:"citext",
		id:"uuid",
		lastSeen:"timestamptz",
		locale:"String",
		newEmail:"citext",
		otpHash:"String",
		otpHashExpiresAt:"timestamptz",
		otpMethodLastUsed:"String",
		passwordHash:"String",
		phoneNumber:"String",
		ticket:"String",
		ticketExpiresAt:"timestamptz",
		totpSecret:"String",
		updatedAt:"timestamptz"
	},
	users_mutation_response:{
		affected_rows:"Int",
		returning:"users"
	},
	uuid: `scalar.uuid` as const,
	virus:{
		createdAt:"timestamptz",
		file:"files",
		fileId:"uuid",
		filename:"String",
		id:"uuid",
		updatedAt:"timestamptz",
		userSession:"jsonb",
		virus:"String"
	},
	virus_aggregate:{
		aggregate:"virus_aggregate_fields",
		nodes:"virus"
	},
	virus_aggregate_fields:{
		count:"Int",
		max:"virus_max_fields",
		min:"virus_min_fields"
	},
	virus_max_fields:{
		createdAt:"timestamptz",
		fileId:"uuid",
		filename:"String",
		id:"uuid",
		updatedAt:"timestamptz",
		virus:"String"
	},
	virus_min_fields:{
		createdAt:"timestamptz",
		fileId:"uuid",
		filename:"String",
		id:"uuid",
		updatedAt:"timestamptz",
		virus:"String"
	},
	virus_mutation_response:{
		affected_rows:"Int",
		returning:"virus"
	},
	website:{
		aboutUsContactBannerImageFileId:"uuid",
		aboutUsContactTitle:"String",
		aboutUsHistoryBannerImageFileId:"uuid",
		aboutUsHistoryDescription:"String",
		aboutUsHistoryTitle:"String",
		aboutUsHistorytKeypointSubtitleOne:"String",
		aboutUsHistorytKeypointSubtitleTwo:"String",
		aboutUsHistorytKeypointTitleOne:"String",
		aboutUsHistorytKeypointTitleTwo:"String",
		aboutUsStatementBannerImageFileId:"uuid",
		aboutUsStatementBannerText:"String",
		aboutUsWeAreReadyBannerImageId:"uuid",
		aboutUsWeAreReadyCustomerSatisfactionDescription:"String",
		aboutUsWeAreReadyInnovationDescription:"String",
		aboutUsWeAreReadySocialResponsibilityDescription:"String",
		aboutUsWeAreReadySpecializedSupportDescription:"String",
		aboutUsWeAreReadySubjectMatterExpertiseDescription:"String",
		aboutUsWeAreReadyTitle:"String",
		aboutUsWeAreReadyTransparencyDescription:"String",
		aboutUsWhatMoveBannerImageFileId:"uuid",
		aboutUsWhatMoveUsMission:"String",
		aboutUsWhatMoveUsPurpose:"String",
		aboutUsWhatMoveUsTitle:"String",
		aboutUsWhatMoveUsValues:"String",
		aboutUsWhatMoveUsVision:"String",
		accentColor:"String",
		backgroundColor:"String",
		brand:"brands",
		brandId:"uuid",
		created_at:"timestamptz",
		domain:"String",
		foregroundColor:"String",
		googleAnalyticsKey:"String",
		googleTagManagerKey:"String",
		homeBrandHighlightBannerImageFileId:"uuid",
		homeBrandHighlightDescription:"String",
		homeBrandHighlightKeypointSubtitleOne:"String",
		homeBrandHighlightKeypointSubtitleTwo:"String",
		homeBrandHighlightKeypointTitleOne:"String",
		homeBrandHighlightKeypointTitleTwo:"String",
		homeBrandHighlightKeypointValuePropositionList:"String",
		homeBrandHighlightTitle:"String",
		homeHeroBannerImageFileId:"uuid",
		homeHeroCallToActionTargetUrl:"String",
		homeHeroCallToActionText:"String",
		homeHeroDescription:"String",
		homeHeroLabel:"String",
		homeHeroTitle:"String",
		homeOurDifferentiatorsBannerImageFileId:"uuid",
		homeOurDifferentiatorsDescription:"String",
		homeOurDifferentiatorsKeypointSubtitleOne:"String",
		homeOurDifferentiatorsKeypointSubtitleTwo:"String",
		homeOurDifferentiatorsKeypointTitleOne:"String",
		homeOurDifferentiatorsKeypointTitleTwo:"String",
		homeOurDifferentiatorsTitle:"String",
		homeWhatWeDoCallToActionTargetUrl:"String",
		homeWhatWeDoCallToActionText:"String",
		homeWhatWeDoCallToBannerOneImageFileId:"uuid",
		homeWhatWeDoCallToBannerOneLegend:"String",
		homeWhatWeDoCallToBannerThreeImageFileId:"uuid",
		homeWhatWeDoCallToBannerThreeLegend:"String",
		homeWhatWeDoCallToBannerTwoImageFileId:"uuid",
		homeWhatWeDoCallToBannerTwoLegend:"String",
		homeWhatWeDoDescription:"String",
		homeWhatWeDoTitle:"String",
		id:"uuid",
		openGraphDescription:"String",
		updated_at:"timestamptz"
	},
	website_aggregate:{
		aggregate:"website_aggregate_fields",
		nodes:"website"
	},
	website_aggregate_fields:{
		count:"Int",
		max:"website_max_fields",
		min:"website_min_fields"
	},
	website_max_fields:{
		aboutUsContactBannerImageFileId:"uuid",
		aboutUsContactTitle:"String",
		aboutUsHistoryBannerImageFileId:"uuid",
		aboutUsHistoryDescription:"String",
		aboutUsHistoryTitle:"String",
		aboutUsHistorytKeypointSubtitleOne:"String",
		aboutUsHistorytKeypointSubtitleTwo:"String",
		aboutUsHistorytKeypointTitleOne:"String",
		aboutUsHistorytKeypointTitleTwo:"String",
		aboutUsStatementBannerImageFileId:"uuid",
		aboutUsStatementBannerText:"String",
		aboutUsWeAreReadyBannerImageId:"uuid",
		aboutUsWeAreReadyCustomerSatisfactionDescription:"String",
		aboutUsWeAreReadyInnovationDescription:"String",
		aboutUsWeAreReadySocialResponsibilityDescription:"String",
		aboutUsWeAreReadySpecializedSupportDescription:"String",
		aboutUsWeAreReadySubjectMatterExpertiseDescription:"String",
		aboutUsWeAreReadyTitle:"String",
		aboutUsWeAreReadyTransparencyDescription:"String",
		aboutUsWhatMoveBannerImageFileId:"uuid",
		aboutUsWhatMoveUsMission:"String",
		aboutUsWhatMoveUsPurpose:"String",
		aboutUsWhatMoveUsTitle:"String",
		aboutUsWhatMoveUsValues:"String",
		aboutUsWhatMoveUsVision:"String",
		accentColor:"String",
		backgroundColor:"String",
		brandId:"uuid",
		created_at:"timestamptz",
		domain:"String",
		foregroundColor:"String",
		googleAnalyticsKey:"String",
		googleTagManagerKey:"String",
		homeBrandHighlightBannerImageFileId:"uuid",
		homeBrandHighlightDescription:"String",
		homeBrandHighlightKeypointSubtitleOne:"String",
		homeBrandHighlightKeypointSubtitleTwo:"String",
		homeBrandHighlightKeypointTitleOne:"String",
		homeBrandHighlightKeypointTitleTwo:"String",
		homeBrandHighlightKeypointValuePropositionList:"String",
		homeBrandHighlightTitle:"String",
		homeHeroBannerImageFileId:"uuid",
		homeHeroCallToActionTargetUrl:"String",
		homeHeroCallToActionText:"String",
		homeHeroDescription:"String",
		homeHeroLabel:"String",
		homeHeroTitle:"String",
		homeOurDifferentiatorsBannerImageFileId:"uuid",
		homeOurDifferentiatorsDescription:"String",
		homeOurDifferentiatorsKeypointSubtitleOne:"String",
		homeOurDifferentiatorsKeypointSubtitleTwo:"String",
		homeOurDifferentiatorsKeypointTitleOne:"String",
		homeOurDifferentiatorsKeypointTitleTwo:"String",
		homeOurDifferentiatorsTitle:"String",
		homeWhatWeDoCallToActionTargetUrl:"String",
		homeWhatWeDoCallToActionText:"String",
		homeWhatWeDoCallToBannerOneImageFileId:"uuid",
		homeWhatWeDoCallToBannerOneLegend:"String",
		homeWhatWeDoCallToBannerThreeImageFileId:"uuid",
		homeWhatWeDoCallToBannerThreeLegend:"String",
		homeWhatWeDoCallToBannerTwoImageFileId:"uuid",
		homeWhatWeDoCallToBannerTwoLegend:"String",
		homeWhatWeDoDescription:"String",
		homeWhatWeDoTitle:"String",
		id:"uuid",
		openGraphDescription:"String",
		updated_at:"timestamptz"
	},
	website_min_fields:{
		aboutUsContactBannerImageFileId:"uuid",
		aboutUsContactTitle:"String",
		aboutUsHistoryBannerImageFileId:"uuid",
		aboutUsHistoryDescription:"String",
		aboutUsHistoryTitle:"String",
		aboutUsHistorytKeypointSubtitleOne:"String",
		aboutUsHistorytKeypointSubtitleTwo:"String",
		aboutUsHistorytKeypointTitleOne:"String",
		aboutUsHistorytKeypointTitleTwo:"String",
		aboutUsStatementBannerImageFileId:"uuid",
		aboutUsStatementBannerText:"String",
		aboutUsWeAreReadyBannerImageId:"uuid",
		aboutUsWeAreReadyCustomerSatisfactionDescription:"String",
		aboutUsWeAreReadyInnovationDescription:"String",
		aboutUsWeAreReadySocialResponsibilityDescription:"String",
		aboutUsWeAreReadySpecializedSupportDescription:"String",
		aboutUsWeAreReadySubjectMatterExpertiseDescription:"String",
		aboutUsWeAreReadyTitle:"String",
		aboutUsWeAreReadyTransparencyDescription:"String",
		aboutUsWhatMoveBannerImageFileId:"uuid",
		aboutUsWhatMoveUsMission:"String",
		aboutUsWhatMoveUsPurpose:"String",
		aboutUsWhatMoveUsTitle:"String",
		aboutUsWhatMoveUsValues:"String",
		aboutUsWhatMoveUsVision:"String",
		accentColor:"String",
		backgroundColor:"String",
		brandId:"uuid",
		created_at:"timestamptz",
		domain:"String",
		foregroundColor:"String",
		googleAnalyticsKey:"String",
		googleTagManagerKey:"String",
		homeBrandHighlightBannerImageFileId:"uuid",
		homeBrandHighlightDescription:"String",
		homeBrandHighlightKeypointSubtitleOne:"String",
		homeBrandHighlightKeypointSubtitleTwo:"String",
		homeBrandHighlightKeypointTitleOne:"String",
		homeBrandHighlightKeypointTitleTwo:"String",
		homeBrandHighlightKeypointValuePropositionList:"String",
		homeBrandHighlightTitle:"String",
		homeHeroBannerImageFileId:"uuid",
		homeHeroCallToActionTargetUrl:"String",
		homeHeroCallToActionText:"String",
		homeHeroDescription:"String",
		homeHeroLabel:"String",
		homeHeroTitle:"String",
		homeOurDifferentiatorsBannerImageFileId:"uuid",
		homeOurDifferentiatorsDescription:"String",
		homeOurDifferentiatorsKeypointSubtitleOne:"String",
		homeOurDifferentiatorsKeypointSubtitleTwo:"String",
		homeOurDifferentiatorsKeypointTitleOne:"String",
		homeOurDifferentiatorsKeypointTitleTwo:"String",
		homeOurDifferentiatorsTitle:"String",
		homeWhatWeDoCallToActionTargetUrl:"String",
		homeWhatWeDoCallToActionText:"String",
		homeWhatWeDoCallToBannerOneImageFileId:"uuid",
		homeWhatWeDoCallToBannerOneLegend:"String",
		homeWhatWeDoCallToBannerThreeImageFileId:"uuid",
		homeWhatWeDoCallToBannerThreeLegend:"String",
		homeWhatWeDoCallToBannerTwoImageFileId:"uuid",
		homeWhatWeDoCallToBannerTwoLegend:"String",
		homeWhatWeDoDescription:"String",
		homeWhatWeDoTitle:"String",
		id:"uuid",
		openGraphDescription:"String",
		updated_at:"timestamptz"
	},
	website_mutation_response:{
		affected_rows:"Int",
		returning:"website"
	}
}

export const Ops = {
query: "query_root" as const,
	mutation: "mutation_root" as const,
	subscription: "subscription_root" as const
}