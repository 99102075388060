import { BindingMode, bindable } from 'aurelia';
import { IAuthService } from '@interfaces/auth-service/auth-service-interface';
import { ICreateProjectService } from '@components/create-project-dialog/services/create-project-service';

interface IMediaProps {
  id: string;
  url: string;
}

export class MediaComponent {
  @bindable({ mode: BindingMode.twoWay }) selectedImage: string;

  private selectedImageId: string;
  private userMedia: IMediaProps[] = [];

  constructor(
    @IAuthService private auth: IAuthService,
    @ICreateProjectService private service: ICreateProjectService
  ) { }

  attached() {
    this.getMedia();
  }

  private async getMedia(): Promise<void> {
    const medias = await this.service.getMedia();

    for (const media of medias) {
      const imageUrl = await this.getImageUrl(media.fileId);

      this.userMedia.push({
        id: media.id,
        url: imageUrl
      });
    }
  }

  private async getImageUrl(fileId: string): Promise<string> {
    if (!fileId) return;

    // const { presignedUrl, error } = await nhost.storage.getPresignedUrl({
    //   fileId: fileId,
    //   width: 200,
    // })

    // if (error) {
    //   console.error('Erro set image', error)
    //   return;
    // }

    // return presignedUrl.url

    return `https://uhgcmhbgmvuhtnaryxwr.storage.sa-east-1.nhost.run/v1/files/${fileId}`
  }

  private selectMedia(id: string) {
    this.selectedImage = this.userMedia.find(i => i.id === id).url;
    this.selectedImageId = id;
  }
}