<import from='@components/undraw/undraw-component' />
<import from='@components/card-header/card-header-component' />
<import from="@components/spinner-button/spinner-button-component" />
<import from="./components/background-image/background-image-component" />

<div class="bg-white rounded-lg shadow dark:bg-gray-700 max-h-[calc(100vh-5rem)] h-full w-[calc(100vw-5rem)]">

  <!-- begin::header -->
  <div class="flex items-start justify-between p-4 border-b rounded-t dark:border-gray-600">

    <div class="flex flex-col">
      <h3 class="text-xl font-semibold text-gray-900 dark:text-white">Gerar projeto</h3>
      <p class="text-sm font-normal text-gray-700 dark:text-gray-400">As combinações de cores poderão ser escolhidas no momento de criação das artes</p>
    </div>

    <button type="button" click.trigger="$dialog.cancel()" class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-hide="defaultModal">
      <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
      </svg>
      <span class="sr-only">Close modal</span>
    </button>
  </div>
  <!-- end::header -->

  <!-- begin::body -->
  <div class="flex flex-grow h-[calc(100vh-15rem)] overflow-hidden">

    <!-- begin:: -->
    <div class="w-[30%] border-r border-gray-200 dark:border-gray-600 overflow-auto scrollbar">
      <card-header-component header-id="modalGenerateProjectFirstSession"></card-header-component>

      <div class="flex flex-col gap-2 p-4 h-full">

        <!-- beggin:brand -->
        <div class="">
          <label for="selectedBrandId" class="form-label">Selecione marca</label>
          <validation-container>
            <select id="selectedBrandId" value.bind="selectedBrandId & validate" class="form-input" disabled>
              <option model.bind="null">Selecione</option>
              <option repeat.for="item of brandsData" model.bind="item.id">${item.brandName}</option>
            </select>
          </validation-container>
        </div>
        <!-- end:brand -->

        <!-- beggin:audience -->
        <!-- <div class="">
          <label for="selectedAudienceId" class="form-label">Selecione a audiência</label>
          <validation-container>
            <select id="selectedAudienceId" value.bind="selectedAudienceId & validate" class="form-input">
              <option model.bind="null">Selecione</option>
              <option repeat.for="item of audiencesData" model.bind="item.id">${item.brandName}</option>
            </select>
          </validation-container>
        </div> -->
        <!-- end:audience -->

        <!-- beggin:product -->
        <div class="">
          <label for="selectedProductId" class="form-label">Selecione um produto</label>
          <validation-container>
            <select id="selectedProductId" value.bind="selectedProductId & validate" class="form-input">
              <option model.bind="null">Selecione</option>
              <option repeat.for="item of productsData" model.bind="item.id">${item.alias}</option>
            </select>
          </validation-container>
        </div>
        <!-- end:product -->

        <!-- beggin:DisplayDimension -->
        <div class="">
          <label for="selectedDisplayDimensionId" class="form-label">Selecione um formato</label>
          <validation-container>
            <select id="selectedDisplayDimensionId" value.bind="selectedDisplayDimensionId & validate" class="form-input">
              <option model.bind="null">Selecione</option>
              <option repeat.for="item of displayDimensionData" model.bind="item.id">${item.alias}</option>
            </select>
          </validation-container>
        </div>
        <!-- end:DisplayDimension -->

        <!-- beggin::flexText -->
        <div class="">
          <label for="flexText" class="form-label">Direcional de criação</label>
          <validation-container>
            <textarea type="text" id="flexText" value.bind="flexText & validate" rows="4" autocomplete="off" class="form-input" placeholder="Exemplo: Crie uma legenda para uma campanha promocional sobre o nosso serviço de consultoria digital"></textarea>
          </validation-container>
        </div>
        <!-- end::flexText -->

      </div>
    </div>
    <!-- end:: -->

    <!-- begin:: -->
    <div class="w-[35%] border-r border-gray-200 dark:border-gray-600 overflow-auto scrollbar">
      <card-header-component header-id="modalGenerateProjectSecondSession"></card-header-component>

      <template promise.bind="getAvailableDisplays(selectedDisplayDimensionId)">
        <template pending>
          <div class="flex flex-col justify-center items-center p-4">
            <div role="status">
              <svg aria-hidden="true" class="w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
              </svg>
            </div>
            <span class="text-sm font-semibold text-gray-400 dark:text-gray-400 pt-2">Loading...</span>
          </div>
        </template>

        <template then.from-view="availableDisplays">
          <div class="flex flex-row gap-4 p-4">
            <ul class="grid w-full gap-6 md:grid-cols-2">
              <li repeat.for="availableDisplay of availableDisplays">
                <validation-container>
                  <input type="radio" id="${availableDisplay.id}" name="displayDimension" id="${availableDisplay.id}" model.bind="availableDisplay.id" checked.bind="selectedTemplateId & validate" class="hidden peer" required>

                  <label for="${availableDisplay.id}" class="inline-flex flex-col gap-2 items-center justify-center w-full p-1 text-gray-500 bg-white border border-gray-200 rounded-lg cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 peer-checked:border-4 peer-checked:border-orange-500 peer-checked:bg-orange-100 hover:text-gray-600 hover:bg-gray-100 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700">

                    <img src="https://uhgcmhbgmvuhtnaryxwr.storage.sa-east-1.nhost.run/v1/files/${availableDisplay.previewFileId}?w=200" class="rounded-lg">
                  </label>
                </validation-container>
              </li>
            </ul>
          </div>

        </template>
      </template>
    </div>
    <!-- end:: -->

    <!-- begin:: -->
    <div class="w-[35%] overflow-auto scrollbar">
      <card-header-component header-id="modalGenerateProjectThirdSession"></card-header-component>
      <!-- selecionar uma unica imagem: produtos, pexels ou ia -->
      <template if.bind="selectedTemplateId">
        <background-image-component selected-image.bind="selectedImage" display-dimension-id.bind="selectedDisplayDimensionId" product-id.bind="selectedProductId" current-image-tab-index.bind="currentImageTabIndex"></background-image-component>
      </template>
    </div>
    <!-- end:: -->

  </div>
  <!-- end::body -->

  <!-- begin::footer -->
  <div class="flex items-center p-4 justify-end space-x-2 border-t border-gray-200 rounded-b dark:border-gray-600">
    <button type="button" click.trigger="close()" class="btn-primary-small">Fechar</button>
    <spinner-button-component label="Gerar" btn-class="btn-success-small" trigger-function.bind="() => handleGenerateProject()"></spinner-button-component>
  </div>
  <!-- end::footer -->

</div>